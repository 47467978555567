import * as signalR from "@microsoft/signalr";
import { Guid } from '@/lib';
import { ITimeSegmentDto, ISunriseDto } from '@/io/dto/infrastructure.dto.ns';
import { WorkspaceAppContextController } from '@/controllers/context/workspace.appContext.controller';
import { Sunrise } from "@/logic/_infra/time";


export default function WorkspaceSignalrDispatcher(connection: signalR.HubConnection, controller: WorkspaceAppContextController) {


    connection.on("workspace", (dto) => { controller.workspaceSetup(dto) });

    connection.on("disposition", (wid, instruction) => controller.dispositionUpdate(wid, instruction));

    connection.on("disposition_postback", (wid, instruction) => {

        console.log(`Postback:${wid} - ${instruction?.length}`)
        controller.dispositionUpdate(wid, instruction)
    }
    );

    connection.on("identificator", (wid, uid, n) => controller.identificator(wid, uid, n));

    //'event participation record'
    //connection.on("event", (wid, nid, dto) => controller.event(wid, nid, dto));

    connection.on("person_strictness", (wid, nid, strict) => controller.personStrictnessUpdate(wid, nid, strict));

    // connection.on("person_endeavours", (wid: Guid, nid: Guid, sunrise: ITimeSegmentDto, values: ITimeSegmentDto[], offset: number) => {

    //     controller.personEndeavours(wid, nid, sunrise, values, offset)
    // });

    connection.on("person_calendar_adjustment", (wid: Guid, nid: Guid, sunrise: ISunriseDto, value: number | null, tag: string | null) => {

        

        controller.personCalendarAdjustmentUpdate(wid, nid, Sunrise.Instance(sunrise) , value, tag)

        
    });

    

    connection.on("person_stop", (wid, nid, value) => controller.personStop(wid, nid, value))

    connection.on("event_workspace", (wid, e) => controller.event_workspace(wid, e))

    connection.on("file", (wid, n, files)=>controller.ticketFileUpdate(wid, n, files));

    connection.on("person_efforts", (wid, nid, p)=>controller.personUpdate(wid, nid, p));
}