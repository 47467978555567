let callback:(()=>void )| null = null

export function setupErrorUI(c:()=>void){

    callback = c
}


export function error(message:string, reason?:any){

    console.error(message);

    if (reason != undefined) console.error(reason);
}

export function warn(message:string){

    console.warn(message);
}

export function exception(reason:any, comment?:string){

    console.error(reason)

    if (callback != null) callback()
}

export function info(reason:any){

    console.log(reason)
}

export function trace(value: string) { console.log(value) }

const module = {

    time: new Intl.DateTimeFormat('en-GB', { hour: "numeric", minute: "numeric", hour12: false }),
    date: new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric' }),
    error,
    warn,
    trace,
    exception,
    info
}

export default module;