//import * as jsonpatch from 'fast-json-patch';
//import { Ticket, IProjectDispatcher } from "mn/logic";

import { PersonRmodel } from "./app.context/workspace/person.rmodel";


export function error(info: unknown): void {

  throw info;
}

export function getQueryVariable(variable: string): string | null {

  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return null;
}

//export type Guid = string

export class Guid {
  
  static newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}

// General -Types

export type url = string;


export interface IDisposable {

  dispose(): void
}

export const sleep = (ms?: number) => new Promise((r) => setTimeout(r, typeof ms == "undefined" ? 1 : ms));

export function dateToUTC(date:Date) { return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); }

//number of minutes you have to add to UTC0 timezone to get 'local' time
export function utcOffset():number {return - 1 * new Date().getTimezoneOffset()}

export function dayMinute(): number {

  const d = new Date()

   const buffer = d.getHours() * 60 + d.getMinutes()

   return buffer;
 }




export function copy(destination: Record<string, unknown>, data: { [key: string]: unknown }, exceptions?: string[]): void {

  for (const key in destination) {

    if (exceptions?.includes(key)) continue;

    if (data[key] == undefined) continue;

    Reflect.set(destination, key, data[key]);

  }
}





