import { Timespending } from '@/app.context/workspace/Event';
import { EffortRmodel, Timeffort } from '@/app.context/workspace/persons/sunrises/effort.rmodel';
import { Direction, SegmentEnd } from '@/logic/_infra/_tech';
import { computed } from 'vue';
import * as lib from '@/lib'


export function offset(props:{offset?:number|null}){

    const x = computed(()=>{

        if (props.offset == undefined || props.offset == null) return lib.utcOffset()
        
        else return props.offset
        
        })

    return x

}

export const emits = {
    "click"(d: Direction, e: SegmentEnd, effort: EffortRmodel, timeffort: Timeffort | null, timespending: Timespending | null) {

        return true
    }
}


