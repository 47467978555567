import * as signalR from '@microsoft/signalr';
import { IUserStorage } from '@/io/storages';

export default function userSignalrStorage(hubConnection: signalR.HubConnection): IUserStorage {

    const storage =
    {

        disconnect() {

            return hubConnection.stop();
        },

        sync(){
            //#someday
        },

        resync(): void {
            
            
        }
    };

    return storage;
}