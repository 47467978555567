<template>
    <span class="tech-dev-button clickable"><slot></slot></span>
</template>
<style>
.tech-dev-button{
    color: darkgoldenrod;
    margin-left: 0.1em;
}

.tech-dev-button:hover{
    color:chocolate
}
</style>