import * as arrays from '@/lib/array.lib'

export class BoardPosition {
    column: number = 0;
    row: number = 0;
}

export const boards = {

    columns: 5,

    clear<T>(b: (T | null)[][], p: BoardPosition): T {

        const buffer = b[p.row][p.column];

        if (b[p.row][p.column] === null) throw Error(`Cell is empty`);

        b[p.row][p.column] = null;

        return buffer!;
    },

    rowAdd<T>(b: (T | null)[][], index?: number) {

        if (typeof index == "undefined") index = b.length;

        const buffer: (T | null)[] = []

        for (let j = 0; j < this.columns; j++) buffer.push(null);

        arrays.insertStrictly(b, buffer, index);

    },

    rowRemove<T>(b: T[][], row: number) {

        if (row > b.length) throw Error(`Can't delete row ${row} when array length is ${b.length}`)

        const row_object = b[row];

        row_object.forEach((e, i) => { if (e != null) throw Error(`Error ${i}  cell is not empty. Row ${row}`) })

        arrays.remove(b, row_object);
    },

    put<T>(b: (T|null)[][], item: T, p: BoardPosition) {

        
        if (this.itemOrNull(b, p) != null) throw Error(`Cell is occupied`);
//todohere for empt array it fails

        if (b.length <= p.row){

            for (let i = 0; i <= p.row - b.length; i++) {
                b.push([])
            }
        }

        if (b[p.row].length <= p.column){

            for(let i = 0; i <= p.column - b[p.row].length; i++){

                b[p.row].push(null);
            }
        }

        b[p.row][p.column] = item;
    },

    move<T>(b: T[][], item: T, p: BoardPosition) {

        if (this.itemOrNull(b, p) != null) throw Error(`Cell is occupied`);

        const position = this.position(b, item);

        this.clear(b, position);

        this.put(b, item, p);
    },

    itemOrNull<T>(b: T[][], p: BoardPosition): T | null {

        return this.at(b, p)

        
        /*
        if (b.length == 0) return null;

        if (b[0].length == 0) return null;

        return b[p.row][p.column];
        */
    },

    items<T>(b: T[][]): T[] {

        const result: T[] = [];

        b.forEach(row => {

            row.forEach(x => {

                if (x != null) result.push(x);
            })
        })


        return result;
    },

    // position<T>(b: T[][], item: T): BoardPosition {

    //     let matches = 0;
    //     const result = new BoardPosition();

    //     for (let row = 0; row < b.length; row++) {

    //         for (let column = 0; column < b[row].length; column++) {

    //             if (b[row][column] === item) {
    //                 result.row = row;
    //                 result.column = column;

    //                 matches++;
    //             }
    //         }
    //     }

    //     if (matches != 1) throw Error(`${matches} matches found`);

    //     return result;
    // },

    position<T>(a: Array<Array<T>>, value: T): { column: number, row: number } {

        const result = this.positionOrNull(a, value);

        if (result === null) throw Error(`Can't find ${value} at array`);

        return result!;
    },

    positionOrNull<T>(a: Array<Array<T>>, value: T): { column: number, row: number } | null {

        if (value === null) throw Error("Value can't be null")

        const result = { column: 0, row: 0 };

        let matches_counter = 0;

        a.forEach((r, rIndex) => {

            const cIndex = r.indexOf(value);


            if (cIndex >= 0) {

                matches_counter++;

                result.column = cIndex;
                result.row = rIndex;
            }
        })

        if (matches_counter > 1) throw Error(`Can't find ${value} at array (or there are few of them)`);

        if (matches_counter === 1) return result;

        return null;
    },

    lastItem<T>(b: T[][]): T | null {

        for (let i = b.length - 1; i >= 0; i--) {

            const row = b[i];

            for (let j = row.length - 1; j >= 0; j--) {

                const item = row[j];

                if (item != null) return item;
            }

        }

        return null;
    },

    lastEmptyPosition<T>(b: T[][]): BoardPosition | null {

        for (let i = b.length - 1; i >= 0; i--) {

            const row = b[i];

            for (let j = row.length - 1; j >= 0; j--) {

                const item = row[j];

                if (item === null) return { column: j, row: i };
            }

        }

        return null;
    },

    firstItem<T>(b: T[][]): T | null {

        for (let i = 0; i < b.length; i++) {

            const row = b[i];

            for (let j = 0; j < row.length; j++) {

                const item = row[j];

                if (item != null) return item;
            }

        }

        return null;
    },


    firstEmptyPosition<T>(b: T[][]): BoardPosition | null {

        for (let i = 0; i < b.length; i++) {

            const row = b[i];

            for (let j = 0; j < row.length; j++) {

                const item = row[j];

                if (item === null) return { column: j, row: i };
            }
        }

        return null;
    },

    at<T>(a: Array<Array<T>>, position: { column: number, row: number }): T | null {

        if (a.length <= position.row) return null;

        if (a[position.row].length <= position.column) return null;

        return a[position.row][position.column];
    },
}