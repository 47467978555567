import { PersonRmodel } from "@/app.context/workspace/person.rmodel";
import { ProjectModel } from "@/app.context/workspace/project.rmodel";
import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel";
import { Guid } from "@/lib";
import { BoardPosition } from "@/logic/_infra/boards";
import { TodoSection } from "@/logic/_infra/queues";
import { Operation } from "./EntityOperation";
import { BoardOperation, TodoOperationType } from "./_def";



export class TicketBoardOperationDto extends Operation {

    name: TodoOperationType;

    n: number = 0;
    nid: Guid | null = null;
    pid: Guid | null = null;

    //wid: Guid

    target: { column: number, row: number } = { column: 0, row: 0 };

    constructor(operation: BoardOperation<TicketRmodel>) {

        super('TicketBoardOperation')

        //this.objects.ticket = operation.object;

        //this.objects = { person: null, ticket: operation.object, proejct: null }

        this.name = operation.name;

        this.n = operation.object.n;

        this.target = operation.target;
    }


}

