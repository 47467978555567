/* eslint-disable */
import { IWorkspaceStorage } from "@/io/storages";
import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel";
import { PersonRmodel } from "@/app.context/workspace/person.rmodel";
import { TodoRmodel } from "@/app.context/workspace/tickets/todo.rmodel";
import { ProjectModel } from "@/app.context/workspace/project.rmodel";

import { BoardPosition } from '@/logic/_infra/boards'
import { queues, TqueuePosition } from '@/logic/_infra/queues'

import { Resolution } from "@/logic/workspaces/tickets/_def";
import { programmator } from "./Xbq/programator";
import { InstructionOperation, TodoBoardOperation, TodoQueueOperation } from "@/app.context/operations";
import { InstructionOperationDto } from "@/io/storages/workspace/_def";
import sys from "@/sys";

// INSTRUCTION - IS SET OF OPERATIONS //

function toInstructionOperation(input: TodoQueueOperation[]): InstructionOperation[] 
{
    const result: InstructionOperation[] = []

    input.forEach(i => result.push(i))

    return result
}

export default class XbqProcessor {

    readonly storage: IWorkspaceStorage;

    //cache: Map<Guid, TicketRmodel> = new Map<Guid, TicketRmodel>();

    constructor(storage: IWorkspaceStorage) {

        this.storage = storage;
    }

    //can create and move todo items at queue
    todoQueue(todo: TodoRmodel, person: PersonRmodel, position: TqueuePosition, /*autoshift?: boolean,*/todo_replacement:TodoRmodel|null, preInsturctions?: InstructionOperationDto[], silent?: boolean) {

        const person_native = todo.person;

        if (todo.person != person) throw Error(`/workspaces/person - Queue disposition update - Attempt assign todo item to the wrong queue: ${todo?.person?.nid} vs ${person_native?.nid}`)

        const position_original = queues.position(person, todo)

        const instruction = programmator.todoQueue(todo, person, position/*, autoshift*/);

        //#ImmediateUI 
        person.workspace.apply(instruction);
        let instruction_dto: InstructionOperationDto[] = instruction.map(o => o.dto())


        if (todo_replacement != null){

            if (position_original == null) sys.warn('Relacment for item that was not presentd at queu')
            else
            {   
                this.todoQueue(todo_replacement, person, position_original, null, undefined, true).forEach(i=>instruction_dto.push(i))
            }
        }
        


        if (preInsturctions != undefined) instruction_dto = preInsturctions.concat(instruction_dto);

        //if (!(silent ?? false)) this.storage.personQueue(person.nid, todo.identificator(), position, instruction_dto);
        if (!(silent ?? false)) this.storage.transactionSubmit(person.workspace.wid, instruction_dto);

        return instruction_dto
    }




    todoQueueClose(todo: TodoRmodel, person: PersonRmodel, /*resolution?: Resolution,*/ todo_replacement?: TodoRmodel | null, silent?: boolean) { //-only todo item

        if (todo_replacement == undefined) todo_replacement = null

        //const autoshift = (todo_upcoming == null)

        const position = queues.position(person, todo);

        if (position == null) sys.warn('position of item on todoQueueClose is null')

        const instruction_preliminary = programmator.todoQueueClose(person, todo/*, autoshift*/);

        //#ImmediateUI 
        person.todoQueueApplyBatch(instruction_preliminary);

        const instruction = toInstructionOperation(instruction_preliminary);

        const insturction_dto: InstructionOperationDto[] = instruction.map(o => o.dto())

        if (todo_replacement != null && position != null){

            this.todoQueue(todo_replacement, person, position, null, undefined, true).forEach(i=>insturction_dto.push(i))
        }

        //if (todo_upcoming != null) todoQueue_AsCurrent(person, instruction, todo_upcoming);

        

        if (!(silent ?? false)) this.storage.transactionSubmit(person.workspace.wid, insturction_dto);

        return insturction_dto
    }

    //can create or move todo items at person grid
    todoBoard(todo: TodoRmodel, person: PersonRmodel, position: BoardPosition): void {

        const w = person.workspace;

        //Bprogramator.put<TodoRmodel>(person.board.person, todo, position)
        const instruction: InstructionOperation[] = []

        programmator.todoBoard(todo, person, position).forEach(o => instruction.push(o));

        //#ImmediateUI 
        w.apply(instruction);

        this.storage.transactionSubmit(person.workspace.wid, instruction.map(o => o.dto()));

    }

    todoBoardClose(todo: TodoRmodel, person: PersonRmodel, silent?: boolean) {

        const w = person.workspace;

        //Bprogramator.put<TodoRmodel>(person.board.person, todo, position)
        const instruction: TodoBoardOperation[] = []

        programmator.todoBoardClose(todo, person).forEach(o => instruction.push(o));

        //#ImmediateUI 
        w.apply(instruction);

        const instruction_result = instruction.map(o => o.dto())

        //this.storage.personBoardClose(person.nid, todo.identificator(), instruction.map(o => o.dto()), resolution);
        if (!(silent ?? false)) this.storage.transactionSubmit(person.workspace.wid, instruction_result);

        return instruction_result
    }

    //create and move tickets at project/person boards
    board(ticket: TicketRmodel, target: ProjectModel | PersonRmodel, position: BoardPosition) { //+ captain.board - only for tickets

        //-- ensure to remove from project queue (if board - project)

        const instruction: InstructionOperation[] = [];

        const workspace = target.workspace;

        programmator.board(ticket, target, position).forEach(o => instruction.push(o));

        //#ImmediateUI 
        workspace.apply(instruction);


        if (target instanceof ProjectModel) {

            //this.storage.projectBoard(target.pid, ticket.n, position, instruction.map(o => o.dto()));
            this.storage.transactionSubmit(ticket.workspace.wid, instruction.map(o => o.dto()));

        }
        else if (target instanceof PersonRmodel) {

            //this.storage.projectCaptainPersonBoard(target.nid, ticket.n, position, instruction.map(o => o.dto()));
            this.storage.transactionSubmit(ticket.workspace.wid, instruction.map(o => o.dto()));
        }
        else throw Error("code:ghsidfghsldfgh");
    }

    boardClose(ticket: TicketRmodel, target: ProjectModel | PersonRmodel, resolution?: Resolution) {

        const workspace = target.workspace;

        const instruction = programmator.boardClose(ticket, target);

        //#ImmediateUI 
        workspace.apply(instruction);

        if (target instanceof ProjectModel) {

            //this.storage.projectBoardClose(target.pid, ticket.n, instruction.map(o => o.dto()), resolution);
            this.storage.transactionSubmit(target.workspace.wid, instruction.map(o => o.dto()));

        }
        else if (target instanceof PersonRmodel) {

            //this.storage.projectCaptainPersonBoardClose(target.nid, ticket.n, instruction.map(o => o.dto()), resolution);
            this.storage.transactionSubmit(target.workspace.wid, instruction.map(o => o.dto()));
        }
        else throw Error("code:ghspokjsldfgh");
    }

    //create/move tickets at pojrect queue
    queue(ticket: TicketRmodel, project: ProjectModel, postion: TqueuePosition) { //-only ticket - queu|pins

        if (ticket === null) throw Error("Ticket arg can't be null");

        const instruction = programmator.queue(ticket, project, postion);

        //#ImmediateUI 
        project.workspace.apply(instruction);

        this.storage.transactionSubmit(project.workspace.wid, instruction.map(o => o.dto()));

    }

    queueClose(ticket: TicketRmodel, project: ProjectModel, preInstruction?: InstructionOperationDto[]) {

        const autoshift = false

        const instruction: InstructionOperationDto[] = []

        if (preInstruction != undefined) {
            preInstruction.forEach(o => instruction.push(o))
        }
        //if (resolution != undefined) instruction.push(ticket.update(resolution))

        const instruction_close = programmator.queueClose(ticket, project, autoshift)

        project.workspace.apply(instruction_close)

        instruction_close.forEach(o => instruction.push(o.dto()))

        

        //executing

        //this.storage.projectQueueClose(project.pid, ticket.n, instruction, resolution);
        this.storage.transactionSubmit(ticket.workspace.wid, instruction);
    }
}