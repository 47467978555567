import sys from '@/sys'
import { InstructionOperation, TicketBoardOperation, TicketQueueOperation, TodoBoardOperation, TodoQueueOperation } from "@/app.context/operations";
import { WorkspaceRmodel } from "@/app.context/workspace.rmodel";
import { PersonRmodel } from "@/app.context/workspace/person.rmodel";
import { TodoRmodel } from "@/app.context/workspace/tickets/todo.rmodel";
import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel";
import AppContext from "@/context";
import { IWorkspaceDto } from "@/io/dto/workspace";
import { IPersonDto_Sunrise } from "@/io/dto/workspaces/person.dto";
import { BlockEntityDto, EntityDeleteDto, EntityUpdateDto, ILabelEntityDto, IPersonEntityDto, Operation, TaskEntityDto, TicketEntityDto, VerificationEntityDto } from "@/io/storages/workspace/EntityOperation";
import { TicketBoardOperationDto } from "@/io/storages/workspace/TicketBoarOperation";
import { TicketQueueOperationDto } from "@/io/storages/workspace/TicketQueueOperation";
import { TodoBoardOperationDto } from "@/io/storages/workspace/TodoBoardOperation";
import { TodoQueueOperationDto } from "@/io/storages/workspace/TodoQueueOperation";
import { BoardOperation, QueueOperation } from "@/io/storages/workspace/_def";
import { Guid } from "@/lib";
import { toResolution } from "@/logic/workspaces/tickets/_def";
import { Sunrise, TimeSegment, minuteCalc as mc } from "@/logic/_infra/time";
import { ITimeSegmentDto, toTimeSegment } from "@/io/dto/infrastructure.dto.ns";
import { TaskRmodel } from "@/app.context/workspace/tickets/task.rmodel";
import { BlockRmodel } from "@/app.context/workspace/tickets/tasks/block.rmodel";
import { VerificationModel } from "@/app.context/workspace/tickets/tasks/verification.rmodel";
import { IMeetingDto } from "@/io/dto/workspaces/eventw.dto";
import { IFile } from '@/io/dto/workspaces/ticket.dto.spec.pack'
import { IEventpDto } from '@/io/dto/workspaces/persons/eventp.dto';
import { ISunriseReportDto } from '@/io/dto/workspaces/persons/sunriseReport.dto';
import { MeetingRmodel } from '@/app.context/workspace/Event';
import { SunriseReportRmodel } from '@/app.context/workspace/persons/sunrise.rmodel';

export class WorkspaceAppContextController {


    context: AppContext

    constructor(context: AppContext) {

        this.context = context
    }

    identificator(wid: Guid, uid: Guid, n: number): void {

        const w = this.context.cursor.workspace;

        if (w != null && w.wid === wid) {

            //const operator = this.context.ops.xbq;

            //if (operator === null) throw Error('Xbq operator is null');

            const ticket = w.cache.get(uid)!;

            ticket.n = n;

            w.cache.delete(uid);
        }
    }

    //here should happen context.model.workspace initialization
    workspaceSetup(dto: IWorkspaceDto) {




        //this.context.cursor.workspace

        let model: WorkspaceRmodel;

        if (this.context.cursor.workspace == null || this.context.cursor.workspace.wid != dto.wid) {



            model = new WorkspaceRmodel(dto.wid, this.context.storages.workspace!, this.context.service);

            sys.info(`/workspace - Setup: ${dto.wid} - New`)
        }
        else model = this.context.cursor.workspace



        model.setup(this.context, dto);


        if (this.context.cursor.person != null) {


            if (this.context.cursor.person.workspace.wid == model.wid) {

                const p = model.personOrNull_nid(this.context.cursor.person.nid)

                this.context.cursor.person = p;
            }
            else this.context.cursor.person = null;

        }

        if (this.context.cursor.project != null) {
            if (this.context.cursor.project.workspace.wid == model.wid) {

                this.context.cursor.project = model.projectOrNull(this.context.cursor.project.pid)

            }
            else this.context.cursor.project = null;
        }

        if (this.context.cursor.workspace != model) this.context.cursor.workspace = model;

        this.context.syncTimezone()

        // const offset = -1 * (new Date().getTimezoneOffset())

        

        model.categories.clear()

        dto.categories.forEach(c=>model.categories.add(c))
    }

    //actually this is already not  just about disposition - this is single universal channed for 'transaction' changes
    async dispositionUpdate(wid: Guid, instruction: Operation[]): Promise<void> {

        sys.info(`/workspace - Delta: ${wid}`)

        const NIDs: Set<Guid> = new Set<Guid>()

        const w = this.context.cursor.workspace

        if (w != null && w.wid === wid) {

            const buffer: InstructionOperation[] = [];

            instruction.forEach(async dto => {

                if (dto.operation == "TicketEntity") {

                    const b = (<TicketEntityDto>dto);

                    let t = w.index.tickets.get(b.n)

                    if (t == undefined) {

                        t = w.ticketNew_Empty(b.n)

                        try {
                        
                            await w.ticket_GetAndUpdateIf(b.n, this.context)

                        } catch (error) {
                            
                            sys.exception(error)
                        }

                        
                    }
                }
                else if (dto.operation == "TaskEntity") {

                    const b = (<TaskEntityDto>dto);

                    const person = w.person_nid(b.nid);

                    let t = w.index.tickets.get(b.n)

                    let flag = false

                    if (t == undefined) {

                        flag = true

                        t = w.ticketNew_Empty(b.n)
                    }


                    t.taskRemoveIf_nid(person.nid)

                    t.taskAdd(person);

                    if (flag) await w.ticket_GetAndUpdateIf(b.n, this.context)

                }
                else if (dto.operation == "BlockEntity") {

                    const b = (<BlockEntityDto>dto);

                    const t = w.index.tickets.get(b.n)!

                    const task = t.task(b.nid);

                    let blocker: PersonRmodel | null = null;

                    if (b.blocker != null) blocker = w.person_nid(b.blocker)!;

                    task.blockNew(blocker);
                }
                else if (dto.operation == "VerificationEntity") {

                    const b = (<VerificationEntityDto>dto);



                    const ticket = w.ticket(b.n);

                    const task = ticket.task(b.nid);

                    const verifier = w.person_nid(b.verifier);

                    const phase = w.process(b.process).phase(b.phase);

                    task.verificationNew(verifier, phase);

                    // w.ticket_GetIf(b.n, this.context)

                    //     .then(t => {

                    //         if (t != null) {


                    //             const ticket = w.ticket(b.n);

                    //             const task = ticket.task(b.nid);

                    //             const verifier = w.person_nid(b.verifier);

                    //             const phase = w.process(b.process).phase(b.phase);

                    //             task.verificationNew(verifier, phase);
                    //         }

                    //     })
                }
                else if (dto.operation == 'label') {

                    const dto_b = <ILabelEntityDto>dto;

                    w.labelSetup(dto_b)

                }
                else if (dto.operation === 'person') {
                    //create new person

                    const dto_b = <IPersonEntityDto>dto;

                    this.context.model.personCreate(w.wid, dto_b.object.name, dto_b.object.nid)

                    w.personSetup(dto_b.object)
                }
                else if (dto.operation == 'TicketQueueOperation') {

                    //dto = (<TicketQueueOperationDto>dto);

                    const dto_b = <TicketQueueOperationDto>dto;

                    const ticket = w.ticket(dto_b.n);

                    const projeect = w.project(dto_b.pid);

                    const x = new QueueOperation<TicketRmodel>(dto_b.name, ticket, dto_b.target);

                    const operation = new TicketQueueOperation(x, projeect)

                    buffer.push(operation);
                }
                else if (dto.operation == 'TicketBoardOperation') {

                    const dto_b = <TicketBoardOperationDto>dto;

                    const ticket = w.ticket(dto_b.n);

                    const x = new BoardOperation<TicketRmodel>(dto_b.name, ticket, dto_b.target);

                    const operation = new TicketBoardOperation(x);

                    if (dto_b.pid != null) {

                        const projeect = w.project(dto_b.pid);
                        operation.projectUpdate(projeect);
                    }

                    if (dto_b.nid != null) {

                        const person = w.person_nid(dto_b.nid);
                        operation.personUpdate(person);
                    }

                    operation.test();

                    buffer.push(operation);


                }
                else if (dto.operation == 'TodoQueueOperation') {

                    const dto_b = <TodoQueueOperationDto>dto;

                    const person = w.person_nid(dto_b.nid);

                    NIDs.add(person.nid);

                    let todo = person.todoItemOrNull(dto_b.n, dto_b.type, dto_b.nid_aux);

                    if (todo === null) todo = person.todoNew(dto_b.n, dto_b.type, dto_b.nid_aux);

                    const x = new QueueOperation<TodoRmodel>(dto_b.name, todo, dto_b.target);

                    const operation = new TodoQueueOperation(x, person);

                    buffer.push(operation);
                }
                else if (dto.operation == 'TodoBoardOperation') {

                    const dto_b = <TodoBoardOperationDto>dto;

                    const person = w.person_nid(dto_b.nid);

                    NIDs.add(person.nid);

                    let todo = person.todoItemOrNull(dto_b.n, dto_b.type, dto_b.nid_aux);

                    if (todo === null) todo = person.todoNew(dto_b.n, dto_b.type, dto_b.nid_aux);

                    const x = new BoardOperation<TodoRmodel>(dto_b.name, todo, dto_b.target);

                    const operation = new TodoBoardOperation(x, person);

                    buffer.push(operation);


                }
                else if (dto.operation === EntityUpdateDto.operation) {

                    const dto_b = <EntityUpdateDto>dto;

                    const entity = w.entity(dto_b.ref)

                    if (entity === null) sys.error(`Can't find entity E20230223`)
                    else {

                        const entity_b = entity as any

                        if (dto_b.field === EntityUpdateDto.Key_Resolution) {

                            const value = dto_b.value as unknown as boolean

                            entity.resolution = toResolution(value)

                        }
                        else if (entity instanceof TicketRmodel) {

                            const ticket = <TicketRmodel>entity_b

                            if (dto_b.field === EntityUpdateDto.Key_Ticket_Label) {
                                if (dto_b.direction != null) {

                                    const labelName = dto_b.value as unknown as string

                                    if (dto_b.direction) {
                                        //add

                                        const l = w.index.labels.get(labelName)!

                                        ticket.labelAdd(l)
                                    }
                                    else {
                                        ticket.labelRemove(labelName)
                                    }
                                }

                            }
                            else if (dto_b.field === EntityUpdateDto.Key_Ticket_Name) {
                                ticket.name = dto_b.value
                            }
                            else {
                                sys.warn(`Unknow ticket entity filed: ${dto_b}`)
                            }
                        }
                        else if (entity instanceof TaskRmodel) {

                            const task = <TaskRmodel>entity_b;

                            task.workable = dto_b.value
                        }
                        else {
                            //public const string Name = "name";

                            entity_b[dto_b.field] = dto_b.value
                        }
                    }
                }
                else if (dto.operation === EntityDeleteDto.operation) {

                    const dto_b = <EntityDeleteDto>dto;

                    const entity = w.entity(dto_b.ref)

                    if (entity instanceof TaskRmodel) {

                        entity.ticket.taskRemoveIf(entity)
                    }

                    else if (entity instanceof BlockRmodel) {

                        entity.task.blockRemoveIf(entity)
                    }

                    else if (entity instanceof VerificationModel) {

                        entity.task.verificationRemoveIf(entity)
                    }
                    else sys.warn(`Uknown of entity delete operation - ${entity}`)

                }
                else throw new Error('Unknown operation type: ' + dto.operation);
            })

            w.apply(buffer);

            for (const nid of NIDs) {

                this.context.triggers.personTodoUpdated(nid);
            }
        }
    }

    /*
    event(wid: Guid, nid: Guid, dto: IEventpDto) {

        sys.info(`/workspaces/person - event - nid:${nid} - ${dto.timepoint} - m:${dto.meeting}`)

        const w = this.context.cursor.workspace;

        if (w?.wid === wid) {

            const p = w.personOrNull_nid(nid);

            if (p != null) {

                const instance = w.eventpInstance(dto)

                if (instance != null) {

                    const buffer = p.events.filter(x => x.equals(instance!))

                    if (buffer.length == 0) {

                        p.events.push(instance)
                        p.syncEvent()
                    }
                    else if (buffer.length == 1) {

                        buffer[0].timespan = instance.timespan
                    }
                    else sys.warn(`Multiple event(p)s per person - ${dto.appointment}`)
                }
                else sys.warn(`Can't perse event info ${dto.appointment} ${dto.timepoint}`)
            }
        }
    }
    */

    personStrictnessUpdate(wid: Guid, nid: Guid, strict: boolean) {

        //person_strictness
        const w = this.context.cursor.workspace

        if (w?.wid == wid) {

            const p = w.person_nid(nid)

            p.strict = strict

            sys.info(`/workspaces/person - "strict" updated: ${strict}`)
        }
    }

    personEndeavours(wid: Guid, nid: Guid, s: ITimeSegmentDto, values: ITimeSegmentDto[], offset: number) {

        sys.info(`/workspaces/person - "endeavours" updated: ${nid}`)

        const w = this.context.cursor.workspace;

        if (w?.wid == wid) {


            const person = w.person_nid(nid)

            const efforts = person.efforts!

            s //cut everything that overlpa with period

            const buffer_original: TimeSegment[] = []

            let buffer_result: TimeSegment[] = []

            efforts.endeavours_Total.forEach(e => buffer_original.push(e))

            const segment = toTimeSegment(s)

            const threshold = { start: segment.timepoint, end: segment.end() }

            buffer_original.forEach(segment => {

                const start = new Date(segment.timepoint)
                const end = start.cloneAndAddMilliseconds(segment.timespan_ms)



                const overlap = start < threshold.end && threshold.start < end;

                if (!overlap) buffer_result.push(segment)

                else {
                    if (start < threshold.start) buffer_result.push(new TimeSegment(start, mc.differenceInMs(start, threshold.start)))
                    if (end > threshold.end) buffer_result.push(new TimeSegment(threshold.end, mc.differenceInMs(threshold.end, end)))
                }

            })


            const buffer: TimeSegment[] = []




            values.forEach(v => {


                const segment = toTimeSegment(v)
                let start = segment.timepoint
                let end = segment.end()

                const overlap = start < efforts.expires && efforts.starts < end;

                if (overlap) {

                    if (start <= efforts.starts) start = efforts.starts
                    if (end > efforts.expires) end = efforts.expires

                    const diff = mc.differenceInMs(start, end);

                    buffer_result.push(new TimeSegment(start, diff))
                }




            })

            //if get periods that perfectly touch each other - we have to join them

            buffer_result = TimeSegment.merge(buffer_result)


            efforts.endeavours_Total.length = 0;
            buffer_result.forEach(e => efforts.endeavours_Total.push(e))
        }
    }

    personCalendarAdjustmentUpdate(wid: Guid, nid: Guid, s: Sunrise, value: number | null, tag: string | null) {

        sys.info(`person_calendar_adjustment. Person: ${nid} Value: ${value}`)

        const w = this.context.cursor.workspace;

        if (w?.wid == wid) {

            const p = w.person_nid(nid)

            p.flags.timeline = true

            //     if (s.isToday()) {

            //         if (w.user_person?.nid == nid) {

            //             if (w.user_Obligations == null) sys.error('User has no obligations')
            //             else {
            //                 w.user_Obligations.endeavours.length = 0;

            //                 values.forEach(s => w.user_Obligations?.endeavours.push(toTimeSegment(s)))
            //             }
            //         }
            //     }
        }
    }

    personStop(wid: Guid, nid: Guid, value: boolean) {

        sys.info(`/workspaces/person - "endeavours" updated: ${nid}`)

        const w = this.context.cursor.workspace;

        if (w?.wid == wid) {

            const person = w.person_nid(nid)

            person.stopped = value
        }
    }

    event_workspace(wid: Guid, event: IMeetingDto) {

        sys.info(`/workspaces - "appointment": ${wid}`)

        const w = this.context.cursor.workspace;

        if (w?.wid == wid/* && w.expires != null*/) {

            const buffer = w.meetings.filter(x => x.isSame(event))

            if (buffer.length == 0) w.meetings.push(MeetingRmodel.instance(event, w))

            else if (buffer.length == 1) buffer[0].set(event, w)

            else sys.error('multiple eventws with same uid ' + event.appointment)
        }
    }

    ticketFileUpdate(wid: Guid, n: number, files: IFile[],): void {

        this.context.triggers.ticketFileUpdate(wid, n, files)
    }

    personUpdate(wid: Guid, nid: Guid, dto: ISunriseReportDto): void {

        //1123 10172023

        const w = this.context.cursor.workspace;

        if (w != null) {
            if (w.wid == wid) {

                const p = w.person_nid(nid)

                if (p.efforts != null) {
                    if (p.efforts.sunrise.equals(Sunrise.Instance(dto.sunrise))) {
                        p.setup_sunriseReport(this.context, dto)
                    }
                }

                this.context.triggers.personEffortsUpdated(p, dto)
            }
        }
    }

    categoryDelete(wid: Guid, name: string, nid: Guid | null) {

        const o = this.context.objects_cursored(wid, nid)

        if (o.person != null) o.person.categories.delete(name)

        else if (o.workspace != null) o.workspace.categories.delete(name)

    }

    category(wid: Guid, name: string, nid: Guid | null) {

        const o = this.context.objects_cursored(wid, nid)

        if (o.person != null) o.person.categories.add(name)

        else if (o.workspace != null) o.workspace.categories.add(name)
    }

    
}