import { absDiffInMs } from '@/lib/date';
import sys from '@/sys'
import dayjs from 'dayjs';
import { Direction, SegmentEnd } from './_tech';

export const minuteCalc =
{
    toAproximatedHoursDecimal,

    toAproximatedHoursString,
    toAproximatedHoursString_Ms,
    differenceInMs,
    toDaytimeMinutes,
    minutesToDayTime
}

/**
 * Translate timespan in minutes in string like (2.2h)
 * @param minutes 
 * @returns 
 */
function toAproximatedHoursString(minutes: number): string {

    //'3 min' is 0.05 of hour

    // if (minutes < 3) return '-'

    // const quotient = Math.floor(minutes / 60)
    // const reminder = minutes % 60;
    // const reminder_quotient = (Math.floor(reminder / 3) ) * 5;

    const abs = Math.abs(minutes)

    let buffer = toAproximatedHoursDecimal(abs) + 'h';

    if (minutes < 0) buffer = '-' + buffer

    return buffer
}

function toAproximatedHoursString_Ms(ms: number): string {

    //'3 min' is 0.05 of hour

    // if (minutes < 3) return '-'

    // const quotient = Math.floor(minutes / 60)
    // const reminder = minutes % 60;
    // const reminder_quotient = (Math.floor(reminder / 3) ) * 5;

    const abs = Math.abs(ms)

    let buffer = toAproximatedHoursDecimal_Ms(abs) + 'h';

    if (ms < 0) buffer = '-' + buffer

    return buffer
}


// function differenceInMinutes(date1: Date, date2: Date): number {
//     const diffInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
//     const diffInMinutes = Math.round(diffInMilliseconds / (1000 * 60)); // 1000 milliseconds in a second, 60 seconds in a minute
//     return diffInMinutes;
// }

function differenceInMs(date1: Date, date2: Date): number {
    const diffInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
    return diffInMilliseconds
}

function toAproximatedHoursDecimal(minutes: number): number {

    //'3 min' is 0.05 of hour

    if (minutes < 3) return 0;

    const quotient = Math.floor(minutes / 60)
    const reminder = minutes % 60;
    const reminder_quotient = (Math.floor(reminder / 3)) * 5;

    return quotient + reminder_quotient / 100;

    //return `${quotient}.${reminder_quotient}h`;
}

function toAproximatedHoursDecimal_Ms(ms: number): number {

    //'3 min' is 0.05 of hour

    const minutes = ms / 60000

    if (minutes < 3) return 0;

    const quotient = Math.floor(minutes / 60)
    const reminder = minutes % 60;
    const reminder_quotient = (Math.floor(reminder / 3)) * 5;

    return quotient + reminder_quotient / 100;

    //return `${quotient}.${reminder_quotient}h`;
}




function toDaytimeMinutes(d: Date | string): number {

    if (typeof d == "string") d = new Date(d);

    return d.getHours() * 60 + d.getMinutes();
}

function minutesToDayTime(value: number): string {

    let hours = Math.floor(value / 60);

    const out = hours >= 48;

    if (out) return 'N/A'

    const overlap = hours >= 24;

    if (overlap) hours -= 24;

    return `${hours}:${('00' + Math.floor(value % 60)).slice(-2)}${overlap ? '*' : ''}`
}


//--

export class UnifromNumberValue {
    value: number = 0
    tag: string | null = null
}

//

const sunrise: number = 300; //in minutes, 5AM

export class Sunrise {


    static Instance(dto: {

        year: number
        month: number
        day: number
    }) {

        return new Sunrise(dto.year, dto.month, dto.day)
    }

    year: number
    month: number
    day: number

    constructor(y: number, m: number, d: number) {
        this.year = y;
        this.month = m;
        this.day = d;
    }


    date() {

        return Sunrise.toDate(this)
    }

    start() {
        const sunrise_start = new Date(this.year, this.month - 1, this.day,)
    }


    static localeSunrise(value?: Date): Sunrise {

        if (value == undefined) value = new Date();

        return Sunrise.localeSunrise_Ext(value, sunrise)
    }

    static localeSunrise_Ext(value: Date, threshold: number): Sunrise {



        const buffer = value!.cloneAndAddMinutes(-threshold);

        return new Sunrise(buffer.getFullYear(), buffer.getMonth() + 1, buffer.getDate())
    }

    static isLocaleToday(value: Date): boolean {

        const today = Sunrise.localeSunrise()

        const x = Sunrise.localeSunrise(value);

        return today.equals(x);
    }


    static fromString(after: string): Sunrise {

        const date = new Date(after);

        return Sunrise.DateAsSunrise(date);
    }

    static DateAsSunrise(x: Date): Sunrise {

        return new Sunrise(x.getFullYear(), x.getMonth() + 1, x.getDate());
    }

    static dateToSunriseDate(x: Date) {

        return this.toDate(this.DateAsSunrise(x))
    }



    static toDate(s: Sunrise) {

        return new Date(s.year, s.month - 1, s.day);
    }

    static toDateString(s: { year: number, month: number, day: number }) {
        return `${s.year}-${s.month}-${s.day}`
    }

    public equals2(obj: { year: number, month: number, day: number }): boolean {

        if (obj === null) return false;

        return this.year === obj.year && this.month == obj.month && this.day === obj.day;
    }

    public equals(obj: Sunrise | null): boolean {

        if (obj === null) return false;

        return this.year === obj.year && this.month == obj.month && this.day === obj.day;
    }

    isToday() {

        return this.equals(Sunrise.localeSunrise())
    }

    toDateString(): string {

        //2011-10-05T00:00:00.000Z
        return `${this.year}-${this.month}-${this.day}T00:00:00.000Z`
    }

    toString(): string {

        const buffer = Sunrise.toDate(this);

        return sys.date.format(buffer)

        //return `${this.year}-${this.month}-${this.day}`
    }



}

export class TimeSegment {


    static normalize<T>(items: { segment: TimeSegment, payload: T }[]) {

        const buffer = this.normalize_Ext(items)

        return buffer.winners
    }

    //Retursn stricktly non-overlaping segments of payloads. Priority is set by items order.
    //'underdogs' contains periods of overlaps that loose
    static normalize_Ext<T>(items: { segment: TimeSegment, payload: T }[]) {

        const result: { segment: TimeSegment, payload: T }[] = []

        const underdogs: { segment: TimeSegment, payload: T }[] = []

        if (items.length > 0) {

            const head = items[0]

            result.push({ segment: head.segment, payload: head.payload })

            for (let index = 1; index < items.length; index++) {

                const element = items[index];

                result.map(x => x.segment)

                const winners = TimeSegment.excludeOverlappingSegments([element.segment], result.map(x => x.segment))

                winners.forEach(x => result.push({ segment: x, payload: element.payload }))

                const underdogs_buffer = TimeSegment.excludeOverlappingSegments([element.segment], winners)

                underdogs_buffer.forEach(x => underdogs.push({ segment: x, payload: element.payload }))
            }
        }

        return { winners: result, underdogs }
    }

    static Next(): TimeSegment {

        return new TimeSegment(new Date())
    }

    minuteSpanToNow(): number {

        return this.timepoint.minuteSpan(new Date());
    }

    matches(basePoint: Date, edge: SegmentEnd): boolean {

        if (edge == "S") {
            return this.timepoint.getTime() == basePoint.getTime()
        }

        if (edge == "E") return this.end().getTime() == basePoint.getTime()

        throw new Error('Unknown enum set');
    }

    point(edge: SegmentEnd) {

        if (edge == "S") return this.timepoint
        if (edge == "E") return this.end()

        throw Error(`Argument: ${edge}`)
    }

    timepoint: Date
    timespan_ms: number = 0
    index: number = 0

    startex: boolean = false
    endex: boolean = false

    //startex: Date | null = null //'start' + 'index'
    //dex: Date | null = null //'end' + 'index'


    /**In minutes, NOT rounded */
    get timespan_min(): number {

        return this.timespan_ms / 60000;
    }

    /**In minutes, floor */
    get timespan_min_floor(): number {

        return Math.floor(this.timespan_min)
    }


    contains(x: Date) {

        return x >= this.timepoint && x < this.end()
    }

    contains_inner(x: Date) {

        return x > this.timepoint && x < this.end()
    }

    static intersectTimeSegmentsWithPayload<T>(A: { segment: TimeSegment, payload: T }[], B: TimeSegment[]): { segment: TimeSegment, payload: T }[] {
        const result: { segment: TimeSegment, payload: T }[] = [];

        for (const a of A) {
            for (const b of B) {
                const a_s = a.segment.timepoint.getTime();
                const a_e = a_s + a.segment.timespan_ms; // Convert minutes to milliseconds
                const b_s = b.timepoint.getTime();
                const b_e = b_s + b.timespan_ms;

                // Check for overlap
                if (!(a_e <= b_s || a_s >= b_e)) {
                    // Calculate the intersection
                    const intersectionStart = new Date(Math.max(a_s, b_s));
                    const intersectionEnd = new Date(Math.min(a_e, b_e));

                    const intersectionTimespan = (intersectionEnd.getTime() - intersectionStart.getTime())

                    const s = new TimeSegment(intersectionStart, intersectionTimespan)
                    s.index = a.segment.index

                    result.push({ segment: s, payload: a.payload });
                }
            }
        }

        return result;
    }

    static intersectTimeSegments(A: TimeSegment[], B: TimeSegment[]): TimeSegment[] {
        const result: TimeSegment[] = [];

        for (const a of A) {
            for (const b of B) {
                const startTime1 = a.timepoint.getTime();
                const endTime1 = startTime1 + a.timespan_ms;
                const startTime2 = b.timepoint.getTime();
                const endTime2 = startTime2 + b.timespan_ms;

                // Check for overlap
                if (!(endTime1 <= startTime2 || startTime1 >= endTime2)) {
                    // Calculate the intersection
                    const intersectionStart = new Date(Math.max(startTime1, startTime2));
                    const intersectionEnd = new Date(Math.min(endTime1, endTime2));

                    const intersectionTimespan = (intersectionEnd.getTime() - intersectionStart.getTime());

                    result.push(new TimeSegment(intersectionStart, intersectionTimespan));
                }
            }
        }

        return result;
    }




    static excludeOverlappingSegments(A: TimeSegment[], B: TimeSegment[]): TimeSegment[] {

        const buffer: TimeSegment[] = [];

        A.forEach(x => buffer.push(x))

        for (const b of B) {

            const copy = buffer.map(x => x)

            buffer.length = 0

            for (const a of copy) {

                this.exclusion(a, b).forEach(x => buffer.push(x))
            }
        }

        return buffer;
    }



    static exclusion(a: TimeSegment, b: TimeSegment): TimeSegment[] {

        const result: TimeSegment[] = []

        const x = this.intersection(a, b)

        if (x != null) {

            if (a.timepoint < x.intersection.timepoint) {

                const s = new TimeSegment(a.timepoint, absDiffInMs(x.intersection.timepoint, a.timepoint))

                s.index = a.index

                result.push(s)
            }

            if (x.intersection.end() < a.end()) {

                const s = new TimeSegment(x.intersection.end(), absDiffInMs(a.end(), x.intersection.end()))

                s.index = a.index

                result.push(s)
            }

        }
        else result.push(a.clone())

        return result
    }

    clone() {

        const s = new TimeSegment(this.timepoint, this.timespan_ms)

        s.index = this.index

        s.startex = this.startex
        s.endex = this.endex

        return s
    }

    static intersection2(a: TimeSegment, B: TimeSegment[]): TimeSegment[] {

        const result: TimeSegment[] = []

        for (const b of B) {

            const x = TimeSegment.intersection(a, b)

            if (x != null) result.push(x.intersection)

        }

        return result
    }

    static intersection(a: TimeSegment, b: TimeSegment): { intersection: TimeSegment; isPaused: boolean } | null {

        const isPaused = a.end() > b.end();

        function index(): number {

            return a.index

            /*
            if (a.index !== 0 && b.index !== 0) {
                return Math.min(a.index, b.index);
            } else if (a.index !== 0) {
                return a.index;
            } else if (b.index !== 0) {
                return b.index;
            }
            return 0;
            */
        }

        if (a.timespan_ms === 0 && b.timespan_ms === 0 && a.timepoint.getTime() === b.timepoint.getTime()) {
            // const intersection: TimeSegment = {
            //     timepoint: a.timepoint,
            //     timespan: a.timespan,
            //     index: index(),
            // };

            const intersection = new TimeSegment(a.timepoint, a.timespan_ms)

            intersection.index = index()

            return { intersection, isPaused: true };
            //else if (a.Contains(b.Timepoint) || a.Contains(b.End()) || b.Contains(a.Timepoint) || b.Contains(a.End()))
        }
        else if (a.contains(b.timepoint) || a.contains(b.end()) || b.contains(a.timepoint) || b.contains(a.end())) {
            const start = new Date(Math.max(a.timepoint.getTime(), b.timepoint.getTime()));
            const end = new Date(Math.min(a.end().getTime(), b.end().getTime()));

            //let indexValue = ;

            /*
            if (a.timepoint.getTime() + a.timespan * 60000 === end.getTime()) {
                indexValue = a.index;
            } else if (b.timepoint.getTime() + b.timespan * 60000 === end.getTime()) {
                indexValue = b.index;
            }
            */

            const intersection = new TimeSegment(start, absDiffInMs(end, start))

            intersection.index = index()

            return { intersection, isPaused: isPaused };
        }
        else return null
    }




    static squashTimeSegments(segments: TimeSegment[]): TimeSegment[] {

        if (segments.length <= 1) {
            return segments;
        }

        // Sort the segments by their start time
        segments.sort((a, b) => a.timepoint.getTime() - b.timepoint.getTime());

        const result: TimeSegment[] = [segments[0]];

        for (let i = 1; i < segments.length; i++) {

            const c = segments[i];
            const l = result[result.length - 1];

            const currentStart = c.timepoint.getTime();
            const currentEnd = c.end().getTime() // currentStart + c.timespan_ms; // Convert minutes to milliseconds
            const lastResultEnd = l.end().getTime() //.timepoint.getTime() + l.timespan_ms;

            if (currentStart > lastResultEnd) {
                // Non-overlapping, add to result
                result.push(c);
            } else {
                // Overlapping, merge the segments
                const newEnd = Math.max(currentEnd, lastResultEnd);
                const newTimespan = (newEnd - l.timepoint.getTime());

                // Update the last result segment with the merged segment
                result[result.length - 1] = new TimeSegment(l.timepoint, newTimespan)

            }
        }

        return result;
    }

    static minTimepoint(timeSegments: TimeSegment[]): Date | null {
        if (timeSegments.length === 0) {
            return null;
        }

        let minTimepoint: Date = timeSegments[0].timepoint;

        for (const segment of timeSegments) {
            if (segment.timepoint < minTimepoint) {
                minTimepoint = segment.timepoint;
            }
        }

        return minTimepoint;
    }

    static maxTimepoint(timeSegments: TimeSegment[]): Date | null {

        if (timeSegments.length === 0) {
            return null;
        }

        let maxTimepoint: Date = timeSegments[0].end();

        for (const segment of timeSegments) {
            if (segment.end() > maxTimepoint) {
                maxTimepoint = segment.end();
            }
        }

        return maxTimepoint;
    }




    shiftStartForward(threshold: Date, minSpan: number) {

        if (this.timepoint > threshold) throw Error('Shift is not forward')

        const point = this.timepoint

        const diff_milisecons = Math.abs(threshold.getTime() - point.getTime())

        //const diff_minutes = Math.floor((diff_milisecons / 1000) / 60);

        this.timepoint = threshold

        //TODO: NEED CALC DIFF IN MINUTES 
        if (diff_milisecons < this.timespan_ms) this.timespan_ms -= diff_milisecons; else this.timespan_ms = 0

        if (this.timespan_ms == 0) this.timespan_ms = minSpan * 60000
    }

    shiftStart(shift: number, minSpan: number) {

        if (shift > 0) this.shiftStartForward2(shift, minSpan)

        if (shift < 0) this.shiftStartBackward2(Math.abs(shift))
    }

    shiftEnd(shift: number, minSpan: number) {

        if (shift > 0) this.shiftEndForward2(shift)

        if (shift < 0) this.shiftEndBackward2(Math.abs(shift), minSpan)
    }


    shift(direction: Direction, span_minutes: number) {

        if (direction == "F") {

            this.shiftEndForward2(span_minutes)
            this.shiftStartForward2(span_minutes, 0)
        }
        else {
            this.shiftStartBackward2(span_minutes)
            this.shiftEndBackward2(span_minutes, 0)
        }
    }

    shiftEdge(edge: SegmentEnd, direction: Direction, span_minutes: number, minLength_minutes: number) {

        if (edge == "S") this.shiftStart2(direction, span_minutes, minLength_minutes)
        if (edge == "E") this.shiftEnd2(direction, span_minutes, minLength_minutes)
    }


    shiftStartForward2(diff_minutes: number, minSpan: number) {

        //const point = this.timepoint

        //const diff_milisecons = Math.abs(threshold.getTime() - point.getTime())

        //const diff_minutes = Math.floor((diff_milisecons / 1000) / 60);

        this.timepoint.addMinutes(diff_minutes) // = threshold

        //TODO: NEED CALC DIFF IN MINUTES 
        if (diff_minutes * 60000 < this.timespan_ms) this.timespan_ms -= diff_minutes * 60000; else this.timespan_ms = 0

        if (this.timespan_ms == 0) this.timespan_ms = minSpan * 60000
    }

    shiftEndForward(threshold: Date) {

        if (threshold < this.end()) throw Error('Shift is not forward')

        //if (this.timepoint > threshold) throw Error()

        const diff_ms = threshold.getTime() - this.end().getTime()


        this.timespan_ms += diff_ms
    }

    shiftEndForward2(diff_minutes: number) {

        // const diff_ms = Math.abs(threshold.getTime() - this.end().getTime())
        // const diff_minutes = Math.floor((diff_ms / 1000) / 60);

        this.timespan_ms += diff_minutes * 60000
    }


    shiftStart2(direction: Direction, diff_minutes: number, minLength_minutes: number) {

        if (direction == "F") this.shiftStartForward2(diff_minutes, minLength_minutes)
        if (direction == "B") this.shiftStartBackward2(diff_minutes)
    }

    shiftEnd2(direction: Direction, diff_minutes: number, minLength_minutes: number) {

        if (direction == "F") this.shiftEndForward2(diff_minutes)
        if (direction == "B") this.shiftEndBackward2(diff_minutes, minLength_minutes)
    }

    shiftStartBackward(threshold: Date) {

        if (threshold > this.timepoint) throw Error("shifit is not backward")

        const diff_ms = Math.abs(threshold.getTime() - this.end().getTime())
        //const diff_minutes = Math.floor((diff_ms / 1000) / 60);

        this.timepoint = threshold
        this.timespan_ms = diff_ms

        //this.timepoint.addMinutes(- diff_minutes)
    }

    shiftStartBackward2(diff_minutes: number) {

        //const diff_ms = Math.abs(threshold.getTime() - this.timepoint.getTime())
        //const diff_minutes = Math.floor((diff_ms / 1000) / 60);

        const diff_ms = diff_minutes * 60000;

        this.timepoint.addMinutes(- diff_minutes)
        this.timespan_ms += diff_ms
    }

    shiftEndBackward(threshold: Date, minSpan: number) {

        if (threshold > this.end()) throw Error('Shift is not backward')

        if (minSpan < 0) console.warn('minSpan wrong value: %d', minSpan)
        if (minSpan < 0) minSpan = 0;

        const point = this.end()

        const diff_milisecons = Math.abs(threshold.getTime() - point.getTime())

        //const diff_minutes = Math.floor((diff_milisecons / 1000) / 60);

        if (diff_milisecons > this.timespan_ms) {

            const diff_start = (diff_milisecons - this.timespan_ms)

            this.timepoint.addMilliseconds(-1 * (diff_start))
        }
        else {

            this.timespan_ms -= diff_milisecons;
        }

        if (this.timespan_ms < minSpan * 60000) {

            const diff_start = minSpan * 60000 - this.timespan_ms

            this.timepoint.addMilliseconds(-1 * diff_start)

            this.timespan_ms = minSpan * 60000
        }
    }

    shiftEndBackward2(diff_minutes: number, minSpan: number) {

        const diff_ms = diff_minutes * 60000
        const minSpan_ms = minSpan * 60000;

        if (minSpan < 0) console.warn('minSpan wrong value: %d', minSpan)
        if (minSpan < 0) minSpan = 0;

        const point = this.end()

        if (diff_ms > this.timespan_ms) {

            const diff_start = (diff_ms - this.timespan_ms)

            this.timepoint.addMinutes(-1 * (diff_start))
        }
        else {

            this.timespan_ms -= diff_minutes * 60000;
        }

        if (this.timespan_ms < minSpan_ms) {

            const diff_start = minSpan_ms - this.timespan_ms

            this.timepoint.addMilliseconds(-1 * diff_start)

            this.timespan_ms = minSpan_ms
        }
    }

    //
    static nextTimepoint(segments: TimeSegment[], threshold: Date): Date | null {
        const buffer: Date[] = []

        segments.forEach(s => {

            if (s.timepoint > threshold) buffer.push(s.timepoint)

            const e = s.timepoint.cloneAndAddMilliseconds(s.timespan_ms)

            if (e > threshold) buffer.push(e)
        })


        if (buffer.length == 0) return null

        buffer.sort((a, b) => a.getTime() - b.getTime());

        return buffer[0]
    }

    static merge(timeSegments: TimeSegment[]): TimeSegment[] {

        if (timeSegments.length <= 1) {
            return timeSegments;
        }

        // Step 1: Sort the array by 'timepoint'
        timeSegments.sort((a, b) => a.timepoint.getTime() - b.timepoint.getTime());

        const mergedSegments: TimeSegment[] = [];
        let currentSegment = timeSegments[0];

        // Step 2: Merge adjacent time segments
        for (let i = 1; i < timeSegments.length; i++) {
            const nextSegment = timeSegments[i];

            if (currentSegment.timepoint.cloneAndAddMilliseconds(currentSegment.timespan_ms) === nextSegment.timepoint) {
                // Merge the segments
                currentSegment.timespan_ms += nextSegment.timespan_ms;
            } else {
                // Add the current segment to the result and update currentSegment
                mergedSegments.push(currentSegment);
                currentSegment = nextSegment;
            }
        }

        // Add the last remaining segment
        mergedSegments.push(currentSegment);

        return mergedSegments;
    }


    static array_MinuteNumberArray(value: number[], base: Date) {


        // console.log('min-to-segment')
        // console.log(base)


        const buffer: TimeSegment[] = []

        for (let index = 0; index + 1 < value.length; index = index + 2) {

            const a = value[index];
            const b = value[index + 1]

            const s = new TimeSegment(base.cloneAndAddMinutes(a), (b - a) * 60000)

            buffer.push(s)
        }

        return buffer
    }

    static toStrings(s: TimeSegment[]) {

        const buffer: string[] = [];

        s.forEach(e => {

            const time_start = e.timepoint

            const time_end = e.timepoint.cloneAndAddMilliseconds(e.timespan_ms)
            buffer.push(`${dayjs(time_start).format('H:mm')} - ${dayjs(time_end).format('H:mm')}`)
        })

        return buffer
    }

    static toMinuteNumberArray(segments: TimeSegment[], offset?: number | null) {


        let shift = -1 * new Date().getTimezoneOffset()

        if (offset != undefined && offset != null) shift = offset

        //turn to dayjs with ofsseting

        //order by dayjs

        //array[0] as basic point, end = + span

        function f(datetime: Date) {

            return datetime.getUTCHours() * 60 + datetime.getUTCMinutes() + shift
        }

        if (segments.length == 0) return []

        let minimum = segments[0].timepoint

        segments.forEach(x => { if (x.timepoint.getTime() < minimum.getTime()) minimum = x.timepoint })


        const base = f(minimum)





        const result: number[] = []

        segments.forEach(e => {

            const a2 = (e.timepoint.getTime() - minimum.getTime()) / 60000 + base //divide to 60 * 1000 to get minutes from milliseconds
            const b2 = a2 + e.timespan_min

            //const a = e.timepoint.getUTCHours() * 60 + e.timepoint.getUTCMinutes() + shift
            //const b = a + e.timespan_min

            result.push(a2)
            result.push(b2)
        })

        return result

    }



    static Instance(dto: { timepoint: string, timespan_ms: number, startex?: boolean, endex?: boolean }) {

        const result = new TimeSegment(new Date(dto.timepoint), dto.timespan_ms)

        if (dto.startex != undefined) result.startex = dto.startex
        if (dto.endex != undefined) result.endex = dto.endex

        return result
    }

    constructor(d: Date, span_ms?: number) {

        this.timepoint = d;

        if (span_ms != undefined) {
            this.timespan_ms = span_ms
        }
    }



    end() {

        return this.timepoint.cloneAndAddMilliseconds(this.timespan_ms)
    }

    setEnd(value: Date) {

        if (value < this.timepoint) {
            sys.warn("Trying to set end lower then start. Set span to 0.")

            this.timespan_ms = 0
        }
        else this.timespan_ms = absDiffInMs(this.timepoint, value)
    }

    setStart(value: Date) {

        const e = this.end()

        if (value > e) {

            sys.warn('Trying set start later then end')
            this.timepoint = value
            this.timespan_ms = 0
        }
        else {
            this.timespan_ms = absDiffInMs(e, value)
            this.timepoint = value
        }

    }

    toString() {


        return `${dayjs(this.timepoint).format("HH:mm:ss.SSS")} - ${dayjs(this.timepoint.cloneAndAddMilliseconds(this.timespan_ms)).format("HH:mm:ss.SSS")}`
    }

    toString_Short(offset: number | null) {

        const a = dayjs(this.timepoint)
        const b = dayjs(this.timepoint.cloneAndAddMilliseconds(this.timespan_ms))

        const format = "HH:mm"

        if (offset != null) return `${a.utcOffset(offset).format(format)} - ${b.utcOffset(offset).format(format)}`

        else return `${a.format(format)} - ${b.format(format)}`
    }

    fit(period: TimeSegment) {

        const x = this
        return x.timepoint >= period.timepoint && x.end() <= period.end()
    }

    static SortEndAscending(a: { segment: TimeSegment }, b: { segment: TimeSegment }) {

        return a.segment.end().getTime() - b.segment.end().getTime()
    }

    static SortEndAscending2(a: TimeSegment, b: TimeSegment) {

        return a.end().getTime() - b.end().getTime()
    }
}

export interface ITimepointTune { //'TimeSegment' tune

    timepoint: Date
    edge: SegmentEnd
    value: Date
}