import * as signalR from '@microsoft/signalr';

import { ITicketStorage, IWorkspaceStorage } from '@/io/storages';
import { Guid } from '@/lib';
import sys from '@/sys';

//assumes that connection seats on workspace wid and ticket n
export default function ticketSignalrStorage(hubConnection: signalR.HubConnection): ITicketStorage {

    const o =
    {
        disconnect() {

            return hubConnection.stop().then(r=>console.log('/signalR/worksapces/ticket - Hub disposed')).catch(sys.exception)
        },

        message(wid:Guid, n:number, message:string, file:string, link:string, localTime?: Date){

            return hubConnection.invoke("message", wid, n, message, file, link, localTime ?? new Date()).catch(sys.exception); 
        },



        description(wid:Guid, n:number, message:string){

            return hubConnection.invoke("description", wid, n, message ).catch(sys.exception); 
        },

        resync(): void {
            
            
        }
    };

    return o;
}