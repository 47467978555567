import { Guid } from "@/lib"

export class Appointment {

    uid: Guid = Guid.newGuid()

    persons: Guid[] = []
    groups: Guid[] = []
    
    datetime: Date = new Date()
    
    weekdays: number[] = []
    monthly: number[] = []
    yearly: number[] = []

    estimation: number = 0
    name: string = ''
    category: string = ''
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

}

// export interface IAppointmentDto {

//     uid: Guid

//     persons: Guid[]
//     groups: Guid[]
    
//     datetime: string
    
//     weekdays: number[] = []
//     monthly: number[] = []
//     yearly: number[] = []

//     estimation: number
//     name: string
// }