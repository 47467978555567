import { Resolution, Status } from '@/logic/workspaces/tickets/_def'
import { PhaseRmodel, ProcessRmodel } from '../process.model'
import { TicketRmodel } from '../ticket.rmodel'
import { VerificationModel } from './tasks/verification.rmodel'
import { PersonRmodel } from '@/app.context/workspace/person.rmodel'
import { BlockRmodel } from './tasks/block.rmodel'
import * as arrays from '@/lib/array.lib'
import { Guid } from '@/lib'


//  = "Good" | "Neutral" | "Warning" | "Bad";

export class TaskRmodel {
    
   
    
    person: PersonRmodel

    ticket: TicketRmodel;

    process: ProcessRmodel | null = null

    resolution: Resolution | null = null

    verifications: VerificationModel[] = []

    blocks: BlockRmodel[] = []
    
    workable: boolean = false

    constructor(person: PersonRmodel, ticket: TicketRmodel) {
        this.ticket = ticket;
        this.person = person;
    }


    isWorkable(){

        if (this.workable) return true;

        const blocks_pending = this.blocks.filter(x=>x.resolution === null)

        return blocks_pending.length == 0
    }

    //todo 9 moving from desk to current should reset bad verification status
    //or we have manually confirm it? like 'okay, got it'?
    /**
     * Good - green, Neutral - gray, Warning - yellow, Bad - red; special - may be something complex of e.g. if task in 'current' position
     * @returns Null if status is 'special' like task is first in queue
     */
    status(): Status | null {
        

        if (this.person.current?.task == this) return null; //this is special mode' - when task is 'current' for person

        if (this.blocks?.length ?? 0){

            //check any un closed blocks (blocks resolved bu unscussesfuly)
            const blocks_faied = this.blocks.filter(x=>x.resolution === Resolution.Failed)

            if (blocks_faied.length) return Status.Bad;

            if (!this.isWorkable()) return Status.Warning;

            return Status.Neutral;
        }


        if (this.verifications?.length ?? 0){

            let buffer:VerificationModel[] = []
            
            buffer = this.verifications.filter(x=>x.resolution === Resolution.Failed);

            if (buffer.length) return Status.Bad;

            buffer = this.verifications.filter(x=>x.resolution === null);

            if (buffer.length) return Status.Good; //if we have some verification still blocked - we used 'green' to indicate that there is stilll something blocked - such as it feels more as 'finished' compared with 'block'

            return Status.Neutral;             

            //check for unresolved verifications
        }

        if (this.resolution === Resolution.Done) return Status.Good;
        if (this.resolution === Resolution.Failed) return Status.Bad;

        return Status.Neutral;
    }

    verificationNew(verifier: PersonRmodel, phase: PhaseRmodel) {

        const model = new VerificationModel(phase ,this, this.person);

        this.verifications.push(model);

        if (verifier != null) {

            model.person = verifier;
        }

        return model;
    }


    blockNew(blocker: PersonRmodel | null): BlockRmodel {

        const blockModel = new BlockRmodel(this);

        this.blocks.push(blockModel);

        if (blocker != null) {

            blockModel.person = blocker;
        }

        return blockModel;
    }

    block(blocker: Guid|null): BlockRmodel {

        //return arrays.single(this.blocks, x => x.person?.nid === blocker || (x.person == null && blocker == null));

        const block = this.blockOrNull(blocker);

        if (block === null) throw new Error(`/workspaces/persons - Can't find block with ${blocker} blocker`)

        return block!;
    }

    blockRemoveIf(b:BlockRmodel){

        arrays.remove(this.blocks, b)
    }

    blockOrNull(blocker: Guid|null): BlockRmodel | null {

        return arrays.singleOrNull(this.blocks, x => x.person?.nid === blocker || (x.person == null && blocker == null));
    }

    blockOrNull_Model(blocker: PersonRmodel): BlockRmodel | null {

        return arrays.singleOrNull(this.blocks, x => x.person == blocker)
    }

    verification(verifier?: Guid, person?: PersonRmodel): VerificationModel {

        if (verifier != undefined) return arrays.single(this.verifications, x => x.person.nid === verifier);

        if (person != undefined) return arrays.single(this.verifications, x => x.person === person);

        throw Error('/workspaces/tickets/task - Wrong arguments - E20230218E-1')
    }

    verificationRemoveIf(v:VerificationModel){

        arrays.remove(this.verifications, v)
    }



    toString(): string {
        return this.ticket.toString() + ':@' + this.person;
    }
}