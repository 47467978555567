import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw, RouteRecordRedirectOption } from 'vue-router'

import Home from '@/app/HomeView.vue'
import Workspace from '@/app/WorkspaceView.vue'
import Account from '@/app/AccountView.vue'

import Project from '@/app/workspace/ProjectSubview.vue'

import Status from '@/app/workspace/StatusSubview.vue'
import Reports from '@/app/workspace/ReportsSubview.vue'
import Calendar from '@/app/workspace/CalendarSubview.vue'
import SettingsOfWorkspace from '@/app/workspace/SettingsSubview.vue'

import Person from '@/app/workspace/PersonSubview.vue'
import PersonTimeline from '@/app/workspace/person/TimelineSubview.vue'

import Ticket from '@/app/workspace/TicketSubview.vue'



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/workspace',
    name: 'workspace',
    props: true,
    component: Workspace,
    children: [
      {
        name: 'ticket',
        path: '/workspace/t/:ticket/:workspace?', 
        component: Ticket,
        props: true
      },
      {
        name: 'project',
        path: '/workspace/:project?/:workspace?',
        component: Project,
        props: true
      },
      {
        name: 'calendar',
        path: '/workspace/c/:workspace?',
        component: Calendar,
        props: true
      },
      {
        name: 'reports',
        path: '/workspace/r//:workspace?',
        component: Reports,
        props: true
      },
      {
        name: 'status',
        path: '/workspace/s/:workspace?',
        component: Status,
        props: true
      },
      {
        name: 'settings',
        path: '/workspace/settings/:workspace?',
        component: SettingsOfWorkspace,
        props: true
      },
      {
        name: 'person',
        path: '/workspace/@:person?/:workspace?',
        component: Person,
        props: true
      },
      {
        name: 'person-timeline',
        path: '/workspace/timeline/@:person?/:workspace?',
        component: PersonTimeline,
        props: true
      },
    ],
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/app/AboutView.vue')
  // }
]

// const router = createRouter({
//   history: createWebHistory(),
//   //mode: 'history'
//   routes
// })



export default routes