import * as array from '@/lib/array.lib'
import * as sys from '@/sys'
import AppContext from '@/context';
import { Resolution } from '@/logic/workspaces/tickets/_def';
import { TicketRmodel } from '@/app.context/workspace/ticket.rmodel';
import { Structure } from '@/logic/_infra/_tech'
import { Entity } from '@/logic/workspaces/_tech'
import { InstructionOperationDto } from '@/io/storages/workspace/_def';
import { IFileStorage, IWorkspaceStorage } from '@/io/storages';
import { Guid } from '@/lib';
import { PersonRmodel } from '@/app.context/workspace/person.rmodel';
import { TodoRmodel } from '@/app.context/workspace/tickets/todo.rmodel';
import { TqueuePosition } from '@/logic/_infra/queues';
import * as todoProcessor from '@/ops/workspaces/tickets/todo.processor'
import { File as Filereocrd } from '@/app.context/workspace/tickets/_spec/Ticket.Im.Rmodel'

/**
 * Takes default project of workspace if item has no specific project set
 * @param context 
 * @param item 
 * @returns 
 */
function itemProject(context: AppContext, item: TicketRmodel) {

    let project = item.project;

    if (project == null) project = context.cursor.workspace!.project_default() //#question:default-project-of-ticket

    if (project == null) throw Error(`/workspaces - Impossible run operation on ticket w/o project - ${item?.n} - E20230207`)

    return project;

}

function ticketStatusUpdate(storage: IWorkspaceStorage, ticket: TicketRmodel, resolution: Resolution | null) {

    const instruction: InstructionOperationDto[] = []

    instruction.push(ticket.update(resolution))

    //item.resolution = status;

    storage.transactionSubmit(ticket.workspace.wid, instruction)

    //this.storage.ticketStatusUpdate(item.workspace.wid, item.n, status)
}

export function ticketResolutionUpdate(context: AppContext, item: TicketRmodel, s: Structure, resolution: Resolution | null) {

    //here implementation

    const ops = context.ops.xbq!;

    // if (item.n == 0) {

    //     sys.warn("/workspaces/ticket - Resolving ticket until N is assigned is not possible")

    //     return
    // }


    // if (e == Entity.Person) {

    //     sys.warn(`/workspaces/person - Unexpected scenario - E20230206-A - $S:{s}/E:${e} - 'todo' item does not make sense for project`)

    //     return;
    // }

    // if (e == Entity.Project) {



    //     return
    // }

    const project = itemProject(context, item)

    if (s == Structure.Queue) {

        if (resolution == Resolution.Done) {
            const instruction_update = item.update(resolution)

            ops.queueClose(item, project, [instruction_update])
        }
        else ticketStatusUpdate(context.storages.workspace!, item, resolution)

        return;
    }

    if (s == Structure.Board) {

        ticketStatusUpdate(context.storages.workspace!, item, resolution)

        return
    }

    sys.warn(`/workspaces/person - Unexpected scenario - E20230206-A - $S:${s} - 'todo' item does not make sense for project`);
}

export function ticket_close(context: AppContext, item: TicketRmodel, s: Structure) {

    const ops = context.ops.xbq!;

    //remove it from project queue

    const project = itemProject(context, item)

    //(1/4)
    if (s == Structure.Queue) {

        ops.queueClose(item, project!)

        return;
    }


    //(2/4)
    if (s == Structure.Board) {

        ops.boardClose(item, project!)

        return;
    }

    //(3/4)
    //ticket at person board - possible in future

    //(4/4)
    //ticket at person queue - never possible, {person, queue} supposed to be only for person 'todos'

    sys.warn(`/workspaces/person - Unexpected scenario - E20230206 - S:${s}`)
}


export function commandSubmitOnTicket2(context: AppContext, command: string, ticket: TicketRmodel) {

    context.storages.workspace!.commandSubmitOnTicket(command, ticket.workspace.wid, ticket.n)
}

export function commandSubmitOnTicket(context: AppContext, command: string, wid: Guid, n: number) {

    context.storages.workspace!.commandSubmitOnTicket(command, wid, n)
}


//(Guid wid, int n, Guid nid, int position)
export function taskCreate(context: AppContext, wid: Guid, n: number, nid: Guid, position: number) {

    context.storages.workspace!.taskCreate(wid, n, nid, position)
}

export function taskCreate_person(context: AppContext, person: PersonRmodel, n: number, position: number) {

    taskCreate(context, person.workspace.wid, n, person.nid, position)
}

//todoQueue_CreateOrPut
export function personUpdate(context: AppContext, t: TicketRmodel | TodoRmodel, person: PersonRmodel, index: number | null) {


    let ticket_b: TicketRmodel

    if (t instanceof TodoRmodel) ticket_b = t.ticket
    else ticket_b = t


    if (index == null) {

        const task = ticket_b.task(person.nid)

        array.remove(ticket_b.tasks, task)

    }



    if (index == null) //cancel
    {

        const item = person.todoItemOrNull_AtQueue_N(ticket_b.n, 'T', null)

        if (item != null) todoProcessor.todo_cancel(context, item) // ticket_closeclose(item, Structure.Queue, Entity.Person)

        else sys.warn(`Can't find task to close. N:${ticket_b.n} Person:${person.name}`)
    }
    else //assign or reassing (mean just change the position of existng task at queue)
    {

        const buffer = person.todoItemOrNull(ticket_b.n, "T", null)

        if (buffer != null) { //just update position

            const position = TqueuePosition.Instance(index)

            let todo_replacement: TodoRmodel | null = null

            if (person.current == buffer) {

                todo_replacement = person.todo_next()
            }

            context.ops.xbq!.todoQueue(buffer, person, position, todo_replacement)


        }
        else {
            //server automatical archieve current resolved task if we recreate it

            context.storages.workspace!.taskCreate(ticket_b.workspace.wid, ticket_b.n, person.nid, index)
        }


    }
}

export function addFiles(storage_files: IFileStorage, storage_workspaces:IWorkspaceStorage, ticket: TicketRmodel, files: File[]) {

    files.forEach(x => console.log(x))

    const t = ticket

    //const s =  storage_files

    files.forEach(x => {

        storage_files.push(t.workspace.wid, t.n, x.name, x, x.type)
        .then(r => {

            const f = new Filereocrd(x.name, r.identificator)

            f.url = r.url



            storage_workspaces.file(t.workspace.wid, t.n, r.identificator, x.name, r.url)
            
        })
    })
}


// export function descriptionUpdate(context: AppContext, t: TicketRmodel, description:string){

//     //const operation = t.update_description(description)

//     //context.storages.workspace!.transactionSubmit(t.workspace.wid, [operation])

//     context.storages.ticket!.description(t.workspace.wid, t.n, description)

    
// }