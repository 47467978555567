import { Resolution } from "@/logic/workspaces/tickets/_def";
import { PhaseRmodel } from "@/app.context/workspace/process.model";
import { TaskRmodel } from "../task.rmodel";
import { PersonRmodel } from "../../person.rmodel";
import { EntityUpdateDto } from "@/io/storages/workspace/EntityOperation";

// Verification Todo Model
export class VerificationModel {
    
    phase: PhaseRmodel
    person: PersonRmodel
    
    resolution: Resolution | null = null
    adhoc = false
    task:TaskRmodel

    constructor(phase: PhaseRmodel, task:TaskRmodel, person:PersonRmodel) {
        this.phase = phase;
        this.task = task;
        this.person = person;
    }
}