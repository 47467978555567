import { PersonRmodel } from '../person.rmodel';
import { EffortRmodel, ExactMeffortRmodel, Meffort, SimpleMeffort, Timeffort } from './sunrises/effort.rmodel';
import { ITimepointTune, Sunrise, TimeSegment } from '@/logic/_infra/time';
import sys from '@/sys';
import { ITimeffortDto, ITimespendingDto, timeffortUpdate } from '@/io/dto/workspaces/persons/effort.dto';
import { ISunriseReportDto } from '@/io/dto/workspaces/persons/sunriseReport.dto';
import { ITodoRefDto, ITodoResolutionDto } from '@/io/dto/workspaces/tickets/todos.ns';
import AppContext from '@/context';
import { ITimeSegmentDto } from '@/io/dto/infrastructure.dto.ns';
import dayjs from 'dayjs';
import { Guid } from '@/lib';
import { Timespending } from '../Event';
import { Direction, SegmentEnd } from '@/logic/_infra/_tech';
import { roundToClosestQuarterHour as closestRoundQuarterHour, absDiffInMs } from '@/lib/date'
import { ITodoDerivativeRef, TodoDerivativeRef, TodoRef } from '@/logic/workspaces/_tech';
import { remove_batch } from '@/lib/array.lib'
import { TodoItemType } from '@/logic/workspaces/tickets/todos/_def.todos';
import { Category } from '@/logic/workspaces/persons/_def';
import { OccupationtRef, EffortResolution, EffortStat, IResolution, PersonQueueUpdate } from '@/logic/workspaces/tickets/_def';
import { min, pull } from 'lodash-es';
import { WorkspaceRmodel } from '@/app.context/workspace.rmodel';


export class SunriseReportRmodel {


    timespending_timesegment_auto(): { timepoint: Date, milliseconds: number | null } {

        const now = new Date()

        // date.setUTCFullYear(this.period.timepoint.getUTCFullYear())  
        // date.setUTCMonth(this.period.timepoint.getUTCMonth())
        // date.setUTCDate(this.period.timepoint.getUTCDate())


        let buffer: TimeSegment

        if (this.endeavours_Total.length > 0) {



            buffer = new TimeSegment(this.endeavours_Total[0].timepoint, 900000) //900 000 = 15 * 60 * 1000
        }
        else {
            const date = new Date()

            //this.endeavours_Total[0].timepoint.copyTo(date)

            date.setUTCFullYear(this.period.timepoint.getUTCFullYear())
            date.setUTCMonth(this.period.timepoint.getUTCMonth())
            date.setUTCDate(this.period.timepoint.getUTCDate())

            date.setUTCMilliseconds(0)
            date.setUTCMinutes(0)

            date.setHours(12)

            buffer = new TimeSegment(date, 3600000) //1000*60*60 = 3 600 000 //1hour
        }


        if (buffer.end() > now) {

            if (buffer.timepoint >= now) return { timepoint: now, milliseconds: null }

            else return { timepoint: buffer.timepoint, milliseconds: null }
        }
        else return { timepoint: buffer.timepoint, milliseconds: buffer.timespan_ms }
    }

    createTimespending(name: string, category: string | null, start: Date, milliseconds_param: number | null): Timespending {

        const now = new Date()

        if (start > now) throw new Error('Argument is incorrect - start')

        const timespending = new Timespending()

        timespending.objects.title = name
        timespending.objects.category = category

        const milliseconds = milliseconds_param == null ? now.getTime() - start.getTime() : milliseconds_param

        timespending.segment = new TimeSegment(start, milliseconds)

        if (milliseconds_param == null) {

            this.timespendings_Current.push(timespending)
        }
        else {

            this.timespendings.push(timespending)
        }

        EffortSummary.Instance(this, this.summary)

        return timespending

    }

    createTimespendingIsExecutable() {

        return this.period.timepoint.getDate() <= new Date().getTime()
    }

    test() {

        //ensure all current timespending ending is same
        if (this.timespendings_Current != null) {

            const buffer = this.timespendings_Current.map(x => x.segment.end())

            for (let i = 1; i < buffer.length; i++) {

                const c = buffer[i];
                const p = buffer[i - 1];

                if (c != p) throw new Error(`Current timespending date mismathces. Index: ${i} ${c} vs ${p}`)

            }
        }


        for (let i = 0; i < this.timefforts.length - 1; i++) {

            const c = this.timefforts[i];
            const n = this.timefforts[i + 1]

            if (c.end > n.start) throw new Error(`Timefforts overlpaing. Index: ${i} ${c} vs ${n}`)
        }


    }


    identificator: { nid: Guid, sunrise: Sunrise } | null = null

    title: string = ''

    person: PersonRmodel

    //-

    timestamp: Date = new Date()

    period: TimeSegment

    //**Person sunriser (NOT user (viewer)) */
    sunrise: Sunrise = new Sunrise(1, 1, 1) //this is 'person' sunrise - it may not be equal to user (viewer) sunrise on 'workspace model' lever;



    get starts(): Date {

        return this.period.timepoint
    }

    get expires(): Date {

        return this.period.end()
    }

    //--

    obligations: number = 0;

    //--

    endeavours: TimeSegment[] = []

    endeavours_Total: TimeSegment[] = []

    offset: number | null = 0

    summary: EffortSummary = new EffortSummary(this)

    //--

    //efforts: EffortRmodel[] = []

    asides: Timeffort[] = [] //deprioritezd, but can be just blocked

    timefforts: Timeffort[] = []

    timeffort_Current: Timeffort | null = null

    mefforts: { simple: SimpleMeffort[], exact: ExactMeffortRmodel[] } = { simple: [], exact: [] }

    //efforts.adjustments effo

    //current: EffortRmodel | null = null

    timespendings: Timespending[] = []

    timespendings_Current: Timespending[] = []

    //tunes_v1: Map<Date, { type: SegmentEnd | null, value: Date }> = new Map()

    tunes: Map<number, { start: Date | null, end: Date | null }> = new Map() //key is 'getTime()' of Date object

    //current_Events: EffortRmodel[] = []

    //--

    predecessor: Sunrise | null = null

    //--



    static Instance(person: PersonRmodel, period: ITimeSegmentDto, dto: ISunriseReportDto, context: AppContext) {

        const result = new SunriseReportRmodel(person, TimeSegment.Instance(period))

        result.setup(context, dto)

        return result;
    }


    static Instance_Summary(person: PersonRmodel, period: ITimeSegmentDto, dto: ISunriseReportDto, context: AppContext) {

        const r = SunriseReportRmodel.Instance(person, period, dto, context)

        return r.summary

        //return EffortSummary.Instance(r)
    }

    constructor(person: PersonRmodel, period: TimeSegment) {

        this.person = person
        this.period = period
    }

    isOutdated(threshold: Date) {

        //this.period.timepoint

        const p = TimeSegment.nextTimepoint(this.endeavours_Total, this.timestamp)

        if (p == null) return false

        return (p <= threshold)

    }

    setup(context: AppContext, dto: ISunriseReportDto) {

        const model = this

        model.period = TimeSegment.Instance(dto.period)

        model.offset = dto.offset

        model.sunrise = Sunrise.Instance(dto.sunrise)

        if (dto.predecessor != null) model.predecessor = Sunrise.Instance(dto.predecessor)

        if (dto.sunrise != null) {



            model.title = dayjs(dto.period.timepoint).format('ddd, DD MMM')
        }

        model.endeavours.replaceWith(dto.endeavours.map(e => TimeSegment.Instance(e)))
        //result.endeavours.length = 0
        //r.endeavours.forEach(e=> result.endeavours.push(TimeSegment.Instance(e)))

        model.endeavours_Total.length = 0
        dto.endeavours_Total.forEach(e => model.endeavours_Total.push(TimeSegment.Instance(e)))




        function personAsideInstance(dto: ITodoResolutionDto): Timeffort {

            const timeffort = new Timeffort()

            const d = new Date(dto.timepoint)

            timeffort.start = d
            timeffort.end = d

            timeffortUpdate(timeffort, dto)

            timeffortTodoIf(timeffort, dto.todo)

            return timeffort
        }



        function timeffortTodoIf(timeffort: Timeffort, ref: ITodoRefDto) {

            PersonRmodel.ObjectsSetup(timeffort.objects, model.person, ref, context.service)


        }

        function personTimeffortInstance(dto: ITimeffortDto): Timeffort {

            const timeffort = new Timeffort()

            const segment = TimeSegment.Instance(dto.segment)

            timeffort.start = segment.timepoint
            timeffort.end = segment.end()
            timeffort.startex = segment.startex
            timeffort.endex = segment.endex

            timeffort.effortType = dto.type

            timeffort.isPaused = dto.isPaused

            if (dto.todo != null) {


                timeffortUpdate(timeffort, dto.todo)

                timeffortTodoIf(timeffort, dto.todo.todo)
            }

            return timeffort
        }

        model.timeffort_Current = null
        if (dto.timeffort_Current != null) {
            model.timeffort_Current = personTimeffortInstance(dto.timeffort_Current)
        }

        model.timefforts.clear()
        if (dto.timefforts != null) {



            dto.timefforts.forEach(dto => {

                model.timefforts.push(personTimeffortInstance(dto))
            })
        }



        model.mefforts.exact.clear()


        if (dto.mefforts?.exact != null) {

            dto.mefforts.exact.forEach(dto => model.mefforts.exact.push(ExactMeffortRmodel.Instance(dto, this.person, context.service)))



            //model.mefforts.exact.forEach(x=>x.objects)
        }
        else throw Error('null argument if (dto.mefforts?.exact  != null){')

        model.mefforts.simple.clear()


        if (dto.mefforts?.simple != null) {

            dto.mefforts.simple.forEach(dto => model.mefforts.simple.push(SimpleMeffort.Instance(dto, this.person, context.service)))
        }


        model.tunes.clear()
        if (dto.tunes != null) {

            dto.tunes.forEach(t => {

                const date = new Date(t.date).getTime()

                if (model.tunes.has(date)) {

                    const x = model.tunes.get(date)!

                    if (t.edge == "S") x.start = new Date(t.value)
                    if (t.edge == "E") x.end = new Date(t.value)
                }
                else model.tunes.set(date, { start: t.edge == "S" ? new Date(t.value) : null, end: t.edge == "E" ? new Date(t.value) : null })
            })
        }

        model.asides.length = 0
        if (dto.asides != null) dto.asides.forEach(dto => model.asides.push(personAsideInstance(dto)))


        function personTimespendingInstance(dto: ITimespendingDto): Timespending {

            const row = new Timespending()

            row.tid = dto.tid
            row.segment = TimeSegment.Instance(dto.segment)

            row.objects.category = dto.category

            row.objects.setGroups(dto.groups, model.person.workspace) //.a.groups.replaceWith(dto.groups)
            row.objects.setPersons(dto.persons, model.person.workspace)  // row.persons.replaceWith(dto.persons)


            row.objects.title  = dto.name ?? ''
            //row.tag = dto.tag
            row.appointed = dto.appointment != null

            row.objects.setTicket(dto.ticket, model.person.workspace)
            row.objects.todo_type = dto.type
            row.objects.todo_person_aux = dto.person_aux


            

            //Todo.Copy(dto, row)

            if (dto.appointment != null) {



                if (dto.appointment.meeting != null) row.objects.meeting = model.person.workspace.meetingOrBillet(dto.appointment.meeting, context.service)


            }



            return row
        }



        this.timespendings.length = 0
        if (dto.timespendings) {

            dto.timespendings.forEach(t => {

                this.timespendings.push(personTimespendingInstance(t))
            })
        }



        this.timespendings_Current.length = 0
        if (dto.timespendings_Current) {

            dto.timespendings_Current.forEach(t => {

                this.timespendings_Current.push(personTimespendingInstance(t))
            })
        }

        model.obligations = dto.obligations

        EffortSummary.Instance(this, model.summary)
    }

    endeavours_total() {

        let result = 0
        this.endeavours_Total.forEach(x => result += x.timespan_min)

        return result
    }


}

///al time in minutes
export class StatusSummary {

    //self:StatusSummary = this

    incrementForcemajour(minutes: number) {

        this.forcemajour += minutes
    }

    updateForcemajourIfGreater(minutes: number) {

        if (minutes > this.forcemajour) this.forcemajour = minutes
    }

    incrementIdle(minutes: number) {

        this.idle += minutes
    }

    updateIdleIfGreater(minutes: number) {

        if (minutes > this.idle) this.idle = minutes
    }

    add(increment: StatusSummary) {



        increment.categories.forEach((span, c) => {

            this.incrementCategory(c, span)
        })



        increment.derivative.forEach((span, d) => {

            this.incrementDerivative_json(d, span)
        })



        increment.tickets.forEach((span, t) => { this.incrementTicket(t, span) })

        increment.tasks.forEach((span, t) => { this.incrementTask(t, span) })

        this.meetings += increment.meetings


        this.forcemajour += increment.forcemajour


        this.idle += increment.idle


        this.intertask += increment.intertask


        this.langoliered += increment.langoliered;



        increment.notices.forEach((span, n) => { this.incrementNotice(n, span) })




    }


    total() {

        let buffer = 0;


        this.categories.forEach(x => buffer += x)

        this.notices.forEach(x => buffer += x)


        this.derivative.forEach(x => buffer += x)

        this.tickets.forEach(x => buffer += x)

        this.tasks.forEach(x => buffer += x)

        buffer += this.meetings;

        buffer += this.forcemajour;

        buffer += this.idle

        buffer += this.intertask

        buffer += this.langoliered;



        return buffer
    }

    reset() {

        this.categories.clear()
        this.derivative.clear()
        this.tickets.clear()
        this.tasks.clear()
        this.meetings = 0
        this.forcemajour = 0
        this.idle = 0
        this.intertask = 0
        this.langoliered = 0
        this.notices.clear()
        this.tickets.clear()
        this.tasks.clear()

    }

    updateIfGreater(item: OccupationtRef , minutes: number) {

        const selfie = this

        const operator1 = {

            ticket(n: number, minutes: number){selfie.updateTicketIfGreater(n, minutes)} ,
            derivative(r: TodoDerivativeRef, minutes: number){selfie.updateDerivativeIfGreater(r, minutes)} ,
            category(n: string, minutes: number){selfie.updateCategoryIfGreater(n, minutes)} ,
            notice(n: string, minutes: number){selfie.updateNoticeIfGreater(n, minutes)},
            task(n: number, minutes: number) {selfie.updateTaskIfGreater(n, minutes)} ,

            meeting(minutes: number) {

                if (minutes > selfie.meetings) selfie.meetings = minutes;
            },

            langoliers(minutes: number) {

                if (minutes > selfie.langoliered) selfie.langoliered = minutes;
            },



            idle: selfie.updateIdleIfGreater,

            forcemajour: selfie.updateForcemajourIfGreater,

            // forcemajour(minutes:number){

            //     if (minutes > selfie.forcemajour) selfie.forcemajour = minutes;
            // },

            intertask(minutes: number) {

                if (minutes > selfie.intertask) selfie.intertask = minutes;
            },
        }

        this.increment_underlying(item, minutes, operator1)


    }

    //increment(item:  { /*ticket: number, type: TodoItemType | null, person_aux: Guid | null, name: string | null, category?: string | null,*/ objects: OccupationtRef }, minutes: number) {
    increment(item: OccupationtRef , minutes: number) {

        const selfie = this

        const operator1 = {

            ticket(n:number, minutes:number){selfie.incrementTicket(n, minutes)},
            derivative(r: TodoDerivativeRef, minutes: number){selfie.incrementDerivative(r, minutes)},
            category(n: string, minutes: number){selfie.incrementCategory(n, minutes)} ,
            notice(n: string, minutes: number){selfie.incrementNotice(n, minutes)} ,
            task(n: number, minutes: number) {selfie.incrementTask(n, minutes)} ,

            meeting(minutes: number) {

                selfie.meetings += minutes;
            },

            langoliers(minutes: number) {

                selfie.langoliered += minutes;
            },

            idle: selfie.incrementIdle,

            forcemajour: selfie.incrementForcemajour,

            intertask(minutes: number) {

                selfie.intertask += minutes;
            },



            // else if (item.category == Category.I) this.idle += minutes
            //     else if (item.category == Category.L) this.langoliered += minutes
            //     else if (item.category == Category.P) this.intertask += minutes
            //     else if (item.category == Category.X) this.forcemajour += minutes
        }

        this.increment_underlying(item, minutes, operator1)


    }

    increment_underlying
        (
            item: OccupationtRef,
            // item: {
            //     // ticket: number,
            //     // type: TodoItemType | null,
            //     // person_aux: Guid | null,
            //     // name: string | null,
            //     // category?: string | null,
            //     objects: 
            // },
            minutes: number,

            operation: {

                ticket: (n: number, minutes: number) => void,
                derivative: (r: TodoDerivativeRef, minutes: number) => void,
                category: (c: string, minutes: number) => void,
                notice: (n: string, minutes: number) => void,
                task: (n: number, minutes: number) => void,
                meeting: (minutes: number) => void,
                langoliers: (minutes: number) => void,
                forcemajour: (minutes: number) => void,
                intertask: (minutes: number) => void,
                idle: (minutes: number) => void
            }
        ) {

        const selfie = this

        //const m = e.meffort

        if (item.meeting != null) {

            //this.meetings += minutes

            operation.meeting(minutes)
        }
        // else if ((item.objects.ticket ?? item.ticket) != 0 != null /*|| item.objects.todo != null*/) {

        //     //const ref = TodoRmodel.ticketOrTodoRef({ ticket: item.objects.ticket ?? undefined, todo: item.objects.todo ?? undefined })

        //     const n = item.objects.ticket ?? item.ticket

        //     if (ref.ticket != 0) {

        //         if (TodoDerivativeRef.IsDerivative(ref)) {

        //             const ref_d = Todo.derivartifeRef(ref) //'referene/derivative'

        //             operation.derivative(ref_d, minutes)

        //         }
        //         else if (ref.type == 'T') operation.task(ref.ticket, minutes)
        //         else operation.ticket(ref.ticket, minutes)
        //     }
        //     else sys.warn('Can not read efforts')
        // }
        else {

            if ((item.ticket?.n ?? 0) != 0) {

                if (item.IsDerivative()) {

                    const ref = TodoDerivativeRef.Instance(item.ticket!.n, item.todo_type!, item.todo_person_aux!)

                    //const ref_d = Todo.derivartifeRef(ref) //'referene/derivative'

                    operation.derivative(ref, minutes)
                }
                else if (item.todo_type == "T") operation.task(item.ticket!.n, minutes)

                else operation.ticket(item.ticket!.n, minutes)

            }
            else if (item.category != undefined && item.category != null && item.category.length > 0) {

                //cat, may be special

                if (item.category == Category.M) operation.meeting(minutes)
                else if (item.category == Category.I) operation.idle(minutes)
                else if (item.category == Category.L) operation.langoliers(minutes) //this.langoliered += minutes
                else if (item.category == Category.P) operation.intertask(minutes) //this.intertask += minutes
                else if (item.category == Category.X) operation.forcemajour(minutes) //this.forcemajour += minutes

                else operation.category(item.category, minutes)
            }
            else operation.notice(item.title, minutes)
        }

    }

    incrementTicket(n: number, minutes: number) {

        let value = this.tickets.get(n) ?? 0

        value += minutes

        this.tickets.set(n, value)
    }

    updateTicketIfGreater(n: number, minutes: number) {

        let value = this.tickets.get(n) ?? 0

        if (minutes > value) value = minutes

        this.tickets.set(n, value)
    }

    incrementTask(n: number, minutes: number) {

        let value = this.tasks.get(n) ?? 0

        value += minutes

        this.tasks.set(n, value)
    }

    updateTaskIfGreater(n: number, minutes: number) {

        let value = this.tasks.get(n) ?? 0

        if (minutes > value) {

            value += minutes

            this.tasks.set(n, value)
        }
    }


    incrementDerivative(r: TodoDerivativeRef, minutes: number) {

        const r_json = JSON.stringify(r)

        this.incrementDerivative_json(r_json, minutes)
    }

    updateDerivativeIfGreater(r: TodoDerivativeRef, minutes: number) {

        const r_json = JSON.stringify(r)

        this.incrementDerivative_json(r_json, minutes)
    }

    incrementDerivative_json(r_json: string, minutes: number) {

        //const r_json = JSON.stringify(r)

        let value = this.derivative.get(r_json) ?? 0

        value += minutes

        this.derivative.set(r_json, value)
    }

    updateDerivativeIfGreater_json(r_json: string, minutes: number) {

        //const r_json = JSON.stringify(r)

        let value = this.derivative.get(r_json) ?? 0

        if (minutes > value) value = minutes

        this.derivative.set(r_json, value)
    }

    incrementNotice(n: string, minutes: number) {

        let value = this.notices.get(n) ?? 0

        value += minutes

        this.notices.set(n, value)
    }

    updateNoticeIfGreater(n: string, minutes: number) {

        let value = this.notices.get(n) ?? 0

        if (minutes > value) {
            value = minutes

            this.notices.set(n, value)
        }
    }

    incrementCategory(n: string, minutes: number) {

        let value = this.categories.get(n) ?? 0

        value += minutes

        this.categories.set(n, value)
    }

    updateCategoryIfGreater(n: string, minutes: number) {

        let value = this.categories.get(n) ?? 0

        if (minutes > value) value = minutes

        this.categories.set(n, value)
    }

    occupations(w:WorkspaceRmodel){

        const result:{ref:OccupationtRef, minutes: number}[] = []

        if (this.intertask > 0)
        {
            const ref = new OccupationtRef()

            ref.category = Category.P

            result.push({ref: ref, minutes: this.intertask})
        }

        if (this.forcemajour > 0){

            const ref = new OccupationtRef()

            ref.category = Category.X

            result.push({ref, minutes:this.forcemajour})
        }

        if (this.idle > 0){

            const ref = new OccupationtRef()

            ref.category = Category.I

            result.push({ref, minutes: this.idle})
        }

        if (this.langoliered > 0){

            const ref  = new OccupationtRef()

            ref.category = Category.L

            result.push({ref, minutes: this.langoliered})
        }
        

        if (this.meetings > 0){

            const ref = new OccupationtRef()

            ref.category = Category.M

            result.push({ref, minutes: this.meetings})
        }

        this.notices.forEach((minutes, n)=>{

            const ref = new OccupationtRef()

            ref.category = Category.N

            ref.title = n

            result.push({ref, minutes})
        })

        this.categories.forEach((minutes, c)=>{

            const ref = new OccupationtRef()

            ref.category = c

            result.push({ref, minutes})
        })

        this.tickets.forEach((minutes, n)=>{

            const ref = new OccupationtRef()

            ref.ticket = w.ticketOrBillet(n)

            result.push({ref, minutes})

        })

        this.tasks.forEach((minutes, n)=>{

            const ref = new OccupationtRef()

            ref.ticket = w.ticketOrBillet(n)

            ref.todo_type = 'T'

            result.push({ref, minutes})
        })

        this.derivative.forEach((minutes, id)=>{


            const r =  <ITodoDerivativeRef>JSON.parse(id)
            
            const ref = new OccupationtRef()

            ref.ticket = w.ticketOrBillet(r.ticket)

            ref.todo_type = r.type

            ref.todo_person_aux = r.person_aux

            result.push({ref, minutes})
            
        })

        return result
    }

    //'green' work, planning
    intertask: number = 0

    langoliered: number = 0

    idle: number = 0

    forcemajour: number = 0

    meetings: number = 0

    notices: Map<string, number> = new Map()

    //no special ('^M') expected - they go to dedicates properties
    categories: Map<string, number> = new Map()

    //<ticket or task N, minutes>
    tickets: Map<number, number> = new Map()

    tasks: Map<number, number> = new Map()

    //JSON.stringify {n:nuber, type:B|V, , nid: nide}
    //{1-V-jfsodfij-fjsldfjs-jflsdkf} - number-type-aux_person_nid
    derivative: Map<string, number> = new Map()
}

export class EffortSummary {

    /**This is object thag goes from server sude; effort summary - is with client side calculatins applied */
    report: SunriseReportRmodel;


    //endeavours_Total: TimeSegment[] = []


    //timeffort_Current: Timeffort | null = null

    setup(context: AppContext, dto: ISunriseReportDto) {
        this.report.setup(context, dto)
    }

    get timeffort_Current() {
        return this.report.timeffort_Current
    }

    get endeavours_Total() {
        return this.report.endeavours_Total
    }

    get sunrise() {

        return this.report.sunrise
    }

    get person() {

        return this.report.person
    }

    get title() {
        return this.report.title
    }

    period: TimeSegment = new TimeSegment(new Date());

    //**In minutes */
    TIMESPENDING_minLENGHT: number = 15 //in minutes



    //**In minutes */
    step = 15

    constructor(r: SunriseReportRmodel) {

        this.report = r
    }


    twitlights: { pre: EffortRmodel[], post: EffortRmodel[] } = { pre: [], post: [] }
    timeline: EffortRmodel[] = []
    current: EffortRmodel | null = null
    timefforts: { segment: TimeSegment, effort: Timeffort }[] = []

    status: StatusSummary = new StatusSummary()
    status_detalization: { holistic: boolean, graytime: number } = { holistic: true, graytime: 0 }
    status_exacts: { totals: StatusSummary, activity: StatusSummary, unallocated: number, overlaps: StatusSummary } = { totals: new StatusSummary(), activity: new StatusSummary(), unallocated: 0, overlaps: new StatusSummary() }
    //key:TodoRef.identificator()
    status_resolutions: Map<string, ({ resolution: IResolution | null, time: Date })[]> = new Map()

    //status: EffortRmodel[] = []
    //status_final: { efforts: EffortRmodel[], asides: Timeffort[] }[] = []


    timefforts_locks: Map<Timeffort, boolean> = new Map()
    timespendings_locks: Map<Timespending, boolean> = new Map()

    mefforts: { segment: TimeSegment, meffort: ExactMeffortRmodel }[] = []

    timestats: EffortStat[] = []

    pqupdates: PersonQueueUpdate[] = []

    // updateStatus_UI(){

    //     this.timestats.clear()
    //     this.pqupdates.clear()

    //     EffortSummary.Status_UI_WriteTo()
    // }

    static Status_UI_writeTo
        (
            effortSummary: EffortSummary,

            timefforts: { segment: TimeSegment, effort: Timeffort }[], //tuned

            timestats: EffortStat[],

            /**person queue updates */
            pqUpdates: PersonQueueUpdate[] //resolutions of asides, stat of 'basic' NONE strictk modes: flex|auto, idle, forcemaour, +/- in todo quee, additional indication if trigger 'focused' to change
        ) 
        {


            timestats.clear()
            pqUpdates.clear()

        function timestatItem(t: number, type: TodoItemType, person_aux: Guid | null, indexator: () => number) {

            const effort = new OccupationtRef()

            // const todo = effortSummary.person.todoItemOrNull_AtQueue_N(t, type, person_aux)

            // if (todo != null) {
            //     effort.todo = todo

            //     effort.ticket = todo.ticket
            // }
            // else {
            //     effort.ticket = effortSummary.person.workspace.ticketOrBillet(t)!
            // }

            effort.ticket = effortSummary.person.workspace.ticketOrBillet(t)!

            const index_timeline = indexator()

            const id = TodoRef.Identificator_Json(t, type, person_aux)

            const resolutions = r.get(id)



            //const b = { effort, minutes: 0, resolution: new EffortResolution(), duplicates: 0, index_timeline: index_timeline }

            const b = new EffortStat(effort)

            b.time = index_timeline

            //b2.minutes

            if (resolutions != undefined && resolutions.length > 0) {

                const resolution = resolutions[resolutions.length - 1]

                if (resolution.resolution != null) b.resolution.set(resolution.resolution)

                b.time = resolution.time.getDate()

                b.duplicates = resolutions.length - 1
            }

            return b
        }

        function timestatItem_empty(effort: OccupationtRef, minutes: number) {

            //return { effort, minutes: minutes, resolution: new EffortResolution(), duplicates: 0, index_timeline: 0 }

            const b = new EffortStat(effort)

            b.minutes = minutes

            return b
        }

        const s = effortSummary.status_exacts
        const r = effortSummary.status_resolutions



        s.totals.tickets.forEach((minutes, t/*ticket number*/) => {

            const ticket = effortSummary?.person.workspace.ticketOrBillet(t)!

            const effort = new OccupationtRef()

            effort.ticket = ticket

            const b = timestatItem_empty(effort, minutes)

            b.time = s.activity.tickets.get(t) ?? 0

            timestats.push(b)
        })



        s.totals.tasks.forEach((minutes, t) => {

            const b2 = timestatItem(t, 'T', null, function () { return s.activity.tasks.get(t) ?? 0 })

            b2.minutes = minutes

            timestats.push(b2)

        })
        s.totals.derivative.forEach((minutes, d) => {

            const todo_ref = TodoRef.Instance_Json(d)

            const b2 = timestatItem(todo_ref.n, todo_ref.type, todo_ref.person_aux, function () { return s.activity.derivative.get(d) ?? 0 })

            b2.minutes = minutes

            timestats.push(b2)

        })
        s.totals.categories.forEach((minutes, c) => {

            const effort = new OccupationtRef()

            effort.category = c

            const b = timestatItem_empty(effort, minutes)

            b.time = s.activity.categories.get(c) ?? 0

            timestats.push(b)

        })
        s.totals.notices.forEach((minutes, n) => {

            const effort = new OccupationtRef()

            effort.category = Category.N

            effort.title = n

            const b = timestatItem_empty(effort, minutes)

            b.time = s.activity.notices.get(n) ?? 0

            timestats.push(b)
        })

        timestats.sort((a, b) => a.time - b.time)

        //time
        //general operation: aside resolve - 'done', 'resolved', 'blocked
        //only 'full review': add to queue (+), task was removed from queue (x)
        //makes sense only for 'short status ivew': start working,  start working with termanaion - by captain, start idle, start forcemajour, 



        //timeline_ops

        effortSummary.report.asides.forEach(a => {

            //const r = EffortResolution.Instance(a.resolution)

            const u = new PersonQueueUpdate(a.start)

            u.resolution.set(a.resolution) 
            

            u.ticket = a.objects.ticket

            if (a.todo != null) {

                u.todo_type = a.todo.type
                u.todo_person_aux = a.todo.person_aux

            }


            

            pqUpdates.push(u)
        })

        

        //if (effortSummary.report.e)

        timefforts.forEach(e => {

            const t = e.effort

            if (t.effortType != null && t.startex && e.effort.span_ms >= 1) //'stop', 'x'
            {
                const u = new PersonQueueUpdate(e.segment.timepoint)

                u.type = t.effortType
                
                pqUpdates.push(u)
            }

        })

        pqUpdates.sort((a, b) => a.time.getDate() - b.time.getDate())

        //#todo5 put +/- of todo items in queue
    }

    static Status_UI(effortSummary: EffortSummary, timefforts: {segment:TimeSegment, effort: Timeffort}[]) {

        const timestats: EffortStat[] = []

        //that fysically happen throug system input on timelne, NOT reported as it was 'that time'
        const timepoints: PersonQueueUpdate[] = []



        this.Status_UI_writeTo(effortSummary, timefforts, timestats, timepoints)


        return { timestats, timepoints }
    }

    mefforts_exact_filter(r:OccupationtRef /* ticket: TicketRmodel | null, todo: TodoRmodel | null*//*, category: string | null*/) {

        return Meffort.Filter(this.report.mefforts.exact,  r /*ticket, todo*//*, category*/)
    }

    mefforts_simple_filter(r:OccupationtRef /* ticket: TicketRmodel | null, todo: TodoRmodel | null, category: string | null*/) {

        return Meffort.Filter(this.report.mefforts.simple, r/*ticket, todo*//*, category*/)
    }


    //'clickl' OK
    
    click(direction: Direction, edge: SegmentEnd, m: EffortRmodel, timeffort: Timeffort | null, timespending: Timespending | null) {

        // m - always
        // timepoint - always
        // f - to specify among zero points 
        // s - to specify among multiple time spending

        const result: { tunes: ITimepointTune[] | null, timespendings: { id: Guid, start: Date, end: Date }[] } = { tunes: null, timespendings: [] }

        if (timeffort != null) {

            if (timespending != null) sys.warn('timespending != null');

            const b: Timeffort[] = []

            let basePoint = new Date()

            for (const t of m.timefforts_zeros) {

                if (t[1] == timeffort) {

                    b.push(t[1])
                    basePoint = t[0]
                }
            }

            if (b.length != 1) throw new Error('Argument: model does not contain timeffort at "zerso"')

            const threshold = this.nextSnapPoint(basePoint, timeffort, direction)

            //const buffer = this.moveTimeffort(direction, timeffort, threshold, edge)

            result.tunes = this.moveTimeffort(direction, timeffort, threshold, edge)

            if (result.tunes == null) return result
        }
        else if (timespending != null) {

            const buffer = m.timespendings.filter(x => x.ref == timespending)

            if (buffer.length != 1) throw Error('Arguemnt: model does not contain timespending specified')

            const operation = this.calculatePotentialShiftForTimespending_OK(direction, edge, timespending) // calculatePotentialShiftForTimespending_OK(direction, edge, timespending)

            if (operation != null) {

                this.applyTimespending([{ timespending: timespending, segment: operation }]).forEach(x => result.timespendings.push(x))

                //result.timespendings.push({id:timespending.id, start:operation.timepoint, end: operation.end()}) 
            }
            else return result
        }
        else if (m.meffort != null) {

            sys.warn('Implement click on meffort')
        }
        else if (m.timeffort != null) {

            const t = m.timeffort

            const basePoint = m.segment_value.point(edge)

            const threshold = this.nextSnapPoint(basePoint, m.timeffort, direction)

            const operation = this.moveTimeffort(direction, t, threshold, edge)

            result.tunes = operation


            if ((direction == 'F' && edge == "E") || (direction == "B" && edge == "S")) {

                let edge_opposite: SegmentEnd | null = null

                if (edge == "E") edge_opposite = 'S'
                if (edge == "S") edge_opposite = 'E'

                if (edge_opposite == null) throw Error('unknow segment end')

                const buffer = this.report.timespendings.concat(this.report.timespendings_Current).filter(x => x.segment.matches(basePoint, edge_opposite!))

                buffer.forEach(x => {

                    const r = this.timespendingPushOrDecline(x, direction, threshold)

                    if (r == null) sys.info('Push declined') //#trace #dev

                    if (r != null) {
                        const segment = x.segment.clone()

                        if (r.start != null) segment.setStart(r.start)
                        if (r.end != null) segment.setEnd(r.end)

                        this.applyTimespending([{ timespending: x, segment: segment }]).forEach(x => result.timespendings.push(x))
                    }


                })
            }

            if (result.tunes == null && result.timespendings.length == 0) return result
        }
        else throw Error('Argument')

        //EffortSummary.Instance(this.report, this)

        this.refresh()

        return result
    }

    refresh() {

        EffortSummary.Instance(this.report, this)
    }


    //OK ('B' method)
    moveTimeffort(direction: Direction, timeffort: Timeffort, threshold: Date, edge: SegmentEnd): ITimepointTune[] | null {

        //- B - / 'doTimeffortMove' func here



        //this.pullStack_OperationOrDecline()

        const buffer = this.split(timeffort)

        if (buffer == null) return null

        let pullArray: { segment: TimeSegment, effort: Timeffort }[] = []
        let pushArray: { segment: TimeSegment, effort: Timeffort }[] = []

        if (direction == 'F') {


            if (edge == "S") {

                pushArray = [buffer.item].concat(buffer.after)
                pullArray = buffer.before
            }

            if (edge == "E") {

                pullArray = buffer.before.concat([buffer.item])
                pushArray = buffer.after
            }


        }

        if (direction == "B") {


            if (edge == "S") {

                pushArray = buffer.before
                pullArray = [buffer.item].concat(buffer.after)
            }

            if (edge == "E") {


                pullArray = buffer.after
                pushArray = buffer.before.concat([buffer.item])



            }
        }



        const operation_pull = this.stackPullOperationOrDecline(pullArray, direction, threshold) // pull(0--timeffort, 'forward', threshold)

        const operation_push = this.stackPushOperationOrDecline(pushArray, direction, threshold)  //this.stackPushOperationOrDecline() push([timeffort+1, end], 'forward, threshold')


        if (operation_pull == null || operation_push == null) return null

        if (operation_pull.length == 0 && operation_push.length == 0) return null

        const operation = operation_push.concat(operation_pull)

        this.applyTunes(operation)

        return operation

    }

    //IN PROGR
    stackPullOperationOrDecline(stack: { segment: TimeSegment, effort: Timeffort }[], direction: Direction, threshold: Date): ITimepointTune[] | null {

        const result: ITimepointTune[] = []

        let threshold_running = threshold

        if (direction == "F") {

            const l = stack.length

            for (let i = l - 1; i >= 0; i--) {

                const element = stack[i];

                if (element.segment.end() < threshold_running) {

                    const isLocked = this.timefforts_locks.get(element.effort) ?? false

                    let segment_copy = element.segment.clone()

                    segment_copy.shiftEndForward(threshold_running)

                    if (isLocked) segment_copy = new TimeSegment(threshold_running.cloneAndAddMilliseconds(-1 * element.segment.timespan_ms), element.segment.timespan_ms)

                    if (segment_copy.fit(this.period)) {

                        const diff = element.effort.segmentDiff(segment_copy, element.segment)

                        diff.forEach(x => result.push(x))

                        threshold_running = segment_copy.timepoint
                    }
                    else return null
                }
                else break;

            }


            return result
        }


        if (direction == "B") {

            for (let i = 0; i < stack.length; i++) {

                const element = stack[i];

                if (element.segment.timepoint > threshold_running) //need to shift elment
                {
                    const isLocked = this.timefforts_locks.get(element.effort) ?? false

                    let segment_copy = element.segment.clone()

                    segment_copy.shiftStartBackward(threshold_running)

                    if (isLocked) segment_copy = new TimeSegment(threshold_running, element.segment.timespan_ms)

                    //check whether it fits limit (period)
                    if (segment_copy.fit(this.period)) {

                        const diff = element.effort.segmentDiff(segment_copy, element.segment)

                        diff.forEach(x => result.push(x))

                        threshold_running = segment_copy.end()
                    }
                    else return null
                }
                else break;
            }

            return result
        }


        return null

    }

    //OK
    stackPushOperationOrDecline(stack: { segment: TimeSegment, effort: Timeffort }[], direction: Direction, threshold: Date): { timepoint: Date, edge: SegmentEnd, value: Date }[] | null {

        const result: { timepoint: Date, edge: SegmentEnd, value: Date }[] = []

        let threshold_running = threshold

        if (direction == "F") {

            for (let i = 0; i < stack.length; i++) {

                const element = stack[i];

                if (element.segment.timepoint < threshold_running) //need to shift elment
                {
                    const isLocked = this.timefforts_locks.get(element.effort) ?? false

                    let segment_copy = element.segment.clone()

                    segment_copy.shiftStartForward(threshold_running, 0)

                    if (isLocked) segment_copy = new TimeSegment(threshold_running, element.segment.timespan_ms)

                    //check whether it fits limit (period)
                    if (segment_copy.fit(this.period)) {

                        const diff = element.effort.segmentDiff(segment_copy, element.segment)

                        diff.forEach(x => result.push(x))

                        threshold_running = segment_copy.end()
                    }
                    else return null
                }
                else break;
            }

            return result
        }

        if (direction == "B") {

            const l = stack.length

            for (let i = l - 1; i >= 0; i--) {

                const element = stack[i];

                if (element.segment.end() > threshold_running) {

                    const isLocked = this.timefforts_locks.get(element.effort) ?? false

                    let segment_copy = element.segment.clone()

                    segment_copy.shiftEndBackward(threshold_running, 0)

                    if (isLocked) segment_copy = new TimeSegment(threshold_running.cloneAndAddMilliseconds(-1 * element.segment.timespan_ms), element.segment.timespan_ms)

                    if (segment_copy.fit(this.period)) {

                        const diff = element.effort.segmentDiff(segment_copy, element.segment)

                        diff.forEach(x => result.push(x))

                        threshold_running = segment_copy.timepoint
                    }
                    else return null
                }
                else break;

            }


            return result
        }

        return null
    }



    split(item: Timeffort): { before: { segment: TimeSegment, effort: Timeffort }[], after: { segment: TimeSegment, effort: Timeffort }[], item: { segment: TimeSegment, effort: Timeffort } } | null {



        const buffer = this.timefforts.filter(x => x.effort == item)

        if (buffer.length == 0) return null

        if (buffer.length > 1) throw Error('buffer.length > 1')

        const result: { before: { segment: TimeSegment, effort: Timeffort }[], after: { segment: TimeSegment, effort: Timeffort }[], item: { segment: TimeSegment, effort: Timeffort } } = { before: [], after: [], item: buffer[0] }

        const index = this.timefforts.indexOf(result.item)

        for (let i = 0; i < index; i++) {

            result.before.push(this.timefforts[i])
        }

        for (let i = index + 1; i < this.timefforts.length; i++) {

            result.after.push(this.timefforts[i])
        }

        return result
    }


    //OK
    applyTimespending(timespenings: { timespending: Timespending, segment: TimeSegment }[]) {


        const result: { id: Guid, start: Date, end: Date }[] = []

        timespenings.forEach(ts => {

            //const x = this.report.timespendings.indexOf(ts.timespending)

            ts.timespending.segment.timepoint = ts.segment.timepoint
            ts.timespending.segment.setEnd(ts.segment.end())

            result.push({ id: ts.timespending.tid, start: ts.segment.timepoint, end: ts.segment.end() })
        })

        return result
    }

    //OK
    applyTunes(tunes: ITimepointTune[]) {

        /*
        tunes:
            - datetime (id)
            - S: datetime 
            - E: datetime

    
        */
        tunes.forEach(t => {

            let x = this.report.tunes.get(t.timepoint.getTime())

            if (x == undefined) {

                if (t.timepoint != t.value) {

                    x =
                    {
                        start: t.edge == 'S' ? t.value : null,
                        end: t.edge == 'E' ? t.value : null
                    }

                    this.report.tunes.set(t.timepoint.getTime(), x)
                }
            }
            else {

                if (t.edge == 'S') {

                    x.start = t.timepoint != t.value ? t.value : null

                }

                if (t.edge == 'E') {

                    x.end = t.timepoint != t.value ? t.value : null
                }

                if (x.end == null && x.start == null) this.report.tunes.delete(t.timepoint.getTime()) //clear if tune does not makes sense
            }

        })



    }

    //OK
    nextSnapPoint(basePoint: Date, timeffort: Timeffort, direction: Direction) {

        const x = closestRoundQuarterHour(basePoint, direction == 'F' ? "forward" : "back")

        if (direction == "F") {

            if (timeffort.start > basePoint && timeffort.start < x) return timeffort.start
            if (timeffort.end > basePoint && timeffort.end < x) return timeffort.end

        }

        if (direction == "B") { //same as for 'F' but SWTICH order of checkkign end/start

            if (timeffort.end < basePoint && timeffort.end > x) return timeffort.end
            if (timeffort.start < basePoint && timeffort.start > x) return timeffort.start
        }

        return x
    }

    //OK
    timespendingPushOrDecline(timespending: Timespending, direction: Direction, threshold: Date) {

        const result: { start: Date | null, end: Date | null } = { start: null, end: null }

        if (direction == "F") {

            if (timespending.segment.timepoint >= threshold) return result
            else {
                let copy = timespending.segment.clone()

                copy.shiftStartForward(threshold, this.TIMESPENDING_minLENGHT)

                const keepLengh = this.timespendings_locks.get(timespending) ?? false

                if (keepLengh) copy = new TimeSegment(threshold, timespending.segment.timespan_ms)

                if (copy.fit(this.period)) {

                    if (copy.timepoint.getTime() != timespending.segment.timepoint.getTime()) result.start = copy.timepoint
                    if (copy.end().getTime() != timespending.segment.end().getTime()) result.end = copy.end()
                }
                else return null
            }
        }

        if (direction == "B") {

            if (timespending.segment.end() <= threshold) return result
            else {
                let copy = timespending.segment.clone();

                copy.shiftEndBackward(threshold, this.TIMESPENDING_minLENGHT);

                const keepLenght = this.timespendings_locks.get(timespending) ?? false

                if (keepLenght) copy = new TimeSegment(threshold.cloneAndAddMilliseconds(-timespending.segment.timepoint), timespending.segment.timespan_ms)

                if (copy.fit(this.period)) {

                    if (copy.timepoint.getTime() != timespending.segment.timepoint.getTime()) result.start = copy.timepoint
                    if (copy.end().getTime() != timespending.segment.end().getTime()) result.end = copy.end()
                }
                else return null
            }
        }

        return result
    }



    calculatePotentialShiftForTimespending_OK(direction: Direction, edge: SegmentEnd, ts: Timespending) {

        //const limit = { start: this.period.timepoint, end: this.period.end() }



        const keepLenght = edge == null //todo reade from map <timespending, locked>

        const segment = ts.segment.clone()

        if (keepLenght) segment.shift(direction, this.step)
        else segment.shiftEdge(edge!, direction, this.step, this.TIMESPENDING_minLENGHT)

        if (!segment.fit(this.period)) return null

        else return segment
    }

    toConsole() {

        const result = this

        console.log('OUTPUT: TIMELINE')

        const FORMAT = 'HH:mm'



        if (result.current != null) {

            console.log('CURRENT:')
            console.log(result.current)
        }

        result.timeline.forEach(x => x.console())

        // console.log('OUTPUT: STATUS')

        // result.status_final.forEach(x => {

        //     x.efforts.forEach(y => console.log(y.toString()))

        //     x.asides.forEach(y => console.log(`->- ${y.toString_timepont(y.end)}`))
        // })
    }

    tech_refreshed = new Date()

    static Instance(r: SunriseReportRmodel, result?: EffortSummary) {


        if (result != undefined) result.tech_refreshed = new Date()

        const timefforts_tuned:
            {
                completed: { segment: TimeSegment, effort: Timeffort }[] //tunied timefforts
                current: { segment: TimeSegment, effort: Timeffort } | null

            } = { completed: [], current: null }

        const timespendings_efforted:
            {
                completed: EffortRmodel[],
                current: EffortRmodel[]

            } = { completed: [], current: [] }

        const twitlights_efforts:
            {
                timefforts: EffortRmodel[],

                timespendings: EffortRmodel[],
            } = { timefforts: [], timespendings: [] }

        //const result = new EffortSummary(r)

        if (result == undefined) result = new EffortSummary(r)
        else {
            result.current = null
            result.timefforts.length = 0
            result.timeline.length = 0
            result.twitlights.pre.length = 0
            result.twitlights.post.length = 0
            result.period
        }


        const endeavours = r.endeavours

        //TRACE console.log("ENDEAVOURS")

        //TRACE endeavours.forEach(e => console.log(e.toString()))

        let lunches: TimeSegment[] = []
        let twitlights: TimeSegment[] = [r.period.clone()]

        if (endeavours.length) {

            const s = TimeSegment.minTimepoint(endeavours)!
            const e = TimeSegment.maxTimepoint(endeavours)!

            const segment_day = new TimeSegment(s, absDiffInMs(s, e))

            lunches = TimeSegment.excludeOverlappingSegments([segment_day], endeavours)

            //TRACE console.log('LUNCHES')
            //TRACE lunches.forEach(l => console.log(l.toString()))

            twitlights = TimeSegment.excludeOverlappingSegments([r.period], [segment_day])

        }

        timefforts_tuned.completed.length = 0





        r.timefforts.forEach((x, i) => {

            const segment = new TimeSegment(x.start, x.span_ms)

            segment.index = i + 1

            if (x.startex) {

                const t = r.tunes.get(x.start.getTime())

                if (t != undefined) {

                    if (t.start != null) segment.setStart(t.start)
                }
            }

            if (x.endex) {

                const t = r.tunes.get(x.end.getTime())

                if (t != undefined) {

                    if (t.end != null) segment.setEnd(t.end)
                }
            }

            timefforts_tuned.completed.push({ segment, effort: x })
        })

        if (r.timeffort_Current != null) {

            const x = r.timeffort_Current

            const segment = new TimeSegment(x.start, x.span_ms)

            if (x.startex) {

                const t = r.tunes.get(x.start.getTime())

                if (t != undefined) {

                    if (t.start != null) segment.setStart(t.start)
                }
            }

            timefforts_tuned.current = { segment, effort: x }
        }



        //#TRACE console.log('Efforts (tuned|completed) count: %d', timefforts_tuned.completed.length)
        //#TRACE timefforts_tuned.completed.forEach(e => console.log(`${e.segment.toString()} | ${e.effort.toString()}`))

        function clusterize(timespendings: Timespending[], endeavours: TimeSegment[]): EffortRmodel[] {

            const result: EffortRmodel[] = []

            if (timespendings.length == 0) return result

            const timespendings_wrap = timespendings.map(function (x) { return { segment: x.segment, payload: x } })

            const spendings_dash = TimeSegment.intersectTimeSegmentsWithPayload(timespendings_wrap, endeavours)

            spendings_dash.sort(function (a, b) {

                return a.segment.timepoint.getTime() - b.segment.timepoint.getTime()
            })

            let current_effort: EffortRmodel | null = null

            if (spendings_dash.length > 0) {

                const spending_1st = spendings_dash[0];

                current_effort = EffortRmodel.timespending(spending_1st.payload, spending_1st.segment)

                result.push(current_effort)

            }

            for (let i = 1; i < spendings_dash.length - 1; i++) {

                const spending_current = spendings_dash[i];

                const x = TimeSegment.intersection(current_effort!.segment_value, spending_current.segment)

                if (x != null) {

                    current_effort!.timespendings.push({ ref: spending_current.payload, segment: spending_current.segment })

                    if (spending_current.segment.timepoint < current_effort!.start) current_effort!.segment_value.setStart(spending_current.segment.timepoint)
                    if (spending_current.segment.end() > current_effort!.end) current_effort!.segment_value.setEnd(spending_current.segment.end())
                }
                else {
                    current_effort = EffortRmodel.timespending(spending_current.payload, spending_current.segment)

                    result.push(current_effort)
                }
            }

            return result
        }

        timespendings_efforted.completed = clusterize(r.timespendings, endeavours)
        timespendings_efforted.current = clusterize(r.timespendings_Current, endeavours)

        if (timespendings_efforted.current.length > 1) throw Error('Error - uncompleted cluster should be on or 1')

        if (timespendings_efforted.current.length > 0) {

            result.current = timespendings_efforted.current[0]
        }

        //TRACE console.log('TIMESPENDINGS.COMPLETED'); timespendings_efforted.completed.forEach(e=>console.log(`${e.segment().toString()} | ${e.event?.appointment}`))


        const clusters = timespendings_efforted.completed.concat(timespendings_efforted.current)

        //endeavours - tspendings'.squashed = endeavours' --- endeavours after manual events; left for base.efforts

        const endeavours_dash = TimeSegment.excludeOverlappingSegments(endeavours, TimeSegment.squashTimeSegments(clusters.map(x => x.segment_value)))

        //TRACE console.log('ENDEAVOURS - AFTER EXLUDING TIMESPENDINGS'); endeavours_dash.forEach(e=>console.log(e.toString()))


        function timefforts_endeavourize(timefforts: { segment: TimeSegment, effort: Timeffort }[], endeavours_dash: TimeSegment[]) {

            const timefforts_wrap = timefforts.map(function (x) { return { segment: x.segment, payload: x } })

            const result = TimeSegment.intersectTimeSegmentsWithPayload(timefforts_wrap, endeavours_dash)

            return result
        }

        //TRACE console.log("ENDEAVOURS' (AFTER MEFFORTS EXL)")

        //TRACE endeavours_dash.forEach(e => console.log(e.toString()))


        //tefforts * endeavours' = tefforts' / tefforts tuned and endeavoured

        //const efforts_wrap = base.efforts.map(function (x) { return { segment: x.segment, payload: x } })

        //const efforts_dash = TimeSegment.intersectTimeSegmentsWithPayload(efforts_wrap, endeavours_dash)


        const timefforts_endevourized:
            {
                completed: { segment: TimeSegment, payload: { segment: TimeSegment, effort: Timeffort } }[]
                current: { segment: TimeSegment, payload: { segment: TimeSegment, effort: Timeffort } }[]

            } = { completed: [], current: [] }


        //

        function normalizeMefforts(mefforts: ExactMeffortRmodel[]) {

            const buffer = mefforts.map(x => { return { segment: x.segment, payload: x } })

            return TimeSegment.normalize(buffer).map(x => { return { segment: x.segment, meffort: x.payload } })
        }

        function normalizeMefforts_obsolete(mefforts: ExactMeffortRmodel[]) {

            const result: { segment: TimeSegment, meffort: ExactMeffortRmodel }[] = []

            mefforts.sort(ExactMeffortRmodel.Sort)

            if (mefforts.length > 0) {

                const head = mefforts[0]

                if (!head.segment.fit(r.period)) throw new Error('Wrong meffort timefram for current period')

                result.push({ segment: head.segment, meffort: head })

                for (let index = 1; index < mefforts.length; index++) {

                    const element = mefforts[index];

                    if (!element.segment.fit(r.period)) throw new Error('Wrong meffort timefram for current period: ' + index.toString())

                    const previous = result[index - 1]

                    head.segment.timepoint
                    const threshold = previous.segment.end()

                    let segment: TimeSegment

                    if (element.segment.timepoint >= threshold) {

                        segment = element.segment.clone()
                    }
                    else if (element.segment.end() > threshold) {

                        segment = new TimeSegment(threshold, element.segment.end().getTime() - threshold.getTime())
                    }
                    else segment = new TimeSegment(threshold, 0)

                    result.push({ segment: segment, meffort: element })
                }

            }

            return result

        }

        const mefforts_exact_normalized = normalizeMefforts(r.mefforts.exact)

        result.mefforts = mefforts_exact_normalized

        //--


        timefforts_endevourized.completed = timefforts_endeavourize(timefforts_tuned.completed, endeavours_dash)

        const mefforts_endeavourized = updateGrayTimefforts(timefforts_endevourized.completed)

        /*

        const timefforts_graytime = timefforts_endevourized.completed.filter(x => x.payload.effort.isWorkGrayTime())

        remove_batch(timefforts_endevourized.completed, timefforts_graytime)





        //--

        const mefforts_endeavourized = TimeSegment.intersectTimeSegmentsWithPayload(mefforts_exact_normalized.map(function (x) { return { segment: x.segment, payload: x.meffort } }), timefforts_graytime.map(x => x.segment))

        const graytime_exlusively_segments = TimeSegment.excludeOverlappingSegments(timefforts_graytime.map(x => x.segment), mefforts_endeavourized.map(x => x.segment))

        // exlusivele calculated graytime (fater mefforts exlusion)
        const timefforts_graytime_dash = TimeSegment.intersectTimeSegmentsWithPayload(timefforts_graytime, graytime_exlusively_segments
        */



        //**Update inpt array with graytime timefforst and return array ot endeavoureod mefforts */
        function updateGrayTimefforts(array: { segment: TimeSegment, payload: { segment: TimeSegment, effort: Timeffort } }[]) {

            //timefforts_endevourized.completed->array
            const timefforts_graytime = array.filter(x => x.payload.effort.isWorkGrayTime())

            remove_batch(array, timefforts_graytime)





            //--

            const mefforts_endeavourized = TimeSegment.intersectTimeSegmentsWithPayload(mefforts_exact_normalized.map(function (x) { return { segment: x.segment, payload: x.meffort } }), timefforts_graytime.map(x => x.segment))

            const graytime_exlusively_segments = TimeSegment.excludeOverlappingSegments(timefforts_graytime.map(x => x.segment), mefforts_endeavourized.map(x => x.segment))

            // exlusivele calculated graytime (fater mefforts exlusion)
            const timefforts_graytime_dash = TimeSegment.intersectTimeSegmentsWithPayload(timefforts_graytime, graytime_exlusively_segments)

            timefforts_graytime_dash.forEach(x => array.push(x))

            return mefforts_endeavourized

        }



        //timefforts_graytime - exlcude - mefforts_endeavourized -> put this to  timefforts_endevourized.completed

        //todo - create EfformRmode.meffrot - that will create EfformMode base on mefforts_endeavourized

        if (timefforts_tuned.current != null) {


            timefforts_endevourized.current = timefforts_endeavourize([timefforts_tuned.current!], endeavours_dash)

            if (timefforts_tuned.current.effort.isWorkGrayTime()) {

                //update 'current' and put to 'mefforts_endeavourized' endeavourized mefforts
                updateGrayTimefforts(timefforts_endevourized.current).forEach(x => mefforts_endeavourized.push(x))
            }


            if (timefforts_endevourized.current.length > 0) {


                //timefforts_endevourized.current.sort((a,b)=>TimeSegment.SortEndAscending(a, b))

                timefforts_endevourized.current.sort(TimeSegment.SortEndAscending)

                const last = timefforts_endevourized.current.length - 1

                //sort by desc timepoint?

                for (let index = 0; index < last; index++) {

                    const element = timefforts_endevourized.current[index];

                    timefforts_endevourized.completed.push(element)

                }

                const c = timefforts_endevourized.current[last] //'c' for 'current'

                //left the only elment in 'current' array of timefforts
                timefforts_endevourized.current.length = 0

                timefforts_endevourized.current.push(c)

                //-

                if (result.current != null) throw Error('current effort rmodel is not null')

                result.current = EffortRmodel.timeffort(c.payload.effort, c.segment)
            }
        }




        //TRACE         console.log("EFFORTS' - EFFORTS TUNED AND ENDEAVOURED (COMPLETED)")

        //TRACE         timefforts_endevourized.completed.forEach(e => console.log(`${e.segment.toString()} || ${e.payload.segment.toString()} | ${e.payload.effort.toString()}`))

        //NONE OVERLAPING TUNED TIMEFFORTS (TEFFORTS.ZEROS)

        // timefforts_tuned.completed.forEach(e => {

        //     //const x = TimeSegment.intersection2(e.segment, endeavours_dash) //this version include 'zeros' that appears also under 'timespending' like during some event

        //     const x = TimeSegment.intersection2(e.segment, endeavours) //this version shows only those 'zeros' that appears out of endeavours at all

        //     if (x.length == 0) result.zeros.push({ timepoint: e.segment.end(), effort: e.effort })

        // })

        //TRACE console.log("EFFORTS'' - ZERO-SIZED EFFORTS "); result.zeros.forEach(e => console.log(`${dayjs(e.timepoint).format('HH:mm')} || ${e.effort.toString()}`))

        const efforts_endevourized_all = timefforts_endevourized.completed.concat(timefforts_endevourized.current).map(x => x.segment).concat(mefforts_endeavourized.map(x => x.segment))





        //TRACE console.log('endeavourized timeffoers'); timefforts_endevourized_all.forEach(x=>console.log(x.segment.toString()))

        //TRACE console.log('endeavourized timeffoers squached'); buffer_tmp.forEach(x=>console.log(x.toString()))



        //endeavours' - base.efforts = idles' -X- idles that possible after tunes idle
        const idles_dash = TimeSegment.excludeOverlappingSegments(endeavours_dash, TimeSegment.squashTimeSegments(efforts_endevourized_all))

        //TRACE console.log('ZEROS - PRE-FINAL'); result.zeros.forEach(e => console.log(`${EffortRmodel.timeffort(e.effort, new TimeSegment(e.timepoint)).segment()})`))  //(2)

        //TRACE console.log('LUNCHES'); lunches.forEach(l=>console.log(l))



        //TRACE console.log('TIMESPENDINGS.COMPLETED 2'); timespendings_efforted.completed.forEach(e=>console.log(e))

        //TRACE console.log('IDLES');idles_dash.forEach(i => console.log(EffortRmodel.idle(i).segment().toString())  )

        //--


        //TWITLIGHTS

        twitlights.forEach(t => {

            //t catch
            timefforts_tuned.completed.forEach(e => {

                if (t.contains(e.segment.end()) && t.endex) {

                    const m = new EffortRmodel()

                    m.offhour = "T"
                    m.segment_value = new TimeSegment(e.segment.end())
                    //m.start = e.segment.end()
                    //m.end = m.start
                    m.timeffort = e.effort
                    m.resolution = e.effort.resolution
                    //m.isZeorEffort = true

                    twitlights_efforts.timefforts.push(m)

                }
            }


            )


        })

        const timespendings_wrap = r.timespendings.map(function (x) { return { segment: x.segment, payload: x } })

        const timespendings_twitlighted = TimeSegment.intersectTimeSegmentsWithPayload(timespendings_wrap, twitlights)

        timespendings_twitlighted.forEach(t => {

            const m = new EffortRmodel()

            m.offhour = "T"
            m.segment_value = t.segment.clone()
            //m.start = t.segment.timepoint // e.segment.end()
            //m.end = t.segment.end() // m.start
            m.timespendings.push({ ref: t.payload, segment: t.segment })
            //m.netto = 0 //used to indicate that atual effort is zero - such as this is twitlight zone

            twitlights_efforts.timespendings.push(m)
        })

        //TRACE console.log('TWITLIGHTS'); twitlights_efforts.timefforts.forEach(e=>console.log(`${e.segment().toString()} | ${e.timeffort?.ticket_n ?? 'n/a'}`))
        //TRACE console.log("-"); twitlights_efforts.timespendings.forEach(e=>console.log(`${e.segment().toString()} | ${e.timespendings[0].ref.event_id}`))

        //TimeSegment.intersectTimeSegments(r.timespendings, twitlights)


        //storing timefforts tuned

        timefforts_tuned.completed.forEach(e => result!.timefforts.push(e))
        if (timefforts_tuned.current != null) result.timefforts.push(timefforts_tuned.current)



        result.period = r.period.clone()

        //merge tspendings', tefforts', tefforts.zeros, lunches and idles' to final result

        //result.zeros.forEach(e => result.timeline.push(EffortRmodel.timeffort(e.effort, new TimeSegment(e.timepoint)))) //(2)

        let twitlights_threshold: Date | null = null

        if (endeavours.length) {

            twitlights_threshold = TimeSegment.minTimepoint(endeavours)!
        }

        twitlights_efforts.timefforts.forEach(e => {

            //do not put to twitlight 'specia' - like 'idle' and 'interrupted' efforts
            if (!((e.timeffort?.isPaused ?? false) || (e.timeffort?.isSpecial() ?? false))) {

                if (twitlights_threshold == null) result!.twitlights.pre.push(e)

                else if (e.segment_value.end() <= twitlights_threshold) result!.twitlights.pre.push(e)

                else result!.twitlights.post.push(e)
            }
        }
        )

        twitlights_efforts.timespendings.forEach(e => {

            if (twitlights_threshold == null) result!.twitlights.pre.push(e)

            else if (e.segment_value.end() <= twitlights_threshold) result!.twitlights.pre.push(e)

            else result!.twitlights.post.push(e)

        })

        timefforts_endevourized.completed.forEach(e => result!.timeline.push(EffortRmodel.timeffort(e.payload.effort, e.segment))) //(1)

        mefforts_endeavourized.forEach(m => result!.timeline.push(EffortRmodel.meffort(m.payload, m.segment)))

        timespendings_efforted.completed.forEach(e => e.catchTimefforts(timefforts_tuned.completed)) //here we put under 'timespending' point of resoulutions that goes under them
        timespendings_efforted.completed.forEach(e => result!.timeline.push(e)) //(3)

        idles_dash.forEach(i => result!.timeline.push(EffortRmodel.idle(i)))   //(4)


        const lunches_efforts: EffortRmodel[] = []

        lunches.forEach(l => lunches_efforts.push(EffortRmodel.lunch(l)))  // (5)

        lunches_efforts.forEach(l => l.catchTimefforts(timefforts_tuned.completed))  // (5.a)
        lunches_efforts.forEach(l => l.catchTimespendings(r.timespendings))

        lunches_efforts.forEach(l => result!.timeline.push(l))  // (5)


        function comparator(a: EffortRmodel, b: EffortRmodel) {

            const a_s = a.start.getTime()
            const b_s = b.start.getTime()

            if (a_s != b_s) return a_s - b_s
            else {
                const a_e = a.end.getTime()
                const b_e = b.end.getTime()

                if (a_e != b_e) return a_e - b_e; else return 0

                //else return a.segment.index - b.segment.index
            }
        }

        result.timeline.sort(comparator)
        result.twitlights.pre.sort(comparator)
        result.twitlights.post.sort(comparator)




        //STATUS (V1) CALCULATION START

        // timefforts_tuned.completed.forEach(t => { //run through 'timeffort.tuned' to not deal with probably 'zeros'

        //     //const x = t.effort

        //     //t.effort.resolution

        //     if (t.effort.isTicket()) {

        //         const efforts = result!.timeline.filter(e => e.timeffort == t.effort)

        //         if (efforts.length > 0) {

        //             const start = TimeSegment.minTimepoint(efforts.map(x => x.segment_value))! //start

        //             let end = TimeSegment.maxTimepoint(efforts.map(x => x.segment_value))!

        //             if (t.segment.endex) end = t.segment.end()

        //             const span_ms = absDiffInMs(start, end)

        //             let efforts_netto = 0

        //             efforts.forEach(x => efforts_netto += x.segment_value.timespan_ms)

        //             const effort_model = new EffortRmodel()

        //             effort_model.timeffort = t.effort
        //             effort_model.segment_value = new TimeSegment(start, span_ms)
        //             effort_model.netto = efforts_netto

        //             result!.status.push(effort_model)
        //         }
        //         else {

        //             if (t.segment.endex) {

        //                 const effort_model = new EffortRmodel()

        //                 effort_model.timeffort = t.effort
        //                 effort_model.segment_value = new TimeSegment(t.segment.end(), 0)

        //                 result!.status.push(effort_model)

        //             }

        //         }
        //     }
        // })

        // //timespending - each as separate record
        // result.timeline.filter(x => x.timespendings.length > 0).forEach(s => {

        //     s.timespendings.forEach(x => {

        //         result!.status.push(EffortRmodel.timespending(x.ref, x.segment))
        //     })
        // })

        // //'isSpecial' timefforts
        // result.timeline.filter(x => x.timeffort?.isSpecial() ?? false).forEach(e => {

        //     result!.status.push(e)
        // })

        // //assumes each timespending goes as separate effort model
        // result.twitlights.post.forEach(x => result!.status.push(x))
        // result.twitlights.pre.forEach(x => result!.status.push(x))

        // result.status.sort(EffortRmodel.Comparator_End_Desc)

        // //count 'repeatnes' for 'todo timeffort'
        // for (let i = 0; i < result.status.length; i++) {

        //     const element = result.status[i];

        //     if (element.timeffort?.isTicket() ?? false) {

        //         for (let j = i + 1; j < result.status.length; j++) {

        //             const element_b = result.status[j];

        //             if (element_b.timeffort?.isTicket() ?? false) {

        //                 if (element.timeffort!.todo!.eq(element_b.timeffort!.todo!)) {

        //                     element.repeat++
        //                 }
        //             }
        //         }
        //     }
        // }


        // if (result.status.length > 0) {

        //     const status_clusterized = EffortRmodel.clusterize(result.status)

        //     status_clusterized.forEach(x => {

        //         const item = { efforts: x.efforts, asides: r.asides.filter(a => a.end >= x.start && a.end < x.end) }

        //         result!.status_final.push(item)
        //     })
        // }


        //STATUS V2 CALC

        //const status =  EffortRmodel.status_v2(result.timeline)

        console.log(`person: ${result.report.person.name} timeline.count: ${result.timeline.length} ` )

        EffortRmodel.status_v2_writeTo(result.timeline, result.status_exacts)

        EffortRmodel.status_resolutions_writeTo(timefforts_tuned.completed.map(x => x.effort), result.status_resolutions)

        //result.status_exacts
        //result.status_resolutions

        EffortSummary.Status_UI_writeTo(result, timefforts_tuned.completed, result.timestats, result.pqupdates)




        //status_v2 - preliminary - before simple efforts

        const totals_simple = SimpleMeffort.Total(result.report.mefforts.simple)

        const totals_simple_grandtotal = totals_simple.total()

        //means sum of simple efforts does not exceed unallocated works(flex/autonomus) time
        result.status.reset()
        result.status_detalization.holistic = result.status_exacts.unallocated >= totals_simple_grandtotal
        result.status_detalization.graytime = 0

        result.status.add(result.status_exacts.totals)

        if (result.status_detalization.holistic) {
            result.status.add(totals_simple)
            result.status_detalization.graytime = result.status_exacts.unallocated - totals_simple_grandtotal
        }

        //result.status_v2.

        return result
    }
}

//function f