import { IEntityRefDto } from '@/io/dto/workspaces/_tech'
import { ILabelDto } from "@/io/dto/workspaces/label.dto";
import { IPersonDto } from "@/io/dto/workspaces/person.dto";
import { Guid } from "@/lib";

export class Operation {

    constructor(operation: string) {

        this.operation = operation;
    }



    readonly operation: string;
}


export class TicketEntityDto extends Operation {

    constructor(name?: string) {

        super("TicketEntity")

        if (typeof name != "undefined") this.name = name;
    }

    name: string = ''
    pid: Guid | null = null
    n: number = 0;
    uid: Guid = Guid.newGuid()

    dto(): TicketEntityDto { return this; }
}

export class TaskEntityDto extends Operation {

    constructor(n: number, nid: Guid) {

        super("TaskEntity")

        this.n = n;
        this.nid = nid;
    }

    n: number
    nid: Guid

    dto() :TaskEntityDto { return this; }
}

//#rn BlockEntityOperationDto (entitt= = 'reocrd create')
export class BlockEntityDto extends Operation {

    constructor(n: number, nid: Guid) {

        super("BlockEntity")

        this.n = n;
        this.nid = nid;
    }

    n: number
    nid: Guid
    blocker: Guid | null = null;

    dto() : BlockEntityDto { return this; }
}

export class VerificationEntityDto extends Operation {

    constructor(n: number, nid: Guid, verifier: Guid) {

        super("VerificationEntity")

        this.n = n;
        this.nid = nid;
        this.verifier = verifier;
    }

    n: number
    nid: Guid
    verifier: Guid;
    process: string = ''
    phase: string = ''

    dto() : VerificationEntityDto { return this; }

}

export interface IPersonEntityDto {

    operation:string
    object : IPersonDto
    //name: string
    //nid: Guid

    
}

export interface ILabelEntityDto extends ILabelDto {

    operation:string
    //label: string
}



export class EntityUpdateDto {

    static readonly operation:string = 'EntityFieldUpdate'
    
    readonly operation:string = EntityUpdateDto.operation


    ref: IEntityRefDto

    field:string = ''
    value: any
    direction: boolean | null = null

    constructor(ref:IEntityRefDto){
        
        this.ref = ref
    }

    static readonly Key_Description = 'description';
    static readonly Key_Resolution = 'resolution';
    static readonly Key_Ticket_Label = 'label';
    static readonly Key_Ticket_Name = 'name';
    static readonly Key_Task_Workable = 'workable';

}

export  class EntityDeleteDto {

    static readonly operation:string = 'EntityDelete'
    
    readonly operation:string = EntityDeleteDto.operation

    /**
     *
     */
    constructor(ref:IEntityRefDto) {
            
        this.ref = ref
    }

    ref: IEntityRefDto
}