import { useAppContext } from "@/app/_tech";
import AppContext from "@/context";

import { SignalrService } from "@/gw/service.signalR";
import { sleep } from "@/lib";

const configuration = 
{
    //user_token:'F5D96C7A-7BE4-4185-8A5F-564FCE66C155'.toLowerCase(), //PK:124, user.124, UID:19EC3ABF-4CD3-472E-AE7F-514B6CCD5843
    //user_token:'user.eugene'.toLowerCase(),
    user_token:'user.alice'.toLowerCase(),
    
    workspace:'',
    person: '11633E2F-AFC3-4463-BC0B-F38B6343D3C5'.toLowerCase(), //alice, PK:453
    //person: '150850AD-136E-4B80-919A-9CB78127D0D3'.toLowerCase(), //alex, PK:8
    ticket: ''
}

export default configuration

export async function context_workspace() : Promise<AppContext>{

        //const service = await SignalrService.Instance("/api", "/hubs");

        const context = useAppContext() 

        await context.authenticate('', configuration.user_token); //moad of auth by user 'token'

        while (context.model.workspaces.length === 0) await sleep();

        let wid = context.model.defaultWorkspaceWid()!;

        if (configuration.workspace != '') wid = configuration.workspace

        console.log(`Workspace wid: ${wid}`);

        context.storages.workspace = context.service.workspaceStorage(wid); //and wait unti it comes at controlel from SignalR

        while (context.cursor.workspace === null) await sleep();

        if (configuration.person != ''){

            context.cursor.person = context.cursor.workspace.person_nid(configuration.person)
        }

        return context
}