



import { EffortRmodel, Timeffort } from "@/app.context/workspace/persons/sunrises/effort.rmodel";
import { IResolution, Status } from '@/logic/workspaces/tickets/_def'
import { Category, EffortType } from "@/logic/workspaces/persons/_def";
import { OccupationtRef, EffortResolution, Resolution } from "@/logic/workspaces/tickets/_def";
import sys from "@/sys";



export function effortColor(e: EffortRmodel) {

    //const x = e

    const r = e.resolution

    return resolutionColor(e.resolution)

    // if (x != null) {

    //     if (x.resolution != null) {

    //         if (x.resolution) return '#9de09e'; else return '#f44336';
    //     }
    //     else {

    //         if (x.resolution == "B" || x.delay == "S") return "#ff9800"; else return null;
    //     }
    // }

    return null;
}

export function resolutionColor(resolution: IResolution | null) {

    const r = resolution

    if (r != null) {

        if (r.queued != null && !r.queued) { /*dequeued*/

            if (r.resolved != null) {
                if (r.resolved) return '#9de09e'; else return '#f44336';
            }
            else return null
        }
        else {
            if (r.blocked) return "#ff9800"; else return null;
        }
    }

    return null;
}

export function timeffortIconCss_Base(t: EffortType) {

    const result: string[] = [];

    if (t == "A") result.push("bi-hammer")
    if (t == "F") result.push("bi-hammer")
    if (t == "I") result.push('bi-cup-hot')
    if (t == "S") result.push('bi-exclamation-square')

    if (result.length == 0) sys.warn('/workspaces/persons/effort.mode Unknwon effort.mode type')

    return result

}



export function timeffortIconCss(t: Timeffort) {

    const result: string[] = [];

    if (t.effortType != null) result.replaceWith(timeffortIconCss_Base(t.effortType))

    return result
}

// export function timeffortResolutionCss(resolution: IResolution) {

//     const result: string[] = [];

//     const r = resolution

//     if (r.queued != null && !r.queued) //removed from queue
//     {
//         if (r.resolved != null) {
//             if (r.resolved == true) {

//                 result.push('bi-check-circle');
//                 result.push('log-item-status-green');
//             }
//             else {
//                 result.push('bi-exclamation-circle');
//                 result.push('log-item-status-red');
//             }
//         }
//         else { //active task was cancelled

//             result.push("bi-stop-circle"); //just black stop circle

//         }

//     }
//     else {
//         if (r.blocked) {

//             result.push("bi-stop-circle");
//             result.push("log-item-status-orange")
//         }
//         else {
//             result.push('bi-pause-circle') //just snoozed (paused)
//             result.push('log-item-status-blue')
//         }
//     }

//     return result
// }

//#rn 'squashed'
export function timeffortCss_Squashed(t: Timeffort, resolution: IResolution | null) {

    const result: string[] = [];

    if (t?.isSpecial() ?? false) timeffortIconCss(t).forEach(x => result.push(x))

    else if (resolution != null)  effortQueueOperationCss(EffortResolution.Instance(resolution), null).forEach(x => result.push(x))
    
    else if (t != null) timeffortIconCss(t).forEach(x => result.push(x))

    return result
}

export function effortQueueOperationCss2(r: IResolution, t: EffortType | null, native?: boolean) {

    return effortQueueOperationCss(EffortResolution.Instance(r), t, native)
}

//

//effortStatCss


export function effortStatsResolutionCss(r: EffortResolution,   effort: OccupationtRef) {

    if (effort.ticket != null /*|| effort.todo != null*/){

        //if (effort.ticket == null) sys.warn("/workspaces/persons/effort ticket is null")

        return effortQueueOperationCss(r, null)
    }
    else if (effort.category == Category.N){

        //icon for notice  - pen?
        return ['bi-pen']
    }
    else 
    {
        //simple builler
        return ['bi-app'] //simple bit roudned square
    }


}

export function effortQueueOperationCss(r: EffortResolution, t: EffortType | null, native?: boolean) {

    const result: string[] = [];

    if (native == undefined) native = true

    if (t != null) result.replaceWith(timeffortIconCss_Base(t)) 
    else
    {
        if (r.queued == null){ //item remains in queue

            if (r.resolution != null) sys.warn ('resolution for item that remained at person queue')

            if (r.blocked != null){

                if (r.blocked){
    
                    result.push("bi-stop-circle");
                    result.push("log-item-status-orange")
                    result.push("mn-orange-element")
                }
                else result.push('bi-bell') //notify that unblocked

            }
            else if (r.focused != null){

                if (!r.focused){

                    result.push('bi-pause-circle') //just snoozed (paused)
                    result.push('log-item-status-blue')
                    result.push("mn-blue-element")
                }
                else if (!native) {

                    result.push('bi-hammer')
                    result.push('mn-red-element')
                }
            }
            else 
            {
                result.push('bi-hammer') //default 'work' icon
            }
        }
        else if (!r.queued){ //ticket removed from queue

            if (r.resolution != null){

                if (r.resolution == Resolution.Done){
    
                    result.push('bi-check-circle');
                    result.push('log-item-status-green');
                    result.push("mn-green-element")
                }
                else if (r.resolution == Resolution.Failed)
                {
                    result.push('bi-exclamation-circle');
                    result.push('log-item-status-red');
                    result.push("mn-red-element")
                }
                else sys.warn("/workspaces/todo Unknown 'resolution' value")
            }
            else if (r.focused == null){

                //was removed from queue - 'green cancel'
                result.push("bi-stop-circle")
                result.push("mn-green-element")
            }
            else result.push("bi-stop-circle"); //just black stop circle //active task was cancelled
            

        }
        else //ticket added to queue
        {
            result.push('bi-plus-square')
        }
    
        return result
    }

    return result
}

export function queueUpdateCss(e: EffortRmodel) {

    const result: string[] = [];

    return result
}

export function effortCss(e: EffortRmodel) {

    const result: string[] = [];

    if (e.offhour == 'L') return result

    else if (e.timeffort != null) {

        timeffortCss_Squashed(e.timeffort, e.resolution).forEach(x => result.push(x))
    }
    else if (e.timespendings.length) {

        result.push('bi-calendar-event')
    }



    return result;
}

export function effortIcon() {

}