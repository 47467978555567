import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel";
import { TodoRmodel } from "@/app.context/workspace/tickets/todo.rmodel";

export function isTodo(item: TodoRmodel | TicketRmodel): item is TodoRmodel 
{ 
    return (item instanceof TodoRmodel)
}

export function isTicket(item: TodoRmodel | TicketRmodel): item is TicketRmodel 
{ 
    return (item instanceof TicketRmodel)
}