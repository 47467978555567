import * as array from '@/lib/array.lib'
import { PersonRmodel } from "@/app.context/workspace/person.rmodel"
import { GroupRmodel } from "@/app.context/workspace/persons.set/group.rmodel"
import { Guid } from "@/lib"

import { IMeetingDto } from '@/io/dto/workspaces/eventw.dto'
import { WorkspaceRmodel } from '../workspace.rmodel'
import sys from '@/sys'
import { TimeSegment } from '@/logic/_infra/time'
import { OccupationtRef } from '@/logic/workspaces/tickets/_def'

//**Workspace 'level' object */

export type EventType = 'A' | 'G' | 'P' //A for 'all', 'G' for 'group', 'p' for 'person'


// export class EffortObjects
// { 
//     //what
//     meeting: MeetingRmodel | null = null
//     ticket:TicketRmodel|null = null


//     todo_type: TodoItemType | null = null
//     todo_person_aux: Guid | null = null

//     //todo: TodoRmodel | null = null 
    

//     //with whoem

//     groups: GroupRmodel[] = [] 
//     persons: PersonRmodel[] = []


//     any(){

//         if (this.ticket != null) return true
//        // if (this.todo != null) return true
//         if (this.groups != null) return true
//         if (this.persons != null) return true

//         if (this.meeting != null) return true


//         return false
//     }
// }


export class Timespending /*implements ITicketOrTodoRef*/ {

    persisted: boolean | null = null //technial for waiting roundtrip of confiramtion from server

    //--

    tid: Guid = Guid.newGuid()

    segment: TimeSegment = new TimeSegment(new Date())

    objects: OccupationtRef =  new OccupationtRef()

    

    appointed: boolean = false //'true' w/o 'meeting' or 'event' filed filled means we are waiting for loading of data


    //--

    //name: string | null = null

    //tag: string | null = null

    get title() {

        return this.objects.title
    }

    //--

    //category: string | null = null

    //persons: Guid[] = []

    //groups: Guid[] = []

    //--

    //ticket: number = 0

    //type: TodoItemType | null = null

    //person_aux: Guid | null = null

    //--


    match(r:OccupationtRef/*n: number, type: TodoItemType | null, person_aux: Guid | null/*, category: string | null*/) {

        return this.objects.match(r)

        //return Todo.Match(this, n, type, person_aux/*, category*/)
    }


    toString_segment(s: TimeSegment) {

        return `${s.toString()} ${this.toString()} [s]` //'s' for 'spending' of time
    }

    toString() {

        if (this.objects.title != '') return this.objects.title

        if (this.objects.meeting != null) return `Meeting: ${this.objects.meeting.appointment.toString()}`

        if (this.appointed) return `Appointment': ${this.tid}`

        return this.tid
    }

    static Filter(array: Timespending[], r:OccupationtRef /* ticket: TicketRmodel | null, todo: TodoRmodel | null/*, category: string | null*/) {

        return array.filter(x=>x.match(r))

        
    }
}

export class MeetingRmodel { //'technial' meeting, planned, person invation (includes 'person_current')


    static instance(dto: IMeetingDto, workspace: WorkspaceRmodel) {

        const result = new MeetingRmodel()

        result.set(dto, workspace)

        return result

    }

    // STRUCTURE //

    mid: Guid = Guid.newGuid()

    appointment: Guid = Guid.newGuid()

    timepoint: Date = new Date()

    title: string | null = null

    persons: PersonRmodel[] = []
    groups: GroupRmodel[] = []



    estimation: number = 0 // '0' means 'no limit set explicitly'

    expirated_estimated: Date | null = null

    // ALGS //

    isSame(event: IMeetingDto) {

        const d = new Date(event.timepoint)

        return this.appointment == event.appointment && this.timepoint == d

    }



    isEventOf(mid: Guid) {

        return this.mid == mid

        //const timepoint_date = new Date(dto.timepoint)

        //return this.timepoint.toISOString() == timepoint_date.toISOString() && this.appointment == dto.appointment
    }


    set(dto: IMeetingDto, workspace: WorkspaceRmodel) {

        const result = this

        result.mid = dto.meeting

        result.title = dto.name
        result.timepoint = new Date(dto.timepoint)
        result.estimation = dto.estimation
        result.appointment = dto.appointment


        result.groups.length = 0

        dto.groups.forEach(g => {

            const group = workspace.groupOrNull(g)

            if (group != null) result.groups.push(group)
            else sys.error(`Group ${g} was not found`)
        })

        result.persons.length = 0

        dto.persons.forEach(p => {

            const person = workspace.personOrNull_nid(p)
            if (person != null) result.persons.push(person)
            else sys.error(`Person ${p} was not found`)

        })
    }



    syncEstimatedExpiration() {

        if (this.estimation > 0) {

            const d = this.timepoint.cloneAndAddMinutes(this.estimation)

            const delta = Date.now() - d.getTime()

            if (delta > 0) {

                this.expirated_estimated = d
            }

        }
    }





    isEveryone() { return this.groups.length == 0 && this.persons.length == 0 }

    type(): EventType {

        if (this.isEveryone()) return 'A'

        if (this.groups.length == 0 && this.persons.length == 1) return 'P'

        return 'G'
    }




    match(person: PersonRmodel): boolean {

        if (this.groups.length == 0 && this.persons.length == 0) return true //'everyone' event

        let result: boolean = false;

        this.persons.forEach(p => {

            if (p == person) result = true;

        })

        if (result) return true;

        for (let index = 0; index < this.groups.length; index++) {

            const element = this.groups[index];

            for (let j = 0; j < element.members.length; j++) {

                const p = element.members[j];

                if (p.nid == person.nid) {
                    result = true;
                    break;
                }
            }

            if (result) break;
        }

        return result;
    }

}

export class TimelineEventRmodel {

    timepoint: Date = new Date()

    timepointAsString() { return timepointAsString(this.timepoint) }



    events: MeetingRmodel[] = []

    isSimple() {

        return this.events.length == 1
    }

    title() {

        return this.events[0]
    }
}

function timepointAsString(date: Date) {

    const minutes = date.getMinutes()

    if (minutes < 10) return `${date.getHours()}:0${minutes}`
    else return `${date.getHours()}:${minutes}`
}

export function EventTimeline(events: MeetingRmodel[]) {

    const result: TimelineEventRmodel[] = []

    const buffer = array.groupBy(events, e => timepointAsString(e.timepoint))

    // Go through each key in the bigObject:
    for (const key in buffer) {

        const timelineItem = new TimelineEventRmodel()
        result.push(timelineItem)

        const events = buffer[key];

        events.forEach(e => {

            timelineItem.timepoint = e.timepoint


            timelineItem.events.push(e)


        })

        //sort events
        timelineItem.events.sort((a, b) => {

            if (a.isEveryone() && !b.isEveryone()) return 1;
            if (b.isEveryone() && !a.isEveryone()) return -1;

            if (a.groups.length > 0 && b.groups.length == 0) return 1;
            if (b.groups.length > 0 && a.groups.length == 0) return -1;



            return 0;
        })

    }

    result.sort(function (a, b) {

        if (a > b) return 1;
        if (b > a) return -1;

        return 0;
    })

    return result
}