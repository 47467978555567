import { ImMessage, TicketPackRmodel } from "@/app.context/workspace/tickets/_spec/Ticket.Im.Rmodel";
import AppContext from "@/context";

import { IFile, ITicketPackDto } from "@/io/dto/workspaces/ticket.dto.spec.pack";
import { Guid } from "@/lib";


export class TicketAppContextController
{
    constructor(context:AppContext) {

        this.context = context

    }

    context:AppContext

    ticketImInitialization(dto: ITicketPackDto): void {

        const model = new TicketPackRmodel(dto.wid, dto.n);

        model.setup(dto)

        this.context.triggers.ticketPackInitialization(model);

    }

    ticketImUpdate(wid: Guid, n: number, messages: ImMessage[],): void {

        this.context.triggers.ticketImUpdate(wid, n, messages)
    }

    ticketFileUpdate(wid: Guid, n: number, files: IFile[],): void {

        this.context.triggers.ticketFileUpdate(wid, n, files)
    }

    ticketDescriptionUpdate(wid: Guid, n: number, description: string,): void {

        this.context.triggers.ticketDescriptionUpdate(wid, n, description)
    }
}

export default function (context: AppContext) {


    const c = new TicketAppContextController(context)

    return c

    /*
    const controller = {

        ticketImInitialization(dto: ITicketPackDto): void {

            const model = new TicketPackRmodel(dto.wid, dto.n);

            model.setup(dto);

            context.triggers.ticketPackInitialization(model);

        },

        ticketImUpdate(wid: Guid, n: number, messages: ImMessage[],): void {

            context.triggers.ticketImUpdate(wid, n, messages);
        }
    }

    return controller;
    */

}