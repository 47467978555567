import { ProjectModel } from "@/app.context/workspace/project.rmodel";
import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel";
import { Guid } from "@/lib";
import { TodoSection, TqueuePosition } from "@/logic/_infra/queues";
import { Operation } from "./EntityOperation";
import { QueueOperation, TodoOperationType } from "./_def";


export class TicketQueueOperationDto extends Operation {


    constructor(operation: QueueOperation<TicketRmodel>, project: ProjectModel) {

        super('TicketQueueOperation')

        this.name = operation.name;

        this.n = operation.object.n;

        this.target = operation.target;

        this.pid = project.pid;
    }

    //name of operation
    name: TodoOperationType;

    n: number = 0;

    //wid: Guid

    target: { section: TodoSection, index: number, } = { section: "queue", index: 0 };

    pid: Guid;

}


