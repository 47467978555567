import {IFile, ITicketPackDto} from  '@/io/dto/workspaces/ticket.dto.spec.pack'
import { Guid } from '@/lib';
import { TicketRmodel } from '../../ticket.rmodel';

//Describes ticket data like linked files, description, chat, etc.
export class TicketPackRmodel {

    wid:Guid

    n:number

    messages: ImMessage[] = []

    description: string | null = null

    files: File[] = []


    constructor(wid:Guid, n:number){
        this.wid = wid;
        this.n = n;
    }

    setup(dto:ITicketPackDto){

        this.messages.length = 0; //clear

        dto.messages.forEach(m=>{

            const mObject = new ImMessage(m.sender); //'message object'

            mObject.content = m.content;
            mObject.file = m.file
            mObject.link = m.link

            this.messages.push(mObject);
        })

        dto.files.forEach(f=>{

            const file = new File(f.name, f.identificator)

            file.url = f.url

            this.files.push(file)
        })

        this.description = dto.description;
    }

    isIdentificatorSame(t:TicketRmodel):boolean{

        return (t.n === this.n && t.workspace.wid === this.wid)

    }


}

export class ImMessage {

    constructor(sender: string, content?:string) {

        this.sender = sender;

        if (content != undefined) this.content = content;
    }

    sender: string;
    content: string = ''
    file: string | null = null
    link: string | null = null
}

export class File {
    
    filename: string;
    url: string | null = null
    identificator: string


    static File(dto:IFile) {
        
        const x = new File(dto.name, dto.identificator)

        x.url = dto.url

        return x
    }

    constructor(filename:string, identificator:string){

        this.filename = filename;
        this.identificator = identificator;
    }
}