import sys from '@/sys';
import { ImMessage } from '@/app.context/workspace/tickets/_spec/Ticket.Im.Rmodel';
import TicketIm from '@/components/Workspaces/tickets/_spec/TicketIm.vue'
import { IFileStorage } from '@/io/storages';
import { Ref, nextTick, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue';
import { TicketAccessLevel } from '@/controllers/workspaces/user.controller.workspace';
import {File as FileRecord} from '@/app.context/workspace/tickets/_spec/Ticket.Im.Rmodel'
import * as ticketProcessor from '@/ops/workspaces/ticket.processor'




export function setupEscapeHook(f: () => void) {

    onMounted(() => {

        document.addEventListener('keyup', h);
    })

    onBeforeUnmount(u)

    function u() {

        sys.info('remove esc hook')
        document.removeEventListener('keyup', h)
    }

    function h(ars: KeyboardEvent) {

        if (ars.code == 'Escape') {

            u()

            f()

            sys.info('Esc hook')

            ars.preventDefault()
        }
    }
}


// function setupEscapeHook_Inner(f:()=>void){

//     document.addEventListener('keyup', h);

//     sys.info('added esc hook')



//     return u
// }

export default function setup(im: Ref<InstanceType<typeof TicketIm> | null>, props: { model: TicketItemModel, files: IFileStorage | null, level?: TicketAccessLevel }) {

    nextTick(() => {

        im.value?.focus()
    })

    watch(() => props.model.todo, () => {

        if (im.value != null) {

            im.value.reset()
        }
    })

    onMounted(() => {
        document.addEventListener('keyup', onKeyUp);
    })

    onBeforeUnmount(() => {
        document.removeEventListener('keyup', onKeyUp)
    })

    function onKeyUp(ars: KeyboardEvent) {



        if (ars.code == 'Backslash' && ars.ctrlKey) {


            im.value?.focus()
            ars.preventDefault()
        }
    }



    function onChatMessage(message: string) {

        if (props.model.storage && props.model.ticket) {
            props.model.storage.message(props.model.ticket.workspace.wid, props.model.ticket.n, message)
        }
    }



    function onFiles(files: File[]) {

        const s = props.files

        const t = props.model.ticket

        const m = props.model.storage

        

        if (s != null && t != null && m != null) {

            //ticketProcessor.addFiles(s, t.workspace.storage, t, files)

            files.forEach(x => {

                s.push(t.workspace.wid, t.n, x.name, x, x.type)
                    .then(r => {

                        console.log(r)

                        const message = new ImMessage('me', r.url)

                        message.file = r.url

                        //hack to avoid VUE error 'attemtp to change property'
                        const buffer = props.model.messages;

                        buffer.push(message)

                        m.message(t.workspace.wid, t.n, '', r.url)
                    })
            })
        }
        else sys.warn("/workspaces/ticket - Can't process file - file storage is null")
    }

    return { files: onFiles, message: onChatMessage }

}

import { TicketItemModel } from '@/app.context/workspace/tickets/_spec/Ticket.Item.Model'
import AppContext from "@/context";
import { TicketPackRmodel } from '@/app.context/workspace/tickets/_spec/Ticket.Im.Rmodel';

export function ticketUpdateController_Alt(context: AppContext, model: TicketItemModel) {

    const handlers: { handler: globalThis.EventListenerOrEventListenerObject, type: string }[] = [];

    let h;

    onBeforeMount(() => {

        h = context.triggers.onTicketPackInitialization((pack) => {

            if (model.ticket === null) return;

            if (!pack.isIdentificatorSame(model.ticket)) return;

            model.update(pack);
        })

        handlers.push(h);

        h = context.triggers.onTicketImUpdate((wid, n, messages) => {

            if (model.ticket == null) return;

            if (!model.ticket.isIdentificatorSame(wid, n)) return;

            model.add(messages);
        })

        handlers.push(h);


        h = context.triggers.onTicketFileUpdate((wid,n, files)=>{

            if (model.ticket == null) return;

            if (!model.ticket.isIdentificatorSame(wid, n)) return;

            const buffer:FileRecord[] =[]

            files.forEach(dto=>buffer.push(FileRecord.File(dto)))

            model.addFiles(buffer);

        })

        h = context.triggers.onTicketDescriptionUpdate((wid, n, description) => {

            if (model.ticket == null) return;

            if (!model.ticket.isIdentificatorSame(wid, n)) return;

            if (model.pack != null) model.pack.description = description

            else sys.warn('model.pack is null')

        })

        handlers.push(h)
    });

    //const handlers: { handler: globalThis.EventListenerOrEventListenerObject, type: string }[] = [];

    onUnmounted(() => {

        handlers.forEach(h => context.triggers.cancel(h)); //usubscribe all evernts by mountd instance
    })
}

export function ticketSummaryUpdate(model: TicketItemModel, pack: TicketPackRmodel) {


    model.messages.length = 0;

    pack.messages.forEach(m => model.messages.push(m));

    model.pack = pack;

}


