// interface Array{

//   replace<T>(elements:T[]) : void

// }


// Array.prototype.replace = function () {
//   return this.getTime();
// };





/**
 * Removes all occurences of value from array
 * @param a 
 * @param value 
 * @returns 
 */
export function remove<T>(a: Array<T>, value: T): Array<T> {

  

  let index = a.indexOf(value)

  while (index > -1) {

    a.splice(index, 1);

    index = a.indexOf(value)
  }

  return a;
}

export function remove_batch<T>(a: Array<T>, value: T[]): Array<T> {

  value.forEach(v=>remove(a, v))

  return a;
}





export function single<T>(a: Array<T>, f: (e: T) => boolean, error?: string): T {

  const buffer = a.filter(f);

  if (buffer.length != 1) throw Error((typeof error === "undefined") ? "Can't find single element at array" : error);

  return buffer[0];
}

export function singleOrNull<T>(a: Array<T>, f: (e: T) => boolean, error?: string): T | null {

  const buffer = a.filter(f);

  if (buffer.length > 1) throw Error((typeof error === "undefined") ? "Can't find single element at array" : error);

  return buffer.length === 1 ? buffer[0] : null;
}

/**
 * 
 * @param a Array
 * @param item Array element
 * @param index Must be less or equal array length. When equal to array lenth. When equal to array lenght - just add to the end
 */
export function insertStrictly<T>(a: Array<T>, item: T, index: number): void {

  if (index > a.length) throw Error(`Can't insert item at ${index} position with array length ${a?.length}`);

  if (a.length === index) {
    a.push(item);
  }
  else {

    //increase array size and copy last item 
    a.push(a[a.length - 1]);

    //shift all other items going down to index
    for (let i = (a.length - 1) - 1; i > index; i--) {

      a[i] = a[i - 1];
    }

    a[index] = item;
  }
}


export function isEqual(a:number[], b:number[]){

    if (a == null || b == null) return false;

    if (a.length != b.length) return false;

    for (let index = 0; index < a.length; index++) {
      
      if (a[index] != b[index]) return false;
    }

    return true;
}


/*
declare global {
 
 
  interface Array<T> {
 
    position<T>(vlaue: T): { x: number, y: number }
  }
}
 
// Array.prototype.Xyz = function (o) {
//   // code to remove "o"
//   return this;
// }
 
Array.prototype.position = function <T>(value: T): { x: number, y: number } {
 
  const result = { x: 0, y: 0 };
 
  let matches_counter = 0;
 
  this.forEach((row, y) => {
 
    const x = row.indexOf(value);
 
    if (x >= 0) {
 
      matches_counter++;
 
      result.x = x;
      result.y = y;
    }
  })
 
  if (matches_counter != 1) throw Error(`Can't find ${value} at array (or there are few of them)`);
 
  return result;
}
*/

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);