import { Guid } from "@/lib";
import { Sunrise } from "../_infra/time";


export class Person //not model!
{
    readonly name: string
    readonly nid: Guid

    captain = false //(1)

    subordinate = false //(2) - NOT captain, NOT subordinate - > 'customer'

    strict = false //if suboridante - strict, ignore autontums

    autonomus = false // if NOT strict sub - autonums or not

    idle = false // ATTENTION! this flag used for 'autonomus' to explicitly indicate that it's idled, DO NOT CONFUSE WITH 'idle' method



    //me = true //means that linked to current user

    constructor(name: string, nid:Guid) {
        this.name = name;
        this.nid = nid;
    }   

    
}




export class PersonObligationSpecification
{
    nid: Guid  = Guid.newGuid()
    start: Sunrise | null = null
    end: Sunrise | null = null

    balast: 
    {
        work: number,
        offdays: number,
        offhours: number

    } = {work:0, offdays:0, offhours:0}

}


