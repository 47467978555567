import { Project } from "../../logic/workspaces/project";
import { TicketRmodel } from './ticket.rmodel';
import { TaskRmodel } from "./tickets/task.rmodel";
import * as arrays from '@/lib/array.lib'
import { WorkspaceRmodel } from "../workspace.rmodel";
import { queues, TodoSection } from '@/logic/_infra/queues'
import { BoardPosition, boards } from '@/logic/_infra/boards';
import { TicketBoardOperation, TicketQueueOperation } from "../operations";

export class ProjectModel extends Project {

    constructor(workspace: WorkspaceRmodel) {

        super();
        this.workspace = workspace;
    }

    readonly workspace: WorkspaceRmodel;

    disposition: { queue: TicketRmodel[], pins: TicketRmodel[], desk: TicketRmodel[], current: TicketRmodel | null, board: (TicketRmodel | null)[][] } =
        {
            queue: [],
            pins: [],
            desk: [], //not factuall used
            current: null, //not factually used
            board: [],
        };

    ticketOrNull(position: BoardPosition): TicketRmodel | null {

        return this.disposition.board[position.row][position.column];
    }

    ticketOrNull_N(n:number): TicketRmodel | null{

        //return null
        let buffer: TicketRmodel | null;
        
        buffer =  arrays.singleOrNull(boards.items(this.disposition.board), x => x != null && x.n == n);

        if (buffer != null) return buffer

        if (this.disposition.current != null && this.disposition.current.n == n) buffer = this.disposition.current

        if (buffer != null) return buffer

        buffer =  arrays.singleOrNull(this.disposition.desk, x => x != null && x.n == n);

        if (buffer != null) return buffer

        buffer =  arrays.singleOrNull(this.disposition.queue, x => x != null && x.n == n);

        if (buffer != null) return buffer

        buffer =  arrays.singleOrNull(this.disposition.pins, x => x != null && x.n == n);

        if (buffer != null) return buffer

        return null
    }


    ticketBoardApply(operation: TicketBoardOperation) {

        const project = this;

        const board = project.disposition.board;

        let ticket = operation.objects.ticket;


        if (ticket === null) {

            ticket = this.workspace.ticket(operation.n);
        }
        else {

            ticket = operation.objects.ticket!;
        }

        if (operation.name === "CREATE") {

            boards.put(board, ticket, operation.target);

        }
        else if (operation.name === "MOVE") {

            boards.move(board, ticket, operation.target);
        }

        else if (operation.name == "CLOSE") {

            const p = boards.position(project.disposition.board, ticket);

            if (operation.target != null) {

                if (p.column != operation.target.column || p.row != operation.target.row) throw Error('Precondiotn pass failed');
            }

            boards.clear(project.disposition.board, p);
        }
        else throw Error(`Unknown operation ${operation.name}`)
    }

    ticketQueueApply(operation: TicketQueueOperation) {

        const project = this;
        const  queue = project.disposition;

        const ticket = operation.objects.ticket;


        // if (ticket === null) {

        //     ticket = this.workspace.ticket(operation.n);
        // }
        // else {

        //     ticket = operation.objects.ticket!;
        // }

        if (operation.name === "CREATE") {
            
            queues.itemAdd(queue, ticket, operation.target);

        }
        else if (operation.name === "MOVE") {

            queues.itemMove(queue, ticket, operation.target);

        }
        else if (operation.name == "CLOSE") {

            queues.itemRemove(queue, ticket, operation.target.section);
        }
        else throw Error(`Unknown operation ${operation.name}`)
    }


}