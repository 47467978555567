import { PersonRmodel } from "../person.rmodel";

export function personSorter(a: PersonRmodel, b: PersonRmodel, me: PersonRmodel | null): number {

    if (a === me) return -1;
    if (b === me) return +1;

    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}