import "@/_dev/assets/dev.css"
import routes from '@/routes'
import DevButton from '@/components/tech/dev/Button-Dev.vue'
import Error from '@/components/tech/dev/ErrorComponent.vue'
import LabComponent from '@/_dev/lab/_LabComponent.vue'
import CalendarLabComponent from '@/_dev/lab/CalendarLabComponent.vue'
import HistoryLabComponent from '@/_dev/lab/HistoryLabComponent.vue'

import { setupApp } from './main'

import HomeDevView from '@/_dev/HomeDevView.vue'

import TimePlannerComponentStand from "@/_dev/lab/TimePlannerComponent_Stand.vue"
//import HomeDevView from '@/_dev/DevStand.vue'

const routes_dev = 
[
    {
        path: '/lab',
        name: 'lab',
        component: LabComponent
    },
    {
        path: '/lab/calendar',
        name: 'lab-calendar',
        component: CalendarLabComponent
    },
    {
        path: '/lab/history',
        name: 'lab-history',
        component: HistoryLabComponent
    },
    {
        path: '/lab/timeplaner',
        name: 'lab-timeplaner',
        component: TimePlannerComponentStand
    }
]

const routes_dev_names:string[] = [];

routes_dev.forEach(r=>routes.push(r)) 
routes_dev.forEach(r=>routes_dev_names.push(r.name))





//todo here we need to adjust 'routes' table to replace 'HomeView' to 'HomeDevView'
//todo here we need to adjust 'routes' table to replace 'HomeView' to 'HomeDevView'

//const routes_b = routes

async function f(){

    routes.forEach(r=>{

        if (r.path == '/') r.component = HomeDevView
    })
    
    // {
    //     path: '/dev.htm',
    //     component: Home
    //   },
    
    const app = await setupApp(routes, routes_dev_names)
    
    app
    .component('dev-button', DevButton)
    .component('Error', Error)
    .mount('#app');
}

f()