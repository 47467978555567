import { Resolution, Status } from '@/logic/workspaces/tickets/_def';
import { LabelModel } from '@/app.context/workspace/label.rmodel';
import { TodoRmodel } from '@/app.context/workspace/tickets/todo.rmodel';
import { TicketRmodel } from '@/app.context/workspace/ticket.rmodel';
import { TaskRmodel } from '@/app.context/workspace/tickets/task.rmodel';
import { computed, ref } from '@vue/reactivity';

import { cardClassOfStatus, ticketInternal, CardClass, personSubmenuController as PersonSubmenuController, tasks_ExceptCurrentPerson } from '@/controllers/workspaces/tickets/_spec/ticket.item.controller'
import sys from '@/sys';
import { Ref, nextTick, reactive } from 'vue';
import { PersonRmodel } from '@/app.context/workspace/person.rmodel';

import { TicketAccessLevel } from '@/controllers/workspaces/user.controller.workspace';
import { ITicketUiElementController } from '@/components/Workspaces/tickets/_spec/ticket.controller.elementary.interface'
import { blockers_unresolved, block_impersonal, todoCardClass } from '@/controllers/workspaces/todo.controller';
import { TqueuePosition } from '@/logic/_infra/queues';
import { BlockRmodel } from '@/app.context/workspace/tickets/tasks/block.rmodel';
import { filesOrNull } from '@/controllers/_tech';
import { item } from '@/app.context/workspace/tickets/_spec/Ticket.Item.Model';
import * as ticketProcessor from '@/ops/workspaces/ticket.processor'


export const emitDefinition = {
    'click': null,
    'x': null,
    'v': null,
    'o': null,
    'stop': null,
    'person': (p: PersonRmodel, index: number | null) => { return true },
    'block': (b: BlockRmodel) => { return true },
    'label': null,
    'command': null,
    'click.right': null,
    'files': null
}


export interface IEmits
{
    block(b: BlockRmodel): void;
    files(f: boolean | File[]): void;
    click(...args:any[]): void;
    label(l: LabelModel): void;
    person(person: PersonRmodel, DefaultRestorePostion: number|null): void;
    o(): void;
    stop(): void;
    command(value: string, arg1: TicketRmodel): void;
    v(...args:any[]):void
    x(...args:any[]):void
}

export interface IProps
{ 
    ticket?: TicketRmodel, 
    todo?: TodoRmodel, 
    level?: TicketAccessLevel, 
    circledX?: boolean, 
    controller?: ITicketUiElementController 
}

export default function f(props: IProps, triggers: IEmits, input: Ref<HTMLInputElement | null>) {

    const mode:
        {
            editing: boolean,
            menu: number,
            menu_person: PersonRmodel | null,
            level: TicketAccessLevel

        } = reactive({
            editing: false,
            menu: 0,
            menu_person: null,
            level: props.level ?? TicketAccessLevel.None
        }
        )

    const ticket = computed(() => {

        return ticketInternal(props);
    })


    const blockers = computed(() => {

        if (props.todo != null) return blockers_unresolved(props.todo)

        return []
    })

    const block_impersonal_ui = computed(() => {

        if (props.todo != null) return block_impersonal(props.todo); else return null

    })




    const personMenuController = PersonSubmenuController(mode)

    //coloring tickets (green, red, yellow, etc)
    const cardClass = computed(() => {

        let result: CardClass = ''

        if (props.todo) {

            const todo = props.todo!;

            result = todoCardClass(todo)

            return result;
        }
        else if (props.ticket) {

            const t = props.ticket!;

            //todo 9 if ticket current (if current one of multitasked - if current 'x times' - put label (x) at top of handel)
            const current = t.tasks.filter(x => x.person.current?.task.ticket == t)

            if (current.length) result = 'focused'
            else {

                //const s = t.status()

                let s: Status | null = null

                if (t.resolution == null) s = Status.Neutral
                if (t.resolution == Resolution.Done) s = Status.Good
                if (t.resolution == Resolution.Failed) s = Status.Warning

                if (s != null) {

                    result = cardClassOfStatus(s)
                }
                else result = ''
            }

            return result;
        }
        else throw new Error('Both ticket and todo are null')
    })





    //exludes person if card represent todo item (not ticket)
    const personTags = computed(() => {

        return tasks_ExceptCurrentPerson(props)
    })



    // const emit = defineEmits({
    //     'click': null,
    //     'x': null,
    //     'v': null,
    //     'o': null,
    //     'stop': null,
    //     'person': (p: PersonRmodel, index: number | null) => { return true },
    //     'block': (b: BlockRmodel) => { return true },
    //     'label': null,
    //     'command': null,
    //     'click.right': null,
    //     'files': null
    // })

    



    function onSubmit(e: KeyboardEvent) {

        mode.editing = false;

        const input = e.target as HTMLInputElement

        if (input != null) {


             triggers.command(input.value, ticketInternal(props))
             //emit('command', input.value, ticketInternal(props))
        }


    }

    function onEditMode() {

        mode.editing = true;

        if (input.value) {

            const t = input.value;

            nextTick(() => t.focus())

            //t.focus();
        }
        else console.log('no textinpu')

    }

    function onCheck() {

        sys.trace('/workspaces/ticket - "Check"')

        const t: any = item(props)

        //emit('v');
        if (t)  triggers.v(t)
    }

    function onStop() {

        sys.trace('/workspaces/ticket - "Stop"')

        //emit('stop');
        triggers.stop();
    }


    function onReset() {

        sys.trace('/workspaces/ticket - - "Check"')

        //if (props.controller?.check(props.))

        //emit('o');
        triggers.o()


    }

    function OnPersonLabelClick(task: TaskRmodel) {

        if (mode.level < TicketAccessLevel.Full) return

        if (task.resolution != null) {
            //emit('person', task.person, TqueuePosition.DefaultRestorePostion);
            triggers.person(task.person, TqueuePosition.DefaultRestorePostion)
        }
        else triggers.person(task.person, null); //cancel task //emit('person', task.person, null); //cancel task
    }

    function OnTagLabelClick(l: LabelModel) {

        //emit('label', l);
        triggers.label(l)
    }

    function onClick(...args:any[]) {

        mode.editing = false

        triggers.click(...args)

        //emit('click');
    }



    function onCross() {

        sys.trace('/workspaces/ticket - Card - "X"')

        const t: any = item(props)

        //emit('x');
        if (t) triggers.x(t)
    }

    function onRightClick() {

        //# TRACE
        // if (props.todo != undefined) {
        //     console.log(`${props.todo.ticket.n} - Blocks:${props.todo!.task.blocks.length}`)

        //     const n = props.todo!.ticket.n

        //     const ticket = props.todo!.ticket.workspace.ticket(n)

        //     //const ticket = context.cursor.workspace!.ticket(n)

        //     ticket.tasks.forEach(t => {

        //         t.blocks.forEach(x => console.log(x))
        //     })

        // }

        if (props.level != undefined && props.level >= TicketAccessLevel.Full) {
            personMenuController.setPersonSubmenuPreliminary()
        }
    }

    function setPersonSubmenu(p: PersonRmodel) {

        personMenuController.setPersonSubmenu(p)
    }

    function resetPersonSubmenu() {

        personMenuController.resetPersonSubmenu()
    }

    function emitPersonSetEvent(p: PersonRmodel, index: number) {

        const e = personMenuController.eventAndResetPersonSubmenu(p, index)

        const item = ticketInternal(props)

        //emit('person', e.person, e.index)
        triggers.person(e.person, e.index)
    }

    function onDrop(e: DragEvent) {

        const f = filesOrNull(e)

        //onDrop

        //ticketProcessor.addFiles(s, m, t, files)

        //if (f != null) emit('files', f)
        if (f != null && f!= false)  triggers.files(f) // emit('files', f)
    }

    function emitBlockEvent(b: BlockRmodel) {

        const item = ticketInternal(props)

        //emit('block', b)
        triggers.block(b)
    }

    const result =
    {
        props,
        mode,
        ticket,
        blockers,
        block_impersonal_ui,
        personTags,
        cardClass: cardClass,

        h:
        {
            drop: onDrop,
            person: emitPersonSetEvent,
            block: emitBlockEvent,
            cross: onCross,
            click: onClick,
            person_label: OnPersonLabelClick,
            tag_label: OnTagLabelClick,
            stop: onStop,
            reset: onReset,
            click_right: onRightClick,
            check: onCheck,
            name_submit: onSubmit,

        },

        ui:
        {
            personMenuReset: resetPersonSubmenu,
            personMenuSet: setPersonSubmenu,
            
            edit: onEditMode
        }
    }

    return result
}