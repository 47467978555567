import { Resolution, toBoolean } from "@/logic/workspaces/tickets/_def"
import { TaskRmodel } from "../../task.rmodel"
import { PersonRmodel } from "@/app.context/workspace/person.rmodel"
import { EntityUpdateDto } from "@/io/storages/workspace/EntityOperation"
import { TodoItemType } from "@/logic/workspaces/tickets/todos/_def.todos"
import { EntityRef } from "@/logic/workspaces/_tech"

export function update_resolution(task: TaskRmodel, r: Resolution | null) {

    const ref = new EntityRef(task.ticket.n, 'T')

    ref.person = task.person.nid

    const operation = new EntityUpdateDto(ref)

    operation.field = EntityUpdateDto.Key_Resolution
    operation.value = toBoolean(r)

    return operation

}

export function update_workable(task: TaskRmodel, workable: boolean) {



    const ref = new EntityRef(task.ticket.n, 'T')

    ref.person = task.person.nid

    const operation = new EntityUpdateDto(ref)

    operation.field = EntityUpdateDto.Key_Task_Workable
    operation.value = workable

    return operation

}

export function updateOperation_Derivative(task: TaskRmodel, r: Resolution | null, type: TodoItemType, blocker: PersonRmodel | null): EntityUpdateDto {
    const ref = new EntityRef(task.ticket.n, type)

    ref.person = blocker?.nid ?? null
    ref.person_aux = task.person.nid


    const operation = new EntityUpdateDto(ref)

    operation.field = EntityUpdateDto.Key_Resolution
    operation.value = toBoolean(r)

    return operation
}



// export function blockCreate(task: TaskRmodel, blocker: PersonRmodel | null): BlockEntityDto
//  {

//     //const block = task.blockNew(blocker);

//     const entity = new BlockEntityDto(task.ticket.n, task.person.nid);

//     entity.blocker = blocker?.nid ?? null

//     return entity

//     // let todo = null;

//     // if (blocker != null) todo = new TodoRmodel(block/*, blocker*/);

//     // return { operation: entity, todo };
// }


