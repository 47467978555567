import { createApp } from 'vue'
import App from './App.vue'
import routes from '@/routes'
import AppContext from './context'
import { SignalrService } from './gw/service.signalR'

import { initializeApp, } from "firebase/app";
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordName, RouteRecordRaw, RouteRecordRedirectOption } from 'vue-router'

import { UserAppContextController } from '@/controllers/context/user.appContext.controller'
import { WorkspaceAppContextController } from '@/controllers/context/workspace.appContext.controller'
import { TicketAppContextController } from '@/controllers/context/workspaces/ticket.appContext.controller'
import dispatcher_user from '@/gw.dispatchers/user.dispatcher.signalR'
import dispatcher_workspace from '@/gw.dispatchers/workspace.dispatcher.signalR'
import dispatcher_ticket from '@/gw.dispatchers/workspaces/ticket.dispatcher.signalR'
import '@/lib/array.lib.extensions'
import '@/lib/date.extensions'
// Importing the global css file
import "@/styles"
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import { googleSdkLoaded } from "vue3-google-login"

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import sys, { setupErrorUI } from '@/sys'
import Notifications from '@kyvg/vue3-notification'
import { useNotification } from "@kyvg/vue3-notification";

dayjs.extend(utc)


const { notify } = useNotification()

setupErrorUI(function () {

    notify({
        title: "Error",
        text: "Problem connectig to server",
        type: "error",

    });
})



//const app = createApp({...})


//import { ServiceMock } from 'dev/mocks'

// Use plugin with defaults

// interface IMnWindow {

//     gapiLoaded(): void
//     gisLoaded(): void
// }

// declare global {
//     interface Window {
//         MN: IMnWindow;
//     }
// }

// window.MN =
// {
//     gapiLoaded: function () {

//         console.log('gapiLoaded')

//         // Discovery doc URL for APIs used by the quickstart
//         const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'; //#config

//         async function initializeGapiClient() {

//             await window.gapi.client.init({
//                 apiKey: 'AIzaSyAKVADbmY7uRuXn44bxw92WCO6szxy1QjY', //#config
//                 discoveryDocs: [DISCOVERY_DOC],
//             });

//             console.log('/integrations/gapi Initialized')
//         }

//         gapi.load('client', initializeGapiClient);


//     },
//     gisLoaded: function () { console.log('/integrations/gis Loaded') }

// }





export async function setupApp(routes: RouteRecordRaw[], routes_anonymous?: RouteRecordName[]) {

    const router = createRouter({
        history: createWebHistory(),
        //mode: 'history'
        routes
    })


    //const prefix = ""; //run locally (localhost) 

    const prefix = "https://back.tasknum.com"; //run smartaAspNet hostin 


    const config =
    {
        api: prefix + "/api",
        hubs: prefix + "/hubs",
    };



    const service = await SignalrService.Instance(config.api, config.hubs);
    //const service = new ServiceMock() //DEBUG VERSION!



    const context = new AppContext(service);

    const controller_context_user = new UserAppContextController(context)

    service.onUserConnection = function (connection) {

        dispatcher_user(connection, controller_context_user)
    }


    const controller_context_workspace = new WorkspaceAppContextController(context)

    service.onWorkspaceConnection = function (connection) {

        dispatcher_workspace(connection, controller_context_workspace)
    }

    const controler_context_ticket = new TicketAppContextController(context)

    service.onTicketConnection = function (connection) {

        dispatcher_ticket(connection, controler_context_ticket)
    }

    googleSdkLoaded((google) => {

        //const c2 = google.accounts.//oauth2.initCodeClient(n)

        const c = google.accounts.oauth2.initTokenClient({

            client_id: '93228742275-jucm48n9ieahck8na9tgu4rpg3jdj331.apps.googleusercontent.com', //#config
            scope: 'https://www.googleapis.com/auth/calendar.readonly',
            callback: (response) => {

                console.log("Handle the response", response)
            }
        })

        context.integrations.Google.requestAccessToken = function () {

            c.requestAccessToken({ prompt: 'consent' })
        }

        //google.accounts.oauth2.hasGrantedAnyScope()


    })


    try {

        await context.initialize();

    } catch (error) {

        sys.exception(error)
    }





    router.afterEach(async (to, from) => {

        if (to.name == 'person-timeline') context.cursor.person_code = 'T'
        else context.cursor.person_code = null
    })

    router.beforeEach(async (to, from) => {

        try {



            if (to.name === "home") return true; // '/home' allowed always

            if (to.name != undefined && routes_anonymous != null) {

                if (routes_anonymous.indexOf(to.name) >= 0) return true;
            }



            if (!context.authenticated.value) //if context.model.user == null
            {
                context.notice("[Authentication]");

                return { name: 'home' }
            }

            //before make sure that we get basic user account data before going any other route then /home
            if (context.model.user == null) {

                context.model.setup(await context.service.user());

                context.storages.user!.sync(); //ensure data is synced through duplex channel
            }

            return true

        } catch (error) {

            sys.exception(error)

            throw error
        }
    })


    const app = createApp(App)
        .use(router)
        .use(VCalendar, {})
        .use(Notifications)
        .provide<AppContext>('C', context)


    return app;

}