function nextInterval(x:number){

    const d = new Date()

    d.setMilliseconds(0)
    d.setSeconds(0)


    const m = d.getMinutes()

    const remainder = m % x;



    const r = remainder > 0 ?  x - remainder : x - m

    return d.addMinutes(m)
}

// export function absDiffInMinutes(a: Date, b: Date): number {

//     const diff_ms = Math.abs(a.getTime() - b.getTime())
    
//     const diff_minutes = Math.floor((diff_ms / 1000) / 60);

//     return diff_minutes
// }

export function absDiffInMs(a: Date, b: Date): number {

  const diff_ms = Math.abs(a.getTime() - b.getTime())
  
  return diff_ms
}


export function roundToClosestQuarterHour(inputDate: Date, direction: 'forward' | 'back'): Date {
    const minutes = inputDate.getMinutes();

    
    
    
    if (minutes != 0 && minutes != 15 && minutes != 30 && minutes != 45){

      
      let roundedMinutes: number;
      
      if (direction === 'forward') {
        roundedMinutes = Math.ceil(minutes / 15) * 15;
      } else {
        roundedMinutes = Math.floor(minutes / 15) * 15;
      }
      
    
  
    const roundedDate = new Date(inputDate);
    roundedDate.setMinutes(roundedMinutes);
    roundedDate.setSeconds(0)
    roundedDate.setMilliseconds(0)
    
    return roundedDate;
  }
  else
  {

    let buffer:Date

    if (direction === 'forward') buffer = inputDate.cloneAndAddMinutes(15)

    else buffer = inputDate.cloneAndAddMinutes(-15)
    
    buffer.setSeconds(0)
    buffer.setMilliseconds(0)

    return buffer
  }
  }

export function nextQuarter_Delta(){

    return nextQuarter().getTime() - Date.now()
}

export function nextQuarter(){

    return nextInterval(15)
}

export function nextHour_Delta(){

    return nextHour().getTime() - Date.now()
}

export function nextHour(){

    return nextInterval(60)
}


export function isToday(date:Date){

    const buffer = new Date()

    

    return buffer.getFullYear() == date.getFullYear() && buffer.getMonth() == date.getMonth() && buffer.getDate() == date.getDate()
}

export function minDate(dates: Date[]): Date | null {
    if (dates.length === 0) {
      return null;
    }
  
    let minDate: Date = dates[0];
  
    for (const date of dates) {
      if (date < minDate) {
        minDate = date;
      }
    }
  
    return minDate;
  }

  export function maxDate(dates: Date[]): Date | null {
    if (dates.length === 0) {
      return null;
    }
  
    let minDate: Date = dates[0];
  
    for (const date of dates) {
      if (date > minDate) {
        minDate = date;
      }
    }
  
    return minDate;
  }

// function nextQuarter_old(){

//     const d = new Date()

//     d.setMilliseconds(0)
    


//     const m = d.getMinutes()

//     const r = 15 - (m % 15)

//     return d.addMinutes(m)
// }