import { TodoRef } from "@/logic/workspaces/_tech";
import { OccupationtRef, Resolution, Status } from "@/logic/workspaces/tickets/_def";
import { PersonRmodel } from "../person.rmodel";
import { TaskRmodel } from "./task.rmodel";
import { BlockRmodel } from "./tasks/block.rmodel";
import { VerificationModel } from "./tasks/verification.rmodel";
import { IEntityRefDto } from '@/io/dto/workspaces/_tech'
import { TodoItemType } from "@/logic/workspaces/tickets/todos/_def.todos";
import { TicketRmodel } from "../ticket.rmodel";
import { EntityRef } from "@/logic/workspaces/_tech";
import { IMeffortDto } from "@/io/dto/workspaces/persons/effort.dto";
import { Guid } from "@/lib";
import { ITicketOrTodoRef } from "@/logic/workspaces/tickets/todo";


export class TodoRmodel {




    // static  meffortRef(props: {ticket?: TicketRmodel, todo?: TodoRmodel, category?:string})  {


    //     const id = Guid.newGuid()

        

    //     const args: IMeffortDto = {

    //         mid: id,

    //         name: null,

    //         category: props.category ?? null,

    //         ticket: 0,

    //         type: null,

    //         person_aux: null,

    //         groups: [],
    //         persons: []
    //     }

    //     TodoRmodel.ticketOrTodoRef_WriteTo(props, args)

    //     return args
    // }

    // static  ticketOrTodoRef(props: {ticket?: TicketRmodel, todo?: TodoRmodel, category?: string | null}):ITicketOrTodoRef 
    // {
    //     const dto: ITicketOrTodoRef = {
    //         ticket: 0,
    //         type: null,
    //         person_aux: null,
    //         //category: null
    //     }

    //     this.ticketOrTodoRef_WriteTo(props, dto)

    //     return dto
    // }

    // static  ticketOrTodoRef_WriteTo(props: {ticket?: TicketRmodel, todo?: TodoRmodel, category?: string | null}, dto:OccupationtRef)  {

    //     //dto.category = props.category ?? null

    //     if (props.ticket != undefined) dto.ticket = props.ticket.n

    //     if (props.todo != undefined){

    //         dto.ticket = props.todo.ticket.n
    //         dto.type = props.todo.type()
    //         dto.person_aux = props.todo.auxPerson()
    //     }
        

    //     //else throw Error('Argument: ticket and todo bothe are empty')
    // }

    //---


    //person:PersonRmodel

    task: TaskRmodel

    block: BlockRmodel | null = null

    verification: VerificationModel | null = null

    get person():PersonRmodel{
        
        if (this.isTask()) return this.task.person;
        else if (this.isBlock()) return this.block!.person!;
        else if (this.isVerification()) return this.verification!.person;

        throw Error(`/workspaces/person - Unknown todo item type - 20230218E`)
    }

    get ticket():TicketRmodel{
        
        return this.task.ticket;
    }


    
    status() : Status | null{


        if (this.isBlock()) return Status.Neutral;

        if (this.isVerification()) return Status.Neutral;

        return this.task.status();
    } 
    
    

    identificator(): TodoRef {
        
        //const nid = (this.isDerivative()) ? this.auxPerson()

        const x = new TodoRef(this.task.ticket.n, this.type())

        x.person_aux = this.auxPerson()

        return x
    }

    auxPerson(){

        if (this.isBlock()) return this.task.person.nid
        if (this.isVerification()) return this.task.person.nid; 

        return null; //task
    }



    
    ref(): IEntityRefDto{
        
        const r = new EntityRef(this.task.ticket.n, this.type())

        if (this.isBlock()) r.person_aux = this.task.person.nid;
        if (this.isVerification()) r.person_aux = this.task.person.nid;

        return r
    }

    refJson(): string
    {
        const r = this.ref();

        return JSON.stringify(r)
    }


    public get key():string {
    
        const code:string = this.type();

        //console.log(code)

        //const p = this.auxPerson()

        const p = this.auxPerson()

        //console.log(p)

        let result = `${code}-${this.task.ticket.n}`

        if (p != null) result = `${code}-${this.task.ticket.n}-${p}`

        //console.log(result) //#DEV/TRACE

        return result

        //return `${code}-${this.task.ticket.n}`

        //return code + this.task.ticket.n.toString();
    }

    type():TodoItemType{

        if (this.isBlock()) return 'B';
        if (this.isVerification()) return 'V';

        return "T";
    }

    constructor(object: TaskRmodel | BlockRmodel | VerificationModel/*, person:PersonRmodel*/) {

        //this.person  = person

        if ( object instanceof TaskRmodel){

            this.task = object;
        }
        else if (object instanceof BlockRmodel){

            this.task = object.task;
            this.block = object;

        }
        else if (object instanceof VerificationModel){
            this.task = object.task;
            this.verification = object;
        }
        else throw console.error(`/app/workspace/person - Can't construct 'Todi' item ${object}`);
    }

    isTask() { return this.block === null && this.verification === null;}

    isBlock() { return this.block != null;}

    isVerification() { return this.verification != null;}

    isDerivative(){

        return this.isBlock() || this.isVerification()
    }

    derivativePerson(){

        if (!this.isDerivative()) return null

        if (this.isBlock()) return this.block!.task.person

        if (this.isVerification()) return this.verification!.task.person

        throw new Error('Corrupted state of todo item - E20230908')
    }

    toString(): string {
        return this.task.ticket.toString() + ':@' + this.task.person.toString();
    }

    update_resolution(r:Resolution){

        if (this.isTask()) this.task.resolution = r;
        else if (this.isBlock()) this.block!.resolution = r
        else if (this.isVerification()) this.verification!.resolution = r
        else throw Error(`/workspaces/tickets/todo - Corrupted todo item type - 20230908E`)
    }
}

