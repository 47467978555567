//ref: EntityRef, index: number

import { PersonRmodel } from "@/app.context/workspace/person.rmodel";
import AppContext from "@/context";
import { TqueuePosition } from "@/logic/_infra/queues";
import { IEntityRefDto } from '@/io/dto/workspaces/_tech'
import sys from "@/sys";
import { TodoRmodel } from "@/app.context/workspace/tickets/todo.rmodel";


export function queueEntity(context: AppContext, p: PersonRmodel, ref: IEntityRefDto, position: TqueuePosition) {

  //const p = person_model.value!;

  if (ref.type != null) {

    //const todo = p.todoItemOrNull(ref.n, ref.type, ref.aux)!

    //such as we about to move item to queue - first of all we chech the queu
    let todo = p.todoItemOrNull_AtQueue_N(ref.n, ref.type, ref.person_aux)!

    if (todo == null) todo = p.todoItemOrNull_AtBoard_N(ref.n, ref.type, ref.person_aux)!;

    queueEntity_todo(context, todo, position)

    /*
    let todo_replacement:TodoRmodel | null = null

    if (p.current == todo){

        todo_replacement = p.todo_next()
    }

    context.ops!.xbq!.todoQueue(todo, p, position, todo_replacement)
    */
  }
  else sys.warn(`/worksaces/persons - Ticket drop does not make sense - E20230290-B`)
}

export function queueEntity_todo(context: AppContext, todo: TodoRmodel, position: TqueuePosition) {

  const person = todo.person

  let todo_replacement: TodoRmodel | null = null

  if (person.current == todo) {

    todo_replacement = person.todo_next()
  }

  context.ops!.xbq!.todoQueue(todo, person, position, todo_replacement)
}



export function stop(context:AppContext, p: PersonRmodel, value: boolean) {

  context.storages.workspace!.personStopUpdate(p.workspace.wid, p.nid, value)
}