import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel";
import AppContext from "@/context";
import sys from "@/sys";
import { download } from "@/controllers/_tech";
import { IFileStorage } from "@/io/storages";

export async function downloadTicketFile_identificator(ticket: TicketRmodel, identificator:string, storage:IFileStorage, name?:string){

    if (storage == null) sys.error('/workspace/ticket - Attempt to download file when there is no file info') 
    else
    {


        const url = await storage.url(ticket.workspace.wid, ticket.n, identificator)

        download(url, name ?? identificator)

        /*

        const link = document.createElement('a')

        link.href = url
        link.download = name ?? identificator
        link.click()
        */
    }
}


export async function downloadTicketFile(ticket: TicketRmodel, context:AppContext){

    // if (ticket.file == null || context.storages.files == null) sys.warn('/workspace/ticket - Attempt to download file when there is no file info') 
    // else
    // {
    //     const url = await context.storages.files.url(ticket.workspace.wid, ticket.n, ticket.file)

    //     const link = document.createElement('a')

    //     link.href = url
    //     link.download = ticket.file
    //     link.click()
    // }

    if (ticket.file == null) sys.warn('/workspace/ticket - Attempt to download file when there is no file info') 

    else return downloadTicketFile_identificator(ticket, ticket.file, context.storages.files!, ticket.file)
}

export function clipboardTicketTitle(ticket: TicketRmodel) {

    navigator.clipboard.writeText(ticket.clipboardTitle());
}


//GENERAL