import { IFileStorage } from "@/io/storages";
import { Guid } from '@/lib';
import { FirebaseApp, } from "firebase/app";
import { FirebaseStorage, getDownloadURL, getStorage, getBytes, ref, uploadBytes } from "firebase/storage";





export interface IFileIndex {
    identificatorCreate(wid: Guid, n: number, name: string, type?:string): Promise<{ identificator: string, pk: number }>

    //supposet to be used for confirming that file was actuall stored
    itentificatorStatusUpdate(wid: Guid, n: number, pk: number): Promise<void>
}

export class FirebaseFileStorage implements IFileStorage {


    storage: FirebaseStorage
    index: IFileIndex;

    /**
     *
     */
    constructor(app: FirebaseApp, index: IFileIndex) {



        // Initialize Cloud Storage and get a reference to the service
        this.storage = getStorage(app);
        this.index = index;



    }

    async push(wid: Guid, n: number, filename: string, content: ArrayBuffer | Blob | Uint8Array, type?:string): Promise<{identificator:string, url:string}> {

        let file_id:string

        return this.index.identificatorCreate(wid, n, filename, type)
        .then(async id => {

            file_id = id.identificator

            const storageRef = ref(this.storage, this.path(wid, n, id.identificator));

            const url = await uploadBytes(storageRef, content)
            .then(() => this.index.itentificatorStatusUpdate(wid, n, id.pk))
            .then(()=>getDownloadURL(storageRef))
            
            return {identificator:id.identificator, url}

        })
    }
    
    pull(wid: Guid, n: number, fileIdentificator: string): Promise<ArrayBuffer> {

        const storageRef = ref(this.storage, this.path(wid, n, fileIdentificator));

        return getBytes(storageRef)
    }

    //'wid/n/filename.png'
    //type:"name.png" | 'images/png'
    url(wid: Guid, n: number, fileIdentificator: string): Promise<string> {

        // Create a storage reference from our storage service
        const storageRef = ref(this.storage, this.path(wid, n, fileIdentificator));

        //in chat - if type in ('image/*') - the build download url to display image at chat dialog
        return getDownloadURL(storageRef)
    }

    path(wid: Guid, n: number, filename: string): string {

        //gs://mndev-c64ec.appspot.com/content/3D5C82B9-794A-43EF-89C0-546AA0D7D8F3/1

        return '/content/' + wid.toString().toUpperCase() + '/' + n.toString() + '/' + filename
    }
}