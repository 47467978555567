import { Guid } from "@/lib"
import { TodoItemType } from "./tickets/todos/_def.todos"


/**
 * //'P' for project, 'N' for 'PersoN'
 */
export enum Entity
{
    Project = 'P',
    Person = 'N'
}

export class EntityRef {
    n: number  =  0
    type: TodoItemType | null  = null
    
    /**
     * Optional - supposed to be used in blocks/verifications to indicate the task
     */
    person_aux: Guid | null  = null

    /**
     * Optional - supposed to be used in blocks/verifications and tasks to indicate the person who working on this task/block/verification. It may be empty in cases when person is set on upper level
     */
    person: Guid | null = null 

    constructor(n: number, type: TodoItemType | null) {
        this.n = n;
        this.type = type;
    }
}






/**
 * NID here supposed to inicate person of task that was BLOCKED or under verification
 */
export class TodoRef {
    
    static Identificator_Json(n: number, t:TodoItemType, person_aux?: Guid | null) {
        
        const buffer = {n: n, type: t, person_aux: person_aux ?? null}

        return JSON.stringify(buffer)
    }

    static Instance_Json(identificator:string) : TodoRef {

        const b = <{n: number, type: TodoItemType, person_aux: Guid | null}>JSON.parse(identificator)

        const r = new TodoRef(b.n, b.type)

        r.person_aux = b.person_aux

        return r
    }

    // STRUCTURE //

    constructor(n: number, type: TodoItemType) {
        this.n = n;
        this.type = type;
    }
    
    readonly n: number
    readonly type: TodoItemType

    person_aux: Guid | null = null

    // ALGS //

    eq(r:TodoRef){

        if (r.n != this.n) return false
        if (r.type != this.type) return false
        if (r.person_aux != this.person_aux) return false

        return true
    }

    identificator(){

        return TodoRef.Identificator_Json(this.n, this.type, this.person_aux)
    }


}

export interface ITodoDerivativeRef{

    ticket: number

    type: TodoItemType

    person_aux: Guid
}

export class TodoDerivativeRef implements ITodoDerivativeRef{


    static Instance(n:number, type: TodoItemType, nid: Guid){


        const buffer = new TodoDerivativeRef()

        //if (!this.IsDerivative(x)) throw Error()

        buffer.ticket = n
        buffer.type = type
        buffer.person_aux = nid

        return buffer
    }

    ticket:number = 0

    type: TodoItemType = 'B'

    person_aux: Guid = Guid.newGuid()

    todoRef(){
        
        const b = new TodoRef(this.ticket, this.type)

        b.person_aux = this.person_aux

        return b
    }


    static ref_TodoRef(x:TodoRef){

        

        if (!this.IsDerivative(x.type)) throw Error()

        return TodoDerivativeRef.Instance(x.n, x.type!, x.person_aux!)

        
    }

    // static IsDerivative(r:{type:TodoItemType | null}){

    //     return !(r.type == null || r.type == "T")
    // }

    static IsDerivative(type:TodoItemType | null){

        return !(type == null || type == "T")
    }
}