import { PersonRmodel } from "@/app.context/workspace/person.rmodel";
import { TodoRmodel } from "@/app.context/workspace/tickets/todo.rmodel";
import { Guid } from "@/lib";
import { BoardPosition } from "@/logic/_infra/boards";
import { TqueuePosition } from "@/logic/_infra/queues";
import { Operation } from "./EntityOperation";
import { BoardOperation, TodoOperationType } from "./_def";
import { TodoItemType } from "@/logic/workspaces/tickets/todos/_def.todos";



export class TodoBoardOperationDto extends Operation {


    constructor(operation: BoardOperation<TodoRmodel>, person: PersonRmodel) {


        super('TodoBoardOperation')

        this.name = operation.name;
        this.n = operation.object.task.ticket.n;
        this.type = operation.object.type();
        this.nid = person.nid;

        if (operation.object.type() === "B") {
            this.nid_aux = operation.object.block!.person!.nid
        }

        if (operation.object.type() === "V") {
            this.nid_aux = operation.object.verification!.person!.nid;
        }


        this.target = operation.target;
    }

    n: number = 0;

    type: TodoItemType = "T";

    readonly name: TodoOperationType;

    readonly target: { column: number, row: number } = { column: 0, row: 0 };

    //name of operation

    readonly nid: Guid

    /**
     * Supposed to be used for 'blocker' or 'verifier' identificator
     */
    nid_aux: Guid | null = null

}
