import * as signalR from "@microsoft/signalr";
import {TicketAppContextController} from '@/controllers/context/workspaces/ticket.appContext.controller'

export default function TicketSignalrDispatcher(connection: signalR.HubConnection, controller: TicketAppContextController ) {

    connection.on("ticket", (dto)=>controller.ticketImInitialization(dto));

    connection.on("message", (wid, n, messages)=>controller.ticketImUpdate(wid, n, messages));

    connection.on("file", (wid, n, files)=>controller.ticketFileUpdate(wid, n, files));

    connection.on("description", (wid, n, description)=>controller.ticketDescriptionUpdate(wid, n, description));
}