import { TodoRmodel } from "@/app.context/workspace/tickets/todo.rmodel";
import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel";
import { ImMessage, TicketPackRmodel } from "@/app.context/workspace/tickets/_spec/Ticket.Im.Rmodel";
import AppContext from "@/context";
import { ITicketStorage } from "@/io/storages";
import sys from "@/sys";
import { ticketSummaryUpdate } from "@/controllers/workspaces/tickets/_spec/ticket.controller.chat";
import { isTicket, isTodo } from "@/controllers/workspaces/tickets/_model/ticket.item.controller";
import {File} from '@/app.context/workspace/tickets/_spec/Ticket.Im.Rmodel'


export class TicketItemModel { //with possible 'todo'

    ticket: TicketRmodel | null = null
    todo: TodoRmodel | null = null
    pack: TicketPackRmodel | null = null
    messages: ImMessage[] = []
    storage: ITicketStorage | null = null

    update(pack: TicketPackRmodel) {

        ticketSummaryUpdate(this, pack)
    }

    add(messages: ImMessage[]) {
        

        messages.forEach(m => this.messages.push(m));
    }

    addFiles(files: File[]) {

        if (this.pack != null) files.forEach(m => this.pack!.files.push(m));
    }

    clear(): void {
        this.ticket = null;
        this.pack = null;
        this.messages.length = 0;
        //this.storage?.disconnect();
        this.storage = null;

        //if (this.context.cursor.ticket_aux) this.context.cursor.ticket_aux.storage = null;
    }

    disposeIf(): Promise<void> {


        if (this.storage == null) return Promise.resolve();

        return this.storage!.disconnect().then(x => this.clear()) //.catch(sys.exception);

    }

    set(t: TicketRmodel | TodoRmodel, context: AppContext): Promise<void> {

        if (isTicket(t)) {

            sys.info(`/workspaces/ticket - Set ticket context: ${t?.n}`)
        }


        if (isTodo(t)) {

            sys.info(`/workspaces/ticket - Set todo context: ${t?.ticket.n}`)
        }



        return this.disposeIf().then(() => {

            if (isTicket(t)) {

                this.ticket = t;
                this.storage = context.service.ticketStorage(t.workspace.wid, t.n); //and now wait while   
            }

            if (isTodo(t)){

                this.ticket = t.ticket
                this.todo = t;
                this.storage = context.service.ticketStorage(t.ticket.workspace.wid, t.ticket.n); //and now wait while   

            }


        }).catch(sys.exception)


    }
}

export function item(props: { ticket?: TicketRmodel, todo?: TodoRmodel }) {

    let t: TicketRmodel | TodoRmodel | null = null

    if (props.ticket) t = props.ticket;
    if (props.todo) t = props.todo;

    return t;
}