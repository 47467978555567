
import { Guid } from '@/lib'
import { reactive } from 'vue'
import { IUserDto } from '@/io/dto/user'
import {single} from '@/lib/array.lib'

export class Model {

    constructor() { 
        
        const _this = this;
    }

    user: { name: string, email: string, uid: Guid } | null = null

    //list of short workstation summaries
    workspaces:
        {
            name: string,
            wid: Guid,
            projects: { pid: Guid, name: string }[],
            persons: { nid: Guid, name: string }[],
            me: Guid | null

        }[] = reactive([])

    //workspace: WorkspaceRmodel | null = null // should be vue {ref(object), ...}

    setup(dto: IUserDto): void {

        this.user = { name: dto.name ?? dto.email, email: dto.email, uid: dto.uid};

        this.workspaces.length = 0; //clear array

        dto.workspaces.forEach(w => {

            const buffer = {
                name: w.name,
                wid: w.wid,
                projects: w.projects.map(function (p: { name: string, pid: Guid }) { return { pid: p.pid, name: p.name }; }),
                persons: w.persons.map(function (p: { name: string, nid: Guid }) { return { nid: p.nid, name: p.name }; }),
                me: w.me,
            };

            this.workspaces.push(buffer);
        });
    }

    personCreate(wid:Guid, name:string, nid:Guid){

        // const buffer = this.workspaces.filter(w => w.wid === wid);

        // if (buffer.length === 0) return null;

        // const workspace_object = buffer[0];

        const w = single(this.workspaces, w => w.wid === wid)

        w.persons.push({nid:nid, name:name})

        single(w.persons, x=>x.name === name) //test that single
        single(w.persons, x=>x.nid === nid) //test that single
    }

    defaultWorkspaceWid(): Guid | null {

        if (this.workspaces.length === 0) return null;

        return this.workspaces[0].wid
    }

    projectPid(project: string, workspace: string): Guid | null {

        const wid = this.workspaceWid(workspace);

        if (wid === null) return null;

        const buffer = this.workspaces.filter(w => w.wid === wid);

        if (buffer.length === 0) return null;

        const workspace_object = buffer[0];

        const buffer_final = workspace_object.projects.filter(p => p.name === project);

        if (buffer_final.length === 0) return null;

        return buffer_final[0].pid;
    }


    personNidAndWid(person: string, workspace: string): {nid:Guid | null, wid: Guid|null} {

        const wid = this.workspaceWid(workspace);

        if (wid === null) return {nid:null, wid:null};

        const buffer = this.workspaces.filter(w => w.wid === wid);

        if (buffer.length === 0) return {wid, nid:null};

        const workspace_object = buffer[0];

        const buffer_final = workspace_object.persons.filter(p => p.name === person);

        if (buffer_final.length === 0) return {wid, nid:null};

        return {nid:buffer_final[0].nid, wid};
    }

    personNid(person: string, workspace: string): Guid | null {

        const bubbfer = this.personNidAndWid(person, workspace)

        return bubbfer.nid;
    }

    workspaceWid(name: string): Guid | null {

        const buffer = this.workspaces.filter(w => w.name === name);

        return buffer.length > 0 ? buffer[0].wid : null;
    }
}