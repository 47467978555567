import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    style: _normalizeStyle(_ctx.itemStyle),
    class: "timeline-item"
  }, [
    _createElementVNode("div", {
      ref: "others",
      style: _normalizeStyle(_ctx.circleStyle),
      class: _normalizeClass([_ctx.slotClass, "timeline-circle"])
    }, [
      _renderSlot(_ctx.$slots, "others")
    ], 6),
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}