import { WorkspaceRmodel } from '@/app.context/workspace.rmodel';
import AppContext from '@/context';
import { computed } from 'vue';
import { personEndeavoursStrings } from './person.controller';


export enum TicketAccessLevel {
  None = 1,
  Normal,
  Full,
}

export function level(context: AppContext) {

  if (context.isCaptain()) return TicketAccessLevel.Full

  if (context.isCurrentPerson()) return TicketAccessLevel.Normal

  return TicketAccessLevel.None
}

export function endeavours(context: AppContext) {

  const user_endeavours = computed(() => userEndeavourStrings(context.cursor.workspace))

  return user_endeavours

  function userEndeavourStrings(w: WorkspaceRmodel | null) {

    //const buffer: string[] = [];

    if (w == null || w.user_person == null) return [];

    return personEndeavoursStrings(w.user_person)
  }

}