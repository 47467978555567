import { Guid } from '@/lib';
import { Person } from '../../logic/workspaces/person'
import { WorkspaceRmodel } from '../workspace.rmodel';
import { TodoRmodel } from './tickets/todo.rmodel';
import { TicketRmodel } from './ticket.rmodel';

import * as arrays from '@/lib/array.lib'
import { queues } from '@/logic/_infra/queues'
import { boards } from '@/logic/_infra/boards';
import { TicketBoardOperation, TodoBoardOperation, TodoQueueOperation } from '../operations';
import { TimeSegment } from '@/logic/_infra/time';
import { TodoItemType } from '@/logic/workspaces/tickets/todos/_def.todos';



import sys from '@/sys';
import { ITodoRefDto } from '@/io/dto/workspaces/tickets/todos.ns';
import { EffortSummary, SunriseReportRmodel } from './persons/sunrise.rmodel';
import { ISunriseReportDto } from '@/io/dto/workspaces/persons/sunriseReport.dto';
import AppContext from '@/context';
import { IService } from '@/io';
//import { ITicketOrTodoRef } from '@/logic/workspaces/tickets/todo';
import { GroupRmodel } from './persons.set/group.rmodel';
import { ITicketOrTodoRef } from '@/logic/workspaces/tickets/todo';




function workable(todo: TodoRmodel) {

    return (!todo.isTask() || todo.task.isWorkable())
}

export type PersonState = 'normal' | 'semi-idle' | 'idle' | 'stop';

export class PersonRmodel extends Person {





    flags = { timeline: false }

    user: Guid | null = null

    get hasUser(): boolean {

        return this.user != null
    }

    readonly workspace: WorkspaceRmodel

    timezoneOffset: number = 0

    log: { date: Date, days: number } = { date: new Date(), days: 1 }

    stopped: boolean = true

    current: TodoRmodel | null = null;

    readonly desk: TodoRmodel[] = []
    readonly queue: TodoRmodel[] = []
    readonly pins: TodoRmodel[] = []




    readonly board:
        {
            readonly captain: (TicketRmodel | null)[][],
            readonly person: (TodoRmodel | null)[][],
        } =
        {
            captain: [], person: [],
        }

    efforts: SunriseReportRmodel | null = null

    history: SunriseReportRmodel[] | null = null

    categories: Set<string> = new Set()

    constructor(name: string, nid: Guid, workspace: WorkspaceRmodel) {

        super(name, nid)

        this.workspace = workspace;
    }


    // static ParticipantsSetup(objects: { groups: GroupRmodel[], persons: PersonRmodel[] }, workspace: WorkspaceRmodel, persons: Guid[], groups: Guid[], service: IService) {


    //     objects.persons.length = 0

    //     persons.forEach(nid => { objects.persons.push(workspace.personOrBillet(nid)) })

    //     objects.groups.length = 0

    //     groups.forEach(uid => { objects.groups.push(workspace.groupOrBillet(uid)) })


    // }

    // //personRmodel + context; if we have todo - we 'cache' it ticket ot 'ticket' field
    static ObjectsSetup(objects: { ticket: TicketRmodel | null/*, todo: TodoRmodel | null*/ }, person: PersonRmodel, ref: /*ITicketOrTodoRef |*/ ITodoRefDto, service: IService) {


        //let todo: TodoRmodel | null = null

        // let n = 0

        // // if ((ref as ITicketOrTodoRef).ticket !== undefined) {

        // //     n = (ref as ITicketOrTodoRef).ticket
        // // }

        // if ((ref as ITodoRefDto).n !== undefined) {

        //     n = (ref as ITodoRefDto).n
        // }
        // else throw Error('Unknown argument')

        // if (ref.type != null) {

        //     todo = person.todoItemOrNull_Ref({ n: n, type: ref.type!, person_aux: ref.person_aux })

        //     if (todo != null) {

        //         objects.todo = todo
        //         objects.ticket = todo.ticket
        //     }
        // }

        //if (todo == null && n != 0) objects.ticket = person.workspace.ticket_billet(n, service)
        if (ref.n != 0) objects.ticket = person.workspace.ticket_billet(ref.n, service)
    }

    //we allow to 'renew' task only it is not active now at person right now
    isRenewable( ticket: TicketRmodel | null, type: TodoItemType | null ) {

    

    //r.timeffort.

    if (ticket != null && (type == null || type == 'T')) {

        //if (type != 'T') return false

        const buffer = this.todoItemOrNull_AtQueue_N(ticket.n, 'T', null)

        if (buffer == null) return true

        return false
    }

    return false
}


    setup_sunriseReport(context: AppContext, dto: ISunriseReportDto) {


        if (this.efforts == null) this.efforts = new SunriseReportRmodel(this, TimeSegment.Instance(dto.period))

        this.efforts.setup(context, dto)
    }

    todos_worksable(): number {

        let buffer = 0

        if (this.current != null) buffer++

        buffer += this.desk.filter(workable).length
        buffer += this.queue.filter(workable).length
        buffer += this.pins.filter(workable).length

        return buffer
    }

    //**Return next workable item for person */
    todo_next(): TodoRmodel | null {




        if (this.IsStrictSuborinate()) {

            //closest 'workable' ticket at desk, queue, pins

            for (let index = 0; index < this.desk.length; index++) {

                const item = this.desk[index]

                if (workable(item)) return item
            }

            for (let index = 0; index < this.queue.length; index++) {

                const item = this.queue[index]

                if (workable(item)) return item
            }

            for (let index = 0; index < this.pins.length; index++) {

                const item = this.pins[index]

                if (workable(item)) return item
            }


        }

        //only if we have one 'workable' ticket among 'desk'
        //of if we have only 'one' workable at 'queue' and no workable at 'desk'
        //or from pins if there is no workable tickets at 'desk', 'queue' and single at pins

        if (this.IsSemiStrictSuborinate()) {

            const buffer_desk = this.desk.filter(workable)

            if (buffer_desk.length > 1) return null

            else if (buffer_desk.length == 1) return buffer_desk[0]
            else {
                const buffer_queue = this.queue.filter(workable)

                if (buffer_queue.length > 1) return null

                else if (buffer_queue.length == 1) return buffer_queue[0]

                else {
                    const buffer_pins = this.pins.filter(workable)

                    if (buffer_pins.length == 1) return buffer_pins[0]
                }
            }


        }

        return null;



    }

    isGuest() {

        return !this.captain && !this.subordinate
    }

    IsStrictSuborinate() {

        return !this.captain && this.subordinate && this.strict
    }

    IsSemiStrictSuborinate() {

        return !this.captain && this.subordinate && !this.strict && !this.autonomus
    }

    isIdle() {

        if (this.IsSemiStrictSuborinate() || this.IsStrictSuborinate()) return this.current == null

        else {
            return this.idle
        }
    }

    isStopped() {

        return this.stopped
    }

    //when we get something out from 'current' possition we need to understand whether whole queue should 'shift down'
    //when we actually have something to 'replace' - we need to prevent this shift to avoid that 'current' spot was automatically occupied with next task
    //'auto shift' also does no work if person is 'autonomus' are there are no 'workable' tasks to work on
    //this logic is duplicated also at C# because similar logic applies when we create new task a some 'default' position but because of queue has no workable items
    //it should automatically go as 'current'
    // function queueAutoShift(person:PersonRmodel){

    //     let todos_workable = 0;

    //     //#copy {60D865C9-7D93-4E63-B0E6-32FDB78FAF03}
    //     todos_workable += person.queue.filter(t=>!t.isTask() || t.task.isWorkable()).length 
    //     todos_workable += person.desk.filter(t=>!t.isTask() || t.task.isWorkable()).length 
    //     todos_workable += person.pins.filter(t=>!t.isTask() || t.task.isWorkable()).length 

    //     //#copy {EEDF76CC-B792-42DF-9A10-81E3FBF35819}
    //     return  todos_workable > 0 && person.subordinate &&  !person.autonomus
    // }




    endeavours_total() {

        return this.efforts?.endeavours_total() ?? 0
    }

    endeavours_balance() {

        if (this.efforts == null) return 0

        if (this.endeavours_total() == 0) return 0

        return this.endeavours_total() - this.efforts.obligations

        //return this.efforts?.endeavours_total() ?? 0
    }

    toString() {

        return this.name
    }

    todoItem_AtBoard_N(n: number, type: TodoItemType, nid: Guid | null): TodoRmodel {

        return this.todoItemOrNull_AtBoard_N(n, type, nid)!
    }

    todoItemOrNull_AtBoard_N(n: number, type: TodoItemType, nid: Guid | null): TodoRmodel | null {

        return arrays.singleOrNull(boards.items(this.board.person), x => x != null && x.task.ticket.n === n && x.type() === type && x.auxPerson() == nid);
    }


    todoItem_AtQueue_N(n: number, type: TodoItemType, nid: Guid | null): TodoRmodel {
        return this.todoItemOrNull_AtQueue_N(n, type, nid)!
    }

    todoItemOrNull_AtQueue_N(n: number, type: TodoItemType, nid: Guid | null): TodoRmodel | null {

        return arrays.singleOrNull(queues.all(this), x => x.task.ticket.n == n && x.type() == type && x.auxPerson() == nid);
    }

    todoItemOrNull_Ref(id: ITodoRefDto): TodoRmodel | null {

        return this.todoItemOrNull(id.n, id.type, id.person_aux)
    }

    // todoItemOrBillet(n: number, type: TodoItemType, nid: Guid | null): TodoRmodel  {

    //     const b = this.todoItemOrNull(n, type, nid)

    //     if (b == null) throw Error()

    //     return b
    // }

    todoItemOrNull(n: number, type: TodoItemType, nid: Guid | null): TodoRmodel | null {

        let buffer = this.todoItemOrNull_AtBoard_N(n, type, nid);

        if (buffer != null) return buffer;

        buffer = this.todoItemOrNull_AtQueue_N(n, type, nid);

        return buffer;
    }

    ticketBoardApply(operation: TicketBoardOperation) {

        const person: PersonRmodel = this;

        if (this.nid != operation.nid) throw Error(`Nid mismatch ${this.nid} vs ${operation.nid}`)

        const board = this.board.captain;

        //const board = this.board.person;

        const ticket = operation.objects.ticket;

        if (operation.name === "CREATE") {


            // if (ticket === null) {

            //     ticket = this.workspace.ticket(operation.n);
            // }

            const current = boards.itemOrNull(board, operation.target);

            if (current != null) throw Error(`Board cell is occupied ${operation.target.column} ${operation.target.row} ${current.n}`)

            boards.put(board, ticket, operation.target);
        }
        // eslint-disable-next-line
        else if (operation.name === "MOVE") {

            // if (ticket === null) {

            //     ticket = arrays.single(boards.items(board), x => x?.n === operation.n)
            // }

            boards.move(board, ticket, operation.target);
        }
        // eslint-disable-next-line
        else if (operation.name === "CLOSE") {

            boards.clear(board, operation.target);
        }
        else throw Error(`Unknown operation type ${operation.name}`);


    }

    todoBoardApply(operation: TodoBoardOperation) {

        if (this.nid != operation.nid) throw Error(`Nid mismatch ${this.nid} vs ${operation.nid}`)

        const board = this.board.person;

        if (operation.name === "CREATE") {

            const todo = operation.objects.todo;

            const current = boards.itemOrNull(this.board.person, operation.target);

            if (current != null) throw Error(`Board cell is occupied ${operation.target.column} ${operation.target.row} ${current.identificator()}`)

            boards.put(this.board.person, todo, operation.target);
        }
        // eslint-disable-next-line
        else if (operation.name === "MOVE") {

            const todo = operation.objects.todo;

            // if (todo === null) {

            //     todo = arrays.single(boards.items(board), x => x?.type() == operation.type && x?.task.ticket.n == operation.n)

            // }
            boards.move(board, todo, operation.target);
        }
        // eslint-disable-next-line
        else if (operation.name === "CLOSE") {

            boards.clear(board, operation.target);
        }
        else throw Error(`Unknown operation type ${operation.name}`);
    }

    //TodoBoardOperation

    handler_CurrentTodoCleared: ((y: TodoRmodel) => void) | null = null;
    handler_CurrentTodoSet: ((y: TodoRmodel) => void) | null = null;

    todoQueueApply(operation: TodoQueueOperation) {


        const queue = this;

        const todo = operation.objects.todo;

        sys.info('/workspaces/person - todoQueueApply')
        sys.info(todo.person.workspace.timestamp)



        function raiseCurrentTodoClearedIf(person: PersonRmodel) {

            if (person.handler_CurrentTodoCleared) {

                const p = queues.position(queue, todo);

                if (p != null && p.section == "current") {

                    person.handler_CurrentTodoCleared(todo)
                }
            }
        }

        function raiseCurrentTodoSetIf(person: PersonRmodel) {

            if (person.handler_CurrentTodoSet) {

                if (operation.target.section == "current") {

                    person.handler_CurrentTodoSet(todo);
                }
            }
        }



        if (operation.name === "CREATE") {

            queues.itemAdd(queue, todo, operation.target);

            raiseCurrentTodoSetIf(this)
        }
        else if (operation.name === "MOVE") {

            queues.itemMove(queue, todo, operation.target);

            raiseCurrentTodoClearedIf(this)
            raiseCurrentTodoSetIf(this)
        }
        else if (operation.name == "CLOSE") {

            queues.itemRemove(queue, todo, operation.target.section);

            raiseCurrentTodoClearedIf(this)
        }
        else throw Error(`Unknown operation ${operation.name}`)
    }

    //transapction apply
    todoQueueApplyBatch(batch: TodoQueueOperation[]) { batch.forEach(operation => this.todoQueueApply(operation)) }

    todoNew(n: number, type: TodoItemType, nid_aux: Guid | null): TodoRmodel {

        const ticket = this.workspace.ticket(n);


        if (nid_aux == null) //derivative
        {
            const task = ticket.task(this.nid)

            if (type == "T") return new TodoRmodel(task/*, this*/);


            throw Error(`Uknown type ${type}`)
        }
        else {
            const task = ticket.task(nid_aux)

            if (type === "B") {

                return new TodoRmodel(task.block(this.nid)/*, this*/);
            }

            if (type === "V") {

                //if (nid_aux === null) throw Error(`For verification model nid is required ${n}`)

                return new TodoRmodel(task.verification(this.nid)/*, this*/)
            }

            throw Error(`Uknown type ${type}. N:${n}`)
        }
    }

    updateCurrentEfforts(date: Date) {


        //const date = new Date()

        //for pesons w/o user assigned - do not run this pop-ups

        // if (!this.hasUser) {

        //     this.event = null;

        //     return;
        // }

        const w = this.workspace



        if (this.efforts != null) {
            this.efforts.timestamp

            if (this.efforts.timeffort_Current != null) {

                sys.info(`Current effort end update ${this.name}`)

                this.efforts.timeffort_Current.end = date //.segment_value.setEnd(date)

                //#trace console.log(this.efforts.timeffort_Current)


            }

            // if (this.efforts.current_Events)
            // {
            //     this.efforts.current_Events.forEach(e=>e.end = date)
            // }

            if (this.efforts.timespendings_Current) {
                this.efforts.timespendings_Current.forEach(e => e.segment.setEnd(date))
            }

            this.efforts.test();
        }
    }

    status(): PersonState {
        if (this.subordinate) {
            if (this.stopped) return 'stop'

            if (this.autonomus) {

                if (this.idle) return 'idle'
            }
            else {
                if (this.strict) {
                    if (this.current == null) {
                        if (this.todos_worksable() > 0) return 'semi-idle'
                        else return 'idle'
                    }
                }
                else {
                    if (this.todos_worksable() == 0) return 'idle'
                }
            }
        }

        return 'normal'

        //normal
        //semiidel
        //idle
        //stopped
    }

}