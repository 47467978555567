import { PersonRmodel } from '@/app.context/workspace/person.rmodel';
import { BlockRmodel } from '@/app.context/workspace/tickets/tasks/block.rmodel';
import { TodoRmodel } from '@/app.context/workspace/tickets/todo.rmodel';

import { CardClass, cardClassOfStatus } from '@/controllers/workspaces/tickets/_spec/ticket.item.controller'
import sys from '@/sys';



export function todoCardClass(todo: TodoRmodel): CardClass {

    const s = todo.status()

    return cardClassOfStatus(s)
}

//**Does NOT return impersonal blocks! */
export function blockers_unresolved(todo: TodoRmodel) : BlockRmodel[]{

    if (todo.isTask())
    {
        const buffer = todo.task.blocks.filter(x=>x.resolution == null && x.person != null)

        return buffer//.map(x=>x.person!)
    }
    else return []
  }

  export function block_impersonal(todo: TodoRmodel) : BlockRmodel | null{

    if (todo.isTask())
    {
        const buffer = todo.task.blocks.filter(x=>x.resolution == null && x.person == null)

        if (buffer.length > 1) sys.warn('Multiple blocks')

        if (buffer.length > 0) return buffer[0]

        else return null
    }
    else return null
  }