import * as sys from '@/sys'
import { Resolution, Status, toBoolean, toResolution, toStatus } from '@/logic/workspaces/tickets/_def';
import { LabelModel } from './label.rmodel';
import { TaskRmodel } from './tickets/task.rmodel';
import { PersonRmodel } from './person.rmodel';
import { ProjectModel } from './project.rmodel';
import { ITicketBasicDto, ITicketDto } from '@/io/dto/workspaces/ticket.dto';
import { WorkspaceRmodel } from '../workspace.rmodel';
import { PhaseRmodel } from './process.model';
import { VerificationModel } from './tickets/tasks/verification.rmodel';
import { Guid } from '@/lib';
import * as arrays from '@/lib/array.lib'
import { ITaskDto } from '@/io/dto/workspaces/tickets/task.dto';
import { EntityUpdateDto } from '@/io/storages/workspace/EntityOperation';
import { EntityRef } from '@/logic/workspaces/_tech';

export class TicketRmodel {
    
    

    n = 0

    name = ''

    file: string | null = null
    link: string | null = null

    readonly workspace: WorkspaceRmodel;

    resolution: Resolution | null = null

    project: ProjectModel | null = null

    labels: LabelModel[] = []

    tasks: TaskRmodel[] = []

    //

    clipboardTitle() {

        return  `#${this.n} - ${this.name}`
        
    }

    get key(): string {
        return this.n.toString()
    }

    constructor(workspace: WorkspaceRmodel) {

        this.workspace = workspace;
    }


    status_simple(): Status | null {

        if (this.resolution == null) return Status.Neutral
        else if (this.resolution) return Status.Good
        else return Status.Warning
    }


    /**
    * 
    * @returns if ticket is multitasked - returns null
    */
    status(): Status | null {

        const r = this.resolution

        //we render ticket 'failed' flags as 'warning' such as on ticket level there is no much sense for 'failed' state
        //it's better user it to indicate ticket as some problematic;

        if (r == Resolution.Failed) return Status.Warning



        if (this.tasks?.length ?? 0) {

            //have tasks

            if (this.tasks.length === 1) {

                const t = this.tasks[0];

                if (t.blocks?.length ?? 0) {

                    const buffer = t.blocks.filter(x => x.resolution === null);

                    if (buffer.length) return Status.Warning;
                }
                else return toStatus(t.resolution);
            }

            return null;
        }
        else {

            const r = this.resolution

            //we render ticket 'failed' flags as 'warning' such as on ticket level there is no much sense for 'failed' state
            //it's better user it to indicate ticket as some problematic;

            if (r == Resolution.Failed) return Status.Warning

            return toStatus(this.resolution)
        }
    }

    isIdentificatorSame(wid: Guid, n: number) {

        return this.workspace.wid == wid && this.n == n;
    }


    dto(): ITicketBasicDto {

        const r = {
            n: this.n,
            name: this.name,
            file: this.file,
            link: this.link,
            resolution: this.resolution,
            labels: this.labels.map(x => x.name),
        };

        return r;

    }

    task_name(name: string): TaskRmodel {

        return arrays.single(this.tasks, x => x.person.name === name);
    }

    task(nid: Guid): TaskRmodel {

        return arrays.single(this.tasks, x => x.person.nid === nid);
    }

    taskIf(nid: Guid): TaskRmodel | null {

        return arrays.singleOrNull(this.tasks, x => x.person.nid === nid);
    }


    /**Means that ticket is NOT presenter at workspace boards */
    isArchieved() {

        const p = this.workspace.projects()

        for (let index = 0; index < p.length; index++) {

            const element = p[index];

            if (element.ticketOrNull_N(this.n) != null) return false
        }

        return true //if ticket was not found at any project - this means it's 'archieved'
    }


    //MUTATIONS


    setup(dto: ITicketDto, workspace: WorkspaceRmodel) {

        const model = this;

        model.n = dto.n;
        model.name = dto.name;
        model.resolution = toResolution(dto.resolution)
        model.file = dto.file
        model.link = dto.link

        const buffer: TaskRmodel[] = []

        this.tasks.forEach(t => buffer.push(t))
        this.tasks.length = 0

        function sync(taskModel: TaskRmodel, t: ITaskDto) {

            if (t.process != null) {

                taskModel.process = workspace.process(t.process);
            }

            //taskModel.resolution = t.resolution;

            taskModel.verifications.length = 0

            t.verifications.forEach(v => {

                const phase = taskModel.process?.phases[0] as PhaseRmodel;

                const verificationPerson = workspace.person_nid(v.verifier);

                const verificationModel = new VerificationModel(phase, taskModel, verificationPerson);

                taskModel.verifications.push(verificationModel);
            });

            taskModel.blocks.length = 0

            t.blocks.forEach(b => {

                let blocker: PersonRmodel | null = null;

                if (b.blocker != null) {

                    blocker = workspace.person_nid(b.blocker);
                }

                const block = taskModel.blockNew(blocker);

                block.resolution = b.resolution
            });
        }

        buffer.forEach(x => {

            const dto_task = arrays.singleOrNull(dto.tasks, z => z.person == x.person.nid)

            if (dto_task != null) {

                this.tasks.push(x)

                sync(x, dto_task)
            }
        })

        

        dto.tasks.forEach(t => {


            if (arrays.singleOrNull(this.tasks, z => z.person.nid == t.person) == null) {
                const person = workspace.person_nid(t.person)

                const taskModel = this.taskAdd(person, t);

                sync(taskModel, t)
            }
        });

        this.labels.length = 0
        dto.labels.forEach(l => {

            const label = workspace.index.labels.get(l);

            if (label === undefined) {

                sys.warn('/workspaces/ticket - labels - missed reference')
            }
            else this.labelAdd(label)

        })
    }

    taskAdd(person: PersonRmodel, dto?: ITaskDto): TaskRmodel {

        const taskModel = new TaskRmodel(person, this);

        if (dto != undefined) {

            taskModel.resolution = dto.resolution
            taskModel.workable = dto.workable
        }

        this.tasks.push(taskModel);

        return taskModel;
    }

    taskRemoveIf_nid(nid: Guid) {

        const task = this.taskIf(nid)

        if (task != null) this.taskRemoveIf(task)
    }

    taskRemoveIf(task: TaskRmodel) {

        arrays.remove(this.tasks, task)
    }

    labelAdd(label: LabelModel): void {

        this.labels.push(label);
    }

    labelRemove(labelName: string): void {

        throw Error('Not Implement - labelRemove');
    }

    update(r: Resolution | null): EntityUpdateDto {
        const ref = new EntityRef(this.n, null)
        const updateOperation = new EntityUpdateDto(ref)

        updateOperation.field = EntityUpdateDto.Key_Resolution
        updateOperation.value = toBoolean(r)

        this.resolution = r;

        return updateOperation
    }

    update_description(description: string | null): EntityUpdateDto {
    
        const ref = new EntityRef(this.n, null)
        const updateOperation = new EntityUpdateDto(ref)
    
        updateOperation.field = EntityUpdateDto.Key_Description
        updateOperation.value = description
    
        
    
        return updateOperation
    }
}