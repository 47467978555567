import { PersonRmodel } from '@/app.context/workspace/person.rmodel';
import { watch, onBeforeMount, computed } from 'vue';
import { useAppContext } from '@/app/_tech';
import { Sunrise, TimeSegment } from '@/logic/_infra/time';
import { IBalanceDto } from '@/io/dto/workspaces/persons/balance.dto';



export default function f(postSetup: (p: PersonRmodel) => void, preSetup: () => void, props: { person: string, workspace: string }, data: { abscense: boolean }) {

    const context = useAppContext();

    watch(() => context.cursor.workspace, () => setup()) //'workspace' prop change will be handled with parent 'workspace-view.vue' container
    watch(() => props.person, () => setup()) //'person' prop we have to handle ourself

    onBeforeMount(() => {
        setup();
    })

    function setup() {

        console.log('person subcontroller - setup')

        preSetup()

        data.abscense = false;

        const ids = context.model.personNidAndWid(props.person, props.workspace)

        const nid = ids.nid;

        if (ids.wid == null || ids.nid == null) data.abscense = true
        else {
            data.abscense = false;

            if (context.cursor.workspace?.Wid() != ids.wid) {

                //we do nothing here such as cursor.workspace supposed to be handled on container level -  see 'WorkspaceView.vue' setup method
            }
            else if (context.cursor.person?.nid != ids.nid) {
                //ren person setup

                context.cursor.person = context.cursor.workspace.person_nid(ids.nid)

                postSetup(context.cursor.person);
            }
            else {
                //run actual page setup

                postSetup(context.cursor.person);
            }
        }
    }
}

export class Desk {

    remainder = { total: 0, period: 0 }
    current = 0
}

export class Obligations {

    expires: Date | null = null

    //off: number | null = null;
    balance: number = 0;
    balance_month: number = 0;
    balance_week: number = 0;
    obligations: number = 0;
    obligations_total: number = 0;
    
    work:number = 0

    offdays: number = 0
    offhours: number = 0

    /**
     * Endeavours minus obligations
     * @param endeavours 
     * @returns 
     */
    todayBalance(endeavours: TimeSegment[]) {

        
        //console.log(endeavours)

        let obligation_buffer = this.obligations;

        // if (this.off != null) {

        //     if (this.off! == 0) obligation_buffer = 0;
        //     else obligation_buffer -= this.off;
        // }

        if (obligation_buffer <= 0) obligation_buffer = 0;

        let endeavours_buffer = 0;

        if (endeavours != null) {
            endeavours.forEach(x => endeavours_buffer += x.timespan_min);
        }

        // console.log(`Endeavours: ${endeavours_buffer}`)
        // console.log(`Obligations: ${obligation_buffer}`)

        return endeavours_buffer - obligation_buffer;
    }


}

export function balanceSetup(model: { obligations: Obligations }, dto: IBalanceDto) {

    if (model.obligations == null) Error('/workspaces/person - Balance controller: empty model')

    const b = model.obligations;



    //obligations for today
    b.obligations = dto.obligations
    b.obligations_total = dto.obligations_Total
    b.work = dto.work

    b.balance = dto.balance
    b.balance_month = dto.balance_Month
    b.balance_week = dto.balance_Week

    //b.off = dto.off

    b.offdays = dto.offdays
    b.offhours = dto.offhours



    //dto.offdays.map(x => b.offdays.push(x))
    //dto.offhours.map(x => b.offhours.push(x))

    if (dto.expiration != null) b.expires = new Date(dto.expiration!);
}


export function utilities(model: { date: Date }) {


    const isToday = computed(() => {

        return Sunrise.isLocaleToday(model.date);
    })

    return {
        isToday
    }

}

export function personStateCss(person:PersonRmodel){

    

    if (person.status() == 'stop') return 'alarm'
    if (person.status() == 'idle') return 'warning'
    if (person.status() == 'semi-idle') return 'notice'

    return ''

}

export function personEndeavoursStrings(person:PersonRmodel){

    //return timeSegmentsToStrings(person.endeavours)

    if (person.efforts == null) return []

    console.log(person.efforts.endeavours_Total)

    return TimeSegment.toStrings(person.efforts.endeavours_Total)
}