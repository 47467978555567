import { PersonRmodel } from "@/app.context/workspace/person.rmodel";
import { TodoRmodel } from "@/app.context/workspace/tickets/todo.rmodel";
import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel";
import AppContext from "@/context";
import { IEntityRefDto } from '@/io/dto/workspaces/_tech'
import { Status } from "@/logic/workspaces/tickets/_def";
import sys from "@/sys";
import { EntityRef } from "@/logic/workspaces/_tech";



// COMPLEX TICKET - DRAG N DROP

export function ticketItemString(item:TicketRmodel | TodoRmodel) {

    if (item instanceof TicketRmodel) return item.name

    if (item instanceof TodoRmodel) return item.ticket.name

    return 'N/A'
}


 export function onTicketItemDrage(e: DragEvent, props: { ticket?: TicketRmodel, todo?: TodoRmodel }) {

    const element = document.getElementById("mn-workspace-logo")

    element?.classList.add("tech-dropable")

    dragDataSetup(e, props)

    //find .log class item
    //apply .log-droppable
}

export function onTicketItemDragEnd(e:DragEvent){

    const element = document.getElementById("mn-workspace-logo")

    element?.classList.remove("tech-dropable")
}

export function dragDataSetup(e: DragEvent, props: { ticket?: TicketRmodel, todo?: TodoRmodel }) {

    if (e.dataTransfer != null /*&& e.target != null*/) {

        const r = ticketItemRef(props)

        e.dataTransfer.setData('text/plain', JSON.stringify(r));
    }
}

export function dragEventRef(e: DragEvent): IEntityRefDto | null {

    if (e?.dataTransfer != null) {

        const data = e.dataTransfer!.getData('text/plain');

        if ((data?.length ?? 0) == 0) return null

        const ref = JSON.parse(data) as IEntityRefDto;

        return ref;
    }
    else return null;
}

//COMPLEXT TICKET - HELPERS

export function ticketInternal(props: { ticket?: TicketRmodel, todo?: TodoRmodel }): TicketRmodel {

    if (props.ticket != undefined) {
        return props.ticket!;
    }

    if (props.todo != undefined) return props.todo!.task.ticket;

    throw new Error('/app/workspaces/ticket - TicketCard component must have todo or ticket item set');
}

export function tasks_ExceptCurrentPerson(props: { ticket?: TicketRmodel, todo?: TodoRmodel }){

    const t = ticketInternal(props)

    //const t = todo.ticket
  
    const exclusion = props.todo != null ? props.todo.task.person : null;
  
    return t.tasks.filter(t => exclusion == null || t.person != exclusion)
  }

export function ticketItemRef(props: { ticket?: TicketRmodel, todo?: TodoRmodel }): IEntityRefDto {

    if (props.ticket != undefined) {

        return new EntityRef(props.ticket!.n, null)
    }

    if (props.todo != undefined) {

        const todo = props.todo!;

        return todo.ref()
    }

    throw new Error('/app/workspaces/ticket - TicketCard component must have todo or ticket item set');
}


//ticket.simple

export type CardClass = "" | "focused" | "good" | "warning" | "bad"; // 'null' as special - {'in progress' or 'multitask'}

//'card class' (card as visual element) vs 'ticket status' vs 'todo status' - they are interconnected but different
export function cardClassOfStatus(s: Status | null): CardClass {

    if (s === null || s === Status.Neutral) return '' //'neutral';
    if (s === Status.Good) return 'good' // 'green';
    if (s === Status.Warning) return 'warning' // 'yellow';
    if (s === Status.Bad) return 'bad' // 'red'

    sys.warn(`/workspaces/person - Unexpected todo item status: ${s}`)

    return ''
}










export function personSubmenuController(mode: { menu: number, menu_person: PersonRmodel | null }){


    const c = 
    {
        setPersonSubmenu(p: PersonRmodel) {

            mode.menu = 2
            mode.menu_person = p
        },

        setPersonSubmenuPreliminary() {

            

            mode.menu = 1

            // setTimeout(function(){

//              if (mode.menu == 1) mode.menu = 0;

        // }, 5000)
            
        },

        resetPersonSubmenu() {
            mode.menu = 0
            mode.menu_person = null
        },

        eventAndResetPersonSubmenu(p: PersonRmodel, index: number) {

            0 // now
            1 // next
            2 //soon --> 2nd, but if curren 2nd was assigned to current (2nd) position today - put as next
                - 1 //end
                - 2 //someday - end
        
            //emit('person.set', { person: p, index: index })
        
            //console.log(`event ${p} ${index}`)
        
            this.resetPersonSubmenu()

            return { person: p, index: index }
        }
    }

    return c
}