import { BoardPosition } from "@/logic/_infra/boards";
import { TqueuePosition } from "@/logic/_infra/queues";
import { BlockEntityDto, EntityDeleteDto, EntityUpdateDto, ILabelEntityDto, IPersonEntityDto, TaskEntityDto, TicketEntityDto, VerificationEntityDto } from "./EntityOperation";
import { TicketBoardOperationDto } from "./TicketBoarOperation";
import { TicketQueueOperationDto } from "./TicketQueueOperation";
import { TodoBoardOperationDto } from "./TodoBoardOperation";
import { TodoQueueOperationDto } from "./TodoQueueOperation";

export type TodoOperationType = "CREATE" | "MOVE" | "CLOSE";

//export type EntityCreationDto = TicketEntityDto | TaskEntityDto | BlockEntityDto | VerificationEntityDto 

//export type InstructionOperationDto = TicketQueueOperationDto
export type InstructionOperationDto = 
TodoQueueOperationDto | 
TodoBoardOperationDto | 
TicketQueueOperationDto | 
TicketBoardOperationDto | 
//EntityCreationDto | 
EntityUpdateDto | 
EntityDeleteDto |
ILabelEntityDto | //interface!
IPersonEntityDto // interface!





class Operation<T>{

    constructor(name: TodoOperationType, object: T) {

        this.name = name;
        this.object = object;
    }

    readonly name: TodoOperationType;

    readonly object: T

}

export class QueueOperation<T> extends Operation<T>{

    constructor(name: TodoOperationType, object: T, target?: TqueuePosition) {

        super(name, object);

        if (typeof target != "undefined") this.target = target;
    }

    target: TqueuePosition = new TqueuePosition();
}

export class BoardOperation<T> extends Operation<T>{

    constructor(name: TodoOperationType, object: T, target?: BoardPosition) {

        super(name, object);

        if (typeof target != "undefined") this.target = target;
    }

    target: { column: number, row: number } = { column: 0, row: 0 };
}