import { Guid } from "@/lib"
import { TodoRef } from "@/logic/workspaces/_tech"
import { TodoDelayReason, TodoItemType } from "@/logic/workspaces/tickets/todos/_def.todos"

export interface ITodoRefDto {
    
    n: number

    type: TodoItemType

    person_aux: Guid | null

    
}


export function toTodoRef(dto:ITodoRefDto){

    const result = new TodoRef(dto.n, dto.type)

    result.person_aux = dto.person_aux

    return result
}



export interface ITodoResolutionDto
{
    timepoint:string

    person: Guid
    
    todo: ITodoRefDto

    //timepoint : string //moment when effort on 'todo' starts
    //timespan : number
    //resolved: string //monent when 'todo' was resolved;

    dequeued: boolean
    delay:    TodoDelayReason | null
    resolution: boolean | null

    //


    //current: dequeued:false, resolution:false, delay: null

    //resolved time not always match with timepoint + timespan; because efforts on task may be splited between few periods

}