import { Sunrise, TimeSegment } from "@/logic/_infra/time"
import { Ref, computed, reactive } from "vue"
import { minuteCalc as mc } from '@/logic/_infra/time';
import { SunriseReportRmodel } from "@/app.context/workspace/persons/sunrise.rmodel";

export function setup(model:{date:Date, efforts: SunriseReportRmodel | null}, context:{timepoint:Ref<number>}){


    function endeavours_initial() {

        //#trace console.log('endeavours_initial')
    
        if (model.efforts == null) return []
        //if (model.efforts.length == 0) return []
    
        const x = model.efforts
    
        return TimeSegment.toMinuteNumberArray(x.endeavours_Total, x.offset)
    }


    const endeavours_numbers_ui: number[] = reactive(endeavours_initial())

    const isToday = computed(() => {

        return Sunrise.isLocaleToday(model.date);
    })

    const endeavours_segments = computed(() => {

        
        return  TimeSegment.array_MinuteNumberArray(endeavours_numbers_ui, Sunrise.dateToSunriseDate(model.date))
    
    })

    const remainder = computed(() => {

        //#trace sys.info('remainder recalc')
    
        if (!isToday.value) {
    
            return { period: '', total: '', total_minutes: 0, period_minutes: 0 }
        }
        else {
            const x = new Date(context.timepoint.value)
    
            const segments = endeavours_segments.value
    
            const segments_remaining = segments.filter(s => s.timepoint > x || s.timepoint.cloneAndAddMinutes(s.timespan_min) > x)
    
            //#trace
            // segments.forEach(s => {
    
            //     console.log(`Timepoint:${s.timepoint} - ${x}`)
            //     console.log(`Timepoint': ${s.timepoint.cloneAndAddMinutes(s.timespan)} - ${x}`)
            // })
    
            const buffer: TimeSegment[] = []
    
            segments_remaining.forEach(s => {
    
    
    
                if (s.timepoint < x) {
    
                    const offset_ms = (x.getTime() - s.timepoint.getTime()) 
    
                    buffer.push(new TimeSegment(x, s.timespan_ms - offset_ms))
                }
                else buffer.push(new TimeSegment(s.timepoint, s.timespan_ms))
            })
    
    
            if (buffer.length == 0) {
                return { period: '', total: '', total_minutes: 0, period_minutes: 0 }
            }
    
    
            const p = buffer[0].timespan_min
    
            let t = 0;
    
            buffer.forEach(s => t = t + s.timespan_min)
    
    
    
            return { period: mc.toAproximatedHoursString(p), total: mc.toAproximatedHoursString(t), total_minutes: t, period_minutes: p }
        }
    })

    

    return {remainder, endeavours_numbers_ui, endeavours_segments, isToday}
}




