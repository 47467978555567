import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel"
import { TodoRmodel } from "@/app.context/workspace/tickets/todo.rmodel"
import { Category, EffortType } from "../persons/_def"
import { TodoItemType, todoTypeIsRequiringAuxPerson } from "./todos/_def.todos"
import { Guid } from "@/lib"
import { MeetingRmodel } from "@/app.context/workspace/Event"
import { GroupRmodel } from "@/app.context/workspace/persons.set/group.rmodel"
import { PersonRmodel } from "@/app.context/workspace/person.rmodel"
import sys from "@/sys"
import { WorkspaceRmodel } from "@/app.context/workspace.rmodel"
import { ITimespendingDto } from "@/io/dto/workspaces/persons/effort.dto"
import { TodoDerivativeRef } from "../_tech"

export enum Resolution {
    Done = 1,
    Failed = 0
}

function resolutionBoolean(r: Resolution | null){

    if (r == null) return null

    if (r == Resolution.Done) return true
    if (r == Resolution.Failed) return false

    throw Error('Unknown resolution value')
}



export interface IResolution {
    
    queued: boolean | null
    resolved: boolean | null
    blocked: boolean | null
    focused: boolean | null 
}


/** It's may be about todo resolution, cancelling OR puting task to todo queueue */
export class PersonQueueUpdate {


    /**
     *
     */
    constructor(time: Date) {

        this.time = time

    }

    //isValid only resolution | quque +- | blocking | start: todo, work:A.F, idle, forcemajour
    //forced:false|true

    time: Date

    native: boolean = true //means happend by person / other then focused person

    //duplicate: number


    //

    type: EffortType | null = null ////start : work (A,Flex,), idle, forcemajour

    ticket: TicketRmodel | null = null

    //assume that if we have ticket - it should be 't'; if not 't' - issue warning, probalby in future it obtain some useful meaning 'null' vs 't'
    todo_type: TodoItemType | null = null

    todo_person_aux: Guid | null = null

    //todo: TodoRmodel | null = null //existing 'todo' means that it was 'reopened' and exists now

    //--

    resolution: EffortResolution = new EffortResolution()

}

/** EndeavourDetalizationItem */
export class EffortStat {

    /**
     *
     */
    constructor(e: OccupationtRef) {

        this.effort = e

    }

    effort: OccupationtRef

    //we can understand that task is reopened when we have 'todo' object itself - addition to ticket
    //reopened: boolean, //true -> (R), false - icon 'Reopen this item'
    duplicates: number = 0 //there was several resolution - 0 means 'no duplicates', 1 = +1

    /**Span */
    minutes: number = 0 //lengths in minutes

    resolution: EffortResolution = new EffortResolution()

    time: number = 0
}

//#todo should be moved upper to 'Models' level - has ref to ticket/meeting model
/** We need this such as we need how to refer as single entity to efforts - e.g. before lunch and after lucnh on same subject */
export class OccupationtRef {
    
    setTicket(ticket: number, workspace: WorkspaceRmodel) {

        if (ticket != 0) this.ticket = workspace.ticketOrBillet(ticket)
    }
    
    jsonString(){

        const b = { type:this.type, ticket: (this.ticket?.n ?? 0), todo_type: this.todo_type, todo_person_aux: this.todo_person_aux, category: this.category, title: this.title}

        return JSON.stringify(b)
    }


    

    //'all null' means 'off hour'

    type: EffortType | null = null // null for 'strict', other - work:flex|autonom, idle, forcemajour

    //--

    ticket: TicketRmodel | null = null  //we have ticket here because time at status may be reported directly to report

    todo_type: TodoItemType | null = null //for ref to 'todos' that was resolved we don't have 'active' 'TodoRmdodel' - so we need plain fields to see this info

    todo_person_aux: Guid | null = null

    //--

    category: string | null = null // 'forcemajour' and 'idle' are combined with 'EffortType' 

    title: string = '' /*name of category on notice*/

    //

    meeting: MeetingRmodel | null = null //category may also be 'meeting' when it reported backed; this meeting is for planed ones

    //with whoem


    /**this is not smae as for meeting - that is more for reporting with backdate of meting with '@john' and '@@devs' - just as references; does not makes sense (for now) when meeting itself is set */
    persons: PersonRmodel[] = []
    groups: GroupRmodel[] = [] 

    // any(){

    //     if (this.ticket != null) return true
    //    // if (this.todo != null) return true
    //     if (this.groups != null) return true
    //     if (this.persons != null) return true

    //     if (this.meeting != null) return true


    //     return false
    // }




    equal(r:OccupationtRef){

        if (r.category != this.category) return false
        if (r.groups.length != this.groups.length) return false

        const flags = {groups:true, persons: true}

        r.groups.forEach(g=>{

            if (this.groups.filter(x=>x.uid == g.uid).length == 0) flags.groups = false
        })

        this.groups.forEach(g=>{

            if (r.groups.filter(x=>x.uid == g.uid).length == 0) flags.groups = false
        })

        if (!flags.groups) return false


        //---

        r.persons.forEach(p=>{

            if (this.persons.filter(x=>x.nid == p.nid).length == 0) flags.persons = false
        })

        this.persons.forEach(p=>{

            if (r.persons.filter(x=>x.nid == p.nid).length == 0) flags.persons = false
        })


        if (!flags.persons) return false

        if (r.persons.length != this.persons.length) return false

        //todo check id of persons/groups

        if (r.meeting != this.meeting) return false

        if ((r.ticket?.n ?? 0) != (this.ticket?.n ?? 0)) return false

        if (r.title != this.title) return false
        if (r.todo_person_aux != this.todo_person_aux) return false

        if (r.todo_type != this.todo_type) return false

        if (r.type != this.type) return false

        return true

    }

    addGroup(uid:Guid, workspace: WorkspaceRmodel){

        this.groups.push(workspace.groupOrBillet(uid))
    }

    addPerson(nid:Guid, workspace: WorkspaceRmodel){

        this.persons.push(workspace.personOrBillet(nid))
    }

    setGroups(uid:Guid[], workspace:WorkspaceRmodel){

        this.groups.clear()

        uid.forEach(x=>this.addGroup(x, workspace))
    }

    setPersons(nid:Guid[], workspace:WorkspaceRmodel){

        this.persons.clear()

        nid.forEach(x=>this.addPerson(x, workspace))
    }

    match(item:OccupationtRef){

                //if ((item.category ?? '') != (category ?? '')) return false

                // if (todo != null) {

                //     return array.filter(x => x.match(todo.ticket.n, todo.type(), todo.auxPerson()/*, category*/))
                // }
                // else if (ticket != null) {
        
                //     return array.filter(x => x.match(ticket.n, null, null/*, category*/))
                // }
                // else return array.filter(x => x.match(0, null, null/*, category*/))

                if (this.ticket != null){

                    if (this.ticket.n != (item.ticket?.n ?? 0)) return false
                }
                else if (item.ticket != null) return false


                if (this.type != null){

                    if (this.type != item.type) return false
                }
                else if (item.type != null) return false

                if (this.todo_type != null){

                    if (this.todo_type != item.todo_type) return false
                }
                else if (item.todo_type != null) return false


                if (this.todo_person_aux != null){

                    if (this.todo_person_aux != item.todo_person_aux) return false
                }
                else if (item.todo_person_aux != null) return false


                if (this.category != null){

                    if (this.category != item.category) return false
                }
                else if (item.category != null) return false


                if (this.title != item.title) return false
                

                return true


                // const n = this.ticket?.n ?? 0


                // if (n != 0 && (item.ticket?.n ?? 0) != n) return false

                // const type = this.todo_type

                // //'task' type also catches just 'ticket'
                // if (type != "T")
                // {
                //     if (item.type != type) return false;
                // }
                // else if (item.todo_type != 'T' && item.type != null) return false
        
                // if (type != null)
                // {
                //     if (todoTypeIsRequiringAuxPerson(type))
                //     {
                //         if (item.person_aux != person_aux) return false
                //     }
                //     else if (person_aux != null) sys.warn('Aux person fon none personal item type')
                // }
        
                // return true
    }

    IsDerivative(){

        return TodoDerivativeRef.IsDerivative(this.todo_type)
    }

    

    setTicketOrTodoRef(props: {ticket?: TicketRmodel, todo?: TodoRmodel, category?: string | null})  {

        //dto.category = props.category ?? null

        if (props.ticket != undefined) this.ticket = props.ticket

        if (props.todo != undefined){

            this.ticket = props.todo.ticket
            this.todo_type = props.todo.type()
            this.todo_person_aux = props.todo.auxPerson()
        }
        
        if (props.category != undefined){

            this.category = props.category
        }

        //else throw Error('Argument: ticket and todo bothe are empty')
    }
}

/** TodoResolution */
export class EffortResolution {

    static Instance(value: IResolution) {

        const result = new EffortResolution()

        result.set(value)

        return result
    }


    dto(): IResolution{

        const r:IResolution = {
            
            queued: this.queued,
            blocked: this.blocked,
            resolved: resolutionBoolean(this.resolution),
            focused: this.focused,
        }

        return r
    }

    set(value: IResolution) {

        const result = this

        result.queued = value.queued
        result.blocked = value.blocked
        
        if (value.resolved != null) result.resolution = value.resolved ? Resolution.Done : Resolution.Failed
        else result.resolution = null
    }

    focused: boolean | null = null //true - start work, false - when paused (selfevident when queued:false) 
    resolution: Resolution | null = null
    queued: boolean | null = null //in/out, n/a
    blocked: boolean | null = null
}


export function toResolution(value: boolean | null) {

    if (value === null) return null;

    else if (value) return Resolution.Done;
    else return Resolution.Failed;
}

//

export function toBoolean(value: Resolution | null | undefined) {

    if (value === null) return null;
    if (value === undefined) return null;

    return Boolean(value)
}


/** Null assumes 'special' mode when status can not be calculated exactly */
export enum Status {
    Good,
    Neutral,
    Warning,
    Bad
}

/**
 * 
 * @param resolution 
 * @returns Good, Bad on Neutral
 */
export function toStatus(resolution: Resolution | null): Status {

    if (resolution === null) return Status.Neutral;

    else if (resolution == Resolution.Done) return Status.Good;
    else return Status.Bad;
}

