import { ITimeSegmentDto, ISunriseDto, toTimeSegment } from "@/io/dto/infrastructure.dto.ns"
import { ITodoRefDto, ITodoResolutionDto, toTodoRef } from "@/io/dto/workspaces/tickets/todos.ns"
import { EffortType } from "@/logic/workspaces/persons/_def"
import { IEventpDto } from "./eventp.dto"
import { IAppointmentRefDto } from "../eventw.dto"
import { ExactMeffortRmodel, Meffort, SimpleMeffort, Timeffort } from "@/app.context/workspace/persons/sunrises/effort.rmodel"
import { SegmentEnd } from "@/logic/_infra/_tech"
import { Guid } from "@/lib"
import { TodoItemType } from "@/logic/workspaces/tickets/todos/_def.todos"
import { ITicketOrTodoRef } from "@/logic/workspaces/tickets/todo"


export interface IEffortDto {


    todo: ITodoResolutionDto | null
    //event: IEventpDto | null
    type: EffortType | null

    isPaused: boolean
    segment: ITimeSegmentDto
}


interface IEffortDto_ORIGINAL {


    todo: ITodoResolutionDto | null
    event: IEventpDto | null
    type: EffortType | null

    isPaused: boolean
    segment: ITimeSegmentDto
}


export interface IExactMeffort extends IMeffortDto{

    segment: ITimeSegmentDto
}

// export function toExactMeffort(dto:IExactMeffort){

//     const m = new ExactMeffortRmodel()
    
//     writeToMeffort(dto, m)

//     m.segment = toTimeSegment(dto.segment)

    

//     return m
// }

// export function toSimpleMeffort(dto:ISimpleMeffort){

//     const m = new SimpleMeffort()

//     writeToMeffort(dto, m)

    
    
//     m.minutes = dto.minutes

//     return m
// }

// function writeToMeffort(dto:IMeffortDto, m:Meffort){

//     m.set(dto)

//     return m
// }


export interface ISimpleMeffort extends IMeffortDto {

    minutes: number
}

export interface IMeffortDto extends ITicketOrTodoRef //#ref {1F532793-3B7F-424D-BB49-3D715361759D}
{
    
    mid: Guid //m

    
}

export interface ITimeffortDto {


    todo: ITodoResolutionDto | null
    //event: IEventpDto | null
    type: EffortType | null

    isPaused: boolean
    segment: ITimeSegmentDto
}

export interface ITimesegmentTuneDto{

    date: string
    edge: SegmentEnd
    value: string
}

export function timeffortUpdate(timeffort: Timeffort, dto: ITodoResolutionDto) {

    

    if (dto.todo != null){

        timeffort.todo = toTodoRef(dto.todo)

        if (dto.dequeued) timeffort.resolution.queued = false //'dequeued == false does not mean that item was added into queu
        timeffort.resolution.resolved = dto.resolution
        if (dto.delay == "B") timeffort.resolution.blocked = true 
    }

    
}


//{3027691D-69A9-4E48-8BB2-AD0736AADDED} c#
export interface ITimespendingDto extends ITicketOrTodoRef {
    
    tag: string | null

    //--

    tid: Guid

    segment: ITimeSegmentDto

    //--

    name: string | null
    
    //--

    category: string | null

    groups: Guid[]

    persons: Guid[]

    //--

    //ticket: number ITicketOrTodoRef

    //type: TodoItemType | null  -> ITicketOrTodoRef

    //person_aux: Guid | null  -> ITicketOrTodoRef

    //
    
    appointment: IAppointmentRefDto | null

    //todo: ITodoRefDto | null

}