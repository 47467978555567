import * as sys from '@/sys'
import { Resolution } from '@/logic/workspaces/tickets/_def';
import { TodoRmodel } from '@/app.context/workspace/tickets/todo.rmodel';
import { TicketRmodel } from '@/app.context/workspace/ticket.rmodel';
import { Structure } from '@/logic/_infra/_tech'
import { Entity } from '@/logic/workspaces/_tech'
import AppContext from '@/context';
import { ticketResolutionUpdate, ticket_close, personUpdate } from '@/ops/workspaces/ticket.processor'
import { PersonRmodel } from '@/app.context/workspace/person.rmodel';
import { BlockRmodel } from '@/app.context/workspace/tickets/tasks/block.rmodel';
import { isTicket, isTodo } from '@/controllers/workspaces/tickets/_model/ticket.item.controller'

//means 'Ticket based UI controll' assuming that we can use ui 'ticket card' and 'ticket strip' same as for 'ticket' as for 'todo' item that is always based on some ticket
export interface ITicketUiElementController {
    check(item: TodoRmodel | TicketRmodel): void
    stop(item: TodoRmodel |  TicketRmodel): void
    reset(item: TodoRmodel | TicketRmodel): void
    close(item: TodoRmodel | TicketRmodel): void
    person(item: TodoRmodel | TicketRmodel, person: PersonRmodel, index: number | null): void
    block(item: BlockRmodel): void
}

export function TicketBoardController(context: AppContext): ITicketUiElementController {


    //function isTicketItem(item: TodoRmodel | TicketRmodel): item is TicketRmodel { return true }

    const o =
    {
        //'gree'
        check(item: TodoRmodel | TicketRmodel) {
            if (isTicket(item)) ticketResolutionUpdate(context, item, Structure.Board, Resolution.Done)
        },
        //'yellow'
        stop(item: TodoRmodel | TicketRmodel) {
            if (isTicket(item)) ticketResolutionUpdate(context, item, Structure.Board, Resolution.Failed)
        },
        //back to neutral
        reset(item: TodoRmodel | TicketRmodel) {
            if (isTicket(item)) ticketResolutionUpdate(context, item, Structure.Board, null)
        },

        //close ticket
        close(item: TodoRmodel | TicketRmodel) {
            if (isTicket(item)) ticket_close(context, item, Structure.Board,)
        },

        person(item: TodoRmodel | TicketRmodel, person: PersonRmodel, index: number | null) {

            if (isTicket(item)) personUpdate(context, item, person, index)
        },

        block(block: BlockRmodel) {

            //todo if (isTicketItem(item)) personUpdate(context, item, person, index)
            sys.warn(`Unexpeced 'block' emit at 'ticket' controller - ${block?.task?.ticket?.n} `)
        },
    }

    return o;

}

export function TicketQueueController(context: AppContext): ITicketUiElementController {


    function isTicketItem(item: TodoRmodel | TicketRmodel): item is TicketRmodel { return true }

    const o =
    {
        //'gree'
        check(item: TodoRmodel | TicketRmodel) {
            if (isTicketItem(item)) ticketResolutionUpdate(context, item, Structure.Queue, Resolution.Done)
        },
        //'yellow'
        stop(item: TodoRmodel | TicketRmodel) {
            if (isTicketItem(item)) ticketResolutionUpdate(context, item, Structure.Queue, Resolution.Failed)
        },
        //back to neutral
        reset(item: TodoRmodel | TicketRmodel) {
            if (isTicketItem(item)) ticketResolutionUpdate(context, item, Structure.Queue, null)
        },

        //close ticket
        close(item: TodoRmodel | TicketRmodel) {
            
            if (isTicketItem(item)) ticket_close(context, item, Structure.Queue)
        },

        person(item: TodoRmodel | TicketRmodel, person: PersonRmodel, index: number | null) {

            if (isTicketItem(item)) personUpdate(context, item, person, index)
        },

        
        block(item: BlockRmodel) {

            //todo if (isTicketItem(item)) personUpdate(context, item, person, index)
        },
    }

    return o;

}