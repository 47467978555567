import { Guid } from "@/lib";
import { Entity } from "@/logic/workspaces/_tech";
import AppContext from "@/context";
import { InstructionOperation } from "@/app.context/operations";
import { programmator } from '@/ops/workspaces_spec/Xbq/programator'
import { PersonRmodel } from "@/app.context/workspace/person.rmodel";
import { ProjectModel } from "@/app.context/workspace/project.rmodel";
import { BoardPosition } from "@/logic/_infra/boards";
import { WorkspaceRmodel } from "@/app.context/workspace.rmodel";
import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel";
import { TicketEntityDto } from "@/io/storages/workspace/EntityOperation";

export function commandSubmit(context: AppContext, title: string, wid: Guid, entity: Entity, id: Guid) {

    context.storages.workspace!.commandSubmit(title, wid, entity, id)
}

export function ticketCreateAndBoard(context:AppContext, title:string, p:ProjectModel, position: BoardPosition){

    throw new Error('Not imlemented')
}

//creates ticket and 'board' it
// export function ticketCreateAndBoard(context: AppContext, title: string, target: ProjectModel | PersonRmodel, position: BoardPosition): void {


//     const storage = context.storages.workspace!

//     const instruction: InstructionOperation[] = [];

//     const workspace = target.workspace;

//     // -- 1 -- //
//     const entity = ticketCreate(target.workspace.cache, title, target);

//     instruction.push(entity.operation);

//     programmator.board(entity.ticket, target, position).forEach(o => instruction.push(o));

//     // -- 2 -- //
//     workspace.apply(instruction);

//     // -- 3 -- //
//     if (target instanceof ProjectModel) {

//         //this.storage.ticketCreateAndProjectBoard(target.pid, title, position, instruction.map(o => o.dto()))
//         storage.transactionSubmit(target.workspace.wid, instruction.map(o => o.dto()))

//     }
//     else if (target instanceof PersonRmodel) {

//         //this.storage.ticketCreateAndCaptainPersonBoard(target.nid, title, position, instruction.map(o => o.dto()))
//         storage.transactionSubmit(target.workspace.wid, instruction.map(o => o.dto()))
//     }
//     else throw Error(`Unknow type of ${target}`)
// }

// function ticketCreate(cache: Map<Guid, TicketRmodel>, title: string, target: ProjectModel | PersonRmodel): { operation: TicketEntityDto, ticket: TicketRmodel } {

//     const entity = new TicketEntityDto()

//     entity.name = title;

//     let ticket: TicketRmodel;

//     const workspace: WorkspaceRmodel = target.workspace;

//     if (target instanceof ProjectModel) {

//         entity.pid = target.pid;

//         ticket = workspace.ticketNew(title);

//         cache.set(entity.uid, ticket);
//     }
//     else if (target instanceof PersonRmodel) {

//         ticket = workspace.ticketNew(title);

//         cache.set(entity.uid, ticket);

//     }
//     else throw Error(`Unknown targe type: ${target}`);

//     //entity.object = ticket;

//     return { operation: entity, ticket };
// }