interface Array<T> 
{
  replaceWith(replacementArray: T[]): void;
  clear():void
  areEqual(arr: T[]): boolean;
}

Array.prototype.replaceWith = function<T>(replacementArray: T[]): void {
  this.clear() // Clear the original array
  Array.prototype.push.apply(this, replacementArray); // Add values from the replacement array
};

Array.prototype.clear = function(): void {
  this.length = 0; // Clear the original array
};





// // Example usage:
// const originalArray: number[] = [1, 2, 3, 4, 5];
// const replacementArray: number[] = [10, 20, 30];

// originalArray.replaceWith(replacementArray);

// console.log(originalArray); // Output: [10, 20, 30]


// Extend the Array type to include an "areEqual" method
// declare global {
//   interface Array<T> {
    
//   }
// }

// Implement the "areEqual" method for arrays of numbers
Array.prototype.areEqual = function <T>(arr: T[]): boolean {
  if (this.length !== arr.length) {
    return false;
  }

  for (let i = 0; i < this.length; i++) {
    if (this[i] !== arr[i]) {
      return false;
    }
  }

  return true;
};