
import * as sys from '@/sys'
import { OccupationtRef, Resolution } from '@/logic/workspaces/tickets/_def';
import { TodoRmodel } from '@/app.context/workspace/tickets/todo.rmodel';
import { TicketRmodel } from '@/app.context/workspace/ticket.rmodel';
import AppContext from '@/context';
import { todo_cancel, todo_resolve, block_cancel } from '@/ops/workspaces/tickets/todo.processor'
import { ITicketUiElementController } from '../../tickets/_spec/ticket.controller.elementary.interface';
import { PersonRmodel } from '@/app.context/workspace/person.rmodel';
import * as ticketProcessor from '@/ops/workspaces/ticket.processor'
import { BlockRmodel } from '@/app.context/workspace/tickets/tasks/block.rmodel';
import { isTicket, isTodo } from '@/controllers/workspaces/tickets/_model/ticket.item.controller'
import { SunriseReportRmodel } from '@/app.context/workspace/persons/sunrise.rmodel';
import { Timespending } from '@/app.context/workspace/Event';
import { ExactMeffortRmodel, SimpleMeffort } from '@/app.context/workspace/persons/sunrises/effort.rmodel';
import { TimeSegment } from '@/logic/_infra/time';
import { roundToClosestQuarterHour } from '@/lib/date';
import { TodoItemType } from '@/logic/workspaces/tickets/todos/_def.todos';
import { Guid } from '@/lib';


export function mefforts_ext(efforts: SunriseReportRmodel | null, props: { ticket?: TicketRmodel, todo?: TodoRmodel, category? : string }) {

    const occupation = new OccupationtRef()

    if (props.category != undefined) occupation.category = props.category
    else if (props.todo != undefined) 
    {
        occupation.ticket = props.todo.ticket
        occupation.todo_type = props.todo.type()
        occupation.todo_person_aux = props.todo.auxPerson()
    }
    else if (props.ticket != undefined){

        occupation.ticket = props.ticket
    }
    else return null

    if (efforts == null) return null

    return {
        exact: efforts.summary.mefforts_exact_filter(occupation /*props.ticket ?? null, props.todo ?? null, props.category ?? null*/),
        simple: efforts.summary.mefforts_simple_filter(occupation /*props.ticket ?? null, props.todo ?? null, props.category ?? null*/),
        timespendings: Timespending.Filter(efforts.timespendings, occupation /*props.ticket ?? null, props.todo ?? null, props.category ?? null*/)
    }

}

export function mefforts_any(mefforts: { exact: ExactMeffortRmodel[], simple: SimpleMeffort[], timespendings: Timespending[] } | null) {

    return mefforts != null && mefforts.exact.length > 0 && mefforts.simple.length > 0 && mefforts.simple.length > 0
}

const TIMESPENDING_LENGH__DEFAULT__MS = 900000 //15 X 60 X 1000

const DEFAULT_TIMEFRAME = 900000 //15 X 60 X 1000

export function timingOptions(now: number, efforts: SunriseReportRmodel, ticket: TicketRmodel | null, todo: TodoRmodel | null, category: string | null) {

    if (category == undefined) category = null


    const options = new TimingOptions()



    const efforts_graytime_buffer = efforts.summary.timeline.map(x => x)

    if (efforts.summary.current != null) efforts_graytime_buffer.push(efforts.summary.current)

    const efforts_flextime = efforts_graytime_buffer.filter(x => (x.timeffort?.isWorkGrayTime() ?? false)).map(x => x.segment_value)

    const efforts_flextime_mefforted = TimeSegment.squashTimeSegments(efforts_graytime_buffer.filter(x => x.meffort != null).map(x => x.segment_value))

    const efforts_flextime_available = TimeSegment.excludeOverlappingSegments(efforts_flextime, efforts_flextime_mefforted) // = efforts_graytime_buffer.filter(x => (x.isGrayWorkTimeEffort) && x.meffort == null).map(x => x.segment_value)

    // if (graytime.length > 0) {

    //     options.mefforts = { exact: true, simple: false }
    //     options.timespendings = false
    // }
    // else {
    //     options.mefforts = null
    //     options.timespendings = true
    // }


    //timespendings

    const ts = timespendings(efforts, ticket, todo, category)

    const ts_squashed = TimeSegment.squashTimeSegments(ts)

    const ts_available = TimeSegment.excludeOverlappingSegments(efforts.endeavours, ts_squashed)

    if (ts_available.length > 0) {

        //we have available frames during working day

        const element = ts_available[ts_available.length - 1]

        options.prospects_aux.timespendings.push(limitedCopy(element))
    }


    //mefforts 

    const mefforts_item = mefforts(efforts, ticket, todo, category)

    //const graytime_available = TimeSegment.excludeOverlappingSegments(graytime, mefforts_item)

    //const now = context.timepoint.value

    if (now >= efforts.period.timepoint.getTime() && now < efforts.period.end().getTime()) {

        //timespending
        if (ts_available.length == 0) {
            const segment = new TimeSegment(efforts.period.timepoint, now - efforts.period.timepoint.getTime()) //from sunrise start to current moment

            const ts_availabe_ext = TimeSegment.excludeOverlappingSegments([segment], ts_squashed)

            if (ts_availabe_ext.length > 0) {

                const element = ts_availabe_ext[ts_availabe_ext.length - 1]

                options.prospects_aux.timespendings.push(limitedCopy(element))

            }
            else options.prospects_aux.timespendings.length = 0 //can't give any option for today
        }


        //mefforts

        const segments_mefforts_normalized = efforts.summary.mefforts.map(x => x.segment)

        segments_mefforts_normalized.sort(TimeSegment.SortEndAscending2)

        let startPoint = roundToClosestQuarterHour(new Date(now), 'back') // now.cloneAndAddMilliseconds(-1* DEFAULT_TIMEFRAME) 

        if (segments_mefforts_normalized.length > 0) {


            const segmetn_last = segments_mefforts_normalized[segments_mefforts_normalized.length - 1]

            startPoint = segmetn_last.end()
        }
        else if (efforts.endeavours.length > 0) {

            startPoint = TimeSegment.minTimepoint(efforts.endeavours)!
        }




        const newPeriod = now - startPoint.getTime()

        const THRESHOLD = 600000 //1MIN IN MS



        if (mefforts_item.length > 0) {

            if (newPeriod > THRESHOLD) {

                options.prospects_aux.mefforts.push({ segment: new TimeSegment(startPoint, newPeriod), overwrite: false })
                efforts_flextime_available.forEach(x => options.prospects_aux.mefforts.push({ segment: x, overwrite: false }))
            }
            else {
                if (efforts_flextime_available.length > 0) {


                    efforts_flextime_available.forEach(x => options.prospects_aux.mefforts.push({ segment: x, overwrite: false }))

                    //options.prospects.meffort = graytime_available[0]  //1st element as prospect

                    //options.prospects_aux.mefforts = graytime_available.filter((x, i) => i > 0); //the rest to addition options
                }
                else {
                    const minimum = TimeSegment.minTimepoint(mefforts_item)!

                    const x = roundToClosestQuarterHour(minimum, 'back')

                    const start = x.getTime() - DEFAULT_TIMEFRAME

                    if (efforts.period.timepoint.getTime() <= start) {

                        const s = new TimeSegment(new Date(start), DEFAULT_TIMEFRAME)

                        const o = TimeSegment.intersection2(s, efforts.mefforts.exact.map(x => x.segment)).length > 0

                        options.prospects_aux.mefforts.push({ segment: s, overwrite: o })

                        //options.prospects.meffort = s

                        //if  s intersect any other effrt
                        //options.prospects.meffort_overwrite = 


                    }
                    else options.prospects_aux.mefforts.length = 0; // no option to add new any intervals; this assumes that it does not make sense to create any new meffort - editing of alredy existing should be enough


                }
            }

        }
        else { //no current mefforts exists

            if (newPeriod > THRESHOLD) {

                options.prospects_aux.mefforts.push({ segment: new TimeSegment(startPoint, newPeriod), overwrite: false })
                //options.prospects_aux.mefforts = graytime_available

                efforts_flextime_available.forEach(x => options.prospects_aux.mefforts.push({ segment: x, overwrite: false }))
            }
            else {

                //if () options.prospects.meffort_overwrite = true

                //options.prospects.meffort = 

                options.prospects_aux.mefforts.push({ segment: new TimeSegment(new Date(startPoint.getTime() - DEFAULT_TIMEFRAME), DEFAULT_TIMEFRAME), overwrite: segments_mefforts_normalized.length > 0 })

                //options.prospects_aux.mefforts = graytime_available

                efforts_flextime_available.forEach(x => options.prospects_aux.mefforts.push({ segment: x, overwrite: false }))
            }

        }






        if (newPeriod > 60000) //1minute = 60 x 1000
        {
            options.prospects_aux.mefforts.push({ segment: new TimeSegment(startPoint, newPeriod), overwrite: false })

            //options.prospects.meffort_overwrite = false
        }




    }
    else //backdate editing
    {
        if (efforts_flextime_available.length > 0) {

            efforts_flextime_available.forEach(x => options.prospects_aux.mefforts.push({ segment: x, overwrite: false }))

            //options.prospects.meffort = graytime_available[0]  //1st element as prospect

            //options.prospects_aux.mefforts = graytime_available.filter((x, i) => i > 0); //the rest to addition options
        }
        else {
            if (mefforts_item.length > 0) {

                const minimum = TimeSegment.minTimepoint(mefforts_item)!

                const x = roundToClosestQuarterHour(minimum, 'back')

                const start = x.getTime() - DEFAULT_TIMEFRAME

                if (efforts.period.timepoint.getTime() <= start) {

                    const s = new TimeSegment(new Date(start), DEFAULT_TIMEFRAME)

                    const o = TimeSegment.intersection2(s, efforts.mefforts.exact.map(x => x.segment)).length > 0

                    options.prospects_aux.mefforts.push({ segment: s, overwrite: o })


                }

                const maximum = TimeSegment.maxTimepoint(mefforts_item)!

                const y = roundToClosestQuarterHour(maximum, 'forward')

                const end = y.getTime() + DEFAULT_TIMEFRAME

                if (efforts.period.end().getTime() > end) {

                    const s = new TimeSegment(new Date(y), DEFAULT_TIMEFRAME)

                    const o = TimeSegment.intersection2(s, efforts.mefforts.exact.map(x => x.segment)).length > 0

                    options.prospects_aux.mefforts.push({ segment: s, overwrite: o })
                }

                //else options.prospects.meffort = null; // no option to add new any intervals; this assumes that it does not make sense to create any new meffort - editing of alredy existing should be enough



            }
            else {
                if (efforts_flextime.length > 0) {

                    const s = efforts_flextime[efforts_flextime.length - 1]

                    options.prospects_aux.mefforts.push({ segment: new TimeSegment(new Date(s.timepoint), DEFAULT_TIMEFRAME), overwrite: true })
                }
                
            }
        }
    }

    return options
}

/**Returns copy if it spane less then constand or return max leng segment with end equal the end of pinput param */
function limitedCopy(element: TimeSegment): TimeSegment {

    if (element.timespan_ms > TIMESPENDING_LENGH__DEFAULT__MS) {

        return new TimeSegment(new Date(element.end().getTime() - TIMESPENDING_LENGH__DEFAULT__MS), TIMESPENDING_LENGH__DEFAULT__MS) //set segment with default length with end as it was proposed
    }
    else return element.clone()

}



function timespendings(efforts: SunriseReportRmodel, ticket: TicketRmodel | null, todo: TodoRmodel | null, category: string | null): TimeSegment[] {

    return f(efforts.timespendings, ticket, todo, category)
}


function f(array: {objects:OccupationtRef, segment:TimeSegment}[], ticket: TicketRmodel | null, todo: TodoRmodel | null, category: string | null): TimeSegment[] {


    let counter = 0

    if (ticket != null) counter++
    if (todo != null) counter++
    if (category != null) counter++

    if (counter != 1) throw new Error("Arguments wrong")

    let n = 0

    let type: TodoItemType | null = null

    let person_aux: Guid | null = null

    if (ticket != null) {
        n = ticket.n
    }

    if (todo != null) {

        n = todo.ticket.n

        type = todo.type()

        person_aux = todo.auxPerson()

    }

    return array.filter(x => x.objects.category == category && x.objects.ticket?.n == n && x.objects.todo_type == type && x.objects.todo_person_aux == person_aux).map(x => x.segment)


}

function mefforts(efforts: SunriseReportRmodel, ticket: TicketRmodel | null, todo: TodoRmodel | null, category: string | null): TimeSegment[] {

    return f(efforts.mefforts.exact, ticket, todo, category)
}

class TimingOptions {
    //timespendings: boolean | null = true //show (nul/nont null + active: true|false)
    //mefforts: { simple: boolean, exact: boolean } | null = null //show (nul/nont null + active: true|false)
    //boolean | null = null 

    //simpleMefforts = false //whether show just meffort lenght

    // prospects: {

    //     timespending: TimeSegment | null, //null when all is full
    //     meffort: TimeSegment | null
    //     meffort_overwrite: boolean

    // } = { timespending: null, meffort: null, meffort_overwrite: false }

    prospects_aux:
        {
            timespendings: TimeSegment[],
            mefforts: { segment: TimeSegment, overwrite: boolean }[]

        } = { timespendings: [], mefforts: [] }
}






// const mefforts = computed(()=>{

//     if (props.efforts == null) return null

//     return {
//         exact: props.efforts.summary.mefforts_exact_filter(props.ticket ?? null, props.todo ?? null), 
//         simple: props.efforts.summary.mefforts_simple_filter(props.ticket ?? null, props.todo ?? null) ,
//         timespendings: Timespending.Filter(props.efforts.timespendings, props.ticket ?? null, props.todo ?? null)
//     } 
// })

// const mefforts_any = computed(()=>{

//     return mefforts.value != null && mefforts.value.exact.length > 0 && mefforts.value.simple.length > 0 && mefforts.value.simple.length > 0
// })


export function TodoUiElementController(context: AppContext): ITicketUiElementController {

    // function isTodoItem(item: TodoRmodel | TicketRmodel): item is TodoRmodel { return true }

    const o =
    {
        //'check' button
        check(item: TodoRmodel | TicketRmodel) {
            if (isTodo(item)) todo_resolve(context, item, Resolution.Done)
        },
        //'stop' button
        stop(item: TodoRmodel | TicketRmodel) {
            sys.warn('Unexpected call')
        },
        //'reset' button
        reset(item: TodoRmodel | TicketRmodel) { sys.warn('Unexpected call') },

        //'X' button
        close(item: TodoRmodel | TicketRmodel) {
            if (isTodo(item)) todo_cancel(context, item)
        },

        //'Person' tag clikc
        person(item: TodoRmodel | TicketRmodel, person: PersonRmodel, index: number) {
            if (isTodo(item)) ticketProcessor.personUpdate(context, item, person, index)
        },

        block(item: BlockRmodel) {

            block_cancel(context, item)


        },

    }

    return o;

}

export function BoardTodoItemController(context: AppContext): ITicketUiElementController {

    function isTodoItem(item: TodoRmodel | TicketRmodel): item is TodoRmodel { return true }

    const o =
    {
        //'check' button
        check(item: TodoRmodel | TicketRmodel) {
            if (isTodoItem(item)) todo_resolve(context, item, Resolution.Done)
        },
        //'stop' button
        stop(item: TodoRmodel | TicketRmodel) {
            sys.warn('Unexpected call')
        },
        //'reset' button
        reset(item: TodoRmodel | TicketRmodel) { sys.warn('Unexpected call') },

        //'X' button
        close(item: TodoRmodel | TicketRmodel) {
            if (isTodoItem(item)) context.ops!.xbq?.todoBoardClose(item, item.person) //just clost 'card' with 'todo' from person board
        },

        //'Person' tag clikc
        person(item: TodoRmodel | TicketRmodel, person: PersonRmodel, index: number) {
            if (isTodoItem(item)) ticketProcessor.personUpdate(context, item, person, index)
        },

        block(item: BlockRmodel) {

            block_cancel(context, item)
        },
    }

    return o;
}