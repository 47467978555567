
import { LabelModel } from '@/app.context/workspace/label.rmodel'
import { personSorter } from '@/app.context/workspace/persons.set/persons.alg'
import { PersonRmodel } from '@/app.context/workspace/person.rmodel'
import { PhaseRmodel, ProcessRmodel } from './workspace/process.model'
import { ProjectModel } from './workspace/project.rmodel'
import { TaskRmodel } from './workspace/tickets/task.rmodel'
import { VerificationModel } from './workspace/tickets/tasks/verification.rmodel'
import { TicketRmodel } from '@/app.context/workspace/ticket.rmodel'
import { IPersonDto } from '@/io/dto/workspaces/person.dto'
import { IEventpDto } from '@/io/dto/workspaces/persons/eventp.dto'
import { IGroupDto } from '@/io/dto/workspaces/persons.set/group.dto'
import { IProcessDto } from '@/io/dto/workspaces/process.dto'
import { IProjectDto } from '@/io/dto/workspaces/project.dto'
import { IWorkspaceDto } from '@/io/dto/workspace'
import { Guid } from '@/lib'
import { reactive } from 'vue'
import { ITicketDto } from '@/io/dto/workspaces/ticket.dto'
import { IEntityRefDto } from '@/io/dto/workspaces/_tech'
import { BlockRmodel } from './workspace/tickets/tasks/block.rmodel'
import { GroupRmodel } from './workspace/persons.set/group.rmodel'
import { TodoRmodel } from './workspace/tickets/todo.rmodel'
import { TodoQueueOperation, TodoBoardOperation, TicketBoardOperation, TicketQueueOperation, InstructionOperation } from './operations'
import { ResourceCalendar } from '@/logic/workspaces/rcalendar'
import { ICalendarDto } from '@/io/dto/workspaces/calendar.dto'
import { singleOrNull } from '@/lib/array.lib'
import * as sys from '@/sys'
import { ILabelDto } from '@/io/dto/workspaces/label.dto'
import { MeetingRmodel } from './workspace/Event'
import AppContext from '@/context'
import { IWorkspaceStorage } from '@/io/storages'
import { Sunrise } from '@/logic/_infra/time'
import { IService } from '@/io'


export class WorkspaceRmodel {

    cache: Map<Guid, TicketRmodel> = new Map<Guid, TicketRmodel>();

    sunrise: Sunrise = new Sunrise(1, 1, 1)

    categories: Set<string> = new Set()
    
    service: IService





    constructor(wid: Guid, storage: IWorkspaceStorage, service: IService) {

        this.wid = wid;

        this.timestamp = new Date().toTimeString()

        this.storage = storage

        this.service = service
    }

    readonly storage: IWorkspaceStorage



    timestamp: string

    readonly wid: Guid;
    //_project: ProjectModel | null = null; //current active project
    name = ''

    //me: string | null = null;
    user_person: PersonRmodel | null = null;


    get isCaptain() {

        return this.user_person?.captain ?? false
    }

    isImpersonated(p: PersonRmodel) {

        return p == this.user_person
    }

    meetings: MeetingRmodel[] = []

    readonly index = {
        persons: new Map<string, PersonRmodel>(),
        //persons_name: new Map<string, UnwrapNestedRefs<PersonModel>>(),
        groups: new Map<Guid, GroupRmodel>(),
        processes: new Map<string, ProcessRmodel>(),
        labels: new Map<string, LabelModel>(),

        projects: new Map<Guid, ProjectModel>(),

        tickets: new Map<number, TicketRmodel>(),

        calendars: new Map<number, ResourceCalendar>(),
    };

    // ALGS //

    meetingOrBillet(e: Guid, service: IService) {

        const workspace = this

        let buffer = singleOrNull(workspace.meetings, x => x.mid == e)

        if (buffer == null) {
            buffer = new MeetingRmodel()

            buffer.mid = e

            workspace.meetings.push(buffer)

            service.meetingOrNull(e, workspace.wid).then(dto => {

                if (dto == null) sys.warn('No meeting found: ' + e.toString())
                else {
                    let model = singleOrNull(workspace.meetings, x => x.mid == dto.meeting)

                    if (model == null) {

                        model = new MeetingRmodel();
                        model.mid = dto.meeting
                        workspace.meetings.push(model)
                    }

                    model.set(dto, workspace)
                }

            })
                .catch(sys.exception)
        }

        return buffer
    }

    //remove from project, add to queue
    apply(instruction: InstructionOperation[]) {

        //x:ITWorkspaceStorage

        instruction.forEach(operation => {

            if (operation instanceof TodoQueueOperation) {

                const person = operation.objects.todo.person;

                //if (person === null) person = this.person_nid(operation.nid);

                const todo = operation.objects.todo;

                //if (todo === null) todo = person.todoItem_AtQueue_N(operation.n, operation.type);

                person.todoQueueApply(operation);
            }
            else if (operation instanceof TodoBoardOperation) {

                const person = operation.objects.person;

                //if (person === null) person = this.person_nid(operation.nid);

                const todo = operation.objects.todo;

                //if (todo === null) todo = person.todoItem_AtBoard_N(operation.n, operation.type);

                person.todoBoardApply(operation);

            }
            // eslint-disable-next-line
            else if (operation instanceof TicketBoardOperation) {

                if (operation.objects.proejct != null) {

                    //project board

                    const project = operation.objects.proejct;

                    // if (project === null) {
                    //     project = this.project(operation.pid);
                    // }

                    project.ticketBoardApply(operation);
                }

                else if (operation.objects.person != null) {
                    const person = operation.objects.person;

                    //if (person === null) person = this.person_nid(operation.nid);

                    person.ticketBoardApply(operation);
                }

                else throw Error(`No nid or pid was set to ticket board operaton ${operation.n}`);
            }
            // eslint-disable-next-line
            else if (operation instanceof TicketQueueOperation) {

                const project = operation.objects.project;

                //if (project === null) project = this.project(operation.pid!);

                project.ticketQueueApply(operation);

            }
            //#someday Validate stricktly type of 'entities' (ignoring them explicitly)
            /*
            else if (operation instanceof EntityOperationDto) {

                operation //do nothing
            }

            else throw Error("Unkonw operation insturction type");
            */
        });
    }






    Wid() { return this.wid; }

    setup(context: AppContext, w: IWorkspaceDto): void {

        this.name = w.name;

        this.sunrise = Sunrise.Instance(w.sunrise)


        if (w.calendars) w.calendars.forEach(c => {

            this.setupCalendar(c);

            //c.dates.forEach(d=>console.log(d))
        });


        if (w.persons) w.persons.forEach(dto => this.personSetup(dto));

        if (w.me != null) {

            if (this.user_person == null || this.user_person.nid != w.me) this.user_person = this.person_nid(w.me);
        }




        //groups
        if (w.groups) w.groups.forEach(dto => this.groupSetup(dto));

        //processes
        if (w.processes) w.processes.forEach(dto => this.processSetup(dto));

        //labels
        if (w.labels) w.labels.forEach(dto => this.labelSetup(dto));

        //projects
        w.projects.forEach(dto => this.projectSetup(dto));

        //tasks(tickets)

        w.tickets.forEach(dto => this.ticketSetup(dto));

        //this.tasksSetup(w.projects, w.persons);

        //PROJECT DISPOSITION SETUP
        if (w.projects) {
            //X!
            w.projects.forEach(dto => {

                const model = this;

                const project = this.project(dto.pid);

                project.disposition.desk.length = 0
                project.disposition.queue.length = 0
                project.disposition.pins.length = 0


                this.tickets(dto.disposition.desk).forEach(x => project.disposition.desk.push(x));
                this.tickets(dto.disposition.queue).forEach(x => project.disposition.queue.push(x));
                this.tickets(dto.disposition.pins).forEach(x => project.disposition.pins.push(x));

                project.disposition.board.length = 0

                dto.disposition.board.forEach(function (row: IEntityRefDto[]) {

                    const buffer: (TicketRmodel | null)[] = [];

                    row.forEach(x => buffer.push(x != null ? model.ticket(x.n) : null));

                    project.disposition.board.push(buffer);
                });
            });
        }

        //PERSON BOARDS SETUP
        if (w.persons) {
            w.persons.forEach(dto => {

                const model = this;

                const person = this.person_nid(dto.nid)

                //const object = this.index.persons.get(dto.name) as PersonModel;
                if (dto.current != null) {

                    const ticket = this.ticket(dto.current!.n);



                    if (dto.current.type === 'T') {

                        // const tasks = ticket.tasks.filter(t => t.person === person);

                        // if (tasks.length != 1) throw new Error(`/app/workspaces/person - Can't find task for person ${person?.name}`);



                        // person.current = new TodoRmodel(tasks[0]/*, person*/);

                        person.current = new TodoRmodel(this.task(dto.current!.n, person.nid))
                    }
                    else {

                        const tasks = ticket.tasks.filter(t => t.person.nid === dto.current!.person_aux);

                        if (dto.current.type === 'V') {

                            // const verifications = tasks[0].verifications.filter(v => v.person === person);

                            // if (verifications.length != 1) throw new Error(`/app/workspaces/person - Can't find verificatin for person ${dto.current?.n}`);

                            // person.current = new TodoRmodel(verifications[0]/*, person*/);

                            person.current = new TodoRmodel(this.verification(dto.current!.n, dto.current.person_aux!, person.nid))

                        }
                        else if (dto.current.type === 'B') {

                            // const blocks = tasks[0].blocks.filter(b => b.person == person);

                            // if (blocks.length != 1) throw Error(`/app/workspaces/person - Can't find block for person ${dto.current?.n}`);

                            // person.current = new TodoRmodel(blocks[0]/*, person*/);

                            person.current = new TodoRmodel(this.block(dto.current!.n, dto.current.person_aux!, person.nid))

                        } else new Error(`/app/workspaces/person - Can't detect todo item type: ${dto.current.type}`)
                    }


                }

                person.desk.length = 0
                person.queue.length = 0
                person.pins.length = 0

                this.todos(dto.queue, person.nid).forEach(t => person.queue.push(new TodoRmodel(t/*, person*/)));
                this.todos(dto.desk, person.nid).forEach(t => person.desk.push(new TodoRmodel(t/*, person*/)));
                this.todos(dto.pins, person.nid).forEach(t => person.pins.push(new TodoRmodel(t/*, person*/)));

                //dto.board_Captain spared is 


                person.board.captain.length = 0

                dto.board_Captain.forEach(function (row: (IEntityRefDto | null)[]) {

                    const buffer: (TicketRmodel | null)[] = [];

                    row.forEach(x => buffer.push(x != null ? model.ticket(x.n) : null));

                    person.board.captain.push(buffer);

                });


                person.board.person.length = 0

                dto.board_Person.forEach(function (row: (IEntityRefDto | null)[]) {

                    person.board.person.push(model.todosSparsedAsModels(row, person));
                });


                if (dto.efforts != null) person.setup_sunriseReport(context, dto.efforts)


            });
        }

        //events - per workspace on current sunrise

        this.meetings.length = 0

        w.events.forEach(e => {

            const model = MeetingRmodel.instance(e, this)

            this.meetings.push(model)
        })

        /*
        w.persons.forEach(p => {

            const person = this.person_nid(p.nid)

            person.events.length = 0

            p.events.forEach(e => {

                const event_person = this.eventpInstance(e)

                if (event_person) person.events.push(event_person)
                else sys.warn(`Eventw was not found: ${e.appointment} ${e.timepoint}`)
            })
        })
        */

        this.updateCurrentEfforts(new Date())
    }


    updateCurrentEfforts(p: Date) { //'timepoint'

        this.persons().forEach(x => x.updateCurrentEfforts(p))
    }

    // eventpInstance(e: IEventpDto) {

    //     const eventw = singleOrNull(this.events, x => x.isEventOf(e))

    //     if (eventw != null) {

    //         const event_person = new EventpModel(eventw, e.uid)
    //         event_person.timespan = e.timespan

    //         return event_person;

    //     }
    //     else return null; // sys.warn(`Eventw was not found: ${e.appointment} ${timepoint_date}`)

    //     //array this.events


    // }

    setupCalendar(c: ICalendarDto) {


        const calendar = new ResourceCalendar(c.dates);
        calendar.label = c.label;
        calendar.id = c.id;

        this.index.calendars.set(c.id, calendar)
    }

    ticketOrNull(n: number): TicketRmodel | null {

        if (!this.index.tickets.has(n)) return null;

        return this.index.tickets.get(n) as TicketRmodel;
    }



    ticket_GetAndUpdateIf(n: number, context: AppContext): Promise<TicketRmodel | null> {

        //console.log(`ticket_GetAndUpdateIf - n:${n}`) //#dev

        return context.service.ticketOrNull(this.wid, n)

            .then(ticket_dto => {

                if (ticket_dto == null) {

                    sys.error(`There is no ticket with n: ${n}`)

                    return null;
                }

                //if (this.index.tickets.has(n)) return Promise.resolve(this.index.tickets.get(n)!)
                if (this.index.tickets.has(n)) {
                    const ticket = this.index.tickets.get(n)!

                    ticket.setup(ticket_dto, this)

                    return ticket
                }
                else {
                    const ticket = this.ticketNew(ticket_dto.name, ticket_dto.n); //#todo check whether PID (Project ID is required here)

                    ticket.setup(ticket_dto, this)



                    return ticket
                }
            })
        //.catch(sys.exception)
    }

    //ticket_GetIf(n: number, context: AppContext): Promise<TicketRmodel | null> {
    ticket_billet(n: number, service: IService): TicketRmodel {


        if (this.index.tickets.has(n)) return this.index.tickets.get(n)!

        else {
            const ticket = this.ticketNew('', n); //#todo check whether PID (Project ID is required here)

            service.ticketOrNull(this.wid, n)

                .then(ticket_dto => {

                    //if (this.index.tickets.has(n)) return Promise.resolve(this.index.tickets.get(n)!)
                    if (this.index.tickets.has(n)) {

                        if (ticket_dto == null) {

                            sys.error(`There is no ticket with n: ${n}`)

                            return null;
                        }

                        const ticket = this.index.tickets.get(n)!

                        ticket.setup(ticket_dto, this)

                        return ticket
                    }
                    else {
                        //return this.index.tickets.get(n)!

                        if (ticket_dto == null) {

                            sys.error(`There is no ticket with n: ${n}`)

                            return null;
                        }

                        const ticket = this.ticketNew(ticket_dto.name, ticket_dto.n); //#todo check whether PID (Project ID is required here)

                        ticket.setup(ticket_dto, this)

                        //this.index.tickets.set(ticket.n, ticket);

                        return ticket
                    }
                })
                .catch(r => sys.exception(r))

            return ticket
        }
    }

    ticket(n: number): TicketRmodel {

        const ticket = this.ticketOrNull(n);

        if (ticket === null) throw new Error(`/app/workspace - Can't find ticket by n: ${n}`);

        return ticket;
    }

    ticketOrBillet(n: number): TicketRmodel {

        const ticket = this.ticketOrNull(n);

        if (ticket === null) return this.ticket_billet(n, this.service)

        return ticket;
    }

    entity(b: IEntityRefDto): TicketRmodel | TaskRmodel | BlockRmodel | VerificationModel | null {

        const ticket = this.ticket(b.n);

        if (b.person) {

            if (b.type == 'T') {
                const task = ticket.task(b.person)

                return task
            }
            else {
                if (b.person_aux == null) throw Error('/workspace/tickets/tasks/verification - Verification {n,nid} has null "nid"')

                const task = ticket.task(b.person_aux!)

                switch (b.type) {

                    case 'B': return task.block(b.person) //.resolution = toResolution(b.resolution)
                    case 'V': return task.verification(b.person) //.resolution = toResolution(b.resolution)
                    default: return null
                }
            }
        }
        else return ticket; //.resolution = toResolution(b.resolution) 
    }

    process(name: string): ProcessRmodel { return this.index.processes.get(name) as ProcessRmodel; }

    groupOrNull(g: Guid) {

        return this.index.groups.get(g) ?? null
    }

    tickets(refs: IEntityRefDto[]): TicketRmodel[] {

        const result: TicketRmodel[] = [];

        refs.forEach(x => { if (x.type === null) result.push(this.ticket(x.n)) })

        return result;
    }

    todosSparsedAsModels(ids: (IEntityRefDto | null)[], person: PersonRmodel): (TodoRmodel | null)[] {
        return this.todosSparsed(ids, person.nid).map(x => { return x != null ? new TodoRmodel(x/*, person*/) : null });
    }

    //means tasks or blocks/verifications
    todosSparsed(ids: (IEntityRefDto | null)[], person: Guid): (TaskRmodel | BlockRmodel | VerificationModel | null)[] {

        const buffer: (TaskRmodel | BlockRmodel | VerificationModel | null)[] = [];

        ids.forEach(id => {

            if (id === null) {
                buffer.push(null);
            }
            //task
            else if (id.type === 'T') {

                buffer.push(this.task(id.n, person))
            }
            else if (id.type === 'B') {

                buffer.push(this.block(id.n, id.person_aux!, person))
            }
            else if (id.type === 'V') {
                buffer.push(this.verification(id.n, id.person_aux!, person));
            }

        });

        return buffer;
    }

    block(n: number, hoster: Guid, blocker: Guid | null): BlockRmodel {

        const ticket = this.index.tickets.get(n)!

        const task = ticket.task(hoster)

        //const person = blocker != null ? this.person_nid(blocker) : null

        return task.block(blocker /*person?.nid ?? null*/)
    }

    verification(n: number, hoster: Guid, verifier: Guid): VerificationModel {

        const ticket = this.index.tickets.get(n)!

        const task = ticket.task(hoster)

        const person = this.person_nid(verifier)

        return task.verification(person)
    }

    //means tasks or blocks/verifications
    todos(ids: IEntityRefDto[], person: Guid): (TaskRmodel | BlockRmodel | VerificationModel)[] {

        const buffer: (TaskRmodel | BlockRmodel | VerificationModel)[] = [];

        this.todosSparsed(ids, person).forEach(x => { if (x != null) buffer.push(x) });

        return buffer;
    }

    task(n: number, person: Guid): TaskRmodel {

        const buffer = this.taskOrNull(n, person);

        if (buffer === null) throw `app/workspace - Can't find task ${person}:${n}`;

        return buffer;
    }

    //search among disposition or among bas

    taskOrNull(n: number, person: Guid): TaskRmodel | null {

        let buffer: TaskRmodel | null = null;

        const person_object = this.person_nid(person);

        this.index.tickets.forEach(t => {

            if (t.n === n) {

                t.tasks.forEach(task => {

                    if (task.person === person_object) {
                        buffer = task;
                    }
                });
            }

        });

        return buffer;
    }

    //task should be in person todo items
    taskOrNull_Disposition(n: number, person: Guid): TaskRmodel | null {

        const person_target = this.person_nid(person);

        if (person_target.current?.isTask && person_target.current?.task.person == person_target)
            return person_target.current.task;

        function f(items: TodoRmodel[]) {

            return items.filter(t => t.isTask() && t.task.person == person_target).map(x => x.task);
        }

        let buffer;

        buffer = f(person_target.pins);

        if (buffer.length > 0) return buffer[0];

        buffer = f(person_target.queue);

        if (buffer.length > 0) return buffer[0];

        buffer = f(person_target.desk);

        if (buffer.length > 0) return buffer[0];

        return null;
    }


    projectOrNull(pid: Guid): ProjectModel | null {

        if (this.index.projects.has(pid)) return this.index.projects.get(pid) as ProjectModel;

        return null;
    }

    project(pid: Guid): ProjectModel {

        const project = this.projectOrNull(pid);

        if (project === null) throw new Error(`/app/workspace - Can't find project ${pid}`);

        return project;
    }

    project_default(): ProjectModel {

        for (const [key, value] of this.index.projects) {

            return value;
        }

        throw Error('/workspace There is no any project at workspace')

    }

    projects(exlusion?: ProjectModel): ProjectModel[] {

        const buffer: ProjectModel[] = []

        for (const [key, value] of this.index.projects) {

            if (typeof exlusion != "undefined") {

                if (exlusion === value) continue; //skiping the project that indicated as 'exlusion'
            }

            buffer.push(value);
        }

        // const wid = this.wid;

        // //'title' project always goes at the very first record
        // function compare(a: ProjectModel, b: ProjectModel): number {

        //     if (a.pid === wid) return 1;
        //     if (b.pid === wid) return -1;

        //     if (a.name < b.name) {
        //         return -1;
        //     }
        //     if (a.name > b.name) {
        //         return 1;
        //     }
        //     return 0;
        // }

        // buffer.sort(compare);

        return buffer;
    }

    calendars() {

        const buffer = []

        for (const [key, value] of this.index.calendars) {

            buffer.push(value);
        }

        return buffer;
    }

    persons(exclude?: PersonRmodel): PersonRmodel[] {

        const buffer: PersonRmodel[] = [];

        for (const [key, value] of this.index.persons) {

            if (typeof exclude != "undefined") {

                //ignore person
                if (exclude === value) continue;
            }

            buffer.push(value);
        }

        const me = this.user_person;

        //'me' always goes at the very first record
        function compare(a: PersonRmodel, b: PersonRmodel): number {

            return personSorter(a, b, me)
        }

        buffer.sort(compare);

        return buffer;
    }

    groups(exclude?: GroupRmodel): GroupRmodel[] {

        const buffer: GroupRmodel[] = [];

        for (const [key, value] of this.index.groups) {

            if (typeof exclude != "undefined") {

                //ignore person
                if (exclude === value) continue;
            }

            buffer.push(value);
        }


        function compare(a: GroupRmodel, b: GroupRmodel): number {

            return 0;

        }

        buffer.sort(compare);

        return buffer;
    }

    person_nid(nid: Guid): PersonRmodel {


        const result = this.personOrNull_nid(nid);

        if (result == null) throw Error(`/app/workspace - Can't find person with nid ${nid}`)

        return result!;
    }

    groupOrBillet(uid: Guid): GroupRmodel {

        const workspace = this

        const group = workspace.groupOrNull(uid)

        if (group != null) return group
        else 
        {
            const group_newbie = new GroupRmodel()

            group_newbie.uid = uid

            group_newbie.name = '?'

            workspace.index.groups.set(uid, group_newbie)

            sys.warn(`Group ${uid} was missed`)

            return group_newbie
        }
    }


    personOrBillet(nid: Guid): PersonRmodel {

        const person = this.personOrNull_nid(nid)

        if (person != null) return person
        else {
            const person_newbie = new PersonRmodel('?', nid, this)

            this.index.persons.set(nid.toString(), person_newbie)

            //objects.persons.push(person_newbie)

            sys.warn(`Person ${nid} was missed`)

            return person_newbie
        }

    }

    personOrNull_nid(nid: Guid): PersonRmodel | null {

        const buffer: PersonRmodel[] = [];

        for (const [k, p] of this.index.persons) {

            if (p.nid === nid) buffer.push(p);
        }

        if (buffer.length === 0) return null;
        if (buffer.length != 1) throw Error(`/app/workspace - Multiple persons with ${nid}`)

        return buffer[0];
    }

    personSetup(dto: IPersonDto): PersonRmodel {

        if (!dto.name) throw new Error(`/app/workspace - Person dto 'name' valule can't be empty ${dto}`);

        const buffer = this.personOrNull_nid(dto.nid);

        if (buffer === null) {

            const personModel: PersonRmodel = reactive(new PersonRmodel(dto.name, dto.nid, this));

            personModel.strict = dto.strict
            personModel.captain = dto.captain
            personModel.subordinate = dto.subordinate
            personModel.stopped = dto.stopped
            personModel.autonomus = dto.autonomus
            personModel.user = dto.user


            personModel.categories.clear()

            dto.categories.forEach(c => personModel.categories.add(c))


            this.index.persons.set(dto.name, personModel);

            return personModel;
        }
        else {
            //todo upate person model.name = p.name;
            return buffer;
        }
    }

    groupSetup(dto: IGroupDto): void {

        //const groupModel = new GroupRmodel();

        let groupModel = this.index.groups.get(dto.uid)

        if (groupModel == undefined) {

            groupModel = new GroupRmodel();
            this.index.groups.set(dto.uid, groupModel)
        }

        groupModel.name = dto.name;
        groupModel.uid = dto.uid

        if (dto.members) {

            groupModel.members.length = 0

            dto.members.forEach(m => {

                const person = this.person_nid(m) as PersonRmodel;

                groupModel!.members.push(person);
            });
        }
    }

    processSetup(dtor: IProcessDto): void {

        const object = new ProcessRmodel();

        object.name = dtor.name;

        this.index.processes.set(object.name, object);

        if (dtor.phases) {
            dtor.phases.forEach(phaseDto => {

                const group = this.index.groups.get(phaseDto.group) as GroupRmodel;

                const phase = new PhaseRmodel(group, object);

                object.phases.push(phase);
            });
        }
    }

    labelSetup(dto: ILabelDto): void {
        const object = new LabelModel();

        object.name = dto.name;



        this.index.labels.set(object.name, object);

        if (dto.process != null) {
            object.process = this.index.processes.get(dto.process) as ProcessRmodel;
        }
    }

    projectSetup(dto: IProjectDto): void {

        let object = this.index.projects.get(dto.pid)

        if (object == undefined) {

            object = new ProjectModel(this);

            this.index.projects.set(dto.pid, object);
        }

        object.name = dto.name;
        object.pid = dto.pid;

        //this.index.projects.set(object.pid, object);
    }

    ticketNew_Empty(n?: number, pid?: Guid | null): TicketRmodel {

        return this.ticketNew('', n, pid)
    }

    ticketNew(title: string, n?: number, pid?: Guid | null): TicketRmodel {

        const model = reactive(new TicketRmodel(this));

        model.name = title;

        if (typeof n != "undefined") {
            model.n = n;
        }

        if (typeof pid != "undefined" && pid != null) model.project = this.project(pid);

        this.index.tickets.set(model.n, model);

        return model;
    }


    ticketSetup(dto: ITicketDto): TicketRmodel {

        let model: TicketRmodel;

        //update logic
        if (this.index.tickets.has(dto.n)) {

            model = this.index.tickets.get(dto.n)!;

            model!.setup(dto, this);
        }
        else {

            model = reactive(new TicketRmodel(this));

            this.index.tickets.set(dto.n, model);

            model.setup(dto, this);
        }

        return model;
    }
}