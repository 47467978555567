import { EntityDeleteDto, EntityUpdateDto } from "@/io/storages/workspace/EntityOperation"
import { Resolution } from "@/logic/workspaces/tickets/_def"
import { TodoRmodel } from "../todo.rmodel"

import * as taskProgramator from '@/app.context/workspace/tickets/tasks/_spec/instruction.calc.task'
import { VerificationModel } from "../tasks/verification.rmodel"
import { BlockRmodel } from "../tasks/block.rmodel"
import { EntityRef } from "@/logic/workspaces/_tech"

export function resolve(todo: TodoRmodel, resolution: Resolution | null) {

    const instruction: EntityUpdateDto[] = []

    if (todo.isBlock()) {

        instruction.push(resolve_block(todo.task.block(todo.person.nid), resolution))
    }

    if (todo.isVerification()) {
        
        instruction.push(resolve_verification(todo.task.verification(todo.person.nid), resolution))
    }

    if (todo.isTask()) {

        instruction.push(taskProgramator.update_workable(todo.task, false) /*todo.task.update_workable(false)*/)

        instruction.push(taskProgramator.update_resolution(todo.task, resolution))
    }

    return instruction
}



function resolve_verification(verification: VerificationModel, r: Resolution | null): EntityUpdateDto {
    
    const insturction = taskProgramator.updateOperation_Derivative(verification.task, r, 'V', verification.person)

    //this.resolution = r

    return insturction
}

function resolve_block(block: BlockRmodel, r: Resolution | null): EntityUpdateDto {
    
    const insturction = taskProgramator.updateOperation_Derivative(block.task, r, 'B', block.person)

    //this.resolution = r

    return insturction
}

export function del(todo: TodoRmodel) : EntityDeleteDto 
{

    const ref = new EntityRef(todo.task.ticket.n, todo.type())
    ref.person_aux = todo.auxPerson()
    ref.person = todo.person.nid
    const dto = new EntityDeleteDto(ref)

    return dto;
}