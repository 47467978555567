import AppContext from "@/context";
import { IUserDto } from "@/io/dto/user";


export default function(context:AppContext){

    const controller = {

        userSetup: (dto: IUserDto) => context.model.setup(dto),
    }

    return controller;
}

export class UserAppContextController{

    constructor(context:AppContext) {
        
        this.context = context
        
    }

    context:AppContext

    userSetup(dto: IUserDto) 
    { 
        this.context.model.setup(dto) 
    }
}