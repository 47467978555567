import { Resolution } from "@/logic/workspaces/tickets/_def";
import { TaskRmodel } from "../task.rmodel";
import { PersonRmodel } from "../../person.rmodel";

export class BlockRmodel {
    
    resolution: Resolution | null = null
    adhoc = false
    task:TaskRmodel
    person: PersonRmodel | null = null;

    constructor(task:TaskRmodel) {
        
        this.task = task;
    }
}

export function blockResolutionString(r:Resolution){

    if (r == Resolution.Done) return 'block:resolved'
    if (r == Resolution.Failed) return 'block:failed'

    return 'Unknown'
}