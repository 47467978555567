import AppContext from "@/context"
import { TicketAccessLevel } from "@/controllers/workspaces/user.controller.workspace"
import { AppConfig, computed } from "vue"

import { level } from '@/controllers/workspaces/user.controller.workspace';


export default function f(context:AppContext){

    const r = 
    {
        user_level: computed(() => level(context))
    };

    return  r
}