import { TicketRmodel } from '@/app.context/workspace/ticket.rmodel';

import { TimeSegment, minuteCalc } from '@/logic/_infra/time';



import sys from '@/sys';
import { Timespending } from '../../Event';
import { EffortType, OffhourType } from '@/logic/workspaces/persons/_def';
import dayjs from 'dayjs';
import { Guid } from '@/lib';
import { absDiffInMs } from '@/lib/date';
import { SegmentEnd } from '@/logic/_infra/_tech';
import { TodoDerivativeRef, TodoRef } from '@/logic/workspaces/_tech';
import { IExactMeffort, IMeffortDto, ISimpleMeffort } from '@/io/dto/workspaces/persons/effort.dto';
import { PersonRmodel } from '../../person.rmodel';
import { IService } from '@/io';
import { toTimeSegment } from '@/io/dto/infrastructure.dto.ns';
import { StatusSummary } from '../sunrise.rmodel';
import { IResolution, OccupationtRef } from '@/logic/workspaces/tickets/_def'
import { WorkspaceRmodel } from '@/app.context/workspace.rmodel';



export class EffortRmodel {

    //assumes timefforts are ordered correctly by timeline

    static status_resolution(timefforts: Timeffort[]) { //assumes completed tuned timefforts

        const map = new Map<string, ({ resolution: IResolution | null, time: Date })[]>()

        EffortRmodel.status_resolutions_writeTo(timefforts, map)

        return map
    }

    static status_resolutions_writeTo(timefforts: Timeffort[], map: Map<string, ({ resolution: IResolution | null, time: Date })[]>): void {

        map.clear()

        timefforts.forEach(t => {

            if (t.todo != null) {

                //t.todo.json()

                const id = t.todo.identificator()

                if (!map.has(id)) map.set(id, [])

                const resolutions = map.get(t.todo.identificator())!

                resolutions.push({ resolution: t.endex ? t.resolution : null, time: t.end })
            }
        })
    }

    static status_v2_writeTo
    (
        timeline: EffortRmodel[], 
        result: { 
            totals: StatusSummary, 
            activity: StatusSummary, 
            unallocated: number, 
            overlaps: StatusSummary 

    }): void {

        result.unallocated = 0

        result.totals.reset()
        result.overlaps.reset()

        timeline.forEach(e => {

            const minutes = e.segment_value.timespan_min_floor

            if (e.timespendings.length > 0) {

                const buffer = TimeSegment.normalize_Ext(e.timespendings.map(x => { return { segment: x.segment, payload: x.ref } }))

                buffer.winners.forEach(x => result.totals.increment(x.payload.objects, x.segment.timespan_min_floor))

                buffer.winners.forEach(x => result.activity.updateIfGreater(x.payload.objects, x.segment.end().getDate())) //end of timespending

                buffer.underdogs.forEach(x => result.overlaps.increment(x.payload.objects, x.segment.timespan_min_floor))
            }
            else if (e.meffort != null) {

                result.totals.increment(e.meffort.objects, minutes)

                result.activity.updateIfGreater(e.meffort.objects, e.end.getDate())

            }
            else if (e.timeffort != null) {

                const t = e.timeffort

                // t.effortType

                // const ref = TodoRmodel.ticketOrTodoRef({ ticket: t.objects.ticket ?? undefined, todo: t.objects.todo ?? undefined })

                // if (ref.ticket != 0) {

                //     if (TodoDerivativeRef.IsDerivative(ref)){

                //         const ref_d = Todo.derivartifeRef(ref) //'referene/derivative'

                //         result.totals.incrementDerivative(ref_d, minutes)

                //         result.activity.updateDerivativeIfGreater(ref_d, e.end.getDate())

                //     }
                //     else if (ref.type == 'T'){

                //         result.totals.incrementTask(ref.ticket, minutes)
                //         result.activity.updateTaskIfGreater(ref.ticket, e.end.getDate())
                //     }
                //     else 
                //     {
                //         result.totals.incrementTicket(ref.ticket, minutes)
                //         result.activity.updateTicketIfGreater(ref.ticket, e.end.getDate())
                //     }

                // }
                // else 
                
                if (t.todo != null) {

                    if (TodoDerivativeRef.IsDerivative(t.todo.type)){

                        const r = TodoDerivativeRef.ref_TodoRef(t.todo)

                        result.totals.incrementDerivative(r, minutes)
                        result.activity.updateDerivativeIfGreater(r, e.end.getDate())
                    }
                    else if (t.todo.type == 'T') {

                        result.totals.incrementTask(t.todo.n, minutes)
                        result.activity.updateTaskIfGreater(t.todo.n, e.end.getDate())
                    }
                    else 
                    {
                        result.totals.incrementTicket(t.todo.n, minutes)
                        result.activity.updateTicketIfGreater(t.todo.n, e.end.getDate())
                    }


                    //warf if does not map to ticket_n #todo9
                }
                else if (t.ticket_n != 0){

                    if (t.ticket_type == 'T'){

                        result.totals.incrementTask(t.ticket_n, minutes)
                        result.activity.updateTaskIfGreater(t.ticket_n, e.end.getDate())
                    }
                    else 
                    {
                        result.totals.incrementTicket(t.ticket_n, minutes)
                        result.activity.updateTicketIfGreater(t.ticket_n, e.end.getDate())
                    }
                }
                else if (t.effortType != null){

                    if (t.effortType == 'A' || t.effortType == 'F') {

                        result.unallocated += minutes
                    }

                    if (t.effortType == 'I') {

                        //result.totals.idle += minutes

                        result.totals.incrementIdle(minutes)

                        result.activity.updateIdleIfGreater(e.end.getDate())

                        //const timepoint = e.end.getDate()

                        //if (ti) result.activity.idle
                    }
                    if (t.effortType == 'S') {

                        //result.totals.forcemajour += minutes

                        result.totals.incrementForcemajour(minutes)

                        result.activity.updateForcemajourIfGreater(e.end.getDate())
                    }
                }
                else sys.warn('Cant calc timeffort span for status')
            }
        })
    }

    static status_v2(timeline: EffortRmodel[]): { totals: StatusSummary, activity: StatusSummary, unallocated: number, overlaps: StatusSummary } {

        const unallocated: number = 0

        const totals = new StatusSummary()

        const activity = new StatusSummary()

        const overlaps = new StatusSummary()

        const result = { totals, activity, unallocated, overlaps }

        this.status_v2_writeTo(timeline, result)

        return result


    }

    static meffort(payload: ExactMeffortRmodel, s: TimeSegment): EffortRmodel {

        //throw new Error('Method not implemented.');

        const effort = new EffortRmodel()

        //effort.effortType = "F"

        effort.segment_value = s.clone()

        effort.meffort = payload



        return effort
    }

    static Comparator_End_Desc(a: EffortRmodel, b: EffortRmodel) {

        const a_e = a.segment_value.end().getTime()
        const b_e = b.segment_value.end().getTime()

        if (a_e == b_e) {

            const a_s = a.segment_value.timepoint.getTime()
            const b_s = b.segment_value.timepoint.getTime()

            return b_s - a_s

        }
        else return b_e - a_e
    }

    static clusterize(set: EffortRmodel[]): { efforts: EffortRmodel[], start: Date, end: Date }[] {
        if ((set?.length ?? 0) == 0) throw Error("Argument: set is null or empty")

        //const d = new Date()

        set.sort(EffortRmodel.Comparator_End_Desc)

        const result: { efforts: EffortRmodel[], start: Date, end: Date }[] = []

        let buffer: { efforts: EffortRmodel[], start: Date, end: Date } = { efforts: [set[0]], start: set[0].start, end: set[0].end }

        result.push(buffer)

        for (let i = 1; i < set.length; i++) {

            const element = set[i];

            if (buffer.end > element.start) {

                buffer.efforts.push(element)

                if (buffer.start > element.start) buffer.start = element.start
                if (buffer.end < element.end) buffer.end = element.end
            }
            else {
                buffer = { efforts: [element], start: element.start, end: element.end }
            }
        }

        return result
    }

    static timespendings(timespendings: Timespending[]) {

        const result = new EffortRmodel()

        timespendings.forEach(t => result.timespendings.push({ ref: t, segment: t.segment }))


        timespendings.forEach(t => {



            if (result.start > t.segment.timepoint) result.segment_value.setStart(t.segment.timepoint)
            if (result.end < t.segment.end()) result.segment_value.setEnd(t.segment.end())
        })

        return result
    }

    static timeffort(t: Timeffort, s: TimeSegment) {

        const effort = new EffortRmodel()

        effort.segment_value = s.clone()

        effort.timeffort = t

        if (!t.isPaused && t.todo != null && t.end.getTime() == s.end().getTime()) {

            effort.resolution = t.resolution
        }

        return effort
    }

    static timespending(t: Timespending, s: TimeSegment) {

        const result = new EffortRmodel()

        result.segment_value = s.clone()

        result.timespendings.push({ ref: t, segment: t.segment.clone() })

        return result
    }

    static idle(s: TimeSegment) {

        const result = new EffortRmodel()



        result.segment_value = s.clone()

        result.timeffort = new Timeffort()

        result.timeffort.effortType = 'I'

        return result
    }

    static lunch(s: TimeSegment) {

        const result = new EffortRmodel()

        result.offhour = "L"

        result.segment_value = s.clone()

        return result
    }

    // STRUCTURE //

    segment_value: TimeSegment = new TimeSegment(new Date()) //#rn to 'segment'

    get start(): Date {

        return this.segment_value.timepoint
    }

    get end(): Date {

        return this.segment_value.end()
    }

    get span_ms(): number {

        return this.segment_value.timespan_ms
    }

    get span_min(): number {

        return this.span_ms / 60000
    }
    

    title: string | null = null

    resolution: IResolution | null = null

    offhour: OffhourType | null = null

    timespendings: { segment: TimeSegment, ref: Timespending }[] = [] //'endeavoured' | 'effort' timesegment should match min/max from timespending (cached value)

    timeffort: Timeffort | null = null

    meffort: ExactMeffortRmodel | null = null

    timefforts_zeros: Map<Date, Timeffort> = new Map()

    //showTimeAndSpan = true

    // ALGS //

    timefforts_zeros_count() {

        let buffer = 0;

        this.timefforts_zeros.forEach(x => buffer++)

        return buffer
    }

    console() {

        const x = this

        function listAll(prefix: string) {

            if (x.timeffort != null) console.log(prefix + x.timeffort.toString_segment(x.segment_value))
            x.timefforts_zeros.forEach((e, key) => console.log(prefix + e.toString_timepont(key)))
            x.timespendings.forEach(x => console.log(prefix + x.ref.toString_segment(x.segment)))
        }

        function c1() {

            const timeString = x.segment_value.toString()

            let description = "[unknown effortType]"


            let lineCounter = 0

            

            let endeavourTypeString = "//Effort"

            if (x.offhour == "L") endeavourTypeString = "//Lunch"
            else if (x.offhour == "T") endeavourTypeString = "//Twitlight"


            const headerString = `${timeString} ${endeavourTypeString}`

            if (x.timeffort != null) lineCounter++;

            x.timefforts_zeros.forEach(x => lineCounter++)

            x.timespendings.forEach(x => lineCounter++)


            if (x.timeffort != null) {



                if (x.timeffort.effortType != null) {

                    const sp = x.timeffort.effortType

                    if (sp == 'A') description = 'Autopilot'
                    if (sp == 'S') description = 'X-Stop'
                    if (sp == 'F') description = 'Flex'
                    if (sp == "I") description = 'IDLE'

                    console.log(`${timeString} ${description}`)

                    if (sp != null && lineCounter != 1) console.warn("(x.effortType != null && lineCounter != 1)")
                    if (x.offhour != null) console.warn('offhour type set for special efforType')

                }
                else if (lineCounter == 1) {

                    const e = x.timeffort
                    console.log(`${timeString}  ${e.toString_segment(x.segment_value)} ${endeavourTypeString}`)
                }
                else console.warn('corrupted 120620262219')
            }
            else {
                if (lineCounter > 1) {

                    console.log(headerString)

                    listAll("  ")
                }
                else if (lineCounter == 1) {

                    if (x.timespendings.length == 1) {
                        const s = x.timespendings[0]

                        console.log(`${timeString}  ${s.ref.toString_segment(s.segment)} ${endeavourTypeString}`)
                    }
                    else {

                        const buffer_keys: Date[] = []

                        x.timefforts_zeros.forEach((x, key) => buffer_keys.push(key))

                        if (buffer_keys.length == 1) {

                            const p = buffer_keys[0]

                            const s = x.timefforts_zeros.get(p)!

                            console.log(`${timeString}  ${s.toString_timepont(p)} ${endeavourTypeString}`)
                        }
                        else console.warn('if (buffer_keys.length == 1){')

                    }








                }
                else {
                    console.log(headerString)
                }
            }

            /////////////////////////

            // if (x.effortType != null) {




            //     if (x.effortType == 'A') description = 'Autopilot'
            //     if (x.effortType == 'S') description = 'X-Stop'
            //     if (x.effortType == 'F') description = 'Flex'
            //     if (x.effortType == "I") description = 'IDLE'

            //     console.log(`${timeString} ${description}`)

            //     if (x.effortType != null && lineCounter > 0) console.warn("(x.effortType != null && lineCounter > 0)")
            //     if (x.endeavourType != 'E') console.warn('endeavourType is not E for special efforType')
            // }
            // else {

            //     if (lineCounter > 1) {

            //         console.log(headerString)

            //         listAll("  ")
            //     }
            //     else if (lineCounter == 1) {

            //         if (x.timeffort != null) {

            //             const e = x.timeffort
            //             console.log(`${timeString}  ${e.toString_segment(x.segment())} ${endeavourTypeString}`)
            //         }
            //         else if (x.timespendings.length == 1) {
            //             const s = x.timespendings[0]

            //             console.log(`${timeString}  ${s.ref.toString_segment(s.segment)} ${endeavourTypeString}`)
            //         }
            //         else {

            //             const buffer_keys: Date[] = []

            //             x.timefforts_zeros.forEach((x, key) => buffer_keys.push(key))

            //             if (buffer_keys.length == 1) {

            //                 const p = buffer_keys[0]

            //                 const s = x.timefforts_zeros.get(p)!

            //                 console.log(`${timeString}  ${s.toString_timepont(p)} ${endeavourTypeString}`)
            //             }
            //             else console.warn('if (buffer_keys.length == 1){')

            //         }








            //     }
            //     else {
            //         console.log(headerString)
            //     }
            // }





        }

        c1()
    }

    catchTimefforts(timefforts_tuned: { segment: TimeSegment, effort: Timeffort }[]) {

        const effort = this

        const effort_segment = effort.segment_value

        timefforts_tuned.forEach(e => {

            const timepoint = e.segment.end()

            if (effort_segment.contains(timepoint)) {

                effort.timefforts_zeros.set(timepoint, e.effort)
            }
        }
        )
    }

    catchTimespendings(timespendings: Timespending[]) {

        const segment = new TimeSegment(this.start, this.span_ms)

        const timespendings_wrap = timespendings.map(function (x) { return { segment: x.segment, payload: x } })

        const intersection = TimeSegment.intersectTimeSegmentsWithPayload(timespendings_wrap, [segment])

        const effort = this

        intersection.forEach(i => {

            effort.timespendings.push({ segment: i.segment, ref: i.payload })
        })
    }


    

    spanString() {

        return minuteCalc.toAproximatedHoursString_Ms(this.span_ms)
    }

    //end of operation in case of time span
    timeString() {

        return sys.time.format(this.end);
    }

    timeString_Start() {

        if (this.span_ms == 0) return this.timeString()

        return sys.time.format(this.start)
    }



    isMultiItem() {

        return this.timeffort == null && this.isTimespending_Multiple()
    }

    isTimespending_Multiple() { return this.timespendings.length > 1 }

    isTimespending_Single() { return this.timespendings.length === 1 }

    timespendingOrNull() {
        if (this.isTimespending_Single()) return this.timespendings[0].ref

        else return null
    }

    isTimespending() { return this.timespendings.length > 0 }

    isEmpty() { return !this.isSpecial() && !this.isTicket() && !this.isTimespending() }

    isTicket() { return (this.timeffort?.ticket_n ?? 0) != 0 }

    isSpecial() { return this.timespendings.length == 0 && (this.timeffort?.ticket_n ?? 0) == 0 }

    size(): number {

        if (this.span_ms == 0) return 0;

        if (this.span_ms <= 15 * 60000) return 1; //xs

        if (this.span_ms <= 60 * 60000) return 2; //s

        if (this.span_ms <= 120 * 60000) return 3;

        if (this.span_ms <= 240 * 60000) return 4;

        return 5;
    }

    toString() {

        let description = "?"

        const x = this

        if (x.isSpecial()) {

            description = "?"

            if (x.timeffort != null) {

                const t = x.timeffort

                if (t.effortType == 'A') description = 'Autopilot'
                if (t.effortType == 'S') description = 'X-Stop'
                if (t.effortType == 'F') description = 'Flex'
                if (t.effortType == "I") description = 'IDLE'
            }

            //if (base.efforts.map(item => item.effort).indexOf(x.effort) < 0) description += '*' //tune-based idle, not from server side
        }
        else if (x.isTimespending()) description = "Timespanding"
        else if (x.isTicket()) description = `Ticket ${x.timeffort!.ticket_n} - ${x.timeffort?.objects?.ticket?.name ?? 'n/a'}`

        const FORMAT = 'HH:mm'

        return `${dayjs(x.start).format(FORMAT)} - ${dayjs(x.start.cloneAndAddMilliseconds(x.span_ms)).format(FORMAT)} ${description}`
    }
}





export class Meffort /*implements IMeffortDto*/ {

    static Filter<T extends Meffort>(array: T[], r:OccupationtRef  /*ticket: TicketRmodel | null, todo: TodoRmodel | null*//*, category: string | null*/) {


        return array.filter(x=>x.match(r))

        

    }

    // STRUCTURES //


    partial = false // {partial, persisted} - create tech state object?

    //--

    mid: Guid = Guid.newGuid()

    //--

    //name: string | null = null //'text' of notice or comment on metting , etc.

    get name():string{

        return this.objects.title
    }

    //ticket: number = 0

    get ticket() {

        return this.objects?.ticket?.n ?? 0
    }

    //type: TodoItemType | null = null

    get type(){

        return this.objects.todo_type
    }

    //person_aux: Guid | null = null

    get person_aux() {

        return this.objects.todo_person_aux
    }

    //category: string | null = null

    get category(){

        return this.objects.category
    }

    //persons: Guid[] = []

    get persons(){

        return this.objects.persons
    }


    get groups(){

        return this.objects.groups
    }
    //groups: Guid[] = []

    addGroups(uids:Guid[], workspace:WorkspaceRmodel){

        uids.forEach(u=>this.addGroup(u, workspace))
    }

    addGroup(uid:Guid, workspace:WorkspaceRmodel){

        this.objects.addGroup(uid, workspace)
    }

    addPerson(nid:Guid, workspace: WorkspaceRmodel){

        this.objects.addPerson(nid, workspace) 
    }

    addPersons(nids:Guid[], workspace: WorkspaceRmodel){

        nids.forEach(nid=>this.addPerson(nid, workspace))
    }

    /**should read 'ref' or 'occupation' */
    objects: OccupationtRef = new OccupationtRef()



    // -- algs -- //

    dto(): IMeffortDto {

        const dto: IMeffortDto = {
            mid: this.mid,
            name: this.name,
            groups: this.groups,
            persons: this.persons,
            ticket: this.ticket,
            type: this.type,
            person_aux: this.person_aux,
            category: this.category
        }

        return dto
    }

    //match(n: number, type: TodoItemType | null, person_aux: Guid | null/*, category: string | null*/) {
    match(r:OccupationtRef) {

        // if (todo != null) {

        //     return array.filter(x => x.match(todo.ticket.n, todo.type(), todo.auxPerson()/*, category*/))
        // }
        // else if (ticket != null) {

        //     return array.filter(x => x.match(ticket.n, null, null/*, category*/))
        // }
        // else return (array.filter(x => x.match(0, null, null/*, category*/)))

        return this.objects.match(r)

        //return Todo.Match(this, n, type, person_aux/*, category*/)
    }

    set(dto: IMeffortDto, workspace:WorkspaceRmodel) {


        const m = this
        //const m = new SimpleMeffort()

        m.mid = dto.mid
        m.objects.category = dto.category
        m.objects.title = dto.name ?? ''
        m.groups.clear()
        m.addGroups(dto.groups, workspace)
        //m.groups.replaceWith(dto.groups.map(x=>x))
        m.persons.clear()
        m.addPersons(dto.persons, workspace)
        //m.persons.replaceWith(dto.persons)
        m.partial = true
        //m.minutes = dto.minutes
        if (dto.ticket != 0) m.objects.ticket =  workspace.ticketOrBillet(dto.ticket)
        m.objects.todo_person_aux = dto.person_aux

        m.objects.todo_type = dto.type
        //if (dto.todo != null) m.todo = toTodoRef(dto.todo)

        return m
    }





}

export class SimpleMeffort extends Meffort {


    static Total(mefforts: SimpleMeffort[]) {

        const result = new StatusSummary()

        mefforts.forEach(x => {

            result.increment(x.objects, x.minutes)
        })

        return result
    }


    static Instance(dto: ISimpleMeffort, person: PersonRmodel, service: IService) {

        const m = new SimpleMeffort()

        m.set(dto, person.workspace)

        m.minutes = dto.minutes

        //PersonRmodel.ObjectsSetup(m.objects, person, m, service)

        //PersonRmodel.ParticipantsSetup(m.objects, person.workspace, m.persons, m.groups, service)

        return m
    }

    minutes: number = 0

}

export class ExactMeffortRmodel extends Meffort {

    segment: TimeSegment = TimeSegment.Next()

    priority: number = 0

    //

    static Instance(dto: IExactMeffort, person: PersonRmodel, service: IService) {

        const m = new ExactMeffortRmodel()

        m.set(dto, person.workspace)

        //m.minutes = dto.minutes
        m.segment = toTimeSegment(dto.segment)

        //m.objects

        //PersonRmodel.ObjectsSetup(m.objects, person, m, service)

        //PersonRmodel.ParticipantsSetup(m.objects, person.workspace, m.persons, m.groups, service)

        return m
    }


    static Sort(a: ExactMeffortRmodel, b: ExactMeffortRmodel) {

        const s = a.segment.timepoint.getTime() - b.segment.timepoint.getTime() //'s' for 'start'

        if (s == 0) {

            const p = a.priority - b.priority //'p' for 'priority'

            if (p == 0) {

                const e = a.segment.end().getTime() - b.segment.end().getTime() //'e' for 'end'

                return e

            }
            else return p

        }
        else return s
    }

}




export class Timeffort {

    static SortByDateDesc(a: Timeffort, b: Timeffort) {

        return b.start.getDate() - a.start.getDate()
    }

    static SortByDateAsc(a: Timeffort, b: Timeffort) {

        return a.start.getDate() - b.start.getDate()
    }

    // STRUCTURE //

    //**Means that this record is part of bigger effor that was interuupted - by som other special segment, e.g. X-Stop */
    isPaused = false

    start: Date = new Date()
    end: Date = new Date()

    get span_ms(): number {

        const delta = absDiffInMs(this.start, this.end)

        if (this.end >= this.start) return delta; else return - delta
    }

    get span_min_floor(){

        return Math.floor(this.span_ms / 60000)
    }

    startex: boolean = false
    endex: boolean = false


    //-

    effortType: EffortType | null = null

    todo: TodoRef | null = null;

    get ticket_type() {

        if (this.todo != null) return this.todo.type

        return null
    }

    get ticket_n() {

        return this.todo?.n ?? 0
    }

    resolution: IResolution = { queued: null, resolved: null, blocked: null, focused: null }
    

    

    

    

    //todo is setup only when it's available
    objects: { ticket: TicketRmodel | null /*, todo: TodoRmodel | null*/ } = { ticket: null/*, todo: null*/ }

    // ALGS //

    segmentDiff(segment_copy: TimeSegment, segment: TimeSegment) {

        const effort = this

        const result: { timepoint: Date, edge: SegmentEnd, value: Date }[] = []

        if (segment_copy.timepoint != segment.timepoint) result.push({ timepoint: effort.start, edge: "S", value: segment_copy.timepoint })
        if (segment_copy.end() != segment.end()) result.push({ timepoint: effort.end, edge: "E", value: segment_copy.end() })

        return result
    }

    timeString() {

        return sys.time.format(this.end);
    }

    timeString_Start() {

        if (this.span_ms == 0) return this.timeString()

        return sys.time.format(this.start)
    }

    spanString() {

        return minuteCalc.toAproximatedHoursString_Ms(this.span_ms)
    }

    isWorkGrayTime() { return this.isSpecial() && (this.effortType == 'A' || this.effortType == 'F') }

    isSpecial() { return this.ticket_n == 0 }

    isTicket() { return this.ticket_n != 0 || this.todo != null } //#rn 'isTodo'


    toString() {

        return this.ticket_n.toString()
    }

    toString_segment(s: TimeSegment) {

        return `${s.toString()} ${this.toString()} [e]`
    }

    toString_timepont(p: Date) {

        return `${dayjs(p).format('HH:mm:ss')} ${this.toString()} [p]`
    }
}