import '@/lib/date.extensions'

export class DateExpression<T> {

    constructor(value: T) {
        this.value = value;
    }

    year: number | null = null;
    /**
     * 0-11 range
     */
    month: number | null = null;
    day: number | null = null;
    dayShift: number | null = null;

    /**
     * 0 - Sunday, 1 - Monday, 2 - TUE - 3, ...
     */
    dayOfWeek: number | null = null;
    /**
     * '-1' means 'last'
     */
    dayOfWeekNumber: number | null = null;


    //absolute: number | null = null

    //delta: number | null = null;

    value: T

    match(date: Date): boolean {

        const date_normalized = date.cloneAndAddDays(this.dayShift != null ? -1 * this.dayShift! : 0);

        if (this.year != null) if (this.year != date_normalized.getFullYear()) return false
        if (this.month != null) if (this.month != date_normalized.getMonth()) return false
        if (this.day != null) if (this.day != date_normalized.getDate()) return false;

        if (this.dayOfWeek != null) if (this.dayOfWeek != date_normalized.getDay()) return false;

        if (this.dayOfWeekNumber != null) {
            if (this.dayOfWeekNumber > 0) if (this.dayOfWeekNumber != date_normalized.dayOfWeekNumber()) return false;

            //last weekday of period - algorithm is just to add 7 days and see wether it will be another month
            if (this.dayOfWeekNumber == -1) if (date_normalized.cloneAndAddDays(7).getMonth() == date_normalized.getMonth()) return false;
        }


        return true;
    }
}

// toString override added to prototype of Foo class
DateExpression.prototype.toString = function () {

    const error = "[ERROR]";

    if (this.value.absolute === null && this.value.delta === null) return error;

    let hours: number;

    if (this.value.absolute != null) hours = this.value.absolute / 60;
    else if (this.value.delta != null) hours = this.value.delta / 60;
    else return error;


    let sign = '';

    if (this.value.delta != null && hours > 0) sign = '+';

    const x = this.year == null ? "*" : this.year.toString();
    const y = this.month == null ? "*" : this.month.toString();
    const z = this.day == null ? "*" : this.day.toString();

    const a = this.dayOfWeek == null ? "*" : this.dayOfWeek
    const b = this.dayOfWeekNumber == null ? "*" : this.dayOfWeekNumber
    const c = this.dayShift == null ? "" : ` ${this.dayShift}`

    return `${x}/${y}/${z} | ${a}/${b}${c}:${sign}${hours}h`;
}