import * as sys from '@/sys'
import { Resolution } from '@/logic/workspaces/tickets/_def';
import { TodoRmodel } from '@/app.context/workspace/tickets/todo.rmodel';
import AppContext from '@/context';
import { EntityDeleteDto } from '@/io/storages/workspace/EntityOperation';
import { InstructionOperationDto } from '@/io/storages/workspace/_def';
import { PersonRmodel } from '@/app.context/workspace/person.rmodel';
import { TqueuePosition } from '@/logic/_infra/queues';
import * as todoProgramator from '@/app.context/workspace/tickets/todos_spec/todo.calc.instruction'
import * as taskProgramator from '@/app.context/workspace/tickets/tasks/_spec/instruction.calc.task'
import { Guid } from '@/lib';
import { BlockRmodel } from '@/app.context/workspace/tickets/tasks/block.rmodel';
import { EntityRef } from '@/logic/workspaces/_tech';

export function todo_resolve(context: AppContext, item: TodoRmodel, resolution: Resolution | null, todo_upcoming?: TodoRmodel) {


    if (resolution == null) { sys.error('null resolution for todo item does not make sense'); return }

    const ref = item.ref()

    if (ref.type == null) sys.error(`Type of task is not set explicityly n:${ref.n}`)

    //--

    const instruction: InstructionOperationDto[] = []

    const person = item.person


    //--

    todoProgramator.resolve(item, resolution).forEach(i => instruction.push(i))

    item.update_resolution(resolution)

    if (item.isTask()) item.task.workable = false

    //--^

    //update ticket status to 'alarm' if resolution of task is 'fail'

    if (resolution == Resolution.Failed && item.isTask() && item.ticket.resolution != Resolution.Failed){

        instruction.push(item.ticket.update(Resolution.Failed)) 
    }


    todo_cancel_derivativesIf_instruction(context, item).forEach(i => instruction.push(i))

    todo_cancel_shadowAtBoardIf_insturction(context, item).forEach(i => instruction.push(i))


    if (todo_upcoming == null) {
        //if item has 'current' position at person Queue - we have to provdie replacement for it or leave null - so we got to 'idle' mode
        if (person.current == item) {
            todo_upcoming = person.todo_next() ?? undefined
        }
    }

    context.ops.xbq!.todoQueueClose(item, person, todo_upcoming, true).forEach(i => instruction.push(i))


    // -- FINAL SUBMIT -- //

    context.storages.workspace!.transactionSubmit(item.ticket.workspace.wid, instruction)
}

export function todo_cancel(context: AppContext, item: TodoRmodel) {

    const instruction = todo_cancel_instruction(context, item)

    context.storages.workspace!.transactionSubmit(item.ticket.workspace.wid, instruction)
}

function todo_cancel_instruction(context: AppContext, item: TodoRmodel) {

    const ops = context.ops.xbq!;

    const person = item.person

    if (item.isTask()) {

        const t = item.task!;

        if (t.resolution == null) {
            t.ticket.taskRemoveIf(t)
        }
    }

    if (item.isBlock()) {

        const b = item.block!;

        if (b.resolution == null) {
            b.task.blockRemoveIf(b)
        }
    }

    if (item.isVerification()) {

        const v = item.verification!;

        if (v.resolution == null) {

            v.task.verificationRemoveIf(v)
        }
    }

    // if (s == Structure.Board) {

    //     ops.todoBoardClose(item, person)

    //     return;
    // }

    // if (s == Structure.Queue) 
    {

        let todo_upcoming: TodoRmodel | null = null

        if (person.current == item) {
            todo_upcoming = person.todo_next()
        }

        const n = item.ticket.n

        const instruction: InstructionOperationDto[] = []

        todo_cancel_derivativesIf_instruction(context, item).forEach(i => instruction.push(i))


        //
        ops.todoQueueClose(item, person, todo_upcoming, true).forEach(i => instruction.push(i))

        todo_cancel_shadowAtBoardIf_insturction(context, item).forEach(i => instruction.push(i))

        instruction.push(todoProgramator.del(item))

        return instruction
    }
}

//todo_delay (for derivatives we only delay ('snooze'))

export function todo_delay(context: AppContext, t: TodoRmodel, todo_upcoming?: TodoRmodel) {

    const person = t.person


    if (todo_upcoming == null) {

        if (person.current == t) {
            todo_upcoming = person.todo_next() ?? undefined
        }
    }

    const instruction_dto = todo_snooze_instruction(context, t, todo_upcoming)

    context.storages.workspace!.transactionSubmit(person.workspace.wid, instruction_dto)
}


/**
* Delay - insturction only - no action!
*/
function todo_snooze_instruction(context: AppContext, t: TodoRmodel, todo_upcoming?: TodoRmodel) {

    const person = t.person

    const instruction_dto: InstructionOperationDto[] = []

    //const autoshift = todo_upcoming == null

    const buffer = context.ops.xbq!.todoQueue(t, person, new TqueuePosition("desk"), /*autoshift,*/ todo_upcoming ?? null, undefined, true); //supposed add to the begining of 'desk(blocked)' queue

    buffer.forEach(x => instruction_dto.push(x))

    // if (todo_upcoming != undefined) {

    //     const buffer_b = context.ops.xbq!.todoQueue(todo_upcoming, person, new TqueuePosition("current"), /*false,*/ undefined, true); //supposed add to the begining of 'desk(blocked)' queue

    //     buffer_b.forEach(x => instruction_dto.push(x))

    // }

    return instruction_dto;
}


function todo_cancel_derivativesIf_instruction(context: AppContext, item: TodoRmodel) {


    const instruction: InstructionOperationDto[] = []

    const n = item.ticket.n

    const person = item.person

    if (!item.isDerivative()) {
        //check for derivateive close/cance 

        item.task.blocks.forEach(b => {

            if (b.resolution == null) {
                
                block_cancel_instruction(context, b).forEach(i=>instruction.push(i))

                /*
                if (b.person != null) {
                    //delte block from person' queue
                    const todo = b.person.todoItemOrNull_AtQueue_N(n, 'B', item.person.nid)

                    if (todo != null) {

                        todo_cancel_instruction(context, todo).forEach(i => instruction.push(i))
                    }
                    else sys.warn(`Block ${n} at person ${b.person.name} but there is no at queue`)
                }
                else {
                    const ref = new EntityRefDto(n, 'B')
                    //if (b.person != null) ref.person = b.person.nid
                    ref.person_aux = person.nid
                    instruction.push(new EntityDeleteDto(ref))
                }
                */
            }
        })

        item.task.verifications.forEach(v => {

            if (v.resolution == null) {
                if (v.person != null) {
                    //delte block from person' queue
                    const todo = v.person.todoItemOrNull_AtQueue_N(n, 'V', item.person.nid)

                    if (todo != null) {

                        todo_cancel_instruction(context, todo).forEach(i => instruction.push(i))
                    }
                    else sys.warn(`Verification ${n} at person ${v.person.name} but there is no at queue`)
                }
                else {
                    const ref = new EntityRef(n, 'V')

                    ref.person_aux = person.nid
                    instruction.push(new EntityDeleteDto(ref))
                }
            }

        })
    }

    return instruction
}

function todo_cancel_shadowAtBoardIf_insturction(context: AppContext, item: TodoRmodel) {

    const person = item.person

    const instruction: InstructionOperationDto[] = []

    const todo_board = person.todoItemOrNull_AtBoard_N(item.ticket.n, item.type(), item.auxPerson())

    if (todo_board != null) context.ops.xbq!.todoBoardClose(todo_board, person, true).forEach(i => instruction.push(i))

    return instruction
}

//task(type of todo)_block
export function block
    (
        context: AppContext,
        t: TodoRmodel,
        data_blockers:
            {
                impersonal: boolean, //means 'anonymous blocker' - the block by person that not in system
                persons: PersonRmodel[],
                workable: boolean
            },
        shiftToDeskQueue?: boolean,
        todo_upcoming?: TodoRmodel
    ) {

    if (shiftToDeskQueue == undefined) shiftToDeskQueue = false

    const person = t.person


    const instruction_dto: InstructionOperationDto[] = []

    if (todo_upcoming == null) {

        if (person.current == t) {
            todo_upcoming = person.todo_next() ?? undefined
        }
    }


    if (t.isDerivative()) {

        if (!shiftToDeskQueue) throw Error('Derivative todos cannot be blocked - only shifted')

        todo_snooze_instruction(context, t, todo_upcoming).forEach(x => instruction_dto.push(x))
    }
    else {
        if (!data_blockers.impersonal && data_blockers.persons.length == 0) data_blockers.impersonal = true // new Error('/workspaces/person/todo - Wrong block operation')

        //first of all we set 'workability' status
        instruction_dto.push(taskProgramator.update_workable(t.task, data_blockers.workable))

        const blockers: (Guid | null)[] = []

        if (data_blockers.impersonal) {

            const block = t.task.blockOrNull(null)

            //ensure that we do not have already unresolved blocks
            if (block == null || block.resolution != null) { //we don't have block yet or block is resolved


                //blocker can be null - in such case no disposition related with putting it ot 'blocker' person todo queue
                blockers.push(null)

                //insturction to add new 'block' on task; such as it is 'impersonal' - no 'todo' item will be put to some person task queue
            }
            else sys.error('trying to creatin blcok when there one unresolved')
        }

        data_blockers.persons.forEach(blocker => {

            const block = t.task.blockOrNull_Model(blocker)

            if (block == null || block.resolution != null) {

                blockers.push(blocker.nid)
            }
            else sys.error('trying to creatin blcok when there one unresolved - B')
        })

        if (shiftToDeskQueue) {

            const buffer_c = todo_snooze_instruction(context, t, todo_upcoming)

            buffer_c.forEach(x => instruction_dto.push(x))

        }
        //NOTICE: IT'S IMPORTANT TO SEND ALL AS SINGLE TRANSACTION SUCH AS SYSTEM UNDERSTAND AS SINGLE OPERATION (BLOCKING & SHIFTING OF TASK)


        context.storages.workspace!.taskBlockCreate(person.workspace.wid, t.ticket.n, t.person.nid, blockers, TqueuePosition.Standart, instruction_dto)
    }
}

export function block_cancel(context:AppContext, b:BlockRmodel){

    const instruction = block_cancel_instruction(context, b)

    b.task.blockRemoveIf(b)

    context.storages.workspace!.transactionSubmit(b.task.ticket.workspace.wid, instruction)
}

function block_cancel_instruction(context:AppContext, b:BlockRmodel){

    const instruction:InstructionOperationDto[] = []

    const person = b.task.person

    const n = b.task.ticket.n

    if (b.person != null) {
        //delte block from person' queue
        const todo = b.person.todoItemOrNull_AtQueue_N(n, 'B', person.nid)

        if (todo != null) {

            todo_cancel_instruction(context, todo).forEach(i => instruction.push(i))
        }
        else sys.warn(`Block ${n} at person ${b.person.name} but there is no at queue`)
    }
    else {
        const ref = new EntityRef(n, 'B')
        //if (b.person != null) ref.person = b.person.nid
        ref.person_aux = person.nid
        instruction.push(new EntityDeleteDto(ref))
    }

    return instruction
}