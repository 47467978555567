export async function download(url: string, name: string) {

    const link = document.createElement('a')

    link.href = url
    link.target = '_blank'
    link.download = name
    link.click()

}

export function filesOrNull(e:DragEvent){

    if (e.dataTransfer == null) return null;
  
    if (e.dataTransfer.files == null) return false
  
    if (e.dataTransfer.files.length == 0) return false
    
    return [...e.dataTransfer.files]
  }