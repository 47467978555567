import { IService } from "@/io";
import { IUserDto } from "@/io/dto/user";
import { IUserStorage, ITicketStorage, IWorkspaceStorage, IFileStorage } from "@/io/storages";
import { Guid } from "@/lib";
import * as signalR from "@microsoft/signalr";
import workspaceStorage from '@/gw/storages/workspace.storage.signalR'

import userStorage from "./storages/user.storage.signalR";
import ticketStorage from './storages/ticket.storage.signalR'
import { ITicketBasicDto, ITicketDto } from "@/io/dto/workspaces/ticket.dto";
import { IObligationSpecificationDto} from "@/io/dto/workspaces/persons/obligationSpecification.dto";
import {IPersonOblgiationCalendarDto} from '@/io/dto/workspaces/person.dto.spec'
import { Sunrise } from "@/logic/_infra/time";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { FirebaseFileStorage, IFileIndex } from "./storage.firebase";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import sys from "@/sys";
import { ISunriseReportDto } from "@/io/dto/workspaces/persons/sunriseReport.dto";
import { IMeetingDto } from "@/io/dto/workspaces/eventw.dto";
import { IBalanceDto } from "@/io/dto/workspaces/persons/balance.dto";


function connectionCreate(url: string, host?: string) {

    const hubConnection = new signalR.HubConnectionBuilder()
        //.withUrl(url + `/ticket?wid=${wid}&n=${n}`)
        .withUrl(url, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build()

    hubConnection.serverTimeoutInMilliseconds = 120000; // 120 seconds

    return hubConnection;
}

//#rn Gateways controller
export class SignalrService implements IService {

    //url_api: string;
    url_hubs: string;

    api: HttpService;
    firebaseApp: FirebaseApp;


    onUserConnection?:(connection:signalR.HubConnection) => void
    onWorkspaceConnection?:(connection:signalR.HubConnection) => void
    onTicketConnection?:(connection:signalR.HubConnection) => void

    static async Instance(url_api: string, url_hubs: string) : Promise<SignalrService>{

        const api = new HttpService(url_api);

        const firebaseConfig = await api.get<FirebaseOptions>("/tech/firebase");

        const firebaseApp = initializeApp(firebaseConfig);

        return new SignalrService(api, url_hubs, firebaseApp);
    }

    constructor(api: HttpService, url_hubs: string, firebaseApp: FirebaseApp) {

        this.url_hubs = url_hubs;

        this.api = api;

        this.firebaseApp = firebaseApp;
    }

    personEfforts(nid: Guid, sunrise: Sunrise): Promise<ISunriseReportDto> {
        return this.api.get<ISunriseReportDto>('/workspaces/person/efforts',
            {
                nid,
                sunrise: Sunrise.toDateString(sunrise),
                // days: 0
            });
    }

    personEfforts_Period(nid: Guid, start: Sunrise, end: Sunrise): Promise<ISunriseReportDto[]> {
        return this.api.get<ISunriseReportDto[]>('/workspaces/person/efforts.period',
            {
                nid,
                start: Sunrise.toDateString(start),
                end: Sunrise.toDateString(end),
            });
    }

    workspaceStatus(wid: Guid, sunrise: Sunrise): Promise<ISunriseReportDto[]> {
        return this.api.get<ISunriseReportDto[]>('/workspace/status',
            {
                wid,
                sunrise: Sunrise.toDateString(sunrise)
            });
    }

    workspaceInsOuts(wid: Guid, sunrise: Sunrise): Promise<{ ins: ITicketBasicDto[], outs: ITicketBasicDto[] }> {
        return this.api.get<{ ins: ITicketBasicDto[], outs: ITicketBasicDto[] }>('/workspace/insouts',  //'ins' and 'outs'
            {
                wid,
                sunrise: Sunrise.toDateString(sunrise)
            });
    }


    personBalance(nid: Guid, sunrise: Sunrise): Promise<IBalanceDto> {
        return this.api.get<IBalanceDto>('/workspaces/person/balance',
            {
                nid,
                sunrise: Sunrise.toDateString(sunrise),
            });
    }

    personObligations(nid: Guid, sunrise: Sunrise, days:number): Promise<IPersonOblgiationCalendarDto> {

        return this.api.get<IPersonOblgiationCalendarDto>('/workspaces/person/obligations',
        {
            nid,
            sunrise: Sunrise.toDateString(sunrise),
            days:days
        });
    }


    obligationSpecifications(wid: Guid): Promise<IObligationSpecificationDto[]> {
        return this.api.get<IObligationSpecificationDto[]>('/workspace/persons',
        {
            wid,
        });
    }

    workspaceEvents(wid: Guid, sunrise: Sunrise): Promise<IMeetingDto[]> {
        return this.api.get<IMeetingDto[]>('/workspace/events',
            {
                wid,
                sunrise: Sunrise.toDateString(sunrise),
            });

    }

    userPersonTimezoneUpdate(offset:number): Promise<void> {
           
        return this.api.post_url<void>('/user/timezone',
        {
            //wid:wid,
            offset:offset,
        });
    }

    authenticateFirebaseStorage(token: string, config:FirebaseApp) {

        const result: { isAuthenticated: boolean, files: IFileStorage | null } = { isAuthenticated: false, files: null }

        console.log(`Token: ${token}`)

        if (token != '') {

            const auth = getAuth();

            return signInWithCustomToken(auth, token)
                .then(userCredentials => {

                    const user = userCredentials.user;

                    if (user != null) {

                        result.files = new FirebaseFileStorage(config, new FileIndex(this.api))
                        result.isAuthenticated = true
                    }
                    else {
                        result.isAuthenticated = true
                    }

                    return result

                })
                .catch(error => {

                    sys.error(`/user - Authentication - Firebase authentication failed ${error?.code} ${error?.message}`)

                    result.isAuthenticated = true

                    return result
                })
        }
        else return result;
    }


    userIsAuthenticated(): Promise<{ isAuthenticated: boolean, files: IFileStorage | null }> {
        

        return this.api.get_String('/user').then(r=>this.authenticateFirebaseStorage(r, this.firebaseApp))
    }

    userAuthenticate(user: string, password: string): Promise<{ isAuthenticated: boolean, files: IFileStorage | null }> {

        return this.api.post_String('/user', { user, password }).then(r=>this.authenticateFirebaseStorage(r, this.firebaseApp))
    }

    userLogoff(): Promise<void> {

        return this.api.simple('/user/off');
    }

    user(): Promise<IUserDto> {

        return this.api.get<IUserDto>('/user/data');
    }

    ticketOrNull(wid: Guid, n: number): Promise<ITicketDto | null> {

        return this.api.get<ITicketDto>('/ticket', { wid, n });
    }

    meetingOrNull(mid: Guid, wid: Guid): Promise<IMeetingDto> {

        return this.api.get<IMeetingDto>('/workspace/meeting', { wid, mid });
    }

    tickets(wid: Guid, numbers: number[]): Promise<ITicketBasicDto[]> {

        return this.api.post<ITicketBasicDto[]>('/workspace/tickets', { wid, numbers });
    }

    userStorage(): IUserStorage {

        const connection = connectionCreate(this.url_hubs + '/user');

        const storage = userStorage(connection);

        if (this.onUserConnection != undefined) this.onUserConnection(connection)

        sys.info(`About user storage connection start`)

        connection.start();

        return storage;
    }

    workspaceStorage(wid: Guid): IWorkspaceStorage {

        const connection = connectionCreate(this.url_hubs + `/workspace?wid=${wid}`);

        const storage = workspaceStorage(connection);

        if (this.onWorkspaceConnection != undefined) this.onWorkspaceConnection(connection)

        sys.info(`About workspace storage connection start: ${wid}`)

        connection.start();

        return storage;
    }

    ticketStorage(wid: Guid, n: number): ITicketStorage {

        const connection = connectionCreate(this.url_hubs + `/ticket?wid=${wid}&n=${n}`)

        const storage = ticketStorage(connection);

        if (this.onTicketConnection != undefined) this.onTicketConnection(connection)

        sys.info(`About ticket storage connection start: ${n}`)

        connection.start();

        sys.info(`Ticket storage instance created: ${n}`)

        return storage;
    }
}

export class HttpService {

    private prefix: string;

    constructor(prefix: string) {
        this.prefix = prefix;
    }

    async simple(query: string): Promise<void> {
        return this.getInternal_Empty(this.prefix + query);
    }

    async get<T>(query: string, params?: {}): Promise<T> {

        return this.getInternal(this.prefix + query, params)
    }

    async post<T>(query: string, data: {}): Promise<T> {

        return this.postInternal(this.prefix + query, data)
    }

    private async getInternal<T>(url: string, params_object?: {}): Promise<T> {

        if (typeof params_object != "undefined") {

            url = url + '?' + new URLSearchParams(params_object).toString();
        }

        return fetch(url, {credentials: 'include'}).then(response => {

            if (!response.ok) throw new Error(response.statusText)

            return response.json() as Promise<T>;

        }).then(data => { return data })
    }

    private async postInternal_Url<T>(url: string, params_object?: {}): Promise<T> {

        if (typeof params_object != "undefined") {

            url = url + '?' + new URLSearchParams(params_object).toString();
        }

        return fetch(url, {method:'POST', credentials: 'include'}).then(response => {

            if (!response.ok) throw new Error(response.statusText)

            return response.json() as Promise<T>;

        }).then(data => { return data })
    }

    private async postInternal_Url_Void(url: string, params_object?: {}): Promise<void> {

        if (typeof params_object != "undefined") {

            url = url + '?' + new URLSearchParams(params_object).toString();
        }

        return fetch(url, {method:'POST', credentials: 'include'}).then(response => {

            if (!response.ok) throw new Error(response.statusText)

            //return response.json() as Promise<T>;

        })//.then(data => { return data })
    }

    private async getInternal_Empty(url: string): Promise<void> {

        return fetch(url, {credentials: 'include'}).then(response => {

            if (!response.ok) throw new Error(response.statusText)

            return;

        })
    }

    async get_String(url: string): Promise<string> {

        return fetch(this.prefix + url, {credentials: 'include'}).then(response => {

            if (!response.ok) throw new Error(response.statusText)

            return response.text()

        })
    }

    private async postInternal<T>(url: string, data: {}): Promise<T> {

        return fetch(url,
            {
                method: 'POST',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
                credentials: 'include'

            }).then(response => {

                if (!response.ok) throw new Error(response.statusText)

                return response.json() as Promise<T>;

            }).then(data => { return data })
    }

    async post_String(url: string, data: {}): Promise<string> {

        //XMLHttpRequest.s

        return fetch(this.prefix + url,
            {
                method: 'POST',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
                credentials: 'include'
                //withCredentials:true

            }).then(response => {

                if (!response.ok) throw new Error(response.statusText)

                return response.text()

            })
    }

    async post_url<T>(query: string, params?: {}): Promise<T> {

        return this.postInternal_Url(this.prefix + query, params)
    }


    url(url: string, params_object?: {}): string {

        if (typeof params_object != "undefined") {

            url = url + '?' + new URLSearchParams(params_object).toString();
        }

        return this.prefix + url;
        
        
    }
    

    // async post_url(url: string, query: string): Promise<string> {

    //     return fetch(this.prefix + url,
    //         {
    //             method: 'POST',
    //             // 'Content-Type': 'application/x-www-form-urlencoded',
    //             //headers: { 'Content-Type': 'application/json' },
    //             //body: JSON.stringify(data)

    //         }).then(response => {

    //             if (!response.ok) throw new Error(response.statusText)

    //             return response.text()

    //         })
    // }

    // Пример отправки POST запроса:
    private async post2(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return await response.json(); // parses JSON response into native JavaScript objects
    }
}


class FileIndex implements IFileIndex{


    api:HttpService
    
    /**
     *
     */
    constructor(api: HttpService) {
        
        this.api = api

    }

    identificatorCreate(wid: Guid, n: number, filename: string, type?:string): Promise<{ identificator: string, pk: number }> {
        
        return this.api.post<{ identificator: string, pk: number }>('/ticket/file-create', {wid:wid, n:n, filename:filename, type: type ?? null})
    }

    itentificatorStatusUpdate(wid: Guid, n: number, pk: number): Promise<void> {

        const url = this.api.url('/ticket/file-update', {pk:pk})
        
        const p = fetch(url, {method:'POST', credentials: 'include'})

        return p.then(r=>{})
    }
}