/** Workmode */
export type EffortType = "S" | "I" | "A" | "F" //stop, idle, autopilot and flex

//export type EndeavourType = "E" | "L" | "T" //regular, lunch, twitlight

export type OffhourType = "L" | "T" // lunch, twitlight

export enum Category {

    N = '', //notice
    M = '^M', //meeting
    L = '^L', //langoliers
    I = '^I', //idle 
    X = '^X', //Force-majour
    P = '^P' //planning  or 'intertask'
}

//category.physical
    //work:
        //flex/autonumus | todo/category*
    //idle
    //forcemajour