import { IService } from "@/io"
import { reactive, ref } from "vue";
import { Model } from "./app.context/model";
import { WorkspaceRmodel } from "./app.context/workspace.rmodel";
import { PersonRmodel } from "./app.context/workspace/person.rmodel";
import { ProjectModel } from "./app.context/workspace/project.rmodel";
import { ImMessage, TicketPackRmodel } from "./app.context/workspace/tickets/_spec/Ticket.Im.Rmodel";
import { IFileStorage, ITicketStorage, IUserStorage, IWorkspaceStorage } from "./io/storages";
import { Guid } from "./lib";
import XbqProcessor from "./ops/workspaces_spec/XbqProcessor";
import sys from "./sys";

import { nextHour_Delta, nextQuarter_Delta } from "./lib/date";
import { TicketItemModel } from "./app.context/workspace/tickets/_spec/Ticket.Item.Model";
import { IFile } from "./io/dto/workspaces/ticket.dto.spec.pack";
import { SunriseReportRmodel } from "./app.context/workspace/persons/sunrise.rmodel";
import { ISunriseReportDto } from "./io/dto/workspaces/persons/sunriseReport.dto";
import { googleSdkLoaded } from "vue3-google-login"
import { unescape } from "lodash-es";

export default class AppContext {


    /**Int in js milliseconds format */
    timepoint = ref(new Date().getTime()) // new Date()

    ui: UI;

    //files: IFileStorage | null = null

    error(message: string) {

        this.tech.error = message;

        //setTimeout(() => this.tech.error = '', 5000); //remove in 3 sec error message
    }

    notice(message: string) {

        if (message === '[Authentication]') this.tech.authentication = message;

        else console.info(message);

        //todo fix this mess with notice/'not authenticated'

        //setTimeout(() => this.tech.error = '', 5000); //remove in 3 sec error message
    }

    catch(error: any) {

        sys.exception(error)
    }

    objects_cursored(wid: Guid, nid: Guid | null): { person: PersonRmodel | null, workspace: WorkspaceRmodel | null } {

        const w = this.cursor.workspace;

        if (w != null) {
            if (w.wid == wid) {

                if (nid != null) {

                    const p = w.person_nid(nid)

                    return { person: p, workspace: w }
                }
            }
            else return { person: null, workspace: w }
        }

        return { person: null, workspace: null }
    }


    //tick

    constructor(service: IService) {

        this.service = service
        this.api = new Api(this)
        this.ui = new UI()

        const cursor = this.cursor
        const user = this.model
        const storages = this.storages

        // let timepoint = this.timepoint

        // function updateTimepoint() {

        //     const x = new Date()

        //     sys.info(`Timepoint:${sys.time.format(x)}`)

        //     timepoint = x

        //     //this.timepoint
        // }

        const context = this

        updateTimepoint(context)

        //let sunrise: Sunrise | null = null

        setTimeout(quarterHourTick, nextQuarter_Delta());



        function updateTimepoint(context: AppContext) {

            const x = new Date()

            sys.info(`Timepoint:${sys.time.format(x)}`)

            context.timepoint.value = x.getTime() // =  .addMilliseconds(x.getTime() - context.timepoint.getTime()) // = x

            context.triggers.tick()

            //this.timepoint
        }

        function quarterHourTick() {

            setTimeout(quarterHourTick, 15 * 60 * 1000); //15 min

            //sys.info('1-Q tick')


        }

        function minuteTick() {

            setTimeout(minuteTick, 1000 * 60);

            sys.info('1-M tick')

            const now = new Date()

            const w = cursor.workspace

            updateTimepoint(context)



            //const w = cursor.workspace

            if (w != null) {

                w.updateCurrentEfforts(new Date(context.timepoint.value))


            }

            if (w != null) {

                w.meetings.forEach(e => e?.syncEstimatedExpiration())


                w.persons().forEach(p => {

                    if (p.efforts?.isOutdated(now) ?? false) {

                        sys.info(`Refreshing sunrise effort report - nid:${p.nid} `)

                        if (storages.workspace) storages.workspace.personEffortReport(p.nid)

                        else sys.error('Empty workspace storage')

                    }

                })
            }
        }

        //set it to the middle of next minute
        const date = new Date()
        date.setMilliseconds(0)
        date.setSeconds(30);
        date.addMinutes(1)

        setTimeout(minuteTick, date.getTime() - Date.now());



        function hourTick() {

            setTimeout(hourTick, 60 * 60 * 1000); //60 min

            sys.info('1-H tick')

            const w = cursor.workspace

            //reload workspace mode because running peson user sunrise expired
            if (w != null) {

                if (w.user_person != null) {
                    const p = w.user_person

                    if (p.efforts != null && p.efforts.expires < new Date()) {

                        //context_workspace.
                        if (storages.workspace) storages.workspace.workspace(w.wid)

                        else sys.error('Empty workspace storage')
                    }
                }
            }
        }

        setTimeout(hourTick, nextHour_Delta());

    }




    tech = reactive({ error: "", warning: "", authentication: "" })

    service: IService

    authenticated = ref(false);

    model = new Model()

    x: Date = new Date()

    cursor:
        {
            workspace: WorkspaceRmodel | null,
            project: ProjectModel | null,
            person: PersonRmodel | null,
            person_code: string | null,


        } = reactive({
            workspace: null, project: null, person: null, person_code: null, /*chat: null,*/ ticket_aux: new TicketItemModel(),
        });


    storages: { user: IUserStorage | null, workspace: IWorkspaceStorage | null, ticket: ITicketStorage | null, files: IFileStorage | null } =
        {
            user: null, workspace: null, ticket: null, files: null
        };

    ops: { xbq: XbqProcessor | null } = { xbq: null }

    triggers: TriggerController = new TriggerController()

    checks: CheckController = new CheckController(this)

    api: Api

    prompt: (() => string) | undefined = undefined

    integrations:
        {
            Google:
            {

                requestAccessToken: (() => void) | null
            }
        } = {
            Google:
            {
                requestAccessToken: null
            }
        }

    //supposded to do super basic initialization - decide at all if user is authenticated
    async initialize(): Promise<void> {

        try {

            const authResult = await this.service.userIsAuthenticated();

            this.authenticated.value = authResult.isAuthenticated

            if (!this.authenticated.value) return;

            const d = new Date();



            this.syncTimezone();
            

            this.storages.user = this.service.userStorage();

            this.storages.files = authResult.files

        } catch (error) {

            sys.exception(error)
        }
    }

    async authenticate(login: string, password: string): Promise<boolean> {

        //todo validate login/password - and disable it for DEBUG builds
        return await this.service.userAuthenticate(login, password).then(async (result): Promise<boolean> => {

            if (result.isAuthenticated) {

                //   .then((userCredential) => {
                //     // Signed in
                //     const user = userCredential.user;
                //     // ...
                //   })
                //   .catch((error) => {

                //     // ...
                //   });

                this.syncTimezone()

                this.storages.user = this.service.userStorage();

                this.authenticated.value = true;

                this.storages.files = result.files

                if (this.model.user == null) {

                    this.model.setup(await this.service.user());

                    this.storages.user!.sync(); //ensure data is synced through duplex channel
                }
            }
            else {
                this.model.workspaces.length = 0;
                this.authenticated.value = false;
            }

            return result.isAuthenticated;
        });
    }

    syncTimezone() {

        const offset = -1 * (new Date().getTimezoneOffset())

        this.service.userPersonTimezoneUpdate(offset).catch(sys.exception)
    }

    logoff() {

        this.model.workspaces.length = 0;
        this.authenticated.value = false;

        this.service.userLogoff().catch(sys.exception);
    }

    isCaptain() {

        if (this.cursor.workspace != null) {

            return this.cursor.workspace.isCaptain
        }
        else return false
    }

    isCurrentPerson() {

        if (this.cursor.workspace != null && this.cursor.person != null) {

            return this.cursor.workspace.user_person == this.cursor.person
        }
    }

    userEfforts() {

        return this.cursor.workspace?.user_person?.efforts ?? null
    }




}

class CheckController {
    readonly context: AppContext;

    constructor(c: AppContext) {
        this.context = c;
    }

    workspace(wid: Guid): boolean {

        return (this.context.cursor?.workspace?.wid == wid)
    }

    // person2(nid: Guid): PersonRmodel | null {

    //     const w = this.context.cursor.workspace;

    //     if (w == null) return null;

    //     return w.personOrNull_nid(nid);
    // }

    person(nid: Guid): boolean {

        return (this.context.cursor?.person?.nid == nid)
    }
}


class TriggerController extends EventTarget {


    /*
    // -- 'person-event-update'
    personEventUpdate(wid: Guid, nid: Guid, event: IEventDto) {

        this.dispatchEvent(new CustomEvent<{ wid: Guid, nid: Guid, event: IEventDto }>('person-event-update', { detail: { wid, nid, event } }));


    }

    onPersonEventUpdate(callback: (wid: Guid, nid: Guid, event: IEventDto) => void): { handler: EventListener, type: string } {

        const handler: EventListenerOrEventListenerObject = (e) => {

            const buffer = <{ wid: Guid, nid: Guid, event: IEventDto }>(<CustomEvent>e).detail;

            callback(buffer.wid, buffer.wid, buffer.event);
        };

        this.addEventListener('person-event-update', handler);

        return { handler, type: 'person-event-update' };
    }*/


    cancel(h: { handler: EventListenerOrEventListenerObject, type: string }) {

        this.removeEventListener(h.type, h.handler);
    }

    //--'ticket-pack-initialization'

    ticketPackInitialization(model: TicketPackRmodel) {

        this.dispatchEvent(new CustomEvent<TicketPackRmodel>('ticket-pack-initialization', { detail: model }));
    }

    onTicketPackInitialization(callback: (model: TicketPackRmodel) => void): { handler: EventListenerOrEventListenerObject, type: string } {

        const handler: EventListenerOrEventListenerObject = (e) => {

            callback(<TicketPackRmodel>(<CustomEvent>e).detail);
        };

        this.addEventListener('ticket-pack-initialization', handler);

        return { handler, type: 'ticket-pack-initialization' };
    }



    //-- 'ticket-im-update'

    ticketImUpdate(wid: Guid, n: number, messages: ImMessage[]) {

        this.dispatchEvent(new CustomEvent<{ wid: Guid, n: number, messages: ImMessage[] }>('ticket-im-update', { detail: { wid, n, messages } }));
    }




    onTicketImUpdate(callback: (wid: Guid, n: number, messages: ImMessage[]) => void): { handler: EventListener, type: string } {

        const handler: EventListenerOrEventListenerObject = (e) => {

            const buffer = <{ wid: Guid, n: number, messages: ImMessage[] }>(<CustomEvent>e).detail;

            callback(buffer.wid, buffer.n, buffer.messages);
        };

        this.addEventListener('ticket-im-update', handler);

        return { handler, type: 'ticket-im-update' };
    }

    ticketFileUpdate(wid: Guid, n: number, files: IFile[]) {

        this.dispatchEvent(new CustomEvent<{ wid: Guid, n: number, files: IFile[] }>('ticket-file-update', { detail: { wid, n, files } }));
    }

    onTicketFileUpdate(callback: (wid: Guid, n: number, files: IFile[]) => void): { handler: EventListener, type: string } {

        const handler: EventListenerOrEventListenerObject = (e) => {

            const buffer = <{ wid: Guid, n: number, files: IFile[] }>(<CustomEvent>e).detail;

            callback(buffer.wid, buffer.n, buffer.files);
        };

        this.addEventListener('ticket-file-update', handler);

        return { handler, type: 'ticket-file-update' };
    }

    tick() {

        this.dispatchEvent(new Event('tick'))
    }

    onTick(callback: () => void) {

        const h: EventListenerOrEventListenerObject = (e) => { callback() }

        this.addEventListener('tick', h)

        return { handler: h, type: 'tick' }
    }

    ticketDescriptionUpdate(wid: Guid, n: number, description: string) {

        this.dispatchEvent(new CustomEvent<{ wid: Guid, n: number, description: string }>('ticket-description-update', { detail: { wid, n, description } }));
    }

    onTicketDescriptionUpdate(callback: (wid: Guid, n: number, description: string) => void): { handler: EventListener, type: string } {

        const handler: EventListenerOrEventListenerObject = (e) => {

            const buffer = <{ wid: Guid, n: number, description: string }>(<CustomEvent>e).detail;

            callback(buffer.wid, buffer.n, buffer.description);
        };

        this.addEventListener('ticket-im-update', handler);

        return { handler, type: 'ticket-im-update' };
    }



    //-- 'person-todos-update'

    personTodoUpdated(nid: Guid) {

        const c_event = new CustomEvent("person-todos-update", { detail: nid.toString() });

        this.dispatchEvent(c_event);
    }

    onPersonTodoUpdated(callback: (nid: Guid) => void): { handler: EventListener, type: string } {


        const handler: EventListenerOrEventListenerObject = (e) => {

            const nid = <Guid>((<CustomEvent>e).detail);

            callback(nid);
        };

        this.addEventListener("person-todos-update", handler)

        return { handler, type: 'person-todos-update' };
    }

    //-- 'person-efforts-update'

    personEffortsUpdated(person: PersonRmodel, report: ISunriseReportDto) {

        const c_event = new CustomEvent("person-efforts-update", { detail: { person, report } });

        this.dispatchEvent(c_event);
    }

    onPersonEffortsUpdated(callback: (person: PersonRmodel, report: ISunriseReportDto) => void): { handler: EventListener, type: string } {


        const handler: EventListenerOrEventListenerObject = (e) => {

            const args = <{ person: PersonRmodel, report: ISunriseReportDto }>((<CustomEvent>e).detail);

            callback(args.person, args.report);
        };

        this.addEventListener("person-efforts-update", handler)

        return { handler, type: 'person-efforts-update' };
    }

    command(value: string) {

        const c_event = new CustomEvent("workspace-command", { detail: value });

        this.dispatchEvent(c_event);
    }

    onCommand(callback: (value: string) => void) {

        const handler: EventListenerOrEventListenerObject = (e) => {

            const value = <string>((<CustomEvent>e).detail);

            callback(value);
        };

        this.addEventListener("workspace-command", handler)

        return { handler, type: "workspace-command" };
    }

    //--

    test() {

        const c_event = new CustomEvent("test", { detail: Date.now().toString() });

        this.dispatchEvent(c_event);
    }

    onTest(callback: (x: string) => void) {
        this.addEventListener("test", function (e) { // change here Event to CustomEvent

            callback(<string>((<CustomEvent>e).detail))
        })
    }

}

// declare interface TriggerController {
//     on(event: 'hello', listener: (name: string) => void): this;
//     on(event: string, listener: Function): this;
// }


class Api {
    constructor(context: AppContext) {

        this.context = context;
    }

    private context: AppContext;


}

export class UI {

    consoleContent: (() => string) = () => '[Console text]' //default just stub returns empty string

    consoleContentClear: (() => void) = () => '' //default just stub returns empty string
}