interface Date {
    getTimestamp(): number;
    addDays(d: number): Date;
    addMinutes(m: number): Date;
    addMilliseconds(ms: number): Date;
    addWeeks(weeks: number): Date
    cloneAndAddDays(d: number): Date;
    cloneAndAddMinutes(m: number): Date;
    cloneAndAddMilliseconds(ms: number): Date;
    
    dayOfWeekNumber(): number

    currentWeek(weekStartDay: number): { start: Date, end: Date }

    /**
     * Span in minutes between date and 'end'
     * @param end end of perion
     */
    minuteSpan(end: Date): number

    copyTo(copy:Date) : Date


}

// Define a new method to override toString
function customToString(this: Date): string {
    // Customize the date format as needed
    const year = this.getFullYear();
    const month = (this.getMonth() + 1).toString().padStart(2, '0');
    const day = this.getDate().toString().padStart(2, '0');
    const hours = this.getHours().toString().padStart(2, '0');
    const minutes = this.getMinutes().toString().padStart(2, '0');
    const seconds = this.getSeconds().toString().padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  // Extend the Date prototype with the custom toString method
  Date.prototype.toString = customToString;
  
  // Example usage
  //const currentDate = new Date();
  //console.log(currentDate.toString()); // Customized date format
  


Date.prototype.getTimestamp = function () {
    return this.getTime();
};

/**
 * Change the object itself
 */
Date.prototype.addDays = function (days: number): Date {

    //const date = new Date(this.valueOf());

    const buffer = this.cloneAndAddDays(days)

    buffer.copyTo(this)

    //this.setDate(this.getDate() + days);

    return this;
}

Date.prototype.addMinutes = function (minutes: number): Date {

    const buffe = this.cloneAndAddMinutes(minutes)

    buffe.copyTo(this)

    return this

    //this.setDate(this.getTime() + minutes * 60000);
    this.setMinutes(this.getMinutes() + minutes);

    //twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 20);

    return this;
}

Date.prototype.addMilliseconds = function (ms: number): Date {

    const buffer = this.cloneAndAddMilliseconds(ms)

    buffer.copyTo(this)

    return this

    //this.setDate(this.getTime() + minutes * 60000);
    this.setMilliseconds(this.getMilliseconds() + ms);

    //twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 20);

    return this;
}

Date.prototype.addWeeks = function (weeks: number): Date {

    return this.addDays(weeks * 7);
}

Date.prototype.cloneAndAddDays = function (days: number): Date {

    const date = new Date(this.valueOf() + days * 86400000); //ms per day

    //return date.addDays(days);

    return date
}

Date.prototype.cloneAndAddMinutes = function (minutes: number): Date {

    const date = new Date(this.valueOf() + minutes * 60000);

    //return date.addMinutes(minutes);

    return date
}

Date.prototype.cloneAndAddMilliseconds = function (ms: number): Date {

    const date = new Date(this.valueOf() + ms);

    //return date.addMilliseconds(ms);

    return date
}

Date.prototype.dayOfWeekNumber = function (): number {

    const month = this.getMonth();

    let n = 1;

    while (this.addDays(-7).getMonth() === month) {
        n++;
    }

    return n;
}

/** 
 * 
*/
Date.prototype.currentWeek = function (weekStartDay: number): { start: Date, end: Date } {

    if (weekStartDay == 7) weekStartDay = 0;
    if (weekStartDay > 7) throw Error(`Weekday can't be ${weekStartDay}`)

    const x = new Date(this.getFullYear(), this.getMonth(), this.getDate());

    let d = x.getDay();

    let start: Date;

    if (weekStartDay === d) start = x;
    else {
        if (weekStartDay > d) d = d + 7;

        start = x.addDays(weekStartDay - d);
    }

    return { start, end: start.cloneAndAddDays(6) };
}

Date.prototype.minuteSpan = function (end: Date): number {

    const span_miliseconds = (end.getTime() - this.getTime())

    //const span_minutes = Math.round(((span_miliseconds % 86400000) % 3600000) / 60000); // minutes

    const span_minutes = Math.round(span_miliseconds/(1000*60))

    return span_minutes
}

Date.prototype.copyTo = function(copy: Date){

    copy.setUTCDate(1)

    copy.setUTCFullYear(this.getUTCFullYear())
    copy.setUTCMonth(this.getUTCMonth())
    copy.setUTCDate(this.getUTCDate())
    copy.setUTCHours(this.getUTCHours())
    copy.setUTCMinutes(this.getUTCMinutes())
    copy.setUTCMilliseconds(this.getUTCMilliseconds())

    return copy
}



// (weekStartDay:number): Date