import { DateExpression } from '@/logic/_infra/calendar'

export class ResourceCalendar {

    label: string = ''

    id: number = 0

    effective: Date | null = null

    expires: Date | null = null

    

    constructor(expressions: (string | DateExpression<TimeResource>)[]) {

        const buffer: DateExpression<TimeResource>[] = []

        expressions.forEach(x => {

            if (typeof x == "string") {

                buffer.push(f(x))
            }
            else buffer.push(x);
        })

        this.expressions = buffer;

        function f(s: string): DateExpression<TimeResource> {

            function TRACE(o: any) { /*console.log(o)*/ }

            //([^:]+):([+-]?)(\d+)([h]?)


            //[predicate]:[8h]
            const match_value = s.match(/^\s*([^:]+):([+-]?)(\d+)([h|m])\s*$/);

            if (match_value === null) throw Error(`Can't parse '${s}' expression`)

            const result = new DateExpression<TimeResource>(new TimeResource());

            const predicate = match_value[1];


            //[date][weekday][shift]
            const match_predicate = predicate.match(/^([^\r\n|[\]]+)(\|([^|[\]]+))?(\[.+\])?$/)!;

            const predicate_buffer: string[] = [match_predicate[1]];

            if (typeof match_predicate[3] != "undefined") predicate_buffer.push(match_predicate[3]);
            if (typeof match_predicate[4] != "undefined") predicate_buffer.push(match_predicate[4]);

            predicate_buffer.forEach((v, i) => {

                const weekMatch = v.match(/^(MON|TUE|WED|THU|FRI|SAT|SUN)(\/(-?\d+))?$/);

                if (weekMatch != null) {

                    switch (weekMatch[1]) {

                        case 'MON': result.dayOfWeek = 1; break;
                        case 'TUE': result.dayOfWeek = 2; break;
                        case 'WED': result.dayOfWeek = 3; break;
                        case 'THU': result.dayOfWeek = 4; break;
                        case 'FRI': result.dayOfWeek = 5; break;
                        case 'SAT': result.dayOfWeek = 6; break;
                        case 'SUN': result.dayOfWeek = 0; break;
                    }

                    if (weekMatch.length > 1 && typeof weekMatch[3] != "undefined") {
                        result.dayOfWeekNumber = parseInt(weekMatch[3])
                    }

                    return;
                }

                const dateMatch = v.match(/^(\*|\d+)\/(\*|\d+)\/(\*|\d+)$/);
                //

                if (dateMatch != null) {

                    if (dateMatch[1] != '*') result.year = parseInt(dateMatch[1])
                    if (dateMatch[2] != '*') result.month = parseInt(dateMatch[2]) - 1; //in js monthes are 0-11
                    if (dateMatch[3] != '*') result.day = parseInt(dateMatch[3])



                    return;
                }

                const shiftMatch = v.match(/^(\[((\+|-)\d+)\])$/);

                if (shiftMatch != null) {
                    result.dayShift = parseInt(shiftMatch[2])

                    return;
                }

                throw Error(`Can't parse ${v}`);
            });


            const H: boolean = match_value[4] === 'h';

            if (!H && match_value[4] != 'm') throw Error(`Can't parse '${match_value[4]}' as time unit`)


            let value: number = parseInt(match_value[3]);

            if (H) value = value * 60;

            const AR = match_value[2]; //'absolute-relative'

            if (AR === '') result.value.absolute = value;
            else if (AR === '+') result.value.delta = value;
            else if (AR === '-') result.value.delta = - 1 * value;
            else throw Error(`Can't parse '${AR}' as absolute/delta switch`)

            TRACE(`PRELIMINARY RESULT:${result.toString()}`)

            return result;
        }

    }

    expressions: DateExpression<TimeResource>[]

    resourceOnDateInMinutes(date: Date): number {

        //date.

        let result = 0;

        this.expressions.forEach(e => {



            if (e.match(date)) {

                if (e.value.absolute != null) result = e.value.absolute;
                else result += e.value.delta!;
            }

        });

        if (result < 0) result = 0;

        return result;
    }



    resourceOnDateInHours(date: Date): number {

        return this.resourceOnDateInMinutes(date) / 60;
    }
}


class TimeResource {
    absolute: number | null = null

    delta: number | null = null;
}