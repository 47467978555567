import { Sunrise, TimeSegment } from "@/logic/_infra/time"

export interface ITimeSegmentDto {

    timepoint: string

    //timespan: number

    timespan_ms: number

    startex?: boolean
    endex?: boolean
}

export function timeSegmentDto(ts:TimeSegment) : ITimeSegmentDto{

    const x = 
    {
        timepoint : ts.timepoint.toISOString(),
        timespan_ms : ts.timespan_ms,
        startex : ts.startex,
        endex : ts.endex,
    }

    return x
}

export function toTimeSegment(dto:ITimeSegmentDto){

    const result = new TimeSegment(new Date(dto.timepoint), dto.timespan_ms)

    result.startex = dto.startex!
    result.endex = dto.endex!

    return result
}



export interface ISunriseDto {

    year: number
    month: number
    day: number
}

export function dateOrNull(s: ISunriseDto | null) {

    if (s == null) return null

    return Sunrise.toDate(new Sunrise(s.year, s.month, s.day))
}