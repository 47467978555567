import { GroupRmodel } from "@/app.context/workspace/persons.set/group.rmodel"
import * as arrays from '@/lib/array.lib'

export class PhaseRmodel //part of process definition (vs 'step' is part of progress on task instance)
{

    
    

    readonly group: GroupRmodel

    readonly process:ProcessRmodel;

    constructor(group: GroupRmodel, process:ProcessRmodel) {
        this.group = group
        this.process = process;
    }
}

export class ProcessRmodel {

    name = ''
    phases: PhaseRmodel[] = []

    phase(group:string): PhaseRmodel {

        return arrays.single(this.phases, x=>x.group.name === group)
    }
}