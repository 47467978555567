import * as arrays from '@/lib/array.lib'

export type Queue<T> = { current: T | null, desk: T[], queue: T[], pins: T[] }

export type TodoSection = "current" | "desk" | "queue" | "pins";

/**
 * Tqueue for 'Todo Queue'
 *
 */
export class TqueuePosition {
    constructor(section?: TodoSection, index?: number) {

        if (typeof section != "undefined") this.section = section;
        if (typeof index != "undefined") this.index = index;

        if (section != "current" && typeof index == "undefined") this.index = -1;
    }

    index: number = 0;

    section: TodoSection = "queue"

    static DefaultRestorePostion:number = 2
    static Standart:number = -1

    static  Instance(index:number) {
        
        //#copy {D737A5B9-7C61-4910-AB78-12DAE54E35C4}
        let position:TqueuePosition;

        if (index == 0) position = new TqueuePosition('current', 0) //now
        else if (index > 0) position = new TqueuePosition('queue', index - 1)
        else
        {
            const buffer = -1
            
            if (index == -1) position = new TqueuePosition('queue', -1)       
            else position = new TqueuePosition('pins', -1)
        }

        return position;
    }
}

export const queues = {

    itemOrNull<T>(q: Queue<T>, position: TqueuePosition): T | null {

        if (position.section == "current") {
            return q.current;
        }

        if (position.section == "desk") {

            if (position.index >= 0) {

                return q.desk[position.index];
            }
            else {
                if (q.desk.length > 0) return q.desk[q.desk.length - 1];
                else return null;
            }
        }

        if (position.section == "queue") {

            if (position.index >= 0) {

                return q.queue[position.index];
            }
            else {
                if (q.queue.length > 0) return q.queue[q.queue.length - 1];
                else return null;
            }
        }

        if (position.section == "pins") {

            if (position.index >= 0) {

                return q.pins[position.index];
            }
            else {
                if (q.pins.length > 0) return q.pins[q.pins.length - 1];
                else return null;
            }
        }

        throw Error(`Unkonw type of section ${position.section}`)
    },

    next<T>(person: Queue<T>): { item: T, section: TodoSection } | null {


        if (person.queue.length > 0) return { item: person.queue[0], section: "queue" };
        if (person.pins.length > 0) return { item: person.pins[0], section: "pins" };

        return null;
    },

    current<T>(person: Queue<T>): T | null {

        if (person.current != null) return person.current;

        return null;
    },


    itemAdd<T>(q: Queue<T>, item: T, position: TqueuePosition) {

        const section = position.section;
        let index = position.index;

        if (section === "current") {

            q.current = item;
        }
        else if (section === "desk") {

            if (index < 0) index = q.desk.length;

            arrays.insertStrictly(q.desk, item, index);
        }
        else if (section === "queue") {

            if (index < 0) index = q.queue.length;

            arrays.insertStrictly(q.queue, item, index);
        }
        else if (section === "pins") {

            if (index < 0) index = q.pins.length;

            arrays.insertStrictly(q.pins, item, index);
        }
        else throw Error(`Unknown TodoSection:${section}`);

    },

    itemRemove<T>(person: Queue<T>, item: T, precondition?: TodoSection) {

        const position = queues.position(person, item);

        if (position == null)
        {
            
            return;
        }

        if (typeof precondition != "undefined") {

            if (position.section != precondition) throw Error('Precondtion failed for simple remove');
        }


        //funciton remove(item, sectio) //for 'current' wiht double check
        if (position.section === "current") {
            person.current = null;
        }
        else if (position.section === "desk") {

            arrays.remove(person.desk, item)
        }
        else if (position.section === "queue") {

            arrays.remove(person.queue, item);
        }
        else if (position.section === "pins") {

            arrays.remove(person.pins, item)
        }
        else throw Error(`Unknown section type: ${position.section}`)
    },

    itemMove<T>(person: Queue<T>, item: T, position: TqueuePosition) {

        this.itemRemove(person, item);

        this.itemAdd(person, item, position);
    },

    position<T>(q: Queue<T>, item: T): { section: TodoSection, index: number } | null {

        //const person = this;

        if (q.current === item) return { section: "current", index: 0 };

        let index: number;

        index = q.desk.indexOf(item);
        if (index >= 0) return { section: "desk", index };

        index = q.queue.indexOf(item);
        if (index >= 0) return { section: "queue", index };

        index = q.pins.indexOf(item);
        if (index >= 0) return { section: "pins", index };

        return null;
    },

    all<T>(q: Queue<T>): T[] {

        const result: T[] = [];

        if (q.current != null) result.push(q.current);

        q.desk.forEach(x => result.push(x));
        q.queue.forEach(x => result.push(x));
        q.pins.forEach(x => result.push(x));

        return result;
    }

}