import { TodoRmodel } from '@/app.context/workspace/tickets/todo.rmodel';
import { Guid } from '@/lib';
import { TodoSection } from '@/logic/_infra/queues';
import { Operation } from './EntityOperation';
import { QueueOperation, TodoOperationType } from './_def';
import { TodoItemType } from '@/logic/workspaces/tickets/todos/_def.todos';


export class TodoQueueOperationDto extends Operation {


    constructor(operation: QueueOperation<TodoRmodel>/*, person: PersonRmodel*/){
        
        super("TodoQueueOperation");

        this.n = operation.object.task.ticket.n;
        this.nid = operation.object.person.nid;
        this.name = operation.name;
        this.type = operation.object.type();
        this.target = operation.target;

        if (operation.object.isBlock()) this.nid_aux = operation.object.block!.task.person!.nid;

        if (operation.object.isVerification()) this.nid_aux = operation.object.block!.task.person!.nid;
    }

    //name of operation
    readonly name: TodoOperationType;

    n: number// = 0;
    type: TodoItemType //= "T";

    nid: Guid
    nid_aux: Guid | null = null;

    target: { section: TodoSection, index: number } //= { section: "queue", index: 0 };

    //што было ці будзе замест таргета раней ці пузней у залежнасці ад кантэкста
    precondition: { n: number, type: string, section: TodoSection, index: number } | null = null;
}