import { BoardPosition, boards } from "@/logic/_infra/boards";
import { Queue, queues, TodoSection, TqueuePosition } from "@/logic/_infra/queues";
import { } from '../XbqProcessor'
import { WorkspaceRmodel } from "@/app.context/workspace.rmodel";
import { PersonRmodel } from "@/app.context/workspace/person.rmodel";
import { TodoRmodel } from "@/app.context/workspace/tickets/todo.rmodel";
import { ProjectModel } from "@/app.context/workspace/project.rmodel";
import { TicketRmodel } from "@/app.context/workspace/ticket.rmodel";
import { BoardOperation, QueueOperation } from "@/io/storages/workspace/_def";
import { InstructionOperation, TicketBoardOperation, TicketQueueOperation, TodoBoardOperation, TodoQueueOperation } from "@/app.context/operations";

/**
 * Insturction to move as 'current' next task - if it is available; null otherwise
 * @param queue 
 * @param position 
 * @returns 
 */
function shiftIf<T>(queue: Queue<T>, position: { section: TodoSection, index: number }) {

    if (position.section === "current") {

        const nextTodo = queues.next(queue);

        if (nextTodo != null) {

            const operation = new QueueOperation<T>("MOVE", nextTodo.item);

            operation.target.index = 0;
            operation.target.section = "current";

            return operation;
        }
        else return null;
    }
}

//when we get something out from 'current' possition we need to understand whether whole queue should 'shift down'
//when we actually have something to 'replace' - we need to prevent this shift to avoid that 'current' spot was automatically occupied with next task
//'auto shift' also does no work if person is 'autonomus' are there are no 'workable' tasks to work on
//this logic is duplicated also at C# because similar logic applies when we create new task a some 'default' position but because of queue has no workable items
//it should automatically go as 'current'
// function queueAutoShift(person:PersonRmodel){

//     let todos_workable = 0;

//     //#copy {60D865C9-7D93-4E63-B0E6-32FDB78FAF03}
//     todos_workable += person.queue.filter(t=>!t.isTask() || t.task.isWorkable()).length 
//     todos_workable += person.desk.filter(t=>!t.isTask() || t.task.isWorkable()).length 
//     todos_workable += person.pins.filter(t=>!t.isTask() || t.task.isWorkable()).length 
    
//     //#copy {EEDF76CC-B792-42DF-9A10-81E3FBF35819}
//     return  todos_workable > 0 && person.subordinate &&  !person.autonomus
// }

const Qprogrammator = {


    /**
     * This shifts current ticket to 'queue' section if applicable. Huge difference with just simple 'put({queue, 0})' that it leave current empty - does not tr to refill it with penindg ticket
     * @param queue targe queu
     * 
     * @returns 
     */
    cleanCurrentIf<T>(queue: Queue<T>) {

        const instructions: QueueOperation<T>[] = []

        const itemAtTargetPosition = queues.itemOrNull(queue, new TqueuePosition("current"));

        if (itemAtTargetPosition != null) {
            //move current to 'queue:0'
            this.move(itemAtTargetPosition, queue, new TqueuePosition("queue", 0)).forEach(o => instructions.push(o));
        }
        //else return empty set

        return instructions;
    },

    /**
     * Prepares insturction to close item from queue
     * @param item Item to close
     * @param queue 
     * @param target Double check, that item exactly as place
     * @returns 
     */
    close<T>(item: T, queue: Queue<T>, autoshif: boolean, target?: TqueuePosition): QueueOperation<T>[] {

        const instruction: QueueOperation<T>[] = [];

        const position = queues.position(queue, item);

        if (position != null) {

            const operation = new QueueOperation<T>("CLOSE", item, position)

            instruction.push(operation);

            if (autoshif) {

                const shiftInstruction = shiftIf(queue, position);

                if (shiftInstruction) instruction.push(shiftInstruction);
            }

            // if (position.section === "current") {

            //     const nextTodo = queues.next(queue);

            //     if (nextTodo != null) {

            //         const operation_aux = new QueueOperation<T>("MOVE", nextTodo.item);

            //         operation_aux.target.index = 0;
            //         operation_aux.target.section = "current";

            //         instruction.push(operation_aux);
            //     }
            // }
        }
        else console.warn(`Attempt to close item that does not exists at queue ${item}`)

        return instruction;
    },




    /**
     * Put (add) item to the queue or 'move' it if exists there already
     */
    put<T>(item: T, queue: Queue<T>, target: TqueuePosition, autoshift:boolean): QueueOperation<T>[] {

        const instructions: QueueOperation<T>[] = []

        let itemAtTargetPosition = queues.itemOrNull(queue, target);

        if (target.section === "current") {

            if (itemAtTargetPosition != null) {

                //move current to 'queue:0'
                this.move(itemAtTargetPosition, queue, new TqueuePosition("queue", 0)).forEach(o => instructions.push(o));
            }

            itemAtTargetPosition = null;
        }

        const position = queues.position(queue, item);

        let operation: QueueOperation<T>;

        if (position != null) operation = new QueueOperation("MOVE", item)
        else operation = new QueueOperation("CREATE", item)

        operation.target = target;

        instructions.push(operation);

        if (position != null && autoshift) {
            const shiftInstruction = shiftIf(queue, position);

            if (shiftInstruction) instructions.push(shiftInstruction);
        }

        return instructions;
    },

    //it supposed just move items exactly where it was specified, WITHOU automatic shift of queu
    move<T>(item: T, queue: Queue<T>, target: TqueuePosition): QueueOperation<T>[] {

        const instructions: QueueOperation<T>[] = []

        const position = queues.position(queue, item);

        if (position == null) throw new Error('Item is not in queue')

        if (target.section === "current") {

            const itemAtTargetPosition = queues.itemOrNull(queue, target);

            if (itemAtTargetPosition != null) {

                //move current to 'queue:0'
                throw new Error('Current position is not empty')
            }
        }

        const operation: QueueOperation<T> = new QueueOperation("MOVE", item)

        operation.target = target;

        instructions.push(operation);

        return instructions;
    }
}

const Bprogramator = {

    /**
     * Put (add) item to the grid or 'move' it if exists there already
     */
    put<T>(board: (T | null)[][], value: T, target: BoardPosition): BoardOperation<T>[] {

        const result: BoardOperation<T>[] = [];

        const position = boards.positionOrNull(board, value);

        let operation: BoardOperation<T>;

        const current = boards.at(board, target);

        if (position != null) {

            if (current != null) {
                //'SWAP' operation

                result.push(new BoardOperation("CLOSE", current, target));
                result.push(new BoardOperation<T>("MOVE", value, target));
                result.push(new BoardOperation<T>("CREATE", current, position));
            }
            else result.push(new BoardOperation<T>("MOVE", value, target));
        }
        else {
            if (current != null) throw Error("Can't create ticket at place that is occupied already");

            result.push(new BoardOperation("CREATE", value, target))
        }

        return result;
    },

    close<T>(array: (T | null)[][], value: T, precondition_position?: BoardPosition): BoardOperation<T>[] {

        const position = boards.position(array, value) //fail if no such item at grid

        if (typeof precondition_position != "undefined") {

            if (position.column != precondition_position.column || position.row != precondition_position.column) throw Error(`Precondtiong positions fails ${precondition_position}`)
        }

        const operation = new BoardOperation("CLOSE", value);

        operation.target = position;

        //#someday as precondionts may work parent?, gap, column

        return [operation];
    },
}

export const programmator = {

    /**
     * Put ticket to project queue
     * @param ticket 
     * @param project 
     * @param position 
     * @returns 
     */
    queue(ticket: TicketRmodel, project: ProjectModel, position: TqueuePosition): InstructionOperation[] { //-only ticket - queu|pins

        const workspace = project.workspace;

        const instruction: (TicketQueueOperation | TicketBoardOperation)[] = [];

        //remove from 'project board' if exists (person 'captains' board is ok)
        this.boardCloseIf(workspace, ticket).forEach(o => instruction.push(o))

        const autoshift = false

        Qprogrammator.put(ticket, project.disposition, position, autoshift).forEach(o => instruction.push(new TicketQueueOperation(o, project)));

        return instruction;
    },

    queueClose(ticket: TicketRmodel, project: ProjectModel, autoshift:boolean): TicketQueueOperation[] {

        const insturction: TicketQueueOperation[] = [];

        Qprogrammator.close(ticket, project.disposition, autoshift).forEach(o => insturction.push(new TicketQueueOperation(o, project)));

        return insturction;
    },

    /**
     * Close ticket from project queue if it exists there
     * @param ticket ticket
     * @param project project queue
     * @param resolution NOT IMPTLEMENTED YET!
     * @returns 
     */
    queueCloseIf(target: WorkspaceRmodel | ProjectModel, ticket: TicketRmodel): TicketQueueOperation[] {

        const insturction: TicketQueueOperation[] = [];

        let matches = 0;

        if (target instanceof WorkspaceRmodel) {

            target.projects().forEach(project => {

                this.queueCloseIf(project, ticket).forEach(o => insturction.push(o));
            });
        }
        else {

            const project = target;

            const position = queues.position(project.disposition, ticket)

            if (position != null) {

                matches++;

                const autoshift = false
                Qprogrammator.close(ticket, project.disposition, autoshift, position).forEach(o => insturction.push(new TicketQueueOperation(o, project)));
            }
        }

        if (matches > 1) throw Error('Multiple ticket appearences at project queue'); //#someday better processing?

        return insturction;
    },

    board(ticket: TicketRmodel, target: ProjectModel | PersonRmodel, target_position: BoardPosition): InstructionOperation[] {

        const result: InstructionOperation[] = [];

        if (target instanceof ProjectModel) {

            const project: ProjectModel = target;

            this.queueCloseIf(project, ticket).forEach(o => result.push(o));

            Bprogramator.put<TicketRmodel>(project.disposition.board, ticket, target_position).forEach(o => {

                const buffer = new TicketBoardOperation(o);

                buffer.projectUpdate(project);

                result.push(buffer);
            });
        }
        else if (target instanceof PersonRmodel) {

            const person: PersonRmodel = target;

            Bprogramator.put<TicketRmodel>(person.board.captain, ticket, target_position).forEach(o => {

                const buffer = new TicketBoardOperation(o);

                buffer.personUpdate(person);

                result.push(buffer);
            });
        }
        else throw Error(`Unkown target type: ${target}`)

        return result;
    },

    boardClose(ticket: TicketRmodel, target: ProjectModel | PersonRmodel): TicketBoardOperation[] //board - null for project, 'person' for personal
    {
        const instruction: TicketBoardOperation[] = [];

        if (target instanceof PersonRmodel) {

            const person = target;

            //project board

            Bprogramator.close<TicketRmodel>(person.board.captain, ticket).forEach(o => {

                const operation = new TicketBoardOperation(o);

                operation.personUpdate(person);

                instruction.push(operation);
            })



            return instruction;
        }


        if (target instanceof ProjectModel) {

            //project board
            const project = target;

            const position = boards.position(project.disposition.board, ticket)

            Bprogramator.close<TicketRmodel>(project.disposition.board, ticket).forEach(o => {

                const operation = new TicketBoardOperation(o);

                operation.projectUpdate(project);

                instruction.push(operation);

            });

            return instruction;
        }

        throw Error('ticketClose_Insruction')
    },

    boardCloseIf(workspace: WorkspaceRmodel, ticket: TicketRmodel): (TicketBoardOperation)[] {


        const instruction: (TicketBoardOperation)[] = [];

        let matches = 0;

        workspace.projects().forEach(p => {

            const position = boards.positionOrNull(p.disposition.board, ticket);

            if (position != null) {

                //here we remove corresponding TICKET from PROJECT level queue
                programmator.boardClose(ticket, p).forEach(o => instruction.push(o));

                matches++;
            }

        });

        if (matches > 1) throw Error('Multiple ticket appearences at project queue'); //#someday better processing?

        return instruction;
    },

    todoBoard(todo: TodoRmodel, person: PersonRmodel, position: BoardPosition): (TodoBoardOperation | TicketQueueOperation)[] {

        const result: (TodoBoardOperation | TicketQueueOperation)[] = [];

        const workspace = person.workspace;


        this.queueCloseIf(workspace, todo.task.ticket);


        Bprogramator.put<TodoRmodel>(person.board.person, todo, position).forEach(o => {

            const operation = new TodoBoardOperation(o, person);

            //operation.

            result.push(operation);
        })

        return result;
    },

    todoBoardClose(todo: TodoRmodel, person: PersonRmodel): TodoBoardOperation[] {

        const instruction: TodoBoardOperation[] = [];

        Bprogramator.close(person.board.person, todo).forEach(o => instruction.push(new TodoBoardOperation(o, person)));

        return instruction;
    },

    //'person tasks - queue'
    todoQueue(todo: TodoRmodel, person: PersonRmodel, target: TqueuePosition/*, autoshift?:boolean*/): (TicketBoardOperation | TicketQueueOperation | TodoQueueOperation)[] {

        const instruction: (TicketBoardOperation | TicketQueueOperation | TodoQueueOperation)[] = [];
        //remove from 'project board' if exists (person 'captains' board is ok)
        this.queueCloseIf(person.workspace, todo.task.ticket).forEach(o => instruction.push(o));

        //const autoshiftable = queueAutoShift(person)

        //const autoshift_param =  (autoshift ?? true) && autoshiftable

        //if (disableAutoshift != undefined && disableAutoshift) autoshiftable  = false

        Qprogrammator.put(todo, person, target, false/*autoshift_param*/).forEach(o => instruction.push(new TodoQueueOperation(o, person)));

        return instruction;
    },

    todoCurrentClean(person: PersonRmodel): (TicketBoardOperation | TicketQueueOperation | TodoQueueOperation)[] {

        const instruction: (TicketBoardOperation | TicketQueueOperation | TodoQueueOperation)[] = [];

        Qprogrammator.cleanCurrentIf(person).forEach(o => instruction.push(new TodoQueueOperation(o, person)))

        return instruction
    },

    //remove from PERSON queue
    todoQueueClose(person: PersonRmodel, todo: TodoRmodel/*, autoshift:boolean*/): TodoQueueOperation[] { //'entity reference' - task, verfication, block + person (for 'v' and 'b' as double check)

        const instruction: TodoQueueOperation[] = [];

        //const autoshiftable = queueAutoShift(person)

        //const autoshift_param =  (autoshift ?? true) && autoshiftable

        Qprogrammator.close(todo, person, false /*autoshift_param*/).forEach(o => instruction.push(new TodoQueueOperation(o, person)))

        return instruction;
    },
}