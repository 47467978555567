import { TaskRmodel } from "@/app.context/workspace/tickets/task.rmodel";
import { Resolution } from "@/logic/workspaces/tickets/_def";
import * as array from '@/lib/array.lib'

export function taskStatusCss(t:TaskRmodel){

    if (t.resolution == Resolution.Done) return 'todo-done'
    if (t.resolution == Resolution.Failed) return 'todo-failed'

    if ( t.blocks.filter(x=>x.resolution == null && x.person != t.person).length > 0) return 'todo-blocked'

    if (t.verifications.filter(x=>x.resolution == null).length > 0) return 'todo-verification'

    return ''
}