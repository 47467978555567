import * as signalR from '@microsoft/signalr';
import { IWorkspaceStorage } from '@/io/storages';
import { Guid } from '@/lib';
import { InstructionOperationDto } from '@/io/storages/workspace/_def';
import { Entity } from '@/logic/workspaces/_tech';
import { ITimepointTune, Sunrise, TimeSegment } from '@/logic/_infra/time';
import { Appointment } from '@/logic/workspaces/appointment';
import sys from '@/sys';
import { IMeffortDto, ITimespendingDto } from '@/io/dto/workspaces/persons/effort.dto';
import { Timespending } from '@/app.context/workspace/Event';
import { timeSegmentDto } from '@/io/dto/infrastructure.dto.ns';

//assumes that connection seats on workspace wid
export default function workspaceSignalrStorage(hubConnection: signalR.HubConnection): IWorkspaceStorage {

    class S implements IWorkspaceStorage
    {

        personTimespendingDelete(tid: Guid, wid: Guid, nid: Guid) : void {

            hubConnection.invoke("personTimespendingDelete", tid, wid, nid).catch(sys.exception);
        }

        personTimespendingCreate(tid: Guid, wid: Guid, nid: Guid, ts: Timespending): void {
        

            const args: ITimespendingDto = {
    
                tag: '-',
            
                //--
            
                tid: ts.tid,
            
                segment: timeSegmentDto(ts.segment),
            
                //--
            
                name: ts.objects.title,
                
                //--
            
                category: ts.objects.category,
            
                //--
            
                ticket: ts.objects.ticket?.n ?? 0,
            
                type:  ts.objects.todo_type, 
            
                person_aux: ts.objects.todo_person_aux,
            
                //
                
                appointment:  null,

                groups: ts.objects.groups.map(x=>x.uid),

                persons: ts.objects.persons.map(x=>x.nid),
            
                
            
            }
            
            hubConnection.invoke("personTimespendingCreate", tid, wid, nid, args).catch(sys.exception);
        }

        personTimelineTunesUpdate(wid: Guid, nid: Guid, tunes: ITimepointTune[]): void {
            
            //throw new Error('Method not implemented.');

            // eslint-disable-next-line
            hubConnection.invoke("tunes", wid, nid, tunes.map(t=>{return {timepoint:t.timepoint, edge:t.edge, value:t.value}}) ).catch(sys.exception);
        }

        personTimespendingsUpdate(wid:Guid, nid:Guid, timespendings: { id: Guid; start: Date; end: Date; }[]): void {
            
            // eslint-disable-next-linen 
            hubConnection.invoke("timespendings", wid, nid, timespendings.map(t=>{ return {id:t.id, start:t.start, end:t.end}})).catch(sys.exception);
            //
        }

        disconnect() {
            
            return hubConnection.stop();
        }
        
        transactionSubmit(wid: Guid, instruction: InstructionOperationDto[]): void {
            
            hubConnection.invoke("transactionSubmit", wid, instruction).catch(sys.exception)
        }
        
        //XBQ
        
        taskCreate(wid: Guid, n:number, nid: Guid, index: number): void {
            
            hubConnection.invoke("taskCreate", wid, n, nid, index).catch(sys.exception);
        }
        
        taskBlockCreate(wid: Guid, n: number, nid: Guid, nids: (Guid | null)[], position:number, instruction: InstructionOperationDto[]): void {

            hubConnection.invoke("taskBlockCreate", wid, n, nid, nids, position, instruction).catch(sys.exception);
        }
        



        //Persons/Events & Appointments

        eventTimespanUpdate(wid: Guid, nid: Guid, tid: Guid, minutes: number): void {

            hubConnection.invoke("eventTimespanUpdate", wid, nid, tid, minutes).catch(sys.exception);
        }

        appointmentCreate(wid: Guid, appointment: Appointment): void {
            
            hubConnection.invoke("workspaceAppointmentUpsert", wid, appointment).catch(sys.exception);
        }

        //WORKSPACE

        commandSubmit(command: string, wid: Guid, entity: Entity, id: Guid): void {

            const entity_char: string = entity //'X';

            hubConnection.invoke("commandSubmit", wid, entity_char, id, command).catch(sys.exception);
        }

        //TICKET

        commandSubmitOnTicket(command: string, wid: Guid, n: number): void {

            hubConnection.invoke("commandSubmitOnTicket", wid, n, command, new Date()).catch(sys.exception);
        }

        //PERSON

        personStrictnessUpdate(wid: Guid, nid: Guid, value: boolean): void {

            hubConnection.invoke("personStrictnessUpdate", wid, nid, value).catch(sys.exception);
        }

        personEndeavoursUpdate(wid: Guid, nid: Guid, sunrise: Sunrise, values: number[], offset:number): void {

            hubConnection.invoke("personEndeavoursUpdate", wid, nid, sunrise, values, offset).catch(sys.exception);
        }

        personCalendarAdjustmentUpdate(wid: Guid, nid: Guid, sunrise: Sunrise, value: number | null, tag?: string): void {

            hubConnection.invoke("personCalendarAdjustmentUpdate", wid, nid, sunrise, value, tag ?? null).catch(sys.exception);
        }

        //: void;
        
        personStopUpdate(wid: Guid, nid: Guid, value: boolean,): void {


            hubConnection.invoke("personStopUpdate", wid, nid, value).catch(sys.exception);
        }

        file(wid:Guid, n:number, fileidentificator:string, name:string, url:string){

            return hubConnection.invoke("file", wid, n, fileidentificator, name, url).catch(sys.exception); 
        }

        resync(): void {
            
            
        }

        personEffortReport(nid: Guid) {
            
            return hubConnection.invoke("personEffortReport", nid).catch(sys.exception); 
        }

        workspace(wid: Guid) {
            
            return hubConnection.invoke("workspace", wid).catch(sys.exception); 
        }

        personMeffortCreate(mid: Guid, wid: Guid, nid: Guid, period: Date, minutes: number, timepoint: Date | null, entity: IMeffortDto) : void {

            hubConnection.invoke("personMeffortCreate", mid, wid, nid, period, minutes, timepoint, entity).catch(sys.exception); 
            
        }

        personMeffortUpdate(mid: Guid, wid: Guid, nid: Guid, minutes: number, timepoint: Date | null) : void{

            hubConnection.invoke("personMeffortUpdate", mid, wid, nid, minutes, timepoint).catch(sys.exception); 

        }

        //personMeffortUpdate(Guid mid, Guid wid, Guid nid, int minutes, DateTime? timepoint)
        
        personMeffortDelete(mid: Guid, wid: Guid, nid: Guid): void {

            hubConnection.invoke("personMeffortDelete", mid, wid, nid).catch(sys.exception); 

        }

        categoryCreate(wid: Guid, name: string,  nid: Guid | null): void {

            hubConnection.invoke("categoryCreate", wid, name, nid).catch(sys.exception); 

        }
      


    }

    return new S()
}