import { TicketBoardOperationDto } from "@/io/storages/workspace/TicketBoarOperation";
import { TicketQueueOperationDto } from "@/io/storages/workspace/TicketQueueOperation";
import { TodoBoardOperationDto } from "@/io/storages/workspace/TodoBoardOperation";
import { TodoQueueOperationDto } from "@/io/storages/workspace/TodoQueueOperation";
import { BoardOperation, QueueOperation, TodoOperationType } from "@/io/storages/workspace/_def";
import { Guid } from "@/lib";
import { BoardPosition } from "@/logic/_infra/boards";
import { TodoSection, TqueuePosition } from "@/logic/_infra/queues";
import { PersonRmodel } from "./workspace/person.rmodel";
import { TodoRmodel } from "./workspace/tickets/todo.rmodel";
import { ProjectModel } from "./workspace/project.rmodel";
import { TicketRmodel } from "./workspace/ticket.rmodel";

export type InstructionOperation = TodoQueueOperation | TodoBoardOperation | TicketQueueOperation | TicketBoardOperation /*| EntityCreationDto*/


export class TicketBoardOperation {
    
    test() {

        if (this.objects.person === null && this.objects.proejct === null)
        throw new Error("Ticket board operation has not project/person specified");
    }




    //filled on clien side
    objects: { person: PersonRmodel | null, ticket: TicketRmodel, proejct: ProjectModel | null }
    operation: BoardOperation<TicketRmodel>;

    constructor(operation: BoardOperation<TicketRmodel>) {

        //this.objects.ticket = operation.object;

        this.objects = { person: null, ticket: operation.object, proejct: null }
        this.operation = operation;

    }

    get nid(): Guid|null{
        return this.objects?.person?.nid ?? null;
    }

    get target(): BoardPosition {
        return this.operation.target;
    }

    get name(): TodoOperationType{
        return this.operation.name;
    }

    get n():number { return this.objects.ticket.n}

    personUpdate(person: PersonRmodel) {

        this.objects.person = person;

        
    }

    projectUpdate(project: ProjectModel) {

        this.objects.proejct = project;

        
    }



    dto(): TicketBoardOperationDto {

        const buffer = new TicketBoardOperationDto(this.operation);

        if (this.objects.person != null) buffer.nid = this.objects.person.nid;

        if (this.objects.proejct != null) buffer.pid = this.objects.proejct.pid;

        return buffer;
    }
}

export class TodoQueueOperation  {

    constructor(operation: QueueOperation<TodoRmodel>, person: PersonRmodel) {

        //super('TodoQueueOperation')

        //this.objects.todo = operation.object;
        //this.objects.person = person;
        this.operation = operation;

        this.objects = {/*person:person, */todo:operation.object};


        if (operation.object.person != person) Error('Self-validation fail 20230117E')
        
    }

    //filled on clien side
    objects: { /*person: PersonRmodel,*/ todo: TodoRmodel}

    readonly operation : QueueOperation<TodoRmodel>
    
    //name
    //target

    get name(): string {

        return this.operation.name;
    }

    get target(): TqueuePosition { return this.operation.target;}

    dto(): TodoQueueOperationDto {


        const result = new TodoQueueOperationDto(this.operation /*, this.objects.person*/);

        

        return result;

    }
}



export class TodoBoardOperation {

    readonly operation: BoardOperation<TodoRmodel>;




    constructor(operation: BoardOperation<TodoRmodel>, person: PersonRmodel) {


        this.objects = { person, todo: operation.object }
        this.operation = operation;

        //this.target = operation.target;


    }

    get target(): BoardPosition {
        return this.operation.target;
    }

    get name(): string {
        return this.operation.name;
    }

    get nid(): Guid {
        return this.objects.person.nid;
    }




    objects: { person: PersonRmodel, todo: TodoRmodel }

    dto(): TodoBoardOperationDto {

        return new TodoBoardOperationDto(this.operation, this.objects.person);

    }
}

export class TicketQueueOperation /*extends DispositionOperation<TqueuePosition>*/ {
    operation: QueueOperation<TicketRmodel>;

    constructor(operation: QueueOperation<TicketRmodel>, project: ProjectModel) {

        this.objects = { ticket: operation.object, project }

        this.operation = operation;

    }

    objects: { ticket: TicketRmodel, project: ProjectModel }

    get name():TodoOperationType {return this.operation.name;}

    get target():TqueuePosition {return this.operation.target;}


    //што было ці будзе замест таргета раней ці пузней у залежнасці ад кантэкста
    precondition: { n: number, section: TodoSection, index: number } | null = null;

    dto(): TicketQueueOperationDto {

        return new TicketQueueOperationDto(this.operation, this.objects.project!)
    }

}