import { TicketItemModel } from "@/app.context/workspace/tickets/_spec/Ticket.Item.Model"
import sys from "@/sys"
import { reactive } from "vue"

export function descriptionSubmit(model: TicketItemModel, description: string, state: { edit: { description: boolean } }) {

    const p = model.pack

    if (p != null) p.description = description

    if (model.ticket != null) {

        const ticket = model.ticket

        if (model.storage != null) {
            model.storage!.description(ticket.workspace.wid, ticket.n, description)
        }
        else sys.warn('storage is null')
    }

    state.edit.description = false

}

export function stateObject(model: TicketItemModel){

    const state: { blocking: boolean, workableOption: boolean, edit:{name: boolean, description:boolean} } = reactive({ blocking: false, workableOption: model.todo != model.todo?.person.current, edit:{name:false, description:false}})

    return state
}
