import { onMounted } from 'vue'
import AppContext from '@/context';
import { Sunrise } from '@/logic/_infra/time';
import { EffortSummary, SunriseReportRmodel } from '@/app.context/workspace/persons/sunrise.rmodel';
import { context_workspace } from '@/_dev/config'
import { Guid } from '@/lib';
import { Timeffort } from '@/app.context/workspace/persons/sunrises/effort.rmodel';
import { TodoRmodel } from '@/app.context/workspace/tickets/todo.rmodel';
import { TodoRef } from '@/logic/workspaces/_tech';
import { Category, EffortType } from '@/logic/workspaces/persons/_def';
import { OccupationtRef, EffortResolution } from '@/logic/workspaces/tickets/_def';
import { TodoItemType } from '@/logic/workspaces/tickets/todos/_def.todos';
//import { f } from '@/_dev/lab/_lab'

export default function bootstrap() {


    return { lab, test, setup, personMeffort_Lab }
}


async function personMeffort_Lab() {

    console.log('personMeffortCreate - Lab')

    if (context == null) console.error('null context at lab')
    else 
    {
        const storage = context.storages.workspace

        if (storage != null){

            //const mid = Guid.newGuid() //F99A3EBB-B7B1-40A5-8D3A-F5B15815528B
            const mid = 'F99A3EBB-B7B1-40A5-8D3A-F5B15815528B'

            const person = context.cursor.person!

            const date = new Date()

            const timepoint = new Date('2024-01-12 10:50:00')

            //storage.personMeffortCreate(mid, person.workspace.wid, person.nid, date, 60, timepoint, {ticket:1, category:'A', type: "B", person_aux: person.nid})
            
            
            //storage.personMeffortUpdate(mid, person.workspace.wid, person.nid, 75, timepoint)

            //storage.personMeffortDelete(mid, person.workspace.wid, person.nid)


        }
        else console.error('null workspace storage')

    }




}

let context: AppContext | null = null



async function setup(): Promise<void> {

    context = await context_workspace()
}

async function lab(){


    //const map = new Map<{a:number, b:number}, string>()
    const map = new Map<string, string>()

    
    

    const k1 = {a:1, b:1}

    map.set(JSON.stringify(k1),'v1')

    const k2 = {a:1, b:1}

    if (map.has(JSON.stringify(k2))) console.log(`map has value - ${map.get(JSON.stringify(k2))}`)

    else console.log('no item')

    const k3 =  <{a:number, b:number}>JSON.parse(JSON.stringify(k2))

    console.log(k3)


}


async function test(model: { efforts: SunriseReportRmodel | null }) {

    

    
    //console.log

    if (context == null) console.warn('context is not initialized')
    if (context == null) return

    

    //await context_workspace()

    // const initializer = 
    // {
    //     wid : '3D5C82B9-794A-43EF-89C0-546AA0D7D8F3',
    //     nid : '11633E2F-AFC3-4463-BC0B-F38B6343D3C5',
    //     person : 'alice'
    // }

    //const wid = ''
    //const nid = '' //453, alice

    const person = context.cursor.person!;

    //     2023-05-03
    // 2023-05-04
    // 2023-05-12
    // 2023-05-13
    // 2023-05-14
    // 2023-05-15
    // 2023-05-16
    // 2023-05-18
    // 2023-05-19

    // 2023-09-03

    // 2023-09-09
    // 2023-09-10
    // 2023-09-11

    // 2023-10-17

    const sunrise = new Sunrise(2024, 1, 12)
    //const sunrise = new Sunrise(2023, 9, 11)

    //2024-01-12 16:16:00


    //read events, tasks, operations and endeavours on $date
    context.service.personEfforts(person.nid, sunrise)
        .then(efforts => {

            const r = SunriseReportRmodel.Instance(person, efforts.period, efforts, context!)

            model.efforts = r

            const result = r.summary

            //const result =   f(r)


            //--//

            console.log('PRIMAR OUTPUT')

            result.toConsole()

            //console.log('TEST CLICK ')

            //result.click("F","E", result.timeline[1], null, null)

            //result.toConsole()





        })


}

